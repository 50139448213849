<script setup>
    import { ref, watch } from 'vue';
    import AISummarizer from '../AISummarizer.vue';
    import { useDisplay } from 'vuetify';
    const { smAndDown } = useDisplay();

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        initialRecord: {
            type: Object,
            default: null,
        },
        validationErrors: {
            type: Object,
            default: {},
        },
        narrowMode: {
            type: Boolean,
            default: false,
        },
    });

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';

    const optionsStore = useOptionsStore();
    const {
        fetchCorporationTypeOptions,
        fetchDeveloperOptions,
        fetchManagementCompanyOptions,
        fetchProvinceOptions,
    } = optionsStore;

    const {
        corporationTypeOptions,
        developerOptions,
        managementCompanyOptions,
        provinceOptions,
    } = storeToRefs(optionsStore);

    // Fetch options
    fetchCorporationTypeOptions();
    fetchDeveloperOptions();
    fetchManagementCompanyOptions();
    fetchProvinceOptions();

    /**************************************************************
     *      Form
     **************************************************************/
    const record = ref({
        name: '',
        plan_number: '',
        type_id: '',
        registration_year: '',
        address: '',
        city: '',
        province_id: '',
        postal: '',
        unit_factors: 10000,
        residential_units: '',
        commercial_units: '',
        parking_units: '',
        storage_units: '',
        corporation_owned_units: '',
        owner_occupancy: '',
        square_footage: '',
        number_of_floors: '',
        has_interim_board: false,
        new_home_warranty_policy_start_date: '',
        is_bareland: false,
        has_post_tension_cables: false,
        year_built_approx: '',
        developer_id: '',
        management_company_id: '',
        management_agreement_date: '',
        bylaws_are_registered: false,
        bylaws_raw_ai: '',
        bylaws_summary_ai: '',
        storage_notes: '',
        notes: '',
    });

    if (props.initialRecord) {
        record.value = { ...props.initialRecord };
    }

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/
    watch(
        [
            corporationTypeOptions,
            developerOptions,
            managementCompanyOptions,
            provinceOptions,
        ],
        () => {
            if (props.initialRecord) {
                record.value.type_id = props.initialRecord.type_id;
                record.value.management_company_id =
                    props.initialRecord.management_company_id;
                record.value.developer_id = props.initialRecord.developer_id;
                record.value.province_id = props.initialRecord.province_id;
                emitUpdate();
            }
        }
    );

    const handleSelectUpdate = ($event, field) => {
        record.value[field] = $event;
        emitUpdate();
    };

    /**************************************************************
     *      Emit Form Update
     **************************************************************/
    const emit = defineEmits(['formUpdated']);
    const emitUpdate = () => {
        emit('formUpdated', record.value);
    };
</script>

<template>
    <form
        :class="
            smAndDown
                ? 'grid-cols-1'
                : narrowMode
                  ? 'grid-cols-2'
                  : 'grid-cols-2'
        "
        class="grid gap-x-8 gap-y-2"
    >
        <div class="col-span-full text-xl font-semibold mb-4">
            Basic Information
        </div>

        <v-text-field
            @input="emitUpdate"
            v-model="record.name"
            label="Name"
            type="text"
            required
            outlined
            :error="validationErrors.name ? true : false"
            :error-messages="validationErrors.name"
        />

        <v-text-field
            @input="emitUpdate"
            v-model="record.plan_number"
            label="Plan Number"
            type="text"
            required
            outlined
            :error="validationErrors.plan_number ? true : false"
            :error-messages="validationErrors.plan_number"
        />

        <v-autocomplete
            @update:menu="emitUpdate"
            v-model="record.type_id"
            label="Type"
            placeholder="Type"
            :items="corporationTypeOptions"
            required
            outlined
            :error="validationErrors.type_id ? true : false"
            :error-messages="validationErrors.type_id"
        />

        <v-autocomplete
            v-model="record.registration_year"
            label="Corporation Registration Year"
            placeholder="Corporation Registration Year"
            :items="
                Array.from(
                    { length: 110 },
                    (_, i) => new Date().getFullYear() - i + 1
                )
            "
            :error="validationErrors.registration_year ? true : false"
            :error-messages="validationErrors.registration_year"
        />

        <div class="col-span-full text-xl font-semibold my-4">
            Corporation's Address
        </div>
        <div class="col-span-full text-sm italic mb-2">
            Do not include unit number in address - this is specific to the
            corporation address
        </div>

        <v-text-field
            @input="emitUpdate"
            v-model="record.address"
            label="Address"
            type="text"
            required
            outlined
            :error="validationErrors.address ? true : false"
            :error-messages="validationErrors.address"
        />

        <v-text-field
            @input="emitUpdate"
            v-model="record.city"
            label="City"
            type="text"
            placeholder="Corporation Type"
            required
            outlined
            :error="validationErrors.city ? true : false"
            :error-messages="validationErrors.city"
        />

        <v-autocomplete
            @update:model-value="handleSelectUpdate($event, 'province_id')"
            v-model="record.province_id"
            label="Province"
            placeholder="Province"
            :items="provinceOptions"
            required
            outlined
            clearable
            :error="validationErrors.province_id ? true : false"
            :error-messages="validationErrors.province_id"
        />

        <v-text-field
            @input="emitUpdate"
            v-model="record.postal"
            label="Postal"
            type="text"
            required
            outlined
            :error="validationErrors.postal ? true : false"
            :error-messages="validationErrors.postal"
        />

        <div class="col-span-full text-xl font-semibold my-4">
            Unit Factors/Entitlement
        </div>

        <v-text-field
            @input="emitUpdate"
            v-model="record.unit_factors"
            label="Total Corporation Unit Factors"
            type="number"
            required
            outlined
            :error="validationErrors.unit_factors ? true : false"
            :error-messages="validationErrors.unit_factors"
        />

        <v-text-field
            @input="emitUpdate"
            v-model="record.residential_units"
            label="Residential Units"
            type="number"
            required
            outlined
            :error="validationErrors.residential_units ? true : false"
            :error-messages="validationErrors.residential_units"
        />

        <v-text-field
            @input="emitUpdate"
            v-model="record.commercial_units"
            label="Commercial Units"
            type="number"
            required
            outlined
            :error="validationErrors.commercial_units ? true : false"
            :error-messages="validationErrors.commercial_units"
        />

        <v-text-field
            @input="emitUpdate"
            v-model="record.parking_units"
            label="Parking Units"
            type="number"
            required
            outlined
            :error="validationErrors.parking_units ? true : false"
            :error-messages="validationErrors.parking_units"
        />

        <v-text-field
            @input="emitUpdate"
            v-model="record.storage_units"
            label="Storage Units"
            type="number"
            required
            outlined
            :error="validationErrors.storage_units ? true : false"
            :error-messages="validationErrors.storage_units"
        />

        <v-text-field
            @input="emitUpdate"
            v-model="record.corporation_owned_units"
            label="Corporation Owned Units"
            type="number"
            required
            outlined
            :error="validationErrors.corporation_owned_units ? true : false"
            :error-messages="validationErrors.corporation_owned_units"
        />

        <v-text-field
            @input="emitUpdate"
            v-model="record.owner_occupancy"
            label="Owner Occupancy (Of Total Livable Units)"
            type="number"
            required
            outlined
            :error="validationErrors.owner_occupancy ? true : false"
            :error-messages="validationErrors.owner_occupancy"
        />

        <v-text-field
            @input="emitUpdate"
            v-model="record.square_footage"
            label="Approx. Total Insured Square Footage"
            type="number"
            required
            outlined
            :error="validationErrors.square_footage ? true : false"
            :error-messages="validationErrors.square_footage"
        />

        <div class="col-span-full text-xl font-semibold my-4">
            Miscellaneous Information
        </div>

        <v-text-field
            @input="emitUpdate"
            v-model="record.number_of_floors"
            label="Number of Floors (If Applicable)"
            type="number"
            required
            outlined
            :error="validationErrors.number_of_floors ? true : false"
            :error-messages="validationErrors.number_of_floors"
        />

        <v-checkbox
            @input="emitUpdate"
            v-model="record.has_interim_board"
            label="Has Interim Board"
            required
            :error="validationErrors.has_interim_board ? true : false"
            :error-messages="validationErrors.has_interim_board"
        />

        <v-text-field
            @input="emitUpdate"
            v-model="record.new_home_warranty_policy_start_date"
            label="New Home Warranty Policy Start Date (If Applicable)"
            type="date"
            required
            outlined
            :error="
                validationErrors.new_home_warranty_policy_start_date
                    ? true
                    : false
            "
            :error-messages="
                validationErrors.new_home_warranty_policy_start_date
            "
        />

        <v-checkbox
            @input="emitUpdate"
            v-model="record.is_bareland"
            label="Is Bareland Condominium"
            required
            :error="validationErrors.is_bareland ? true : false"
            :error-messages="validationErrors.is_bareland"
        />

        <v-checkbox
            @input="emitUpdate"
            v-model="record.has_post_tension_cables"
            label="Has Post-Tension Cables"
            required
            :error="validationErrors.has_post_tension_cables ? true : false"
            :error-messages="validationErrors.has_post_tension_cables"
        />

        <div class="col-span-full text-xl font-semibold my-4">Development</div>

        <v-autocomplete
            @update:model-value="
                handleSelectUpdate($event, 'year_built_approx')
            "
            v-model="record.year_built_approx"
            label="Year Built"
            placeholder="Year Built"
            :items="
                Array.from(
                    { length: 100 },
                    (_, i) => new Date().getFullYear() - i
                )
            "
            required
            outlined
            :error="validationErrors.year_built_approx ? true : false"
            :error-messages="validationErrors.year_built_approx"
        />

        <v-autocomplete
            @update:model-value="handleSelectUpdate($event, 'developer_id')"
            v-model="record.developer_id"
            label="Developer"
            placeholder="Developer"
            :items="developerOptions"
            required
            clearable
            outlined
            :error="validationErrors.developer ? true : false"
            :error-messages="validationErrors.developer"
        />

        <div class="col-span-full text-xl font-semibold my-4">Management</div>

        <v-autocomplete
            @update:model-value="
                handleSelectUpdate($event, 'management_company_id')
            "
            v-model="record.management_company_id"
            label="Management Company"
            placeholder="Management Company"
            :items="managementCompanyOptions"
            required
            outlined
            clearable
            :error="validationErrors.management_company_id ? true : false"
            :error-messages="validationErrors.management_company_id"
        />

        <v-text-field
            @input="emitUpdate"
            v-model="record.management_agreement_date"
            label="Management Agreement Date"
            type="date"
            required
            outlined
            :error="validationErrors.management_agreement_date ? true : false"
            :error-messages="validationErrors.management_agreement_date"
        />

        <div class="col-span-full text-xl font-semibold my-4">
            Internal Notes
        </div>
        <v-textarea
            class="col-span-full"
            @input="emitUpdate"
            v-model="record.notes"
            label="Internal Notes"
            outlined
            :error="validationErrors.notes ? true : false"
            :error-messages="validationErrors.notes"
        />
    </form>
</template>
