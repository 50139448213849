<script setup>
    import { usePage } from '@inertiajs/vue3';
    import { computed } from 'vue';
    import { useDisplay } from 'vuetify';

    const page = usePage();
    const { smAndDown } = useDisplay();
    const review = computed(() => page.props.review);
</script>
<template>
    <div class="bg-[#131313] text-[#f3f3f3]">
        <div
            :class="smAndDown.value ? 'p-2' : 'p-8'"
            class="max-w-[1400px] mx-auto flex flex-col md:flex-row justify-between gap-2"
        >
            <div class="self-center">
                <div class="uppercase text-3xl md:text-5xl font-bold">
                    {{ review.corporation.name }}
                </div>
                <div class="text-lg md:text-2xl">
                    Financial Overview and Risk Assessment
                </div>
            </div>
            <div class="flex flex-col self-center">
                <div class="flex md:justify-center">
                    <img class="max-w-24 p-2" src="/imgs/cdr-light-1.png" />
                </div>
                <div class="md:flex items-end text-xs md:text-base">
                    Review #{{ review.review_case_number }}
                </div>
            </div>
        </div>
    </div>
</template>
