<script setup>
    import { ref, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';
    import { useToast } from 'vue-toast-notification';
    import axios from 'axios';

    const toast = useToast();
    const loading = ref(false);
    const validationErrors = ref({});
    const serverMessages = ref(null); // Holds messages from the server

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        corporation: Object,
        operatingAccount: Object,
    });

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        if (!file.value) {
            toast.open({
                message: 'Please select a file before submitting.',
                type: 'error',
            });
            return;
        }

        // Clear previous messages
        serverMessages.value = null;

        loading.value = true;
        const formData = new FormData();
        formData.append('file', file.value);

        axios
            .post(
                `/api/v1/corporations/${props.corporation.id}/operating-accounts/${props.operatingAccount.id}/details/import`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then(response => {
                const {
                    message,
                    successes,
                    errors,
                    expected_headers,
                    received_headers,
                } = response.data;

                // Display the main message inline
                serverMessages.value = {
                    type: 'success',
                    message,
                    successes,
                    errors,
                    expected_headers,
                    received_headers,
                };
            })
            .catch(error => {
                const errorMessage =
                    error?.response?.data?.message ||
                    'An unexpected error occurred.';
                const backendErrors = error?.response?.data?.errors || null;

                // Display the error messages inline
                serverMessages.value = {
                    type: 'error',
                    message: errorMessage,
                    errors: backendErrors,
                    expected_headers:
                        error?.response?.data?.expected_headers || null,
                    received_headers:
                        error?.response?.data?.received_headers || null,
                };
            })
            .finally(() => {
                loading.value = false;
            });
    };

    const file = ref(null);
    const handleFileChange = event => {
        const selectedFile = event.target.files[0];

        if (selectedFile && selectedFile.type !== 'text/csv') {
            toast.open({
                message: 'Only CSV files are allowed.',
                type: 'error',
            });
            file.value = null;
            return;
        }

        file.value = selectedFile;
    };

    /**************************************************************
     *      Modal Logic
     **************************************************************/
    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
        resetForm();
    };

    const resetForm = () => {
        file.value = null;
        validationErrors.value = {};
        serverMessages.value = null; // Clear messages when modal closes
    };

    watch(
        () => props.isOpen,
        isOpen => {
            if (isOpen) resetForm();
        }
    );
</script>

<template>
    <BaseModal
        :loading="loading"
        maxWidth="1280px"
        :isOpen="isOpen"
        saveText="UPLOAD FILE"
        cancelText="CLOSE"
        title="Upload Operating Account CSV File"
        @close="onClose"
        @save="onSave"
    >
        <!-- File Input -->
        <div class="mb-4">
            <v-file-input
                v-model="file"
                accept=".csv"
                label="Select a CSV file to upload"
                outlined
                dense
            ></v-file-input>
        </div>

        <!-- Server Messages -->
        <div v-if="serverMessages" class="mb-4">
            <div
                :class="{
                    'text-green-500': serverMessages.type === 'success',
                    'text-red-500': serverMessages.type === 'error',
                }"
                class="text-sm mb-2"
            >
                {{ serverMessages.message }}
            </div>
            <!-- Success Messages -->
            <ul
                v-if="serverMessages.successes"
                class="text-green-500 text-sm list-disc pl-5"
            >
                <li
                    v-for="(success, index) in serverMessages.successes"
                    :key="index"
                >
                    <strong>Row {{ success.row }}:</strong>
                    {{ success.component }} - {{ success.status }}
                </li>
            </ul>

            <!-- Error Messages -->
            <ul
                v-if="serverMessages.errors"
                class="text-red-500 text-sm list-disc pl-5"
            >
                <li
                    v-for="(error, index) in serverMessages.errors"
                    :key="index"
                >
                    <strong>Row {{ error.row }}:</strong>
                    {{ error.errors.join(', ') }}
                </li>
            </ul>

            <!-- Expected and Received Headers -->
            <div
                v-if="
                    serverMessages.expected_headers &&
                    serverMessages.received_headers
                "
                class="text-sm mt-4"
            >
                <p class="text-red-500 font-bold">
                    Please make sure you are using the provided template.
                </p>
                <div class="text-gray-600">
                    <span class="font-semibold">Expected Headers:</span>
                    <pre>{{ serverMessages.expected_headers.join(', ') }}</pre>
                </div>
                <div class="text-gray-600">
                    <span class="font-semibold">Received Headers:</span>
                    <pre>{{ serverMessages.received_headers.join(', ') }}</pre>
                </div>
            </div>
        </div>
    </BaseModal>
</template>
