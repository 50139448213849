<script setup>
    import { ref } from 'vue';
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    const { formatMoney } = useMoneyFormatter();
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Modal
     **************************************************************/
    import LevyModal from '../../Components/Modals/LevyModal.vue';
    const isModalOpen = ref(false);
    const selectedLevy = ref(null);

    const showUpdateModal = item => {
        selectedLevy.value = item;
        isModalOpen.value = true;
    };

    const showCreateModal = () => {
        selectedLevy.value = null;
        isModalOpen.value = true;
    };

    const closeModal = () => {
        isModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    const props = defineProps({
        corporation: Object,
    });

    const destroy = item => {
        if (
            !confirm(
                'Are you sure you want to delete this levy? This action cannot be undone.'
            )
        ) {
            return;
        }

        axios
            .delete(
                `/api/v1/corporations/${props.corporation.id}/levies/${item.id}`
            )
            .then(response => {
                toast.success('Levy Deleted Successfully');
                searchTrigger.value = Date.now(); // cause the table to reload
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.');
            });
    };

    /**************************************************************
     *      Table
     **************************************************************/

    const headers = [
        { title: 'Date', key: 'date', sortable: false },
        {
            title: 'Total',
            key: 'total_amount',
            value: item => formatMoney(item.total_amount),
            sortable: false,
        },
        { title: 'Reason', key: 'reason', sortable: false },
        {
            title: 'AI Generated',
            key: 'documents',
            value: item => (item.documents.length > 0 ? 'Yes' : 'No'),
            sortable: false,
        },
    ];

    const searchTrigger = ref(Date.now());

    const additionalParams = ref({
        search: '',
        type: null,
    });
</script>
<template>
    <div>
        <LevyModal
            :corporation="corporation"
            :isOpen="isModalOpen"
            :item="selectedLevy"
            @close="closeModal"
        />
        <TableViewManager
            title="Special Levies"
            :headers="headers"
            subtitle="This is where you can view a listing of a corporation's levies."
            :endpoint="`/api/v1/corporations/${corporation.id}/levies`"
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            hide-breadcrumbs
            :actions="[
                {
                    title: 'Action',
                    icon: 'mdi-pencil',
                    buttonText: 'Edit',
                    buttonColor: 'secondary',
                    action: item => showUpdateModal(item),
                },
                {
                    title: 'Delete',
                    icon: 'mdi-delete',
                    buttonText: 'Delete',
                    buttonColor: 'error',
                    action: item => destroy(item),
                },
            ]"
        >
            <template #actions>
                <v-btn color="primary" class="px-4" @click="showCreateModal">
                    Create New
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn>
            </template>
        </TableViewManager>
    </div>
</template>
