<script setup>
    import { ref, computed, watch } from 'vue';

    const emit = defineEmits(['refresh']);
    const loading = ref(false);

    const props = defineProps({
        review: {
            type: Object,
            required: true,
        },
    });

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';

    const optionsStore = useOptionsStore();
    const { fetchUserOptions } = optionsStore;
    const { userOptions } = storeToRefs(optionsStore);

    fetchUserOptions();

    const clientOptions = computed(() => {
        return userOptions.value.filter(user => user.is_client);
    });

    const contractorOptions = computed(() => {
        return userOptions.value.filter(user => user.is_contractor);
    });

    const agentOptions = computed(() => {
        return userOptions.value.filter(user => user.is_agent);
    });

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});
    const assignedClients = ref([]);
    const assignedContractors = ref([]);
    const assignedAgents = ref([]);

    /**************************************************************
     *      Watchers
     **************************************************************/
    // Populate assigned clients, contractors, and agents from review (initialization)
    watch(clientOptions, () => restoreAssignedUsersFromReview(), {
        immediate: true,
    });
    watch(contractorOptions, () => restoreAssignedUsersFromReview(), {
        immediate: true,
    });
    watch(agentOptions, () => restoreAssignedUsersFromReview(), {
        immediate: true,
    });

    function restoreAssignedUsersFromReview() {
        assignedClients.value = clientOptions.value
            .filter(client =>
                props.review.users.some(user => user.id === client.id)
            )
            .map(x => x.id);

        assignedContractors.value = contractorOptions.value
            .filter(contractor =>
                props.review.users.some(user => user.id === contractor.id)
            )
            .map(x => x.id);

        assignedAgents.value = agentOptions.value
            .filter(agent =>
                props.review.users.some(user => user.id === agent.id)
            )
            .map(x => x.id);
    }

    /**************************************************************
     *      Validation
     **************************************************************/
    function validateAtLeastOneClient() {
        if (assignedClients.value.length === 0) {
            validationErrors.value.clients =
                'At least one client must be assigned.';
            return false;
        }
        return true;
    }

    /**************************************************************
     *      Update Users Assigned to the Review
     **************************************************************/
    const updateAssignedUsers = () => {
        if (!validateAtLeastOneClient()) {
            toast.open({
                message: 'Please assign at least one client.',
                type: 'error',
            });
            return;
        }

        loading.value = true;

        const payload = {
            users: [
                ...assignedClients.value,
                ...assignedContractors.value,
                ...assignedAgents.value,
            ],
        };

        axios
            .post(`/api/v1/reviews/${props.review.id}/users`, payload)
            .then(() => {
                toast.open({
                    message: 'Users updated successfully!',
                    type: 'success',
                });

                emit('refresh');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                validationErrors.value = error.response?.data?.errors || {};
            })
            .finally(() => {
                loading.value = false;
            });
    };
</script>

<template>
    <div class="max-w-[1000px] p-4 border rounded-lg shadow-lg mb-12">
        <div class="flex flex-col gap-4">
            <div class="text-2xl font-bold mb-8">Assign Users</div>
            <v-autocomplete
                v-model="assignedClients"
                :items="clientOptions"
                label="Assign Clients"
                item-text="name"
                item-value="value"
                chips
                multiple
                :rules="[
                    () =>
                        assignedClients.length > 0 ||
                        'At least one client is required',
                ]"
            />
            <v-autocomplete
                v-model="assignedContractors"
                :items="contractorOptions"
                label="Assign Contractors"
                item-text="name"
                item-value="value"
                chips
                multiple
            />
            <v-autocomplete
                v-model="assignedAgents"
                :items="agentOptions"
                label="Assign Agents"
                item-text="name"
                item-value="value"
                chips
                multiple
            />
            <div>
                <v-btn
                    :loading="loading"
                    color="primary"
                    @click="updateAssignedUsers"
                >
                    Update Users
                </v-btn>
            </div>
            <span v-if="validationErrors.clients" class="error-text">
                {{ validationErrors.clients }}
            </span>
        </div>
    </div>
</template>
