<script setup>
    import { ref } from 'vue';
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    const { formatMoney } = useMoneyFormatter();
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Modal
     **************************************************************/
    import InsuranceCertificateModal from '../../Components/Modals/InsuranceCertificateModal.vue';
    const isModalOpen = ref(false);
    const selectedInsuranceCertificate = ref(null);

    const showUpdateModal = item => {
        selectedInsuranceCertificate.value = item;
        isModalOpen.value = true;
    };

    const showCreateModal = () => {
        selectedInsuranceCertificate.value = null;
        isModalOpen.value = true;
    };

    const closeModal = () => {
        isModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    const props = defineProps({
        corporation: Object,
    });

    const destroy = item => {
        if (
            !confirm(
                'Are you sure you want to delete this insurance certificate? This action cannot be undone.'
            )
        ) {
            return;
        }

        axios
            .delete(
                `/api/v1/corporations/${props.corporation.id}/insurance-certificates/${item.id}`
            )
            .then(response => {
                toast.success('Insurance Certificate Deleted Successfully');
                searchTrigger.value = Date.now(); // cause the table to reload
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.');
            });
    };

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = [
        {
            title: 'Insurance Company',
            key: 'insurance_company',
            sortable: false,
        },
        { title: 'Expiry Date', key: 'expiry_date', sortable: false },
        {
            title: 'AI Generated',
            key: 'documents',
            value: item => (item.documents.length > 0 ? 'Yes' : 'No'),
            sortable: false,
        },
        {
            title: 'Replacement Value',
            key: 'replacement_value',
            value: item => formatMoney(item.replacement_value),
            sortable: false,
        },
    ];

    const searchTrigger = ref(Date.now());

    const additionalParams = ref({
        search: '',
        type: null,
    });
</script>
<template>
    <div>
        <InsuranceCertificateModal
            :corporation="corporation"
            :isOpen="isModalOpen"
            :item="selectedInsuranceCertificate"
            @close="closeModal"
        />
        <TableViewManager
            title="Insurance Certificates"
            :headers="headers"
            subtitle="This is where you can view a listing of a corporation's insurance certificates."
            :endpoint="`/api/v1/corporations/${corporation.id}/insurance-certificates`"
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            hide-breadcrumbs
            :actions="[
                {
                    title: 'Action',
                    icon: 'mdi-pencil',
                    buttonText: 'Edit',
                    buttonColor: 'secondary',
                    action: item => showUpdateModal(item),
                },
                {
                    title: 'Delete',
                    icon: 'mdi-delete',
                    buttonText: 'Delete',
                    buttonColor: 'error',
                    action: item => destroy(item),
                },
            ]"
        >
            <template #actions>
                <v-btn color="primary" class="px-4" @click="showCreateModal">
                    Create New
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn>
            </template>
            <template #filters>
                <v-text-field
                    v-model="additionalParams.search"
                    label="Search"
                    dense
                    hide-details
                ></v-text-field>
            </template>
        </TableViewManager>
    </div>
</template>
