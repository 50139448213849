<script setup>
    import { ref, watch } from 'vue';
    import axios from 'axios';
    import BaseModal from '../BaseModal.vue';
    import AISummarizer from '../AISummarizer.vue';
    import { useToast } from 'vue-toast-notification';
    import { useClipboard } from '@vueuse/core';

    const props = defineProps({
        isOpen: Boolean,
        corporation: Object,
    });

    const minutes = ref([]);
    const toast = useToast();

    const { copy, copied, isSupported } = useClipboard({ legacy: true });

    const loadMinutes = () => {
        axios
            .get(
                `/api/v1/corporations/${props.corporation.id}/meeting-minutes/summary`
            )
            .then(response => {
                minutes.value = response.data;
            })
            .catch(error => {
                console.error(error);
                toast.error('Error loading meeting minutes.');
            });
    };

    watch(
        () => props.isOpen,
        isOpen => {
            if (isOpen) {
                loadMinutes();
            }
        }
    );

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };

    const openDocument = documentId => {
        window.open(`/api/v1/documents/${documentId}/download`, '_blank');
    };

    const copyRawText = async () => {
        const formattedText = minutes.value
            .map(
                minute => `
Meeting Date: ${new Date(minute.date).toLocaleDateString()}
Meeting Type: ${minute.type.charAt(0).toUpperCase() + minute.type.slice(1)}

${minute.notes_raw_ai}

____________________
`
            )
            .join('\n');

        const success = await copy(formattedText);
        if (success) {
            toast.success('Copied to clipboard!');
        } else {
            toast.error('Clipboard write failed.');
        }
    };

    const copySummarizedText = async () => {
        const formattedText = minutes.value
            .map(
                minute => `
Meeting Date: ${new Date(minute.date).toLocaleDateString()}
Meeting Type: ${minute.type.charAt(0).toUpperCase() + minute.type.slice(1)}

${minute.notes_summary_ai}

____________________
`
            )
            .join('\n');

        const success = await copy(formattedText);
        if (success) {
            toast.success('Copied to clipboard!');
        } else {
            toast.error('Clipboard write failed.');
        }
    };
</script>

<template>
    <BaseModal
        saveText="Done"
        :isOpen="isOpen"
        :record="{}"
        title="Summary of All Meeting Minutes"
        @close="onClose"
        @save="onClose"
    >
        <div class="p-4">
            <div v-if="!minutes.length" class="text-center text-gray-500">
                No meeting minutes available.
            </div>
            <div v-else class="space-y-6">
                <!-- <div class="flex justify-end space-x-2 mb-4">
                    <v-btn type="button" color="primary" @click.prevent="copyRawText">
                        Copy All Raw Text
                    </v-btn>
                    <v-btn type="button" color="primary" @click.prevent="copySummarizedText">
                        Copy All Summarized Text
                    </v-btn>
                </div> -->
                <div
                    class="border-b p-4"
                    v-for="minute in minutes"
                    :key="minute.id"
                >
                    <div class="flex items-center justify-between">
                        <h2 class="text-xl font-bold">
                            {{
                                minute.type.charAt(0).toUpperCase() +
                                minute.type.slice(1)
                            }}
                            Meeting
                        </h2>
                        <span class="text-gray-600">
                            {{ new Date(minute.date).toLocaleDateString() }}
                        </span>
                    </div>
                    <div>
                        <div class="ml-1 whitespace-pre-wrap">
                            {{ minute.notes_summary_ai }}
                        </div>
                    </div>
                    <div
                        v-if="minute.documents && minute.documents.length"
                        class="mt-4"
                    >
                        <p class="font-semibold mb-2">View Documents</p>
                        <div class="flex flex-wrap gap-2">
                            <v-btn
                                v-for="document in minute.documents"
                                :key="document.id"
                                color="primary"
                                @click="openDocument(document.id)"
                            >
                                <v-icon left>mdi-download</v-icon>
                                {{ document.original_name }}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseModal>
</template>
