<script setup>
    import { watch } from 'vue';

    const props = defineProps({
        errorMessages: {
            type: String,
            default: '',
        },
        error: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: [Number, String],
            default: 0,
        },
        config: {
            type: Object,
            default: () => ({}),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    });

    import { useCurrencyInput } from 'vue-currency-input';
    const defaultConfig = {
        currency: 'CAD',
        locale: 'en-CA',
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false,
        precision: 2,
        valueRange: { min: 0 },
        prefix: '',
        suffix: '',
        thousands: ',',
        decimal: '.',
        disableNegative: false,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: false,
        focusOnRight: false,
    };

    const { inputRef, formattedValue, setValue } = useCurrencyInput({
        ...defaultConfig,
        ...props.config,
    });

    watch(
        () => props.modelValue,
        value => {
            setValue(value);
        }
    );

    const emit = defineEmits(['update:modelValue', 'input', 'change']);
</script>

<template>
    <vTextField
        v-bind="{ ...$attrs }"
        ref="inputRef"
        v-model.lazy="formattedValue"
        :disabled="disabled"
        :loading="isLoading"
        :error="error"
        :error-messages="errorMessages"
    ></vTextField>
</template>
