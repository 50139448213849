<script setup>
    import { ref, computed } from 'vue';
    import { usePage } from '@inertiajs/vue3';

    const page = usePage();
    const meetingMinutes = ref([]);

    meetingMinutes.value = page.props.review.corporation.meeting_minutes.map(
        meetingMinute => {
            return {
                id: meetingMinute.id,
                type: meetingMinute.type,
                date: meetingMinute.date,
                notesRaw: meetingMinute.notes_raw_ai,
                notesSummary: meetingMinute.notes_summary_ai,
            };
        }
    );

    const visibleCount = ref(8);

    const visibleMeetingMinutes = computed(() => {
        return meetingMinutes.value.slice(0, visibleCount.value);
    });

    const showMore = () => {
        visibleCount.value += 8;
    };
</script>

<template>
    <div class="max-w-[1400px] mx-auto px-8 my-24">
        <div class="text-2xl md:text-4xl font-bold mb-8">
            Meeting Minutes
            <span v-if="meetingMinutes.length > 0">
                ({{ meetingMinutes.length }})
            </span>
        </div>
        <div class="flex flex-col md:flex-row gap-12 my-12">
            <div class="max-w-[800px] self-center">
                Below is a list of summarized board meeting minutes and annual
                general meetings that have been reported to us.
            </div>
            <p
                class="max-w-[600px] self-center md:border-l-2 md:pl-2 text-sm bg-gray-50 p-2"
            >
                Meeting minutes provide direct insight into the corporation's
                operation. It is crucial that potential buyers review and
                understand the meeting minutes to ensure they are informed. Here
                we've summarized the key points of each meeting minute.
            </p>
        </div>
        <div v-if="meetingMinutes.length == 0" class="text-2xl font-bold">
            No meeting minutes have been reported to us as of the date of this
            review.
        </div>
        <v-expansion-panels v-else>
            <v-expansion-panel
                v-for="meetingMinute in visibleMeetingMinutes"
                :key="meetingMinute.id"
            >
                <v-expansion-panel-title>
                    <div class="flex justify-between items-center">
                        <div class="font-bold">
                            {{ meetingMinute.type.toUpperCase() }} -
                            {{
                                new Date(
                                    meetingMinute.date
                                ).toLocaleDateString()
                            }}
                        </div>
                    </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <div class="flex flex-col gap-4">
                        <div class="text-sm text-gray-700">
                            <div class="font-bold">Summary:</div>
                            <div class="whitespace-pre-line">
                                {{
                                    meetingMinute.notesSummary ||
                                    'No summary available'
                                }}
                            </div>
                        </div>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <div
            v-if="visibleMeetingMinutes.length < meetingMinutes.length"
            class="mt-8 flex justify-center"
        >
            <v-btn color="primary" @click="showMore">Show More</v-btn>
        </div>
    </div>
</template>
