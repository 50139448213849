import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

export const useOptionsStore = defineStore('options', () => {
    const roleOptions = ref(['Admin', 'Contractor', 'Client', 'Agent']);
    const adminRoleOptions = ref(['Admin', 'Contractor']);
    const clientRoleOptions = ref(['Client', 'Agent']);
    const corporationTypeOptions = ref([]);
    const developerOptions = ref([]);
    const managementCompanyOptions = ref([]);
    const componentOptions = ref([]);
    const corporationComponentOptions = ref([]);
    const reviewStatusOptions = ref([]);
    const corporationOptions = ref([]);
    const userOptions = ref([]);
    const provinceOptions = ref([]);
    const documentTypeOptions = ref([]);
    const reviewTypeOptions = ref([]);
    const balanceTypeOptions = ref([]);
    const deficiencyTypeOptions = ref([]);

    /**
     * Generalized function to fetch options data from the API
     */
    const getOptionsData = async (key, endpoint) => {
        try {
            const response = await axios.get(endpoint);
            key.value = response.data;
        } catch (error) {
            console.error(`Failed to fetch ${key}:`, error);
        }
    };

    const fetchCorporationTypeOptions = () => {
        getOptionsData(
            corporationTypeOptions,
            '/api/v1/options/corporation-types'
        );
    };

    const fetchDeveloperOptions = () => {
        getOptionsData(developerOptions, '/api/v1/options/developers');
    };

    const fetchManagementCompanyOptions = () => {
        getOptionsData(
            managementCompanyOptions,
            '/api/v1/options/management-companies'
        );
    };

    const fetchComponentOptions = () => {
        getOptionsData(componentOptions, '/api/v1/options/components');
    };

    const fetchCorporationComponentOptions = corporation_id => {
        getOptionsData(
            corporationComponentOptions,
            `/api/v1/options/corporation/${corporation_id}/components`
        );
    };

    const fetchReviewStatusOptions = () => {
        getOptionsData(reviewStatusOptions, '/api/v1/options/reviews/statuses');
    };

    const fetchCorporationOptions = () => {
        getOptionsData(corporationOptions, '/api/v1/options/corporations');
    };

    const fetchUserOptions = () => {
        getOptionsData(userOptions, '/api/v1/options/users');
    };

    const fetchProvinceOptions = () => {
        getOptionsData(provinceOptions, '/api/v1/options/provinces');
    };

    const fetchDocumentTypeOptions = () => {
        getOptionsData(documentTypeOptions, '/api/v1/options/document-types');
    };

    const fetchReviewTypeOptions = () => {
        getOptionsData(reviewTypeOptions, '/api/v1/options/review-types');
    };

    const fetchBalanceTypeOptions = () => {
        getOptionsData(balanceTypeOptions, '/api/v1/options/balance-types');
    };

    const fetchDeficiencyTypeOptions = () => {
        getOptionsData(
            deficiencyTypeOptions,
            '/api/v1/options/deficiency-types'
        );
    };

    return {
        // State
        roleOptions,
        corporationTypeOptions,
        corporationOptions,
        developerOptions,
        managementCompanyOptions,
        componentOptions,
        corporationComponentOptions,
        reviewStatusOptions,
        userOptions,
        provinceOptions,
        documentTypeOptions,
        adminRoleOptions,
        clientRoleOptions,
        reviewTypeOptions,
        balanceTypeOptions,
        deficiencyTypeOptions,
        // Actions
        fetchCorporationTypeOptions,
        fetchDeveloperOptions,
        fetchManagementCompanyOptions,
        fetchComponentOptions,
        fetchCorporationComponentOptions,
        fetchReviewStatusOptions,
        fetchCorporationOptions,
        fetchUserOptions,
        fetchProvinceOptions,
        fetchDocumentTypeOptions,
        fetchReviewTypeOptions,
        fetchBalanceTypeOptions,
        fetchDeficiencyTypeOptions,
    };
});
