<script setup>
    import axios from 'axios';
    import { ref } from 'vue';

    import { router, Link } from '@inertiajs/vue3';

    import { usePage } from '@inertiajs/vue3';
    const page = usePage();

    const email = ref('');
    const password = ref('');
    const validationErrors = ref({});

    const login = () => {
        validationErrors.value = {};
        axios
            .post('api/v1/login', {
                email: email.value,
                password: password.value,
            })
            .then(response => {
                if (response.data.href) {
                    router.visit(response.data.href);
                } else {
                    router.visit('/reviews');
                }
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                ) {
                    if (error?.response?.data?.errors) {
                        validationErrors.value = error.response.data.errors;
                    }
                }
            });
    };
</script>

<template>
    <LoginPageLayout>
        <div
            class="flex flex-col justify-center h-full -mt-20 max-w-[500px] mx-auto"
        >
            <div class="self-center">
                <img class="md:max-w-40 max-w-24 p-2" src="/imgs/logo.png" />
            </div>

            <div class="text-3xl md:text-5xl font-bold text-center mb-4">
                Welcome Back
            </div>
            <div v-if="page.props.flash.info" class="my-4">
                <v-alert type="info">
                    {{ page.props.flash.info }}
                </v-alert>
            </div>
            <div v-if="page.props.flash.error" class="my-4">
                <v-alert type="error">
                    {{ page.props.flash.error }}
                </v-alert>
            </div>
            <v-form @submit.prevent="login" class="px-8 md:px-12 lg:px-32">
                <v-text-field
                    v-model="email"
                    label="Email"
                    type="email"
                    required
                    class="mt-6"
                    outlined
                    :error="validationErrors.email ? true : false"
                    :error-messages="validationErrors.email"
                />
                <v-text-field
                    class="mt-4"
                    v-model="password"
                    label="Password"
                    type="password"
                    required
                    outlined
                    :error="validationErrors.password ? true : false"
                    :error-messages="validationErrors.password"
                />
                <div class="flex justify-end">
                    <Link href="/forgot-password">Forgot Password?</Link>
                </div>
                <v-btn
                    block
                    size="large"
                    class="mt-4"
                    type="submit"
                    color="primary"
                >
                    Login
                </v-btn>
                <div class="flex justify-center mt-4">
                    <Link href="/register" class="self-center">
                        <span class="text-gray-500">
                            Don't have an account?
                        </span>
                        Sign Up
                    </Link>
                </div>
            </v-form>
        </div>
    </LoginPageLayout>
</template>
