<script setup>
    import { ref, watch, computed, onMounted, onBeforeUnmount } from 'vue';
    import BaseModal from '../BaseModal.vue';
    import axios from 'axios';
    import { useToast } from 'vue-toast-notification';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        item: Object,
    });

    /**************************************************************
     *      Toast
     **************************************************************/
    const toast = useToast();

    /**************************************************************
     *      API Calling
     **************************************************************/
    const record = ref({});
    const loading = ref(true);
    let pollingInterval = null;

    const fetchDocumentData = async () => {
        loading.value = true;
        try {
            const response = await axios.get(
                `/api/v1/documents/${props.item.id}`
            );
            record.value = response.data.document;
            checkPollingConditions(); // Adjust polling as needed
        } catch (error) {
            toast.error('Failed to fetch document data');
        } finally {
            loading.value = false;
        }
    };

    const startPolling = () => {
        if (!pollingInterval) {
            pollingInterval = setInterval(fetchDocumentData, 5000);
        }
    };

    const stopPolling = () => {
        if (pollingInterval) {
            clearInterval(pollingInterval);
            pollingInterval = null;
        }
    };

    const checkPollingConditions = () => {
        if (
            record.value.being_ocr_processed ||
            record.value.being_ai_processed
        ) {
            startPolling();
        } else {
            stopPolling();
        }
    };

    watch(
        () => props.isOpen,
        async isOpen => {
            if (isOpen) {
                await fetchDocumentData();
            } else {
                stopPolling();
            }
        }
    );

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};
        }
    );

    const getAIDataAsJson = computed(() => {
        try {
            return JSON.parse(record.value.ai_data);
        } catch (e) {
            return {};
        }
    });

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };

    /**************************************************************
     *      Lifecycle Hooks
     **************************************************************/
    onMounted(() => {
        if (props.isOpen) {
            fetchDocumentData();
        }
    });

    onBeforeUnmount(() => {
        stopPolling();
    });
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :title="`Document AI Data`"
        @close="onClose"
        noActions
    >
        <!-- this is the weirdest bug I have ever encountered -->
        <template v-if="!loading || loading">
            <div class="text-2xl font-bold">OCR</div>
            <div class="grid grid-cols-2 gap-2">
                <div v-if="record.being_ocr_processed">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                    OCR In Progress
                </div>
                <template v-else>
                    <div>
                        {{
                            record.ocr_complete
                                ? 'OCR Complete'
                                : 'OCR Incomplete'
                        }}
                    </div>
                    <div>
                        Failed Attempts: {{ record.ocr_failed_attempts ?? 0 }}
                    </div>
                    <v-textarea
                        class="col-span-full mt-4"
                        label="OCR Text"
                        v-model="record.ocr_text"
                    />
                </template>
            </div>
            <div class="text-2xl font-bold mt-4">AI Data</div>
            <div class="grid grid-cols-2 gap-2">
                <div v-if="record.being_ai_processed">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                    AI Processing In Progress
                </div>
                <template v-else>
                    <div>
                        {{
                            record.ai_processed
                                ? 'AI Processing Complete'
                                : 'AI Processing Incomplete'
                        }}
                    </div>
                    <v-textarea
                        class="col-span-full mt-4"
                        label="AI Summary"
                        v-model="record.ai_summary"
                    />
                    <div class="col-span-full">AI Data</div>
                    <div class="col-span-full">
                        <div class="grid grid-cols-3 gap-3">
                            <div class="font-bold">Field</div>
                            <div class="font-bold">Confidence</div>
                            <div class="font-bold">Value</div>
                        </div>
                        <div
                            v-for="(field, key) in getAIDataAsJson"
                            :key="key"
                            class="grid grid-cols-3 gap-3 py-2 border-b"
                        >
                            <div>{{ key }}</div>
                            <div>
                                <v-chip
                                    :color="
                                        field.confidence === 'high'
                                            ? 'green'
                                            : field.confidence === 'medium'
                                              ? 'orange'
                                              : 'red'
                                    "
                                    dark
                                >
                                    {{ field.confidence }}
                                </v-chip>
                            </div>
                            <div>{{ field.value }}</div>
                        </div>
                    </div>
                    <div class="col-span-full">AI Processing Log</div>
                    <v-textarea
                        class="col-span-full mt-4"
                        v-model="record.ai_processing_notes"
                    />
                </template>
            </div>
            <div class="flex justify-end space-x-2 mt-4">
                <v-btn color="primary" @click="onClose">Close</v-btn>
            </div>
        </template>
    </BaseModal>
</template>
