<script setup>
    import axios from 'axios';
    import { ref, onMounted, nextTick } from 'vue';
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    const props = defineProps({
        review: {
            type: Object,
            required: true,
        },
    });

    // Store fetched notes and the text of a new note
    const notes = ref([]);
    const newNote = ref('');
    const notesContainer = ref(null);
    const loading = ref(false);

    // Fetch notes on component mount
    const fetchNotes = () => {
        axios
            .get(`/api/v1/reviews/${props.review.id}/notes`)
            .then(response => {
                notes.value = response.data.notes;
                nextTick(() => {
                    if (notesContainer.value) {
                        notesContainer.value.scrollTop =
                            notesContainer.value.scrollHeight;
                    }
                });
            })
            .catch(error => {
                toast.error('Failed to fetch notes');
                console.error(error);
            });
    };

    // Save a new note
    const saveNote = () => {
        loading.value = true;
        axios
            .post(`/api/v1/reviews/${props.review.id}/notes`, {
                note: newNote.value, // sending the note content
            })
            .then(() => {
                toast.success('Note saved successfully');
                newNote.value = '';
                // refetch notes to show newly added note
                fetchNotes();
            })
            .catch(error => {
                toast.error('Failed to save note');
                console.error(error);
            })
            .finally(() => {
                loading.value = false;
            });
    };

    // Fetch notes as soon as the component is mounted
    onMounted(() => {
        fetchNotes();
    });
</script>

<template>
    <div class="max-w-[1000px] p-4 border rounded-lg shadow-lg mb-12">
        <div class="text-2xl font-bold">Notes</div>

        <div ref="notesContainer" class="h-96">
            <div v-for="note in notes" :key="note.id" class="py-4 border-b">
                <div class="text-sm text-gray-500 py-2">
                    <strong>
                        {{ note.user.first_name }} {{ note.user.last_name }}
                    </strong>
                    -
                    {{
                        new Date(note.created_at).toLocaleString('en-CA', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                        })
                    }}
                </div>
                <div class="mt-2 pb-4 whitespace-pre-wrap">
                    {{ note.note }}
                </div>
            </div>
        </div>
        <div class="items-center mt-16">
            <v-textarea
                :loading="loading"
                :disabled="loading"
                v-model="newNote"
                label="Write your note here..."
                class="mt-5"
            />
            <v-btn
                :loading="loading"
                :disabled="loading"
                @click="saveNote"
                color="primary"
                class="self-center"
            >
                Save Note
            </v-btn>
        </div>
    </div>
</template>
