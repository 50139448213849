<script setup>
    import { onMounted, ref } from 'vue';
    import { router } from '@inertiajs/vue3';

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Modal
     **************************************************************/
    import FixReviewDocumentsModal from '../Modals/FixReviewDocumentsModal.vue';
    const isModalOpen = ref(false);

    const openFixDocumentsModal = () => {
        isModalOpen.value = true;
    };

    const closeModal = () => {
        isModalOpen.value = false;
    };

    /**************************************************************
     *      State
     **************************************************************/
    const loading = ref(false);

    const props = defineProps({
        review: Object,
    });

    const timelineItems = ref([]);

    const setTimelineItems = () => {
        const statusHistory = props.review.status_history;

        timelineItems.value = [];

        for (let i = 0; i < statusHistory.length; i++) {
            const history = statusHistory[i];
            const status = history.to_status;

            // Client doesn't need to see this status
            if (status.name === 'Pending Administrative Review') {
                continue;
            }

            if (status.name === 'Cannot Proceed') {
                continue;
            }

            if (status.name === 'Pending Submission') {
                continue;
            }

            let itemStatus = {
                description: '',
                color: '',
                icon: '',
            };

            switch (status.name) {
                case 'Pending Submission':
                    itemStatus.description =
                        'Your submission has been received by our team.';
                    itemStatus.color = 'gray';
                    itemStatus.icon = 'mdi-check-circle-outline';
                    break;
                case 'Under Review':
                    itemStatus.description =
                        'A specialist has been assigned to review your case. You will be notified if any action is needed on your part.';
                    itemStatus.color = 'gray';
                    itemStatus.icon = 'mdi-account-search-outline';
                    break;
                case 'Pending Administrative Review':
                    itemStatus.description =
                        'Your review is pending administrative review. You will be notified if any action is needed on your part.';
                    itemStatus.color = 'green';
                    itemStatus.icon = 'mdi-account-clock-outline';
                    break;
                case 'Pending Documents':
                    itemStatus.description =
                        "We're missing documents and cannot proceed with your review. Please review the reason and resubmit your documents.";
                    itemStatus.color = 'red-lighten-2';
                    itemStatus.icon = 'mdi-alert-circle-outline';
                    break;
                case 'Completed':
                    itemStatus.description =
                        'Your review is completed and ready to be downloaded!';
                    itemStatus.color = 'green';
                    itemStatus.icon = 'mdi-check-circle-outline';
                    break;
                case 'Cancelled':
                    itemStatus.description = 'Your review has been cancelled.';
                    itemStatus.color = 'red';
                    itemStatus.icon = 'mdi-cancel';
                    break;
                default:
                    itemStatus.description = 'Unknown status';
                    itemStatus.color = 'gray';
                    itemStatus.icon = 'mdi-help-circle-outline';
                    break;
            }

            if (
                status.name === 'Under Review' &&
                timelineItems.value.length > 0 &&
                timelineItems.value[timelineItems.value.length - 1].name ===
                    'Under Review'
            ) {
                continue;
            }

            timelineItems.value.push({
                name: status.name,
                when: status.created_at,
                when: new Date(history.created_at).toLocaleString('en-US', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                }),
                rejection_reason: status.rejection_reason,
                rejected_documents: status.document_types,
                color: itemStatus.color,
                description: itemStatus.description,
                icon: itemStatus.icon,
            });
        }
    };

    const resubmitReview = () => {
        loading.value = true;
        axios
            .post(`/api/v1/reviews/${props.review.id}/resubmit`)
            .then(() => {
                toast.open({
                    message: 'Your review has been resubmitted!',
                    type: 'success',
                });
                reloadReview();
            })
            .catch(error => {
                toast.open({
                    message:
                        'Something went wrong when resubmitting your review! Please try again.',
                    type: 'error',
                });
            })
            .finally(() => {
                loading.value = false;
            });
    };

    const reloadReview = () => {
        loading.value = true;
        router.reload({
            only: ['review'],
            replace: true,
            onSuccess: () => {
                loading.value = false;
                setTimelineItems();
            },
        });
    };

    const viewReport = () => {
        window.open(`/reviews/${props.review.id}/view`, '_blank');
    };

    onMounted(() => {
        reloadReview();
    });
</script>

<template>
    <div>
        <FixReviewDocumentsModal
            v-if="isModalOpen"
            :review="props.review"
            :isOpen="isModalOpen"
            :item="selectedOperatingAccount"
            @close="closeModal"
            @resubmit-review="resubmitReview"
        />
        <div v-if="loading" class="p-12 flex gap-4 items-center">
            <v-progress-circular
                indeterminate
                color="primary"
                size="64"
            ></v-progress-circular>
            <div class="text-2xl">Loading Your Review Information...</div>
        </div>

        <div v-else>
            <div
                v-if="review.status.name === 'Completed'"
                class="px-8 py-2 bg-green-500 text-white rounded-lg mt-8 flex justify-between max-w-4xl items-center self-center mx-auto"
            >
                <div class="flex flex-col gap-2">
                    <div class="text-2xl font-bold">Your Report Is Ready!</div>
                    <div>
                        We've emailed you a link to your report, you can also
                        view it here by clicking the button below.
                    </div>
                    <div>
                        <v-btn
                            color="green-lighten-3"
                            size="large"
                            class="mt-4"
                            @click="viewReport"
                        >
                            View Report!
                        </v-btn>
                    </div>
                </div>
                <img src="/imgs/approve-badge.png" class="w-40" alt="Approve" />
            </div>
            <div
                v-else-if="review.status.name === 'Pending Documents'"
                class="px-8 py-8 bg-gray-100 text-black rounded-lg mt-8 flex flex-col sm:flex-row justify-between max-w-4xl items-center self-center mx-auto"
            >
                <div class="flex flex-col gap-2">
                    <div class="text-2xl font-bold">
                        There was an issue with your submission
                    </div>
                    <div>
                        We've identified some issues with your submission.
                        Please resolve them and resubmit your documents.
                    </div>
                    <div>
                        <v-btn
                            color="red-lighten-1"
                            size="large"
                            class="mt-4"
                            @click="openFixDocumentsModal"
                        >
                            Fix Documents
                        </v-btn>
                    </div>
                </div>
                <img src="/imgs/alert.png" class="w-40" alt="Alert" />
            </div>
            <div
                v-else
                class="px-8 py-2 bg-gray-100 rounded-lg mt-8 flex flex-col sm:flex-row justify-between max-w-4xl items-center self-center mx-auto"
            >
                <div class="flex flex-col gap-2">
                    <div class="text-2xl font-bold">
                        Your Customized Report Is On Its Way!
                    </div>
                    <div>
                        We've emailed you an invoice for your purchase, and we
                        will notify you via email if we need further information
                        to process your review.
                    </div>
                </div>
                <img
                    src="/imgs/signing-contract.gif"
                    class="w-40"
                    alt="Contract"
                />
            </div>
            <v-timeline side="end">
                <v-timeline-item
                    v-for="item in timelineItems"
                    :key="item.id"
                    :dot-color="item.color"
                    size="small"
                >
                    <v-alert
                        :color="item.color"
                        :icon="item.icon"
                        :value="true"
                    >
                        <div class="flex flex-col gap-1">
                            <div class="font-bold">
                                {{
                                    item.name == 'Under Review'
                                        ? 'Submission Received - Under Review'
                                        : item.name
                                }}
                            </div>
                            <div>{{ item.description }}</div>
                            <div class="italic text-sm">
                                {{ item.when }}
                            </div>
                        </div>
                    </v-alert>
                </v-timeline-item>
            </v-timeline>
        </div>
    </div>
</template>
