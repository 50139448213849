<script setup>
    import { ref } from 'vue';
    import { router } from '@inertiajs/vue3';

    /**************************************************************
     *      Modal
     **************************************************************/
    import ConfirmationModal from '../../Components/Modals/ConfirmationModal.vue';
    const isModalOpen = ref(false);

    const showCreateModal = () => {
        isModalOpen.value = true;
    };

    const handleClose = () => {
        isModalOpen.value = false;
    };

    const handleConfirm = () => {
        isModalOpen.value = false;

        axios
            .post('/api/v1/reviews')
            .then(response => {
                const review = response.data.review;
                accessReview(review);
            })
            .catch(error => {
                console.error(error);
            });
    };

    /**************************************************************
     *      Helpers
     **************************************************************/
    const getClientNames = clients => {
        return clients
            .map(
                client =>
                    `${client.first_name} ${client.last_name} (${client.roles[0].name})`
            )
            .join(', ');
    };

    const getContractorNames = users => {
        const contractors = users.filter(
            user => user.roles[0].name === 'contractor'
        );

        if (contractors.length) {
            return contractors
                .map(
                    contractor =>
                        `${contractor.first_name} ${contractor.last_name.charAt(0)}.`
                )
                .join(', ');
        }

        return '-';
    };

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';
    import { useUserStore } from '@/stores/user';

    const userStore = useUserStore();
    const { userIsAdmin, userIsContractor, userIsAgent } =
        storeToRefs(userStore);

    const optionsStore = useOptionsStore();
    const { fetchReviewStatusOptions, fetchCorporationOptions } = optionsStore;
    const { reviewStatusOptions } = storeToRefs(optionsStore);
    const { corporationOptions } = storeToRefs(optionsStore);

    if (userIsAdmin.value || userIsContractor.value) {
        fetchReviewStatusOptions();
        fetchCorporationOptions();
    }

    /**************************************************************
     *      Navigation
     **************************************************************/
    const accessReview = item => {
        if (userIsAdmin.value || userIsContractor.value) {
            router.visit(`/reviews/${item.id}/admin`);
        } else {
            router.visit(`/reviews/${item.id}/client`);
        }
    };

    const viewReview = item => {
        window.open(`/reviews/${item.id}/view`, '_blank');
    };

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = ref([
        {
            title: 'Case #',
            key: 'review_number',
            sortable: true,
        },
        {
            title: 'Status',
            key: 'status',
            value: item => item.status.name,
            sortable: true,
        },
        {
            title: 'Reviewer',
            key: 'reviewer',
            value: item => getContractorNames(item.users),
            sortable: false,
        },
        {
            title: 'Client',
            key: 'client',
            sortable: false,
        },
        {
            title: 'Corporation',
            key: 'corporation.name',
            value: item => (item.corporation ? item.corporation.name : '-'),
            sortable: false,
        },
        {
            title: 'Client Paid',
            key: 'is_paid',
            value: item => (item.is_paid ? 'Paid' : 'Unpaid'),
            sortable: true,
        },
        {
            title: 'Condition Date',
            key: 'condition_date',
            value: item => {
                if (!item.condition_date) {
                    return '-';
                }
                const date = new Date(item.condition_date);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            },
            sortable: true,
        },
    ]);

    if (!userIsAdmin.value && !userIsContractor.value) {
        headers.value = headers.value.filter(header =>
            [
                'Case #',
                'Corporation',
                'Status',
                'Type',
                'Condition Date',
            ].includes(header.title)
        );
    } else if (userIsContractor.value) {
        headers.value = headers.value.filter(
            header => header.title !== 'Client Paid'
        );
    }

    const searchTrigger = ref(Date.now());

    const additionalParams = ref({
        search: '',
        status: null,
        corporation: null,
        client_paid: null,
    });
</script>
<template>
    <AuthenticatedLayout>
        <ConfirmationModal
            :isOpen="isModalOpen"
            message="Are you sure you want to create a new review?"
            @close="handleClose"
            @confirm="handleConfirm"
        />
        <TableViewManager
            title="Reviews"
            :headers="headers"
            subtitle="This is where you can view a listing of your reviews"
            endpoint="/api/v1/reviews"
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            :actions="[
                {
                    title: 'Access',
                    icon: 'mdi-arrow-right-bold-circle-outline',
                    buttonText: 'Access',
                    buttonColor: 'secondary',
                    showActionText: true,
                    action: item => accessReview(item),
                },
                ...(!userIsAgent
                    ? [
                          {
                              title: 'View',
                              icon: 'mdi-eye-arrow-right',
                              buttonText: 'view',
                              buttonColor: 'secondary',
                              showActionText: true,
                              action: item => viewReview(item),
                          },
                      ]
                    : []),
            ]"
        >
            <template #actions>
                <v-btn color="primary" class="px-4" @click="showCreateModal">
                    Create New Review
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn>
            </template>
            <template v-if="userIsAdmin || userIsContractor" #filters>
                <v-text-field
                    v-model="additionalParams.search"
                    label="Search"
                    dense
                    hide-details
                ></v-text-field>
                <v-autocomplete
                    v-model="additionalParams.status"
                    :items="reviewStatusOptions"
                    label="Status"
                    clearable
                    dense
                    hide-details
                />
                <v-autocomplete
                    v-model="additionalParams.corporation"
                    :items="corporationOptions"
                    label="Corporation"
                    clearable
                    dense
                    hide-details
                />
                <v-autocomplete
                    v-if="userIsAdmin"
                    v-model="additionalParams.client_paid"
                    :items="['Paid', 'Unpaid']"
                    label="Client Payment Status"
                    clearable
                    dense
                    hide-details
                />
            </template>

            <template #item.review_number="{ item }">
                <div
                    class="underline hover:font-bold cursor-pointer"
                    @click="accessReview(item)"
                >
                    {{ item.review_case_number }}
                </div>
            </template>
            <template #item.is_paid="{ item }">
                <v-icon
                    :color="item.is_paid ? 'green-lighten-1' : 'red-lighten-1'"
                >
                    {{ item.is_paid ? 'mdi-check-circle' : 'mdi-close-circle' }}
                </v-icon>
            </template>

            <template #item.status="{ item }">
                <v-chip
                    variant="flat"
                    size="small"
                    :color="
                        {
                            'Pending Submission': 'grey',
                            'Under Review': 'blue',
                            'Pending Administrative Review': 'orange',
                            'Pending Documents': 'yellow',
                            'Cannot Proceed': 'red',
                            Completed: 'green',
                            Cancelled: 'black',
                        }[item.status.name]
                    "
                >
                    {{ item.status.name }}
                </v-chip>
            </template>
            <template #item.client="{ item }">
                <v-icon
                    class="cursor-help"
                    v-tooltip="
                        getClientNames(
                            item.users.filter(
                                user => user.roles[0].name !== 'contractor'
                            )
                        )
                    "
                    color="green"
                >
                    mdi-account-multiple-outline
                </v-icon>
            </template>
        </TableViewManager>
    </AuthenticatedLayout>
</template>
