<script setup>
    import axios from 'axios';
    import { ref } from 'vue';
    import { vMaska } from 'maska/vue';

    import { router, Link } from '@inertiajs/vue3';
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    const loading = ref(false);
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const phone = ref('');
    const password = ref('');
    const passwordConfirm = ref('');
    const validationErrors = ref({});

    const unmaskedPhone = ref('');
    defineExpose({ unmaskedPhone });

    const register = () => {
        loading.value = true;
        validationErrors.value = {};
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        axios
            .post('api/v1/register', {
                first_name: firstName.value,
                last_name: lastName.value,
                email: email.value,
                phone: unmaskedPhone.value,
                password: password.value,
                password_confirmation: passwordConfirm.value,
                invite_token: token ? token : null,
            })
            .then(response => {
                // On register, take user to the dashboard for their first generated review
                router.visit(response.data.href);
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                ) {
                    if (error?.response?.data?.errors) {
                        validationErrors.value = error.response.data.errors;
                    }
                }

                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
            })
            .finally(() => {
                loading.value = false;
            });
    };
</script>

<template>
    <LoginPageLayout>
        <div class="flex flex-col justify-center h-full max-w-[500px] mx-auto">
            <div class="self-center">
                <img class="md:max-w-40 max-w-24 p-2" src="/imgs/logo.png" />
            </div>
            <div class="text-2xl md:text-4xl font-bold text-center mb-4 px-4">
                Create an Account to Start your Review
            </div>
            <v-form @submit.prevent="register" class="px-8 md:px-12 lg:px-32">
                <div class="flex gap-2 mt-4">
                    <v-text-field
                        v-model="firstName"
                        label="First Name"
                        type="text"
                        required
                        outlined
                        :error="validationErrors.first_name ? true : false"
                        :error-messages="validationErrors.first_name"
                        :loading="loading"
                        :disabled="loading"
                    />
                    <v-text-field
                        v-model="lastName"
                        label="Last Name"
                        type="text"
                        required
                        outlined
                        :error="validationErrors.last_name ? true : false"
                        :error-messages="validationErrors.last_name"
                        :loading="loading"
                        :disabled="loading"
                    />
                </div>
                <v-text-field
                    class="mt-4"
                    v-model="email"
                    label="Email"
                    type="email"
                    required
                    outlined
                    :error="validationErrors.email ? true : false"
                    :error-messages="validationErrors.email"
                    :loading="loading"
                    :disabled="loading"
                />
                <v-text-field
                    class="mt-4"
                    v-model="phone"
                    label="Phone"
                    type="tel"
                    required
                    outlined
                    :error="validationErrors.phone ? true : false"
                    :error-messages="validationErrors.phone"
                    placeholder="(###) ###-####"
                    v-maska:unmaskedPhone.unmasked="'(###) ###-####'"
                    :loading="loading"
                    :disabled="loading"
                />
                <v-text-field
                    class="mt-4"
                    v-model="password"
                    label="Password"
                    type="password"
                    required
                    outlined
                    :error="validationErrors.password ? true : false"
                    :error-messages="validationErrors.password"
                    :loading="loading"
                    :disabled="loading"
                />
                <v-text-field
                    class="mt-4"
                    v-model="passwordConfirm"
                    label="Confirm Password"
                    type="password"
                    required
                    outlined
                    :error="validationErrors.password_confirm ? true : false"
                    :error-messages="validationErrors.password_confirm"
                    :loading="loading"
                    :disabled="loading"
                />
                <v-btn
                    block
                    type="submit"
                    color="primary"
                    class="my-2"
                    :loading="loading"
                    :disabled="loading"
                >
                    Register
                </v-btn>
                <div class="flex justify-center mt-4">
                    <Link href="/login" class="self-center" :disabled="loading">
                        <span class="text-gray-500">
                            Already have an account?
                        </span>
                        Login
                    </Link>
                </div>
                <div class="mt-4 text-center text-gray-500 text-xs">
                    By registering you agree to our
                    <Link
                        href="/terms"
                        class="text-primary"
                        :disabled="loading"
                    >
                        terms of service
                    </Link>
                    and
                    <Link
                        href="/privacy"
                        class="text-primary"
                        :disabled="loading"
                    >
                        privacy policy
                    </Link>
                    .
                </div>
            </v-form>
        </div>
    </LoginPageLayout>
</template>
