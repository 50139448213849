<script setup>
    import { usePage } from '@inertiajs/vue3';
    import { computed, ref } from 'vue';
    import CondoFeeKPICard from './KPICards/CondoFeeKPICard.vue';
    import DepreciationKPICard from './KPICards/DepreciationKPICard.vue';
    import OperatingVsActualKPICard from './KPICards/OperatingVsActualKPICard.vue';
    import ReserveFundRecommendationKPICard from './KPICards/ReserveFundRecommendationKPICard.vue';
    import ReplacementCostKpiCard from './KPICards/ReplacementCostKpiCard.vue';
    import TimelineKPICard from './KPICards/TimelineKPICard.vue';

    const page = usePage();
    const review = computed(() => page.props.review);
</script>
<template>
    <div>
        <div
            class="relative flex flex-col gap-8 md:flex-row py-32 px-8 bg-fixed bg-center bg-cover my-12"
            style="background-image: url('/imgs/contact-us-img.jpg')"
        >
            <div class="absolute inset-0 bg-[#131313] opacity-50"></div>
            <div
                class="relative w-full max-w-[1400px] mx-auto flex flex-col md:flex-row gap-8"
            >
                <div class="md:w-1/2">
                    <div class="md:text-6xl text-3xl font-bold text-white">
                        6 KEY FACTORS AT A GLANCE
                    </div>
                </div>
                <div class="md:w-1/2 flex items-center">
                    <div class="text-white">
                        Discover the 6 critical Key Performance Indicators that
                        are essential for assessing the overall health of
                        {{ review.corporation.name }}.
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col gap-12 md:m-24 max-w-[1400px] mx-auto">
            <CondoFeeKPICard backgroundColor="bg-[#F3F3F3]" />
            <ReserveFundRecommendationKPICard />
            <DepreciationKPICard backgroundColor="bg-[#F3F3F3]" />
            <ReplacementCostKpiCard />
            <OperatingVsActualKPICard backgroundColor="bg-[#F3F3F3]" />
            <TimelineKPICard backgroundColor="bg-[#131313]" />
        </div>
    </div>
</template>
