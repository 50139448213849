<script setup>
    import { ref, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';
    import AISummarizer from '../AISummarizer.vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        item: Object,
        corporation: Object,
    });

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        validationErrors.value = {};
        const action = mode.value === 'Create' ? axios.post : axios.patch;
        const endpoint =
            mode.value === 'Create'
                ? `/api/v1/corporations/${props.corporation.id}/insurance-certificates`
                : `/api/v1/corporations/${props.corporation.id}/insurance-certificates/${record.value.id}`;

        const payload = {
            ...record.value,
        };

        action(endpoint, payload)
            .then(response => {
                emit('close');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            });
    };

    const record = ref({});
    const mode = ref('Create');

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});
    const defaultRecord = ref({
        expiry_date: '',
        replacement_value: null,
        flood_deductible: null,
        water_deductible: null,
        sewer_deductible: null,
        hail_deductible: null,
        other_losses_deductible: null,
        fidelity_bond_limit: null,
        betterments_and_improvements_limit: null,
        notes_raw_ai: '',
        notes_summary_ai: '',
    });

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/

    // Watch for changes on input record in order to set state for update or create modes
    watch(
        () => props.item,
        recordToUpdate => {
            if (recordToUpdate) {
                record.value = {
                    ...recordToUpdate,
                };
                mode.value = 'Update';
            } else {
                record.value = { ...defaultRecord.value };
                mode.value = 'Create';
            }
        }
    );

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};

            if (isOpen && mode.value === 'Create') {
                record.value = { ...defaultRecord.value };
            }
        }
    );

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };

    const handleRawTextUpdated = rawText => {
        record.value.notes_raw_ai = rawText;
    };

    const handleSummaryTextUpdated = summaryText => {
        record.value.notes_summary_ai = summaryText;
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :title="`${mode} Insurance Certificate`"
        @close="onClose"
        @save="onSave"
    >
        <div class="grid grid-cols-2 gap-4">
            <v-text-field
                v-model="record.insurance_company"
                label="Insurance Company"
                type="text"
                :error="validationErrors.insurance_company ? true : false"
                :error-messages="validationErrors.insurance_company"
            />

            <v-text-field
                v-model="record.expiry_date"
                label="Expiry Date"
                type="date"
                :error="validationErrors.expiry_date ? true : false"
                :error-messages="validationErrors.expiry_date"
            />

            <CurrencyInput
                v-model="record.replacement_value"
                label="Insured Replacement Value"
                :error="validationErrors.replacement_value ? true : false"
                :error-messages="validationErrors.replacement_value"
            />

            <div
                class="col-span-full text-2xl font-semibold border-b py-4 my-4"
            >
                Deductibles
            </div>

            <CurrencyInput
                v-model="record.flood_deductible"
                label="Flood Deductible"
                :error="validationErrors.flood_deductible ? true : false"
                :error-messages="validationErrors.flood_deductible"
            />

            <CurrencyInput
                v-model="record.water_deductible"
                label="Water Deductible"
                :error="validationErrors.water_deductible ? true : false"
                :error-messages="validationErrors.water_deductible"
            />

            <CurrencyInput
                v-model="record.sewer_deductible"
                label="Sewer Deductible"
                :error="validationErrors.sewer_deductible ? true : false"
                :error-messages="validationErrors.sewer_deductible"
            />

            <CurrencyInput
                v-model="record.hail_deductible"
                label="Hail Deductible"
                :error="validationErrors.hail_deductible ? true : false"
                :error-messages="validationErrors.hail_deductible"
            />

            <CurrencyInput
                v-model="record.other_losses_deductible"
                label="Other Losses Deductible"
                :error="validationErrors.other_losses_deductible ? true : false"
                :error-messages="validationErrors.other_losses_deductible"
            />

            <div
                class="col-span-full text-2xl font-semibold border-b py-4 my-4"
            >
                Misc
            </div>

            <CurrencyInput
                v-model="record.betterments_and_improvements_limit"
                label="Betterments and Improvements Limit"
                :error="
                    validationErrors.betterments_and_improvements_limit
                        ? true
                        : false
                "
                :error-messages="
                    validationErrors.betterments_and_improvements_limit
                "
            />

            <CurrencyInput
                v-model="record.fidelity_bond_limit"
                label="Fidelity Bond Limit"
                :error="validationErrors.fidelity_bond_limit ? true : false"
                :error-messages="validationErrors.fidelity_bond_limit"
            />

            <div
                class="col-span-full text-2xl font-semibold border-b py-4 my-4"
            >
                Notes
            </div>

            <AISummarizer
                what="Insurance Certificate"
                :initialRawText="record.notes_raw_ai"
                :initialSummaryText="record.notes_summary_ai"
                @rawTextUpdated="handleRawTextUpdated"
                @summaryUpdated="handleSummaryTextUpdated"
            />
        </div>
    </BaseModal>
</template>
