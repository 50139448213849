<script setup>
    import { ref, computed } from 'vue';
    import { Bar } from 'vue-chartjs';
    import { usePage } from '@inertiajs/vue3';

    import { useDisplay } from 'vuetify';
    const { smAndDown } = useDisplay();

    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    const { formatMoney } = useMoneyFormatter();

    const page = usePage();

    const insurance = ref(
        page.props.review.corporation.LatestInsuranceCertificate
    );

    const insurancePremium = ref(
        page.props.operation_budget_stats.yearly_premium
    );
</script>

<template>
    <div
        :class="[
            smAndDown ? 'my-12 py-12 bg-[#f3f3f3]' : 'py-24 my-24 bg-[#f3f3f3]',
        ]"
    >
        <div class="max-w-[1400px] mx-auto px-8">
            <div class="text-2xl md:text-4xl font-bold mb-8">Insurance</div>
            <div class="flex gap-8">
                <div>
                    <div>Insured By</div>
                    <div class="font-bold text-lg md:text-2xl">
                        {{ insurance.insurance_company }}
                    </div>
                </div>
                <div>
                    <div>Expiry Date</div>
                    <div class="font-bold text-lg md:text-2xl">
                        {{ insurance.expiry_date }}
                    </div>
                </div>
            </div>
            <div class="flex flex-col md:flex-row gap-6 my-8">
                <div
                    class="border-dotted border-2 border-gray-400 p-6 flex gap-2"
                >
                    <div>
                        <div class="flex gap-2">
                            <div class="font-bold text-lg md:text-2xl">
                                Insured Replacement Value
                            </div>
                            <v-icon
                                small
                                class="ml-2"
                                v-tooltip.bottom="
                                    'This is what the insurance company values the entire corporations insured assets (at base furnishings) in the event a total replacement is required'
                                "
                            >
                                mdi-help-rhombus
                            </v-icon>
                        </div>
                        <div class="font-bold text-lg md:text-2xl">
                            {{
                                formatMoney(insurance.replacement_value, false)
                            }}
                        </div>
                    </div>
                </div>
                <div
                    class="border-dotted border-2 border-gray-400 p-6 flex gap-2"
                >
                    <div>
                        <div class="flex gap-2">
                            <div class="font-bold text-lg md:text-2xl">
                                Premium Yearly Cost
                            </div>
                            <v-icon
                                small
                                class="ml-2"
                                v-tooltip.bottom="
                                    'The cost to insure the corporation for a year'
                                "
                            >
                                mdi-help-rhombus
                            </v-icon>
                        </div>
                        <div class="font-bold text-lg md:text-2xl">
                            {{ formatMoney(insurancePremium, false) }}
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="font-bold text-lg md:text-2xl mb-6">
                    Deductibles
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div v-if="insurance.flood_deductible">
                        <div>Flood Deductible</div>
                        <div class="font-bold text-lg md:text-2xl">
                            {{ formatMoney(insurance.flood_deductible, false) }}
                        </div>
                    </div>
                    <div v-if="insurance.hail_deductible">
                        <div>Hail Deductible</div>
                        <div class="font-bold text-lg md:text-2xl">
                            {{ formatMoney(insurance.hail_deductible, false) }}
                        </div>
                    </div>
                    <div v-if="insurance.sewer_deductible">
                        <div>Sewer Deductible</div>
                        <div class="font-bold text-lg md:text-2xl">
                            {{ formatMoney(insurance.sewer_deductible, false) }}
                        </div>
                    </div>
                    <div v-if="insurance.water_deductible">
                        <div>Water Deductible</div>
                        <div class="font-bold text-lg md:text-2xl">
                            {{ formatMoney(insurance.water_deductible, false) }}
                        </div>
                    </div>
                    <div v-if="insurance.other_losses_deductible">
                        <div>Other Losses Deductible</div>
                        <div class="font-bold text-lg md:text-2xl">
                            {{
                                formatMoney(
                                    insurance.other_losses_deductible,
                                    false
                                )
                            }}
                        </div>
                    </div>
                </div>
                <div
                    v-if="
                        insurance.betterments_and_improvements_limit ||
                        insurance.fidelity_bond_limit
                    "
                    class="grid grid-cols-1 md:grid-cols-2 gap-4"
                >
                    <div
                        class="font-bold text-lg md:text-2xl col-span-full mt-6 border-t pt-6"
                    >
                        Miscellaneous
                    </div>
                    <div v-if="insurance.fidelity_bond_limit">
                        <div>Fidelity Bond Limit</div>
                        <div class="font-bold text-lg md:text-2xl">
                            {{
                                formatMoney(
                                    insurance.fidelity_bond_limit,
                                    false
                                )
                            }}
                        </div>
                    </div>
                    <div v-if="insurance.betterments_and_improvements_limit">
                        <div>Betterments and Improvements Limit</div>
                        <div class="font-bold text-lg md:text-2xl">
                            {{
                                formatMoney(
                                    insurance.betterments_and_improvements_limit,
                                    false
                                )
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
