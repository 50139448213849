<script setup>
    import axios from 'axios';
    import { ref } from 'vue';
    import { useToast } from 'vue-toast-notification';
    const emit = defineEmits(['refresh']);

    const loading = ref(false);

    const toast = useToast();
    const validationErrors = ref({});

    const props = defineProps({
        review: {
            type: Object,
            required: true,
        },
    });

    const form = ref({
        ...props.review,
    });

    /**************************************************************
     *      Update Risk Assessment for review
     **************************************************************/
    const updateRiskAssessment = () => {
        loading.value = true;
        validationErrors.value = {};

        const payload = {
            corporation_id: form.value.corporation_id,
            ai_risk_assessment: form.value.ai_risk_assessment,
            ai_risk_assessment_level: form.value.ai_risk_assessment_level,
            ai_risk_assessment_summary: form.value.ai_risk_assessment_summary,
        };

        axios
            .post(`/api/v1/reviews/${form.value.id}`, payload)
            .then(response => {
                toast.open({
                    message: 'Review updated successfully!',
                    type: 'success',
                });

                emit('refresh');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            })
            .finally(() => {
                loading.value = false;
            });
    };

    const generateRiskAssessment = () => {
        loading.value = true;
        axios
            .post(`/api/v1/reviews/${form.value.id}/generate-risk-assessment`)
            .then(response => {
                toast.open({
                    message: 'Risk assessment generated successfully!',
                    type: 'success',
                });

                emit('refresh');

                form.value.ai_risk_assessment = response.data.assessment;
                form.value.ai_risk_assessment_level = response.data.level;
                form.value.ai_risk_assessment_summary = response.data.summary;
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
            })
            .finally(() => {
                loading.value = false;
            });
    };
</script>

<template>
    <div
        class="max-w-[1000px] flex flex-col gap-4 p-4 border rounded-lg shadow-lg mb-12"
    >
        <div class="text-2xl font-bold">AI-Driven Risk Assessment</div>
        <v-textarea
            class="col-span-full"
            v-model="form.ai_risk_assessment_summary"
            label="AI Risk Assessment Summary"
            outlined
            rows="5"
            :error="validationErrors.ai_risk_assessment_summary ? true : false"
            :error-messages="validationErrors.ai_risk_assessment_summary"
            :loading="loading"
        />
        <v-textarea
            class="col-span-full"
            v-model="form.ai_risk_assessment"
            label="AI Risk Assessment"
            outlined
            rows="15"
            :error="validationErrors.ai_risk_assessment ? true : false"
            :error-messages="validationErrors.ai_risk_assessment"
            :loading="loading"
        />
        <v-select
            class="col-span-full"
            v-model="form.ai_risk_assessment_level"
            :items="['Low', 'Medium', 'High']"
            label="Risk Level"
            outlined
            :error="validationErrors.ai_risk_assessment_level ? true : false"
            :error-messages="validationErrors.ai_risk_assessment_level"
            :loading="loading"
        />
        <div class="flex justify-end gap-4">
            <v-btn
                @click="generateRiskAssessment"
                color="secondary"
                :loading="loading"
            >
                Generate Risk Assessment
                <v-icon class="ml-4">mdi-refresh</v-icon>
            </v-btn>
            <v-btn
                @click="updateRiskAssessment"
                color="primary"
                :loading="loading"
            >
                Save
                <v-icon class="ml-4">mdi-content-save</v-icon>
            </v-btn>
        </div>
    </div>
</template>
