<template>
    <div class="p-8 max-w-[800px] mx-auto">
        <img class="max-w-40 p-2" src="/imgs/logo.png" />
        <div class="text-3xl font-bold">Condo Doc Review Terms of Service</div>
        <div class="mt-4 space-y-6">
            <p class="text-sm text-gray-600">Last Updated: January 15, 2025</p>

            <div>
                <h2 class="text-2xl font-semibold mt-4">1. Introduction</h2>
                <p>
                    Welcome to Condo Doc Review Ltd., your Condominium and
                    Strata Document Review Service. By engaging our document
                    review service, you ("the Client") agree to the terms
                    outlined in this Terms of Service. We encourage you to read
                    and understand these Terms carefully as they outline our
                    commitments to you and your responsibilities.
                </p>
            </div>

            <div>
                <h2 class="text-2xl font-semibold mt-4">
                    2. Description of Services
                </h2>
                <p>
                    Condo Doc Review Ltd. specializes in reviewing condominium
                    (condo) or strata corporation documents by:
                </p>
                <ul class="list-disc list-inside ml-4">
                    <li>
                        Summarizing client-provided documents in electronic
                        format.
                    </li>
                    <li>
                        Utilizing both human expertise and advanced AI
                        technologies to extract and analyze information.
                    </li>
                    <li>
                        Including relevant details from prior reviews of the
                        same condo/strata corporation where archived data is
                        available.
                    </li>
                </ul>
                <p>
                    Our detailed summaries provide charts, tables, and
                    AI-generated risk assessments to help you understand unit
                    contributions, cost comparisons, depreciation, and financial
                    forecasting, benefiting your decision-making process.
                </p>
            </div>

            <div>
                <h2 class="text-2xl font-semibold mt-4">
                    3. Scope and Limitations
                </h2>
                <p>
                    Extrapolated Information: We include information from
                    previous client documents related to the same condo/strata
                    corporation solely for your benefit.
                </p>
                <p>
                    Approximations: The summaries contain approximations in
                    charts, tables, and financial predictions derived from your
                    documents, archived data, and comparative studies.
                </p>
                <p>
                    AI-Generated Risk Assessment: These assessments are included
                    as a guide to help evaluate potential risks. They are based
                    on available data and should not be solely relied upon for
                    making decisions.
                </p>
                <p>
                    Home Inspection Reports: We do not review home inspection
                    reports. If you have concerns, you should discuss them
                    directly with your home inspector or real estate agent.
                </p>
            </div>

            <div>
                <h2 class="text-2xl font-semibold mt-4">
                    4. Indemnification and Acknowledgments
                </h2>
                <p>
                    By using our services, you agree to protect Condo Doc Review
                    Ltd. and its team from any legal claims or losses related to
                    your use of our summaries. We make every effort to provide
                    you with accurate and useful information, but we must note:
                </p>
                <ul class="list-disc list-inside ml-4">
                    <li>
                        Errors and Omissions: While we strive for accuracy, some
                        information may contain errors due to errors within the
                        documents reviewed.
                    </li>
                    <li>
                        Incomplete Documents: Summaries based on incomplete
                        documents may be less reliable, and it's essential that
                        they be as complete as possible for an accurate
                        assessment.
                    </li>
                </ul>
            </div>

            <div>
                <h2 class="text-2xl font-semibold mt-4">
                    5. Use of Data and Excerpts
                </h2>
                <p>
                    We respect your privacy and comply with relevant privacy
                    laws. We will:
                </p>
                <ul class="list-disc list-inside ml-4">
                    <li>Data Storage: Retain the documents you provide.</li>
                    <li>
                        Publication and Sharing: Use excerpts from your
                        documents for broader consumer protection and service
                        improvement without compromising personal information.
                    </li>
                </ul>
            </div>

            <div>
                <h2 class="text-2xl font-semibold mt-4">
                    6. Intellectual Property
                </h2>
                <p>
                    All summaries and assessments created by our service are the
                    intellectual property of Condo Doc Review Ltd. You can use
                    these materials for personal or internal business purposes
                    but cannot redistribute them without our written consent.
                </p>
            </div>

            <div>
                <h2 class="text-2xl font-semibold mt-4">
                    7. Customer Responsibilities
                </h2>
                <p>To help us serve you better, please:</p>
                <ul class="list-disc list-inside ml-4">
                    <li>
                        Provide all necessary and relevant documents in
                        electronic format.
                    </li>
                    <li>
                        Pay for the Service, including any charges from
                        third-party payment service providers.
                    </li>
                    <li>
                        Verify the accuracy and completeness of documents before
                        submission. This helps ensure the quality and utility of
                        our service.
                    </li>
                </ul>
            </div>

            <div>
                <h2 class="text-2xl font-semibold mt-4">8. Disclaimers</h2>
                <ul class="list-disc list-inside ml-4">
                    <li>
                        Accuracy: We are committed to providing high-quality
                        service; however, all our reports are based on available
                        information and may have limitations.
                    </li>
                    <li>
                        No Professional Advice: Our summaries should not replace
                        professional advice. Always consult a qualified
                        professional before making significant decisions based
                        on our reports.
                    </li>
                    <li>
                        Limitation of Liability: As permitted by law, we limit
                        our liability for any direct, indirect, or consequential
                        damages related to the use of our service to the amount
                        paid for the service provided.
                    </li>
                </ul>
            </div>

            <div>
                <h2 class="text-2xl font-semibold mt-4">9. Governing Law</h2>
                <p>
                    The laws of Alberta, Canada, govern these Terms. Any
                    disputes related to these Terms will be handled exclusively
                    in the courts of the Court of King's Bench of Alberta.
                </p>
            </div>

            <div>
                <h2 class="text-2xl font-semibold mt-4">
                    10. Changes to These Terms
                </h2>
                <p>
                    We may update these Terms at any time. The most current
                    version is always available on our website or upon request.
                    Continued use of our service after updates means you accept
                    the revised Terms.
                </p>
            </div>

            <div>
                <h2 class="text-2xl font-semibold mt-4">
                    11. Contact Information
                </h2>
                <p>
                    For any questions or concerns about these Terms, please
                    reach out to us at:
                </p>
                <ul class="list-none ml-4">
                    <li>
                        <strong>Email:</strong>
                        info@condodocreview.com
                    </li>
                    <li>
                        <strong>Phone:</strong>
                        780-886-0869
                    </li>
                    <li>
                        <strong>Address:</strong>
                        Box 234, Seba Beach, Alberta
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
