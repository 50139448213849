<script setup>
    import { ref, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';
    import { useToast } from 'vue-toast-notification';

    import { useDate } from 'vuetify';
    const adapter = useDate();

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});
    const defaultRecord = ref({
        fiscal_year_start: null,
        reporting_period_end: null,
    });
    const record = ref({});

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        item: Object,
        corporation: Object,
    });

    /**************************************************************
     *      Toast
     **************************************************************/
    const toast = useToast();

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        const action = mode.value === 'Create' ? axios.post : axios.patch;
        const endpoint =
            mode.value === 'Create'
                ? `/api/v1/corporations/${props.corporation.id}/operating-accounts`
                : `/api/v1/corporations/${props.corporation.id}/operating-accounts/${record.value.id}`;

        const payload = {
            fiscal_year_start: record.value.fiscal_year_start
                ? record.value.fiscal_year_start.toISOString().split('T')[0]
                : null,
            reporting_period_end: record.value.reporting_period_end
                ? record.value.reporting_period_end.toISOString().split('T')[0]
                : null,
        };

        action(endpoint, payload)
            .then(() => {
                toast.open({
                    message:
                        mode.value === 'Create'
                            ? 'Operating Account created successfully!'
                            : 'Operating Account updated successfully!',
                    type: 'success',
                });
                record.value = { ...defaultRecord.value };
                emit('close');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            });
    };

    const mode = ref('Create');

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/

    // Watch for changes on input record in order to set state for update or create modes
    watch(
        () => props.item,
        recordToUpdate => {
            if (recordToUpdate) {
                record.value = {
                    id: recordToUpdate.id,
                    fiscal_year_start: adapter.parseISO(
                        recordToUpdate.fiscal_year_start
                    ),
                    reporting_period_end: adapter.parseISO(
                        recordToUpdate.reporting_period_end
                    ),
                };
                mode.value = 'Update';
            } else {
                record.value = { ...defaultRecord.value };
                mode.value = 'Create';
            }
        }
    );

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};

            if (isOpen && mode.value === 'Create') {
                record.value = { ...defaultRecord.value };
            }
        }
    );

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :title="`${mode} Operating Account (Planned Vs. Actual)`"
        @close="onClose"
        @save="onSave"
    >
        <div class="grid grid-cols-2 gap-4">
            <div class="text-lg font-bold pl-5">Start of Fiscal Year</div>
            <div class="text-lg font-bold pl-5">End of Reporting Period</div>
            <div class="flex flex-col">
                <v-date-picker
                    v-model="record.fiscal_year_start"
                    required
                    outlined
                    show-adjacent-months
                    title="Start of the budget's fiscal reporting period"
                />
                <div
                    v-if="validationErrors.fiscal_year_start"
                    class="text-red-500 text-sm"
                >
                    <ul>
                        <li
                            v-for="error in validationErrors.fiscal_year_start"
                            :key="error"
                        >
                            {{ error }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="flex flex-col">
                <v-date-picker
                    v-model="record.reporting_period_end"
                    :disabled="!record.fiscal_year_start"
                    required
                    outlined
                    show-adjacent-months
                    title="End of the latest budget's reporting period"
                />
                <div
                    v-if="validationErrors.reporting_period_end"
                    class="text-red-500 text-sm"
                >
                    <ul>
                        <li
                            v-for="error in validationErrors.reporting_period_end"
                            :key="error"
                        >
                            {{ error }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </BaseModal>
</template>
