<script setup>
    import KPICard from './KPICard.vue';
    import { ref, computed } from 'vue';
    import { Pie } from 'vue-chartjs';
    import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    import { usePage } from '@inertiajs/vue3';
    import { useDisplay } from 'vuetify';

    ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

    const { smAndDown } = useDisplay();
    const { formatMoney } = useMoneyFormatter();
    const page = usePage();

    const components = ref(
        page.props.review.corporation.components.map(component => ({
            name: component.name,
            depreciation_percentage:
                component.corporation_component.depreciation_percentage,
            remaining_life_expectancy:
                component.corporation_component.remaining_life_expectancy,
            effective_age: component.corporation_component.effective_age,
            current_replacement_cost: Number(
                component.corporation_component.current_replacement_cost
            ),
            depreciation_date:
                component.corporation_component.depreciation_date,
        }))
    );

    const yearsSinceLastStudy = computed(() => {
        const studyDate = page.props.review.corporation.LatestReserveFundStudy
            .study_date
            ? new Date(
                  page.props.review.corporation.LatestReserveFundStudy.study_date
              )
            : new Date();
        const currentDate = new Date();
        return currentDate.getFullYear() - studyDate.getFullYear();
    });

    const totalAssets = computed(() => {
        return page.props.reserve_fund_analysis_chart_data.total_assets;
    });

    const totalDepreciated = computed(() => {
        return page.props.reserve_fund_analysis_chart_data.total_depreciation;
    });

    const totalRemaining = computed(() => {
        return page.props.reserve_fund_analysis_chart_data.total_remaining;
    });

    // Create chart data for the pie chart using the computed values
    const chartData = computed(() => {
        return {
            labels: ['Depreciation', 'Remaining Asset Equity'],
            datasets: [
                {
                    data: [totalDepreciated.value, totalRemaining.value],
                    backgroundColor: [
                        'rgba(211, 211, 211, 0.9)',
                        'rgba(34, 150, 34, 0.9)',
                    ],
                },
            ],
        };
    });

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 15,
                    font: { size: 13, weight: 'bold' },
                },
            },
            tooltip: {
                callbacks: {
                    label: context => {
                        const label = context.label || '';
                        const value = context.raw;
                        return `${label}: ${formatMoney(value, false)}`;
                    },
                },
            },
            datalabels: {
                formatter: value => `${formatMoney(value, false)}`,
                color: 'black',
                font: { size: 15, weight: 'bold' },
                backgroundColor: 'white',
                borderRadius: 5,
            },
        },
        cutout: 40,
    };
</script>

<template>
    <KPICard>
        <template #icon>3</template>
        <template #title>
            <div class="text-2xl md:text-4xl font-bold">Asset Depreciation</div>
        </template>
        <template #subtitle>
            <div>The total depreciation of the corporation's assets</div>
            <div class="text-gray-700 italic text-xs my-2">
                Note: this is based on the reserve fund planner's assumptions
                and estimates
            </div>
        </template>
        <template #chart>
            <div
                :class="{
                    'md:w-2/5': true,
                    'w-full flex justify-center': smAndDown,
                }"
            >
                <Pie :data="chartData" :options="chartOptions" />
            </div>
        </template>
        <template #additional-info>
            <div class="md:w-3/5">
                <div class="flex flex-col gap-1 md:gap-12">
                    <div class="border-dotted border-2 border-gray-400 p-6">
                        <div class="flex gap-2">
                            <div
                                class="font-bold text-lg md:text-2xl self-center"
                            >
                                Total Depreciation
                            </div>
                            <v-icon
                                small
                                class="self-center"
                                v-tooltip.bottom="
                                    'This is the sum total of the accrued depreciation of all assets.'
                                "
                            >
                                mdi-help-rhombus
                            </v-icon>
                        </div>
                        <div class="font-bold text-xl">
                            {{
                                formatMoney(
                                    page.props.reserve_fund_analysis_chart_data
                                        .total_depreciation
                                )
                            }}
                            <span class="text-sm">
                                As of
                                {{
                                    new Date(
                                        page.props.review.corporation.LatestReserveFundStudy.study_date
                                    ).getFullYear() || 'N/A'
                                }}
                                ({{ yearsSinceLastStudy }} years ago)
                            </span>
                        </div>
                    </div>
                    <div class="border-dotted border-2 border-gray-400 p-6">
                        <div class="flex gap-2">
                            <div
                                class="font-bold text-lg md:text-2xl self-center"
                            >
                                Your Share Of Depreciation
                            </div>
                            <v-icon
                                small
                                class="self-center"
                                v-tooltip.bottom="
                                    'This is the portion of depreciation your shares are liable for'
                                "
                            >
                                mdi-help-rhombus
                            </v-icon>
                        </div>
                        <div class="font-bold text-xl">
                            {{
                                formatMoney(
                                    page.props.reserve_fund_analysis_chart_data
                                        .total_depreciation *
                                        (page.props.review.OwnershipPercentage /
                                            100)
                                )
                            }}
                            <span class="text-sm">
                                As of
                                {{
                                    new Date(
                                        page.props.review.corporation.LatestReserveFundStudy.study_date
                                    ).getFullYear() || 'N/A'
                                }}
                                ({{ yearsSinceLastStudy }} years ago)
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #why-it-matters>
            This helps you understand the remaining useful life of the
            Corporation's components. High levels of depreciation are indicative
            of aging components that may require replacement in the near future,
            which may lead to levies if the reserve fund is not adequately
            funded.
        </template>
        <template #view-details>
            <div class="flex flex-col gap-4">
                <div class="flex flex-col md:flex-row justify-between">
                    <div class="font-bold">Total Assets</div>
                    <div>{{ formatMoney(totalAssets) }}</div>
                </div>
                <div class="flex flex-col md:flex-row justify-between">
                    <div class="font-bold">Depreciation</div>
                    <div>{{ formatMoney(totalDepreciated) }}</div>
                </div>
                <div class="flex flex-col md:flex-row justify-between">
                    <div class="font-bold">Remaining Equity</div>
                    <div>{{ formatMoney(totalRemaining) }}</div>
                </div>
            </div>
        </template>
    </KPICard>
</template>
