<script setup>
    import { ref } from 'vue';

    /**************************************************************
     *      Modal
     **************************************************************/
    import ManagementCompanyModal from '../../Components/Modals/ManagementCompanyModal.vue';
    const isModalOpen = ref(false);
    const selectedManagementCompany = ref(null);

    const showUpdateModal = item => {
        selectedManagementCompany.value = item;
        isModalOpen.value = true;
    };

    const showCreateModal = () => {
        selectedManagementCompany.value = null;
        isModalOpen.value = true;
    };

    const closeModal = () => {
        isModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = [
        {
            title: 'Company',
            key: 'company_name',
            value: item => item.company_name,
            sortable: false,
        },
        {
            title: 'Broker',
            key: 'broker',
            value: item => item.broker,
            sortable: false,
        },
        {
            title: 'Phone',
            key: 'phone',
            value: item => item.phone,
            sortable: false,
        },
        {
            title: 'Email',
            key: 'email',
            value: item => item.email,
            sortable: false,
        },
        {
            title: 'Address',
            key: 'address',
            value: item => `${item.address}, ${item.city}, ${item.postal}`,
            sortable: false,
        },
    ];

    const searchTrigger = ref(Date.now());

    const additionalParams = ref({
        search: '',
        type: null,
    });
</script>
<template>
    <AuthenticatedLayout>
        <ManagementCompanyModal
            :isOpen="isModalOpen"
            :item="selectedManagementCompany"
            @close="closeModal"
        />
        <TableViewManager
            title="Management Companies"
            :headers="headers"
            subtitle="This is where you can view a listing of all management companies, system wide."
            endpoint="/api/v1/management-companies"
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            :actions="[
                {
                    title: 'Action',
                    icon: 'mdi-pencil',
                    buttonText: 'Edit',
                    buttonColor: 'secondary',
                    action: item => showUpdateModal(item),
                },
            ]"
        >
            <template #actions>
                <v-btn color="primary" class="px-4" @click="showCreateModal">
                    Create New
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn>
            </template>
            <template #filters>
                <v-text-field
                    v-model="additionalParams.search"
                    label="Search"
                    dense
                    hide-details
                ></v-text-field>
            </template>
        </TableViewManager>
    </AuthenticatedLayout>
</template>
