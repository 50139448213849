<script setup>
    import { ref } from 'vue';
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Modal
     **************************************************************/
    import MeetingMinutesModal from '../../Components/Modals/MeetingMinutesModal.vue';
    import CorporationSummaryMeetingMinutesModal from '../../Components/Modals/CorporationSummaryMeetingMinutesModal.vue';

    const isModalOpen = ref(false);
    const isSummaryModalOpen = ref(false);
    const selectedMeetingMinutes = ref(null);

    const showUpdateModal = item => {
        selectedMeetingMinutes.value = item;
        isModalOpen.value = true;
    };

    const showCreateModal = () => {
        selectedMeetingMinutes.value = null;
        isModalOpen.value = true;
    };

    const showSummaryModal = () => {
        isSummaryModalOpen.value = true;
    };

    const closeModal = () => {
        isModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    const closeSummaryModal = () => {
        isSummaryModalOpen.value = false;
    };

    const props = defineProps({
        corporation: Object,
    });

    const destroy = item => {
        if (
            !confirm(
                'Are you sure you want to delete these meeting minutes? This action cannot be undone.'
            )
        ) {
            return;
        }

        axios
            .delete(
                `/api/v1/corporations/${props.corporation.id}/meeting-minutes/${item.id}`
            )
            .then(response => {
                toast.success('Meeting Minutes Deleted Successfully');
                searchTrigger.value = Date.now(); // cause the table to reload
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.');
            });
    };

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = [
        { title: 'Date', key: 'date', sortable: false },
        {
            title: 'Meeting Type',
            key: 'type',
            value: item => item.type.toUpperCase(),
            sortable: false,
        },
        {
            title: 'AI Generated',
            key: 'documents',
            value: item => (item.documents.length > 0 ? 'Yes' : 'No'),
            sortable: false,
        },
    ];

    const searchTrigger = ref(Date.now());

    const additionalParams = ref({
        search: '',
        type: null,
    });
</script>
<template>
    <div>
        <MeetingMinutesModal
            :corporation="corporation"
            :isOpen="isModalOpen"
            :item="selectedMeetingMinutes"
            @close="closeModal"
        />
        <CorporationSummaryMeetingMinutesModal
            :corporation="corporation"
            :isOpen="isSummaryModalOpen"
            @close="closeSummaryModal"
        />
        <TableViewManager
            title="Meeting Minutes"
            :headers="headers"
            :endpoint="`/api/v1/corporations/${corporation.id}/meeting-minutes`"
            subtitle="This is where you can view a listing of a corporation's general meetings and AGMs."
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            hide-breadcrumbs
            :actions="[
                {
                    title: 'Action',
                    icon: 'mdi-pencil',
                    buttonText: 'Edit',
                    buttonColor: 'secondary',
                    action: item => showUpdateModal(item),
                },
                {
                    title: 'Delete',
                    icon: 'mdi-delete',
                    buttonText: 'Delete',
                    buttonColor: 'error',
                    action: item => destroy(item),
                },
            ]"
        >
            <template #actions>
                <v-btn color="secondary" class="px-4" @click="showSummaryModal">
                    Summary View
                    <v-icon class="self-center ml-2">mdi-eye</v-icon>
                </v-btn>
                <v-btn color="primary" class="px-4" @click="showCreateModal">
                    Create New
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn>
            </template>
        </TableViewManager>
    </div>
</template>
