<template>
    <div class="bg-[#131313] text-[#f1f1f1] mt-24">
        <div class="md:m-24 max-w-[1400px] mx-auto py-24 px-8">
            <div class="font-bold mt-4 text-3xl md:text-7xl">
                Looking for More Guidance?
            </div>
            <div class="mt-2">
                Purchasing a condominium involves more than just a financial
                commitment—it's a lifestyle decision. Ensure you reach out to
                the management company for detailed information and
                clarifications. If you need further assistance or personalized
                advice, feel free to contact us!
            </div>
            <div class="md:text-xl mt-8 font-bold">Get in touch with us</div>
            <div class="grid md:grid-cols-3 gap-4">
                <div>
                    <div class="font-bold mt-2">Email</div>
                    <div>admin@condodocreview.com</div>
                </div>
                <div>
                    <div class="font-bold mt-2">Phone</div>
                    <div>+1 (780) 886-0869</div>
                </div>
                <div>
                    <div class="font-bold mt-2">Website</div>
                    <div>www.condodocreview.com</div>
                </div>
            </div>
        </div>
    </div>
</template>
