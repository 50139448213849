<script setup>
    import { ref, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';
    import AISummarizer from '../AISummarizer.vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        item: Object,
        corporation: Object,
    });

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        const action = mode.value === 'Create' ? axios.post : axios.patch;
        const endpoint =
            mode.value === 'Create'
                ? `/api/v1/corporations/${props.corporation.id}/projects`
                : `/api/v1/corporations/${props.corporation.id}/projects/${record.value.id}`;

        const payload = {
            ...record.value,
        };

        action(endpoint, payload)
            .then(response => {
                emit('close');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            });
    };

    const record = ref({});
    const mode = ref('Create');

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});
    const defaultRecord = ref({
        component: '',
        year: new Date().getFullYear(),
        was_planned: false,
        planned_amount: '',
        was_completed: false,
        actual_amount: '',
    });

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/

    // Watch for changes on input record in order to set state for update or create modes
    watch(
        () => props.item,
        recordToUpdate => {
            if (recordToUpdate) {
                record.value = {
                    ...recordToUpdate,
                };
                mode.value = 'Update';
            } else {
                record.value = { ...defaultRecord.value };
                mode.value = 'Create';
            }
        }
    );

    const handleRawTextUpdated = rawText => {
        record.value.notes_raw_ai = rawText;
    };

    const handleSummaryTextUpdated = summaryText => {
        record.value.notes_summarized_ai = summaryText;
    };

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};

            if (isOpen && mode.value === 'Create') {
                record.value = { ...defaultRecord.value };
            }
        }
    );

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :title="`${mode} Capital Project`"
        @close="onClose"
        @save="onSave"
    >
        <div class="grid grid-cols-2 gap-4">
            <v-text-field
                v-model="record.year"
                label="Year"
                placeholder="Year"
                type="number"
                :error="validationErrors.year ? true : false"
                :error-messages="validationErrors.year"
            />

            <v-text-field
                v-model="record.component"
                label="Component"
                :error="validationErrors.component ? true : false"
                :error-messages="validationErrors.component"
            />

            <v-checkbox v-model="record.was_planned" label="Was Planned" />

            <CurrencyInput
                :disabled="!record.was_planned"
                v-model="record.planned_amount"
                label="Planned Amount"
                :error="validationErrors.planned_amount ? true : false"
                :error-messages="validationErrors.planned_amount"
            />

            <v-checkbox v-model="record.was_completed" label="Was Completed" />

            <CurrencyInput
                :disabled="!record.was_completed"
                v-model="record.actual_amount"
                label="Actual Amount"
                :error="validationErrors.actual_amount ? true : false"
                :error-messages="validationErrors.actual_amount"
            />
        </div>
    </BaseModal>
</template>
