<script setup>
    import { ref, computed } from 'vue';
    import { Bar } from 'vue-chartjs';
    import { usePage } from '@inertiajs/vue3';
    import {
        Chart as ChartJS,
        BarElement,
        Tooltip,
        Legend,
        CategoryScale,
        LinearScale,
    } from 'chart.js';
    import { useDisplay } from 'vuetify';
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';

    ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale);

    const { smAndDown } = useDisplay();
    const { formatMoney } = useMoneyFormatter();

    const page = usePage();

    const transformData = data =>
        Object.entries(data).map(([category, values]) => ({
            category,
            planned: values.planned,
            actual: values.actual,
        }));

    const expensesDataByCategory = ref(
        transformData(
            page.props.operating_budget_plan_vs_actual_chart_data
                .planned_vs_actual_breakdown_expenses_by_category
        )
    );

    const expensesDataByItem = ref(
        page.props.operating_budget_plan_vs_actual_chart_data
            .planned_vs_actual_breakdown_expenses_by_item
    );

    const incomeData = ref(
        transformData(
            page.props.operating_budget_plan_vs_actual_chart_data
                .planned_vs_actual_breakdown_income
        )
    );

    const createChartData = (data, labels, colors) =>
        computed(() => ({
            labels: data.value.map(item => item.name || item.category),
            datasets: labels.map((label, index) => ({
                label,
                data: data.value.map(item => item[label.toLowerCase()]),
                backgroundColor: colors[index],
                borderColor: colors[index].replace(/, 0\.\d+\)/, ', 1)'),
                borderWidth: 1,
            })),
        }));

    const expensesChartDataByCategory = createChartData(
        expensesDataByCategory,
        ['Planned', 'Actual'],
        ['rgba(54, 162, 235, 0.9)', 'rgba(255, 99, 132, 0.9)']
    );

    const incomeChartData = createChartData(
        incomeData,
        ['Planned', 'Actual'],
        ['rgba(54, 162, 235, 0.9)', 'rgba(255, 99, 132, 0.9)']
    );

    const expensesChartDataByItem = createChartData(
        expensesDataByItem,
        ['Planned', 'Actual'],
        ['rgba(54, 162, 235, 0.9)', 'rgba(255, 99, 132, 0.9)']
    );

    const chartOptions = ref({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: { display: true },
            tooltip: {
                callbacks: {
                    label: tooltipItem => formatMoney(tooltipItem.raw, true),
                },
            },
            datalabels: {
                formatter: value => `${formatMoney(value, false)}`,
                color: 'black',
                font: { size: 16, weight: 'bold' },
                backgroundColor: 'white',
                borderRadius: 5,
            },
        },
        scales: {
            x: { title: { display: true, text: 'Category' } },
            y: {
                beginAtZero: true,
                ticks: { callback: value => formatMoney(value, true) },
                title: { display: true, text: 'Amount (CAD)' },
            },
        },
    });

    const horizontalChartOptions = ref({
        responsive: true,
        maintainAspectRatio: true,
        indexAxis: 'x',
        plugins: {
            legend: { display: true },
            tooltip: {
                callbacks: {
                    label: tooltipItem => formatMoney(tooltipItem.raw, true),
                },
            },
            datalabels: false,
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: { callback: value => formatMoney(value, true) },
                title: { display: true, text: 'Amount (CAD)' },
            },
            x: { title: { display: true, text: 'Category' } },
        },
    });

    const calculateTotal = (data, field) =>
        computed(() =>
            data.value.reduce((sum, item) => sum + parseFloat(item[field]), 0)
        );

    const totalPlannedIncome = calculateTotal(incomeData, 'planned');
    const totalActualIncome = calculateTotal(incomeData, 'actual');
    const totalPlannedExpenses = calculateTotal(
        expensesDataByCategory,
        'planned'
    );
    const totalActualExpenses = calculateTotal(
        expensesDataByCategory,
        'actual'
    );
    const totalPlannedExpensesByItem = calculateTotal(
        expensesDataByItem,
        'planned'
    );
    const totalActualExpensesByItem = calculateTotal(
        expensesDataByItem,
        'actual'
    );

    const calculateDifference = (planned, actual) =>
        computed(() => planned.value - actual.value);

    const incomeDifference = calculateDifference(
        totalPlannedIncome,
        totalActualIncome
    );
    const expensesDifference = calculateDifference(
        totalPlannedExpenses,
        totalActualExpenses
    );
    const expensesDifferenceByItem = calculateDifference(
        totalPlannedExpensesByItem,
        totalActualExpensesByItem
    );
</script>

<template>
    <div class="max-w-[1400px] mx-auto px-8 my-24">
        <h1 class="text-2xl md:text-4xl font-bold mb-8">
            Operating Account Income & Expenses
        </h1>

        <div class="flex flex-col md:flex-row gap-4 my-12">
            <div class="max-w-[800px] self-center">
                <div>
                    This section compares the Corporation’s planned budget with
                    actual spending and income up to
                    {{ page.props.operation_budget_stats.reporting_period_end }}
                    ({{
                        page.props.operation_budget_stats.fiscal_year_progress
                    }}% through the latest fiscal year).
                </div>
            </div>
            <p
                class="max-w-[800px] self-center md:border-l-2 md:pl-2 text-sm bg-gray-50 p-2"
            >
                The Operating Account covers daily expenses, while the Reserve
                Fund is for long-term savings.
            </p>
        </div>

        <section class="mb-12">
            <div class="mb-12">
                <h2 class="text-2xl font-bold mb-4">
                    Operating Income Streams
                </h2>
                <Bar
                    v-if="!smAndDown"
                    :data="incomeChartData"
                    :options="chartOptions"
                />
                <div class="mt-8">
                    <div>
                        Planned Yearly Income:
                        {{ formatMoney(totalPlannedIncome, true) }}
                    </div>
                    <div>
                        Income as of
                        {{
                            page.props.operation_budget_stats
                                .reporting_period_end
                        }}: {{ formatMoney(totalActualIncome, true) }}
                    </div>
                    <div>
                        {{
                            incomeDifference >= 0
                                ? 'Outstanding Receivables'
                                : 'Excess Income'
                        }}:
                        {{ formatMoney(Math.abs(incomeDifference), true) }}
                    </div>
                </div>
            </div>
            <v-expansion-panels v-if="smAndDown" class="mt-8">
                <v-expansion-panel
                    v-for="income in incomeData"
                    :key="income.category"
                >
                    <v-expansion-panel-title>
                        <div class="font-bold">
                            {{ income.category }} -
                            {{ formatMoney(income.actual, true) }}
                        </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <div>
                            <div>
                                <strong>Planned:</strong>
                                {{ formatMoney(income.planned, true) }}
                            </div>
                            <div>
                                <strong>Latest:</strong>
                                {{ formatMoney(income.actual, true) }}
                            </div>
                        </div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>

            <div>
                <h2 class="text-2xl font-bold mb-4">
                    Operating Expenses by Category
                </h2>
                <Bar
                    v-if="!smAndDown"
                    :data="expensesChartDataByCategory"
                    :options="horizontalChartOptions"
                />
                <div class="mt-8">
                    <div>
                        Yearly Planned Expenses:
                        {{ formatMoney(totalPlannedExpenses, true) }}
                    </div>
                    <div>
                        Latest Expenses as of
                        {{
                            page.props.operation_budget_stats
                                .reporting_period_end
                        }}:
                        {{ formatMoney(totalActualExpenses, true) }}
                    </div>
                    <div
                        :class="{
                            'text-green-500': expensesDifference >= 0,
                            'text-red-500': expensesDifference < 0,
                        }"
                    >
                        {{
                            expensesDifference >= 0
                                ? 'Remaining in Budget'
                                : 'Over Spending By'
                        }}:
                        {{ formatMoney(Math.abs(expensesDifference), true) }}
                    </div>
                </div>
                <v-expansion-panels v-if="smAndDown" class="mt-8">
                    <v-expansion-panel
                        v-for="expense in expensesDataByCategory"
                        :key="expense.category"
                    >
                        <v-expansion-panel-title>
                            <div class="font-bold">
                                {{ expense.category }} -
                                {{ formatMoney(expense.actual, true) }}
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div>
                                <div>
                                    <strong>Planned:</strong>
                                    {{ formatMoney(expense.planned, true) }}
                                </div>
                                <div>
                                    <strong>Latest:</strong>
                                    {{ formatMoney(expense.actual, true) }}
                                </div>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </section>

        <section class="mb-12">
            <h2 class="text-2xl font-bold mb-4">Operating Expenses by Item</h2>
            <Bar
                v-if="!smAndDown"
                :data="expensesChartDataByItem"
                :options="horizontalChartOptions"
            />
            <div class="mt-8">
                <div>
                    Yearly Planned Expenses by Item:
                    {{ formatMoney(totalPlannedExpensesByItem, true) }}
                </div>
                <div>
                    Latest Expenses by Item as of
                    {{
                        page.props.operation_budget_stats.reporting_period_end
                    }}:
                    {{ formatMoney(totalActualExpensesByItem, true) }}
                </div>
                <div
                    :class="{
                        'text-green-500': expensesDifferenceByItem >= 0,
                        'text-red-500': expensesDifferenceByItem < 0,
                    }"
                >
                    {{
                        expensesDifferenceByItem >= 0
                            ? 'Remaining in Budget'
                            : 'Over Spending By'
                    }}:
                    {{ formatMoney(Math.abs(expensesDifferenceByItem), true) }}
                </div>
            </div>
            <v-expansion-panels v-if="smAndDown" class="mt-8">
                <v-expansion-panel
                    v-for="expense in expensesDataByItem"
                    :key="expense.name"
                >
                    <v-expansion-panel-title>
                        <div class="font-bold">
                            {{ expense.name }} -
                            {{ formatMoney(expense.actual, true) }}
                        </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <div>
                            <div>
                                <strong>Planned:</strong>
                                {{ formatMoney(expense.planned, true) }}
                            </div>
                            <div>
                                <strong>Latest:</strong>
                                {{ formatMoney(expense.actual, true) }}
                            </div>
                        </div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </section>
    </div>
</template>
