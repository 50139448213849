import './bootstrap';
import '../css/app.scss';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';

import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

import { createPinia } from 'pinia';
const pinia = createPinia();

import { vuetify } from '@/plugins/vuetify/vuetify';

const appName = import.meta.env.VITE_APP_NAME || 'Condo Document Review';

// Define global layout components
import LoginPageLayout from './Layouts/LoginPageLayout.vue';
import AuthenticatedLayout from './Layouts/AuthenticatedLayout.vue';
import TableViewManager from './Components/TableViewer/TableViewManager.vue';
import TableView from './Components/TableViewer/TableView.vue';
import CurrencyInput from './Components/Inputs/CurrencyInput.vue';

createInertiaApp({
    title: title => `${appName}`,
    resolve: name =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue')
        ),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(vuetify)
            .use(pinia)
            .use(ToastPlugin)
            .component('LoginPageLayout', LoginPageLayout)
            .component('AuthenticatedLayout', AuthenticatedLayout)
            .component('TableView', TableView)
            .component('TableViewManager', TableViewManager)
            .component('CurrencyInput', CurrencyInput)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
