<script setup>
    import axios from 'axios';
    import { ref } from 'vue';
    import { useToast } from 'vue-toast-notification';
    const emit = defineEmits(['refresh']);

    const loading = ref(false);

    const toast = useToast();
    const validationErrors = ref({});

    const props = defineProps({
        review: {
            type: Object,
            required: true,
        },
    });

    const makeChanges = ref(false);

    const form = ref({
        id: props.review.id,
        is_paid: props.review.is_paid,
        payment_date: props.review.payment_date,
        review_cost: props.review.review_cost / 100,
        processing_fee: props.review.processing_fee / 100,
        sub_total: props.review.sub_total / 100,
        gst: props.review.gst / 100,
        discount_amount: props.review.discount_amount / 100,
        final_total: props.review.final_total / 100,
        coupon_was_valid: props.review.coupon_was_valid,
        coupon_code: props.review.coupon_code,
    });

    /**************************************************************
     *      Update Payment Info for review
     **************************************************************/
    const updatePaymentInformation = () => {
        if (
            !confirm(
                'Careful! Are you sure you want to do this? Incorrect information can cause issues.'
            )
        ) {
            return;
        }

        loading.value = true;
        validationErrors.value = {};

        const payload = {
            is_paid: form.value.is_paid,
            payment_date: form.value.payment_date,
            review_cost: form.value.review_cost * 100,
            processing_fee: form.value.processing_fee * 100,
            sub_total: form.value.sub_total * 100,
            gst: form.value.gst * 100,
            discount_amount: form.value.discount_amount * 100,
            final_total: form.value.final_total * 100,
            coupon_was_valid: form.value.coupon_was_valid,
            coupon_code: form.value.coupon_code,
        };

        axios
            .post(`/api/v1/reviews/${form.value.id}/payment`, payload)
            .then(response => {
                toast.open({
                    message: 'Review payment information updated successfully!',
                    type: 'success',
                });

                emit('refresh');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            })
            .finally(() => {
                loading.value = false;
            });
    };

    const downloadInvoice = () => {
        window.open(`/api/v1/reviews/${props.review.id}/invoice`, '_blank');
    };
</script>
<template>
    <div class="max-w-[1000px] p-8 border rounded-lg shadow-lg mb-12">
        <div class="flex justify-between border-b pb-4 mb-12">
            <div class="text-2xl self-center font-bold mb-8">
                Payment & Invoice
            </div>
            <v-btn
                color="primary"
                class="mb-12"
                :loading="loading"
                @click="downloadInvoice"
            >
                Download Invoice
            </v-btn>
        </div>

        <v-switch
            v-model="makeChanges"
            :loading="loading"
            label="Make Changes"
        ></v-switch>

        <div v-if="makeChanges" class="grid grid-cols-2 gap-4">
            <v-switch
                v-model="form.is_paid"
                :loading="loading"
                label="Is Paid"
            ></v-switch>
            <v-text-field
                v-model="form.payment_date"
                :loading="loading"
                label="Payment Date"
                type="date"
                :error="validationErrors.payment_date ? true : false"
                :error-messages="validationErrors.payment_date"
            ></v-text-field>
            <CurrencyInput
                v-model="form.review_cost"
                :loading="loading"
                label="Review Cost"
                :error="validationErrors.review_cost ? true : false"
                :error-messages="validationErrors.review_cost"
            ></CurrencyInput>
            <CurrencyInput
                v-model="form.processing_fee"
                :loading="loading"
                label="Processing Fee"
                :error="validationErrors.processing_fee ? true : false"
                :error-messages="validationErrors.processing_fee"
            ></CurrencyInput>
            <CurrencyInput
                v-model="form.sub_total"
                :loading="loading"
                label="Sub Total"
                :error="validationErrors.sub_total ? true : false"
                :error-messages="validationErrors.sub_total"
            ></CurrencyInput>
            <CurrencyInput
                v-model="form.gst"
                :loading="loading"
                label="GST"
                :error="validationErrors.gst ? true : false"
                :error-messages="validationErrors.gst"
            ></CurrencyInput>
            <CurrencyInput
                v-model="form.discount_amount"
                :loading="loading"
                label="Discount Amount"
                :error="validationErrors.discount_amount ? true : false"
                :error-messages="validationErrors.discount_amount"
            ></CurrencyInput>
            <CurrencyInput
                v-model="form.final_total"
                :loading="loading"
                label="Final Total"
                :error="validationErrors.final_total ? true : false"
                :error-messages="validationErrors.final_total"
            ></CurrencyInput>
            <v-switch
                v-model="form.coupon_was_valid"
                :loading="loading"
                label="Coupon Was Valid"
            ></v-switch>
            <v-text-field
                v-model="form.coupon_code"
                :loading="loading"
                label="Coupon Code"
                :error="validationErrors.coupon_code ? true : false"
                :error-messages="validationErrors.coupon_code"
            ></v-text-field>
            <v-btn
                color="red"
                :loading="loading"
                @click="updatePaymentInformation"
            >
                Update Payment Information
            </v-btn>
        </div>

        <div v-else class="grid grid-cols-2 gap-4">
            <div>
                <strong>Is Paid:</strong>
                {{ form.is_paid ? 'Yes' : 'No' }}
            </div>
            <div>
                <strong>Payment Date:</strong>
                {{ form.payment_date }}
            </div>
            <div>
                <strong>Review Cost:</strong>
                ${{ form.review_cost }}
            </div>
            <div>
                <strong>Processing Fee:</strong>
                ${{ form.processing_fee }}
            </div>
            <div>
                <strong>Sub Total:</strong>
                ${{ form.sub_total }}
            </div>
            <div>
                <strong>GST:</strong>
                ${{ form.gst }}
            </div>
            <div>
                <strong>Discount Amount:</strong>
                ${{ form.discount_amount }}
            </div>
            <div>
                <strong>Final Total:</strong>
                ${{ form.final_total }}
            </div>
            <div>
                <strong>Coupon Was Valid:</strong>
                {{ form.coupon_was_valid ? 'Yes' : 'No' }}
            </div>
            <div>
                <strong>Coupon Code:</strong>
                {{ form.coupon_code }}
            </div>
        </div>
    </div>
</template>
