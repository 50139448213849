<template>
    <v-responsive>
        <v-app class="font-roboto">
            <v-main>
                <div class="bg-[#FEFEFE]">
                    <slot />
                </div>
            </v-main>

            <!-- Footer -->
            <v-footer app class="p-2">
                <div class="text-center text-xs w-full">
                    © {{ new Date().getFullYear() }} Condo Doc Review
                </div>
            </v-footer>
        </v-app>
    </v-responsive>
</template>
