import { defineStore } from 'pinia';
import { computed } from 'vue';

import { usePage } from '@inertiajs/vue3';

export const useUserStore = defineStore('user', () => {
    const userIsAdmin = computed(() => {
        const { props } = usePage();

        const roles = props?.auth?.user?.roles || [];

        const adminRoles = ['super_admin', 'admin'];

        const profileRoles = roles.map(role => role.name);

        return adminRoles.some(role => profileRoles.includes(role));
    });

    const userIsContractor = computed(() => {
        const { props } = usePage();

        const roles = props?.auth?.user?.roles || [];

        const contractorRoles = ['contractor'];

        const profileRoles = roles.map(role => role.name);

        return contractorRoles.some(role => profileRoles.includes(role));
    });

    const userIsSuperAdmin = computed(() => {
        const { props } = usePage();

        const roles = props?.auth?.user?.roles || [];

        const superAdminRoles = ['super_admin'];

        const profileRoles = roles.map(role => role.name);

        return superAdminRoles.some(role => profileRoles.includes(role));
    });

    const userIsAgent = computed(() => {
        const { props } = usePage();

        const roles = props?.auth?.user?.roles || [];

        const agentRoles = ['agent'];

        const profileRoles = roles.map(role => role.name);

        return agentRoles.some(role => profileRoles.includes(role));
    });

    return {
        userIsAdmin,
        userIsAgent,
        userIsContractor,
        userIsSuperAdmin,
    };
});
