<script setup>
    import { ref, inject } from 'vue';
    import { router } from '@inertiajs/vue3';

    /**************************************************************
     *      Modal
     **************************************************************/
    import CorporationModal from '../../Components/Modals/CorporationModal.vue';
    const isModalOpen = ref(false);
    const selectedCorporation = ref(null);

    const accessCorporation = item => {
        router.visit(`/corporations/${item.id}`);
    };

    const showCreateModal = () => {
        selectedCorporation.value = null;
        isModalOpen.value = true;
    };

    const closeModal = () => {
        isModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = [
        { title: 'Name', key: 'name', sortable: false },
        { title: 'Plan Number', key: 'plan_number', sortable: false },
        {
            title: 'Address',
            key: 'address',
            value: item => {
                const addressParts = [
                    item.address,
                    item.city,
                    item.province_id ? item.province.name : null,
                    item.postal,
                ].filter(part => part);
                const fullAddress = addressParts.join(', ');
                return fullAddress.length > 15
                    ? fullAddress.substring(0, 15) + '...'
                    : fullAddress;
            },
            sortable: false,
        },
        {
            title: 'Type',
            key: 'type',
            value: item => item.type.name,
            sortable: false,
        },
    ];

    const searchTrigger = ref(Date.now());

    const additionalParams = ref({
        search: '',
        type: null,
    });

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';

    const optionsStore = useOptionsStore();
    const { fetchCorporationTypeOptions } = optionsStore;
    const { corporationTypeOptions } = storeToRefs(optionsStore);

    fetchCorporationTypeOptions();
</script>
<template>
    <AuthenticatedLayout>
        <CorporationModal
            :isOpen="isModalOpen"
            :item="selectedCorporation"
            @close="closeModal"
        />
        <TableViewManager
            title="Corporations"
            :headers="headers"
            endpoint="/api/v1/corporations"
            subtitle="This is where you can view a listing of all corporations."
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            :actions="[
                {
                    title: 'Access',
                    icon: 'mdi-arrow-right-bold-circle-outline',
                    buttonText: 'Access',
                    buttonColor: 'secondary',
                    action: item => accessCorporation(item),
                },
            ]"
        >
            <template #actions>
                <v-btn color="primary" class="px-4" @click="showCreateModal">
                    Create New
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn>
            </template>
            <template #filters>
                <v-text-field
                    v-model="additionalParams.search"
                    label="Search"
                    dense
                    hide-details
                ></v-text-field>
                <v-autocomplete
                    v-model="additionalParams.type"
                    :items="corporationTypeOptions"
                    label="Corporation Type"
                    clearable
                    dense
                    hide-details
                />
            </template>
        </TableViewManager>
    </AuthenticatedLayout>
</template>
