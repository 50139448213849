<script setup>
    import { ref, computed, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';
    import { vMaska } from 'maska/vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        item: Object,
        what: String,
    });

    const unmaskedPhone = ref('');
    defineExpose({ unmaskedPhone });

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        const action = mode.value === 'Create' ? axios.post : axios.patch;
        const endpoint =
            mode.value === 'Create'
                ? '/api/v1/tenants'
                : `/api/v1/tenants/${record.value.id}`;

        const payload = {
            name: record.value.name,
        };

        action(endpoint, payload)
            .then(response => {
                record.value = { ...defaultRecord.value };
                emit('close');
            })
            .catch(error => {
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            });
    };

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';
    const { roleOptions, adminRoleOptions, clientRoleOptions } =
        storeToRefs(useOptionsStore());

    const record = ref({});
    const mode = ref('Create');

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});
    const defaultRecord = ref({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        role: null,
        password: '',
    });

    const setPassword = ref(true);

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/

    // Watch for changes on input record in order to set state for update or create modes
    watch(
        () => props.item,
        recordToUpdate => {
            if (recordToUpdate) {
                record.value = {
                    ...recordToUpdate,
                };
                setPassword.value = false;
                mode.value = 'Update';
            } else {
                record.value = { ...defaultRecord.value };
                setPassword.value = true;
                mode.value = 'Create';
            }
        }
    );

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};
        }
    );

    const emit = defineEmits(['close']);
    const onClose = () => {
        record.value = { ...defaultRecord.value };
        emit('close');
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :title="`${mode} ${what}`"
        @close="onClose"
        @save="onSave"
    >
        <div class="grid grid-cols-2 gap-4">
            <v-text-field
                v-model="record.name"
                label="Name"
                type="text"
                :error="validationErrors.name ? true : false"
                :error-messages="validationErrors.name"
            />
        </div>
    </BaseModal>
</template>
