<script setup>
    import { ref, inject } from 'vue';

    /**************************************************************
     *      Modal
     **************************************************************/
    import DeveloperModal from '../../Components/Modals/DeveloperModal.vue';
    const isModalOpen = ref(false);
    const selectedDeveloper = ref(null);

    const showUpdateModal = item => {
        selectedDeveloper.value = item;
        isModalOpen.value = true;
    };

    const showCreateModal = () => {
        selectedDeveloper.value = null;
        isModalOpen.value = true;
    };

    const closeModal = () => {
        isModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = [{ title: 'Name', key: 'name', sortable: false }];

    const searchTrigger = ref(Date.now());

    const additionalParams = ref({
        search: '',
        type: null,
    });
</script>
<template>
    <AuthenticatedLayout>
        <DeveloperModal
            :isOpen="isModalOpen"
            :item="selectedDeveloper"
            @close="closeModal"
        />
        <TableViewManager
            title="Developers"
            :headers="headers"
            endpoint="/api/v1/developers"
            subtitle="This is where you can view a listing of a developers, system wide."
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            :actions="[
                {
                    title: 'Action',
                    icon: 'mdi-pencil',
                    buttonText: 'Edit',
                    buttonColor: 'secondary',
                    action: item => showUpdateModal(item),
                },
            ]"
        >
            <template #actions>
                <v-btn color="primary" class="px-4" @click="showCreateModal">
                    Create New
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn>
            </template>
            <template #filters>
                <v-text-field
                    v-model="additionalParams.search"
                    label="Search"
                    dense
                    hide-details
                ></v-text-field>
            </template>
        </TableViewManager>
    </AuthenticatedLayout>
</template>
