<script setup>
    import { ref } from 'vue';

    import EditDocumentModal from '@/Components/Modals/EditDocumentModal.vue';

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = [
        {
            title: 'Name',
            key: 'name',
            value: item => item.original_name,
            sortable: false,
        },
        {
            title: 'Document Type',
            key: 'document_type.name',
            value: item => item.document_type.display_name,
            sortable: false,
        },
        {
            title: 'Corporation',
            key: 'corporation.name',
            value: item => item?.corporation?.name ?? 'N/A',
            sortable: false,
        },
        {
            title: 'Review #',
            key: 'review.review_case_number',
            value: item => item?.review?.review_case_number ?? 'N/A',
            sortable: false,
        },
        {
            title: 'File Info',
            key: 'file_info',
            value: item => {
                const sizeInMB = item.size / (1024 * 1024);
                if (sizeInMB >= 0.01) {
                    return `${item.mime_type.replace('application/', '').toUpperCase()} - ${sizeInMB.toFixed(2)} MB`;
                } else {
                    const sizeInKB = item.size / 1024;
                    return `${item.mime_type.replace('application/', '').toUpperCase()} - ${sizeInKB.toFixed(2)} KB`;
                }
            },
            sortable: false,
        },
    ];

    const searchTrigger = ref(Date.now());

    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';
    const { documentTypeOptions, corporationOptions } =
        storeToRefs(useOptionsStore());

    const optionsStore = useOptionsStore();
    const { fetchDocumentTypeOptions, fetchCorporationOptions } = optionsStore;

    fetchDocumentTypeOptions();
    fetchCorporationOptions();

    const additionalParams = ref({
        search: '',
        documentType: null,
        corporation: null,
    });

    const download = item => {
        window.open(`/api/v1/documents/${item.id}/download`, '_blank');
    };

    const view = item => {
        window.open(`/api/v1/documents/${item.id}/view`, '_blank');
    };

    const destroy = item => {
        if (
            !confirm(
                'Are you sure you want to delete this document? This action cannot be undone.'
            )
        ) {
            return;
        }

        axios
            .delete(`/api/v1/documents/${item.id}`)
            .then(response => {
                toast.success('Document Deleted Successfully');
                searchTrigger.value = Date.now(); // cause the table to reload
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.');
            });
    };

    /**************************************************************
     *      Modal
     **************************************************************/
    const isModalOpen = ref(false);
    const selectedDocument = ref(null);

    const showUpdateModal = item => {
        selectedDocument.value = item;
        isModalOpen.value = true;
    };

    const closeModal = () => {
        selectedDocument.value = null;
        isModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };
</script>
<template>
    <AuthenticatedLayout>
        <EditDocumentModal
            :item="selectedDocument"
            :isOpen="isModalOpen"
            @close="closeModal"
        />
        <TableViewManager
            title="Documents"
            :headers="headers"
            subtitle="This is where you can view all system-wide documents."
            endpoint="/api/v1/documents"
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            :actions="[
                {
                    title: 'Action',
                    icon: 'mdi-download',
                    buttonText: 'Download',
                    buttonColor: 'secondary',
                    action: item => download(item),
                },
                {
                    title: 'View',
                    icon: 'mdi-eye',
                    buttonText: 'View',
                    buttonColor: 'primary',
                    action: item => view(item),
                },
                {
                    title: 'Update',
                    icon: 'mdi-pencil',
                    buttonText: 'Edit',
                    buttonColor: 'secondary',
                    action: item => showUpdateModal(item),
                },
                {
                    title: 'Delete',
                    icon: 'mdi-delete',
                    buttonText: 'Delete',
                    buttonColor: 'error',
                    action: item => destroy(item),
                },
            ]"
        >
            <template #filters>
                <v-text-field
                    v-model="additionalParams.search"
                    label="Search"
                    dense
                    hide-details
                ></v-text-field>
                <v-autocomplete
                    v-model="additionalParams.documentType"
                    :items="documentTypeOptions"
                    label="Document Type"
                    clearable
                    dense
                    hide-details
                />
                <v-autocomplete
                    v-model="additionalParams.corporation"
                    :items="corporationOptions"
                    label="Corporation"
                    clearable
                    dense
                    hide-details
                />
            </template>
        </TableViewManager>
    </AuthenticatedLayout>
</template>
