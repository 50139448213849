<script setup>
    import KPICard from './KPICard.vue';
    import { useDisplay } from 'vuetify';
    import { ref, computed, watch } from 'vue';
    import { Bar } from 'vue-chartjs';
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    import {
        Chart as ChartJS,
        BarElement,
        Tooltip,
        Legend,
        CategoryScale,
        LinearScale,
    } from 'chart.js';

    ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale);

    import { usePage } from '@inertiajs/vue3';
    const page = usePage();
    const { smAndDown } = useDisplay();
    const { formatMoney } = useMoneyFormatter();

    /**
     * Data structure:
     * {
     *   total_replacement_cost: number,
     *   unit_replacement_cost: number,
     *   listing_price: number
     * }
     */
    const insuranceVsListingData = ref({
        total_rebuild_cost: 0,
        unit_rebuild_cost: 0,
        listing_price: 0,
    });

    // Watch for new data from the server
    watch(
        () => page.props.replacement_cost_chart_data,
        newValue => {
            insuranceVsListingData.value.total_rebuild_cost =
                newValue.total_replacement_cost;
            insuranceVsListingData.value.unit_rebuild_cost =
                newValue.unit_replacement_cost;
            insuranceVsListingData.value.listing_price = newValue.listing_price;
        },
        { immediate: true }
    );

    // Prepare data for the bar chart
    const chartData = computed(() => ({
        labels: ['Insurable Rebuild Cost', 'Listing Price'],
        datasets: [
            {
                label: 'Cost Comparison',
                data: [
                    insuranceVsListingData.value.unit_rebuild_cost,
                    insuranceVsListingData.value.listing_price,
                ],
                backgroundColor: ['#2196F3', '#4CAF50'], // or colors of your choice
            },
        ],
    }));

    // Chart options
    // Chart options
    const chartOptions = ref({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: { display: false },
            tooltip: {
                callbacks: {
                    label: tooltipItem => {
                        return `${formatMoney(tooltipItem.raw, false)}`;
                    },
                },
            },
            datalabels: {
                formatter: value => `${formatMoney(value, false)}`,
                color: 'black',
                font: { size: 16, weight: 'bold' },
                backgroundColor: 'white',
                borderRadius: 5,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: value => `${formatMoney(value)}`,
                },
            },
        },
    });

    // Compute the absolute difference
    const rawDifference = computed(
        () =>
            insuranceVsListingData.value.listing_price -
            insuranceVsListingData.value.unit_rebuild_cost
    );

    // Compute the % difference relative to the rebuild cost
    const percentDifference = computed(() => {
        if (!insuranceVsListingData.value.unit_rebuild_cost) return 0;
        return (
            (rawDifference.value /
                insuranceVsListingData.value.unit_rebuild_cost) *
            100
        );
    });

    // A simple classification of risk based on how big the gap is
    const riskLevel = computed(() => {
        const absPercent = Math.abs(percentDifference.value);
        if (absPercent < 10) {
            return 'Low';
        } else if (absPercent >= 10 && absPercent <= 30) {
            return 'Medium';
        } else {
            return 'High';
        }
    });

    const riskExplanation = computed(() => {
        switch (riskLevel.value) {
            case 'Low':
                return 'The listing price is close to the insurance rebuild cost. This generally indicates a lower risk scenario, but always consider market factors and building condition. Always consult professional advice.';
            case 'Medium':
                return "The listing price differs somewhat from the rebuild cost. Investigate further to understand if it's due to market demand, building upgrades, or underlying issues. Always consult professional advice.";
            case 'High':
                return 'There is a significant gap between the listing price and rebuild cost. This could indicate a hot market (price well above cost) or potential red flags (price well below cost). Always consult professional advice.';
            default:
                return '';
        }
    });
</script>

<template>
    <KPICard>
        <!-- Title & Icon -->
        <template #icon>4</template>
        <template #title>
            <div class="text-2xl md:text-4xl font-bold">
                Rebuild Cost Comparison
            </div>
        </template>
        <template #subtitle>
            <div class="text-sm md:text-base">
                A snapshot comparing the cost to rebuild the unit brand new, to
                what the market currently demands.
                <span class="italic">
                    Many factors—like age, location, and demand—affect true
                    market value, so this figure is
                    <span class="font-bold">not</span>
                    intended for price negotiation.
                </span>
            </div>
        </template>

        <!-- Chart -->
        <template #chart>
            <div
                :class="{
                    'md:w-1/2': true,
                    'w-full flex justify-center': smAndDown,
                }"
            >
                <Bar :data="chartData" :options="chartOptions" />
            </div>
        </template>

        <!-- Additional Info: Gap and Risk -->
        <template #additional-info>
            <div class="md:w-1/2 flex flex-col gap-4 md:gap-6">
                <div class="border-dotted border-2 border-gray-400 p-6">
                    <div class="text-xl md:text-2xl font-bold">Cost Gap</div>
                    <div class="flex items-center gap-2 mt-2">
                        <div
                            :class="{
                                'text-green-500': rawDifference > 0,
                                'text-red-500': rawDifference < 0,
                            }"
                            class="text-2xl font-bold"
                        >
                            ${{ Math.abs(rawDifference).toLocaleString() }}
                        </div>
                        <v-icon
                            size="large"
                            :class="{
                                'text-green-500': rawDifference > 0,
                                'text-red-500': rawDifference < 0,
                            }"
                        >
                            {{
                                rawDifference > 0
                                    ? 'mdi-arrow-up'
                                    : 'mdi-arrow-down'
                            }}
                        </v-icon>
                    </div>
                    <div class="text-sm md:text-base mt-2">
                        (Listing price is
                        <strong>
                            {{ rawDifference > 0 ? 'above' : 'below' }}
                        </strong>
                        the rebuild cost)
                    </div>
                </div>

                <div class="border-dotted border-2 border-gray-400 p-6">
                    <div class="text-xl md:text-2xl font-bold">
                        Potential Risk
                    </div>
                    <div class="flex items-center gap-2 mt-2">
                        <div
                            :class="{
                                'text-green-500': riskLevel === 'Low',
                                'text-yellow-500': riskLevel === 'Medium',
                                'text-red-500': riskLevel === 'High',
                            }"
                            class="text-xl font-bold"
                        >
                            {{ riskLevel }}
                        </div>
                        <!-- Example icon usage for risk -->
                        <v-icon
                            size="small"
                            :class="{
                                'text-green-500': riskLevel === 'Low',
                                'text-yellow-500': riskLevel === 'Medium',
                                'text-red-500': riskLevel === 'High',
                            }"
                        >
                            {{
                                riskLevel === 'Low'
                                    ? 'mdi-shield-check'
                                    : riskLevel === 'Medium'
                                      ? 'mdi-alert'
                                      : 'mdi-alert-circle'
                            }}
                        </v-icon>
                    </div>

                    <div class="text-sm md:text-base mt-2">
                        {{ riskExplanation }}
                    </div>
                </div>
            </div>
        </template>

        <!-- Why It Matters -->
        <template #why-it-matters>
            <div class="flex flex-col gap-4">
                <p>
                    <strong>Insurable Rebuild Cost</strong>
                    represents the estimated amount needed to rebuild the condo
                    unit from scratch for insurance purposes. It
                    <em>does not</em>
                    include factors like depreciation, location desirability, or
                    overall market conditions.
                </p>
                <p>
                    This comparison
                    <strong>is not</strong>
                    a direct market valuation tool. A higher listing price
                    compared to rebuild cost could mean a hot market or premium
                    location. A lower one might suggest an older property or a
                    buyer's market. Neither is inherently “good” or “bad”
                    without further investigation.
                </p>
            </div>
        </template>

        <!-- View Details -->
        <template #view-details>
            <div class="flex flex-col gap-2">
                <div>
                    The listing price is
                    <span
                        :class="{
                            'text-green-500': rawDifference > 0,
                            'text-red-500': rawDifference < 0,
                        }"
                    >
                        {{ Math.abs(percentDifference).toFixed(2) }}% (≈ ${{
                            Math.abs(rawDifference).toLocaleString()
                        }})
                    </span>
                    {{ rawDifference > 0 ? 'above' : 'below' }} the rebuild
                    cost.
                </div>
                <div>
                    A
                    <strong>big difference</strong>
                    can be a sign of a
                    <em>hot</em>
                    market (if higher) or potential underlying issues (if
                    lower). Use this metric to start a conversation with real
                    estate and insurance professionals—do not rely on it alone
                    to judge the condo’s fair market value.
                </div>
            </div>
        </template>
    </KPICard>
</template>
