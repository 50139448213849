<script setup>
    import { ref, computed } from 'vue';
    import { usePage } from '@inertiajs/vue3';
    import { useDisplay } from 'vuetify';
    const { smAndDown } = useDisplay();

    const page = usePage();

    /**************************************************************
     * Components
     **************************************************************/
    import ClientReviewDocumentUploader from '@/Components/Review/ClientReviewDocumentUploader.vue';
    import ClientReviewPaymentProcessor from '@/Components/Review/ClientReviewPaymentProcessor.vue';
    import ClientReviewDetails from '@/Components/Review/ClientReviewDetails.vue';
    import ClientReviewSubmissionStatusDashboard from '@/Components/Review/ClientReviewSubmissionStatusDashboard.vue';
    import ReviewInviteModal from '@/Components/Modals/ReviewInviteModal.vue';

    /**************************************************************
     * State/Data
     **************************************************************/
    const currentStep = ref(1);
    const completedSteps = ref([false, false, false, false]);

    const markStepAs = (step, what) => {
        completedSteps.value[step - 1] = what;
    };

    const setToReviewStep = () => {
        completedSteps.value = [true, true, true, true];
        currentStep.value = 4;
    };

    const onPaymentComplete = () => {
        setToReviewStep();
    };

    if (page.props.review.is_paid) {
        setToReviewStep();
    }

    /**************************************************************
     * Modal
     **************************************************************/
    const isModalOpen = ref(false);
    const showInviteModal = () => {
        isModalOpen.value = true;
    };
    const closeModal = () => {
        isModalOpen.value = false;
    };

    /**************************************************************
     * Support FAB & Overlay
     **************************************************************/
    const isSupportOpen = ref(false);
    const toggleSupport = () => {
        isSupportOpen.value = !isSupportOpen.value;
    };

    const openSupport = () => {
        isSupportOpen.value = true;
    };

    /**************************************************************
     * Breadcrumbs
     **************************************************************/
    const breadcrumbs = computed(() => {
        return [
            {
                title: 'Condo Doc Review',
                disabled: true,
                href: '#',
            },
            {
                title: 'Reviews',
                disabled: false,
                href: '/reviews',
            },
            {
                title: 'Case # ' + page.props.review.review_case_number,
                disabled: false,
                href: '#',
            },
        ];
    });
</script>
<template>
    <AuthenticatedLayout>
        <ReviewInviteModal
            :item="page.props.review"
            :isOpen="isModalOpen"
            @close="closeModal"
        />
        <v-breadcrumbs
            v-if="!smAndDown"
            class="-ml-3 mb-4"
            :items="breadcrumbs"
        >
            <template v-slot:divider>
                <v-icon icon="mdi-chevron-right"></v-icon>
            </template>
        </v-breadcrumbs>

        <div>
            <div class="text-xl self-center font-bold">
                Welcome {{ page?.props?.auth?.user?.first_name }}, Let's Get
                Started!
            </div>
            <p class="text-sm text-gray-500">
                This is the Review Submission Dashboard where you upload your
                documents for review.
            </p>
        </div>

        <div>
            <div
                class="flex flex-col p-8 rounded-lg my-6"
                :style="{
                    background:
                        'linear-gradient(157.38deg, #F6F6FB 8.53%, #E6E1EC 44.37%, #DBE8F4 78.12%)',
                }"
            >
                <div class="text-xl font-bold">Invite Collaborators</div>
                <p class="text-base text-gray-800 py-4">
                    You can invite your real estate agent, a family member, or
                    friend, to help you with the review process. Click the
                    button below to send an invitation.
                </p>
                <div class="mt-2">
                    <v-btn color="primary" @click="showInviteModal">
                        Invite
                    </v-btn>
                </div>
            </div>
        </div>

        <v-stepper
            v-model="currentStep"
            :items="['Documents', 'Details', 'Payment', 'Review']"
        >
            <template v-slot:item.1>
                <v-card flat>
                    <ClientReviewDocumentUploader
                        @open-support="openSupport"
                        @step-status="markStepAs"
                        :review="page.props.review"
                    />
                </v-card>
            </template>

            <template v-slot:item.2>
                <v-card flat>
                    <ClientReviewDetails
                        @step-status="markStepAs"
                        :review="page.props.review"
                    />
                </v-card>
            </template>

            <template v-slot:item.3>
                <v-card flat>
                    <ClientReviewPaymentProcessor
                        v-if="currentStep === 3"
                        @step-status="markStepAs"
                        @payment-complete="onPaymentComplete"
                        :review="page.props.review"
                    />
                </v-card>
            </template>

            <template v-slot:item.4>
                <v-card flat>
                    <ClientReviewSubmissionStatusDashboard
                        :review="page.props.review"
                        v-if="currentStep === 4"
                    />
                </v-card>
            </template>

            <template v-slot:actions>
                <div class="p-4 pb-6 flex gap-2 justify-end">
                    <v-btn
                        size="large"
                        color="secondary"
                        v-if="currentStep > 1 && currentStep < 4"
                        @click="currentStep--"
                        class="mr-4"
                    >
                        Previous
                    </v-btn>
                    <v-btn
                        size="large"
                        color="primary"
                        v-if="currentStep < 3"
                        @click="currentStep++"
                        :disabled="!completedSteps[currentStep - 1]"
                    >
                        Next
                    </v-btn>
                </div>
            </template>
        </v-stepper>

        <div
            :class="[
                'fixed',
                smAndDown ? 'bottom-32' : 'bottom-14',
                'right-8',
                'z-50',
            ]"
        >
            <v-btn
                icon
                :size="smAndDown ? 'small' : 'large'"
                color="primary"
                @click="toggleSupport"
                class="shadow-lg rounded-full"
            >
                <v-icon icon="mdi-help"></v-icon>
            </v-btn>
        </div>

        <v-overlay
            v-model="isSupportOpen"
            persistent
            class="align-center justify-center"
        >
            <v-card>
                <div class="py-6 px-12">
                    <div class="flex items-center justify-between mb-6">
                        <div class="text-2xl font-bold">Need Support?</div>
                        <v-btn icon size="small" @click="toggleSupport">
                            <v-icon icon="mdi-close"></v-icon>
                        </v-btn>
                    </div>
                    <div class="text-sm text-gray-700 mb-4">
                        If you have any questions or concerns, or if you are
                        missing required documentation, please reach out:
                    </div>
                    <div class="text-sm mb-2">
                        <a
                            class="font-bold text-blue-500"
                            href="mailto:admin@condodocreview.com"
                        >
                            admin@condodocreview.com
                        </a>
                    </div>
                    <div class="text-sm mb-4">
                        <a
                            class="font-bold text-blue-500"
                            href="tel:+17808861848"
                        >
                            1-780-886-0869
                        </a>
                    </div>
                    <div class="text-xs text-gray-500">
                        Our office hours are:
                        <ul class="list-disc ml-4">
                            <li>Mon - Thu: 8am - 6pm</li>
                            <li>Fri: 8am - 4:30pm</li>
                            <li>Closed Sat, Sun & Stat Holidays</li>
                        </ul>
                    </div>
                </div>
            </v-card>
        </v-overlay>
    </AuthenticatedLayout>
</template>
