<script setup>
    import { ref, computed } from 'vue';
    import { usePage } from '@inertiajs/vue3';

    import { useDisplay } from 'vuetify';
    const { smAndDown } = useDisplay();

    const page = usePage();
    const deficiencies = ref([]);

    deficiencies.value = page.props.review.corporation.deficiencies.map(
        deficiency => {
            return {
                id: deficiency.id,
                componentName: deficiency.component_name,
                hasTechnicalAudit: deficiency.has_technical_audit,
                auditSummaryRaw: deficiency.audit_summary_raw_ai,
                auditSummarySummarized: deficiency.audit_summary_summarized_ai,
                hasEngineeringReport: deficiency.has_engineering_report,
                dateOfReport: deficiency.date_of_report,
                projectCost: deficiency.estimated_project_cost,
                engineeringReportRaw: deficiency.engineering_report_raw_ai,
                engineeringReportSummarized:
                    deficiency.engineering_report_summarized_ai,
                deficiencyTypes: deficiency.deficiency_types,
            };
        }
    );

    const formatCurrency = value => {
        if (value === null || value === undefined) return 'N/A';
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value);
    };
</script>

<template>
    <div
        class="px-8"
        :class="[
            smAndDown ? 'my-12 py-12 bg-[#f3f3f3]' : 'py-24 my-24 bg-[#f3f3f3]',
        ]"
    >
        <div class="max-w-[1400px] mx-auto">
            <div class="text-2xl md:text-4xl font-bold mb-8">
                Building Deficiencies
                <span v-if="deficiencies.length > 0">
                    ({{ deficiencies.length }})
                </span>
            </div>
            <p class="max-w-[800px] self-center mb-8">
                Below is a list of building deficiencies that have been
                identified.
            </p>
            <div v-if="deficiencies.length == 0" class="text-2xl font-bold">
                No building component deficiencies have been reported to us as
                of the date of this review.
            </div>
            <v-expansion-panels v-else>
                <v-expansion-panel
                    v-for="deficiency in deficiencies"
                    :key="deficiency.id"
                >
                    <v-expansion-panel-title>
                        <div class="flex justify-between items-center">
                            <div class="font-bold">
                                {{ deficiency.componentName }}
                            </div>
                        </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <div class="flex flex-col gap-4">
                            <!-- <div class="text-sm text-gray-700">
                                <div class="font-bold">Audit Summary:</div>
                                <div>
                                    {{
                                        deficiency.auditSummarySummarized ||
                                        'No summary available'
                                    }}
                                </div>
                            </div> -->
                            <div class="text-sm text-gray-700">
                                <div class="font-bold">Engineering Report:</div>
                                <div>
                                    {{
                                        deficiency.engineeringReportSummarized ||
                                        'No report available'
                                    }}
                                </div>
                            </div>
                            <div class="text-sm text-gray-700">
                                <div class="font-bold">
                                    Estimated Project Cost:
                                </div>
                                <div>
                                    {{
                                        deficiency.projectCost
                                            ? formatCurrency(
                                                  deficiency.projectCost
                                              )
                                            : 'Not Provided'
                                    }}
                                </div>
                            </div>
                            <div class="text-sm text-gray-700">
                                <div class="font-bold">Deficiency Type:</div>
                                <div>
                                    <ul>
                                        <li
                                            v-for="type in deficiency.deficiencyTypes"
                                            :key="type.id"
                                        >
                                            {{ type.display_name }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>
