<script setup>
    import KPICard from './KPICard.vue';
    import { useDisplay } from 'vuetify';
    import { ref, computed, watch } from 'vue';
    import { Bar } from 'vue-chartjs';
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    import {
        Chart as ChartJS,
        BarElement,
        Tooltip,
        Legend,
        CategoryScale,
        LinearScale,
    } from 'chart.js';

    import { usePage } from '@inertiajs/vue3';
    const page = usePage();

    const { smAndDown } = useDisplay();

    ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale);

    const { formatMoney } = useMoneyFormatter();

    const plannedVsActualData = ref({
        planned: 0,
        actual: 0,
    });

    watch(
        () =>
            page.props.operating_budget_plan_vs_actual_chart_data
                .planned_vs_actual_total,
        newValue => {
            plannedVsActualData.planned = newValue.planned;
            plannedVsActualData.actual = newValue.actual;
        },
        { immediate: true }
    );

    const chartData = computed(() => ({
        labels: [
            'Yearly Budgeted Expenses',
            `Expenditure - ${
                page.props.operation_budget_stats.fiscal_year_elapsed_time
            }`,
        ],
        datasets: [
            {
                label: 'Expenditure',
                data: [plannedVsActualData.planned, plannedVsActualData.actual],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.9)',
                    'rgba(54, 162, 235, 0.9)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 0.1)',
                ],
                borderWidth: [1, 1],
            },
        ],
    }));

    const chartOptions = ref({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: { display: false },
            tooltip: {
                callbacks: {
                    label: tooltipItem => {
                        return `${formatMoney(tooltipItem.raw, false)}`;
                    },
                },
            },
            datalabels: {
                formatter: value => `${formatMoney(value, false)}`,
                color: 'black',
                font: { size: 16, weight: 'bold' },
                backgroundColor: 'white',
                borderRadius: 5,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: value => `${formatMoney(value)}`,
                },
            },
        },
    });

    const difference = computed(
        () => plannedVsActualData.planned - plannedVsActualData.actual
    );
</script>

<template>
    <KPICard>
        <template #icon>5</template>
        <template #title>
            <div class="text-2xl md:text-4xl font-bold">
                Operating Budget & Expenses
            </div>
        </template>
        <template #subtitle>
            <div>
                <div>
                    A quick glance at the provided fiscal budget and known
                    expenditure.
                </div>
                <div class="text-sm text-gray-600">
                    {{ page.props.operation_budget_stats.fiscal_year_start }} to
                    {{ page.props.operation_budget_stats.reporting_period_end }}
                    -
                    {{
                        page.props.operation_budget_stats
                            .fiscal_year_elapsed_time
                    }}
                </div>
            </div>
        </template>
        <template #chart>
            <div
                :class="{
                    'md:w-1/2': true,
                    'w-full flex justify-center': smAndDown,
                }"
            >
                <Bar :data="chartData" :options="chartOptions" />
            </div>
        </template>
        <template #additional-info>
            <div class="md:w-1/2">
                <div class="flex flex-col md:flex-row gap-4 md:gap-12">
                    <!-- <div
                        class="border-dotted border-2 border-gray-400 p-6 flex gap-2"
                    >
                        <div>
                            <div class="font-bold text-lg md:text-3xl">
                                {{
                                    page.props.operation_budget_stats
                                        .fiscal_year_progress
                                }}%
                            </div>
                            <div>Through Provided Operating Year</div>
                            <div class="flex mt-2">
                                <div
                                    class="text-xs uppercase bg-gray-500 text-white text-center px-2 py-1"
                                >
                                    {{
                                        page.props.operation_budget_stats
                                            .fiscal_year_elapsed_time
                                    }}
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div
                        class="border-dotted border-2 border-gray-400 p-6 flex gap-2"
                    >
                        <div>
                            <div
                                :class="
                                    difference >= 0
                                        ? 'text-green-500'
                                        : 'text-red-500'
                                "
                                class="font-bold text-lg md:text-3xl"
                            >
                                {{
                                    difference >= 0
                                        ? 'On Budget'
                                        : 'Overspending'
                                }}
                            </div>
                            <div>
                                {{ formatMoney(difference) }}
                                {{
                                    difference >= 0
                                        ? 'Remains in the provided Budget'
                                        : 'Over Budget'
                                }}
                            </div>
                        </div>
                        <div class="flex items-center">
                            <v-icon
                                size="large"
                                :class="
                                    difference >= 0
                                        ? 'text-green-500'
                                        : 'text-red-500'
                                "
                            >
                                {{
                                    difference >= 0
                                        ? 'mdi-check-circle'
                                        : 'mdi-alert-circle'
                                }}
                            </v-icon>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #why-it-matters>
            <div>
                <p class="mb-4">
                    Managing the condo corporation's budget is crucial for
                    keeping finances stable and avoiding surprises for owners.
                </p>
                <ul class="list-disc pl-6 space-y-2 mb-4">
                    <li>
                        <strong>Overspending:</strong>
                        If costs exceed the budget (e.g., maintenance), the
                        association may dip into savings or raise condo fees,
                        creating financial strain and possibly lowering property
                        value.
                    </li>
                    <li>
                        <strong>Underspending:</strong>
                        Spending less than planned might delay key repairs or
                        maintenance, leading to bigger, costlier problems later.
                    </li>
                </ul>
                <p>
                    A balanced budget keeps the property well-maintained,
                    protects your investment, and ensures smooth community
                    operations. Regular reviews help avoid issues and maintain
                    transparency. Stay informed—your wallet and home value
                    depend on it!
                </p>
            </div>
        </template>
        <template #view-details>
            <div class="grid grid-cols-1 md:grid-cols-1 gap-4 w-full">
                <v-expansion-panels>
                    <v-expansion-panel
                        v-for="(value, key) in page.props
                            .operating_budget_plan_vs_actual_chart_data
                            .planned_vs_actual_breakdown_expenses_by_category"
                        :key="key"
                    >
                        <v-expansion-panel-title>
                            <div class="font-bold text-xl">{{ key }}</div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div class="text-lg">
                                Planned: {{ formatMoney(value.planned, false) }}
                            </div>
                            <div class="text-lg">
                                Actual: {{ formatMoney(value.actual, false) }}
                            </div>
                            <div
                                :class="
                                    value.planned - value.actual > 0
                                        ? 'text-green-500'
                                        : 'text-red-500'
                                "
                                class="text-lg"
                            >
                                {{
                                    value.planned - value.actual > 0
                                        ? `${formatMoney(value.planned - value.actual, false)} Remains in the Budget`
                                        : `${formatMoney(value.actual - value.planned, false)} Over Budget`
                                }}
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </template>
    </KPICard>
</template>
