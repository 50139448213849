<script setup>
    import { usePage } from '@inertiajs/vue3';
    import { computed, ref } from 'vue';

    import { useDisplay } from 'vuetify';
    const { smAndDown } = useDisplay();
</script>
<template>
    <div
        class="relative flex flex-col gap-8 md:flex-row py-32 px-8 bg-fixed bg-center bg-cover mb-12"
        style="background-image: url('/imgs/interior-img-2.jpg')"
    >
        <div class="absolute inset-0 bg-[#131313] opacity-50"></div>
        <div
            class="relative w-full max-w-[1400px] mx-auto flex flex-col md:flex-row gap-8"
        >
            <div class="md:w-1/2">
                <div class="md:text-6xl text-3xl font-bold text-white">
                    DIGGING INTO THE DETAILS OF YOUR REVIEW
                </div>
            </div>
            <div class="md:w-1/2 flex items-center">
                <div class="text-white">
                    Now that we've covered the top 5 KPIs, let's dig into the
                    finer details of your review.
                </div>
            </div>
        </div>
    </div>
</template>
