<script setup>
    import { ref, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        item: Object,
        corporation: Object,
    });

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';
    const optionsStore = useOptionsStore();
    const { fetchComponentOptions } = optionsStore;

    const { componentOptions } = storeToRefs(optionsStore);

    // Fetch options
    fetchComponentOptions();

    /**************************************************************
     *      Form
     **************************************************************/
    const record = ref({});
    const mode = ref('Create');
    const validationErrors = ref({});
    const defaultRecord = ref({
        component_id: null,
        actual_life_expectancy: null,
        effective_age: null,
        current_replacement_cost: null,
    });

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        const action = mode.value === 'Create' ? axios.post : axios.patch;
        const endpoint =
            mode.value === 'Create'
                ? `/api/v1/corporations/${props.corporation.id}/components/`
                : `/api/v1/corporations/${props.corporation.id}/components/`;

        const payload = {
            ...record.value,
        };

        action(endpoint, payload)
            .then(response => {
                record.value = { ...defaultRecord.value };
                emit('close');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            });
    };

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/

    // Watch for changes to options in order to set initial record values
    watch([componentOptions], () => {
        if (props.initialRecord) {
            record.value.component_id = props.initialRecord.component_id;
        }
    });

    // Watch for changes on input record in order to set state for update or create modes
    watch(
        () => props.item,
        recordToUpdate => {
            if (recordToUpdate) {
                record.value = {
                    ...recordToUpdate,
                };
                mode.value = 'Update';
            } else {
                record.value = { ...defaultRecord.value };
                mode.value = 'Create';
            }
        }
    );

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};

            if (isOpen && mode.value === 'Create') {
                record.value = { ...defaultRecord.value };
            }
        }
    );

    function setDefaultExpectedLife() {
        const component = componentOptions.value.find(
            option => option.value === record.value.component_id
        );

        if (component) {
            record.value.actual_life_expectancy =
                component.default_life_expectancy;
        }
    }

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :title="`${mode} Corporation Component`"
        @close="onClose"
        @save="onSave"
    >
        <div class="grid grid-cols-2 gap-4">
            <v-autocomplete
                v-model="record.component_id"
                :items="componentOptions"
                label="Component"
                required
                @update:modelValue="setDefaultExpectedLife"
                outlined
                :disabled="mode === 'Update'"
                :error="validationErrors.component_id ? true : false"
                :error-messages="validationErrors.component_id"
            />

            <v-text-field
                v-model="record.effective_age"
                label="Effective Age"
                type="number"
                :error="validationErrors.effective_age ? true : false"
                :error-messages="validationErrors.effective_age"
            />

            <v-text-field
                v-model="record.actual_life_expectancy"
                label="Total Life Expectancy (Years)"
                :error="validationErrors.actual_life_expectancy ? true : false"
                :error-messages="validationErrors.actual_life_expectancy"
            />

            <CurrencyInput
                v-model="record.current_replacement_cost"
                label="Current Replacement Cost"
                :error="
                    validationErrors.current_replacement_cost ? true : false
                "
                :error-messages="validationErrors.current_replacement_cost"
            />
        </div>
    </BaseModal>
</template>
