<script setup>
    import { usePage } from '@inertiajs/vue3';
    import { computed, ref } from 'vue';
    const page = usePage();

    import { useDisplay } from 'vuetify';
    const { smAndDown } = useDisplay();

    const review = computed(() => page.props.review);

    const contents = ref([
        'Introduction',
        'Overview',
        'Key Performance Indicators',
        'Timeline',
        'Risk Assessment',
        'Component Depreciation Analysis',
        'Building Deficiencies',
        'Meeting Minutes',
        'Capital Projects',
        'Operating Account Income & Expenses',
        'Insurance',
        'Bylaw Q/A',
        'Footnotes',
    ]);
</script>
<template>
    <div
        :class="[
            'flex flex-col md:flex-row p-8 max-w-[1400px] mx-auto',
            smAndDown ? 'my-8' : 'my-24 gap-8',
        ]"
    >
        <div class="md:w-2/3">
            <div class="md:text-6xl text-3xl font-bold">INTRODUCTION</div>
            <div class="mt-4 max-w-[800px]">
                <div>
                    When you purchase a condominium, you acquire more than just
                    the unit itself; you also gain a share in the corporation—
                    including its
                    <span class="font-bold">assets and liabilities</span>
                    . This report clarifies the details of your purchase and
                    provides a thorough analysis of the corporation's
                    <span class="font-bold">
                        operations and financial position
                    </span>
                    , enabling you to make a well-informed decision.
                </div>
                <div class="mt-4">
                    {{ review.ai_risk_assessment_summary }}
                </div>
                <div class="text-xs text-gray-500 mt-4">
                    Please note that all information provided in this review
                    summary is based on the most recent data supplied to us. The
                    accuracy and completeness of this summary are dependent on
                    the quality and comprehensiveness of the documentation
                    provided.
                </div>
            </div>
        </div>
        <div class="md:w-1/3">
            <div
                :class="[
                    'md:p-6 bg-[#F3F3F3] rounded-2xl p-8',
                    smAndDown ? 'mt-12' : '',
                ]"
            >
                <div class="text-3xl font-bold">Contents</div>
                <div
                    v-for="(content, index) in contents"
                    :key="index"
                    class="uppercase text-xs font-medium pt-2"
                >
                    {{ content }}
                </div>
            </div>
        </div>
    </div>
</template>
