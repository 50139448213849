<script setup>
    import axios from 'axios';
    import { ref, onMounted } from 'vue';
    import { Link } from '@inertiajs/vue3';

    const email = ref('');
    const code = ref('');
    const password = ref('');
    const confirmPassword = ref('');
    const isResetStep = ref(false);
    const validationErrors = ref({});

    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    // Function to parse query parameters from the URL
    const getQueryParams = () => {
        const params = new URLSearchParams(window.location.search);
        email.value = params.get('email') || '';
        code.value = params.get('reset_code') || '';
        isResetStep.value = !!email.value && !!code.value;
    };

    // Run the function on component mount to check the URL for parameters
    onMounted(() => {
        getQueryParams();
    });

    const requestReset = function () {
        axios
            .post('api/v1/forgot-password', {
                email: email.value,
            })
            .then(response => {
                toast.open({
                    message:
                        'Password reset email sent successfully! Please check your email.',
                    type: 'success',
                });

                isResetStep.value = true;
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                ) {
                    if (error?.response?.data?.errors) {
                        validationErrors.value = error.response.data.errors;
                    }
                }

                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
            });
    };

    import { router } from '@inertiajs/vue3';

    const resetPassword = () => {
        axios
            .post('api/v1/reset-password', {
                email: email.value,
                token: code.value,
                password: password.value,
                password_confirmation: confirmPassword.value,
            })
            .then(response => {
                toast.open({
                    message:
                        'Password reset successfully! Please login with your new password.',
                    type: 'success',
                });

                router.visit('/login');
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                ) {
                    if (error?.response?.data?.errors) {
                        validationErrors.value = error.response.data.errors;
                    }
                }

                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
            });
    };
</script>

<template>
    <LoginPageLayout>
        <div
            class="flex flex-col justify-center h-full -mt-20 max-w-[500px] mx-auto"
        >
            <div class="self-center">
                <img class="md:max-w-40 max-w-24 p-2" src="/imgs/logo.png" />
            </div>
            <div class="text-3xl md:text-4xl font-bold text-center mb-4">
                {{ isResetStep ? 'Reset Password' : 'Request Password Reset' }}
            </div>
            <v-form
                @submit.prevent="isResetStep ? resetPassword() : requestReset()"
                class="px-8 md:px-12 lg:px-32"
            >
                <v-text-field
                    v-if="!isResetStep"
                    v-model="email"
                    label="Email"
                    type="email"
                    required
                    class="mt-6"
                    outlined
                    :error="validationErrors.email ? true : false"
                    :error-messages="validationErrors.email"
                />
                <template v-if="isResetStep">
                    <v-text-field
                        :disabled="true"
                        v-model="email"
                        label="Email"
                        type="email"
                        class="mt-6"
                        outlined
                        :error="validationErrors.email ? true : false"
                        :error-messages="validationErrors.email"
                        readonly
                    />
                    <v-text-field
                        v-model="code"
                        label="Code"
                        class="mt-4"
                        outlined
                        :error="validationErrors.token ? true : false"
                        :error-messages="validationErrors.token"
                        readonly
                    />
                    <v-text-field
                        v-model="password"
                        label="New Password"
                        type="password"
                        class="mt-4"
                        outlined
                        :error="validationErrors.password ? true : false"
                        :error-messages="validationErrors.password"
                        required
                    />
                    <v-text-field
                        v-model="confirmPassword"
                        label="Confirm New Password"
                        type="password"
                        class="mt-4"
                        outlined
                        :error="validationErrors.password ? true : false"
                        :error-messages="validationErrors.password"
                        required
                    />
                </template>
                <div class="flex justify-end mt-4">
                    <Link href="/login">Back to Login</Link>
                </div>
                <v-btn
                    block
                    size="large"
                    class="mt-4"
                    type="submit"
                    color="primary"
                >
                    {{ isResetStep ? 'Reset Password' : 'Send Reset Email' }}
                </v-btn>
            </v-form>
        </div>
    </LoginPageLayout>
</template>
