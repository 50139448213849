<script setup>
    import { ref, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        item: Object,
    });

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        const payload = {
            document_type_id: record.value.document_type_id,
            original_name: record.value.original_name,
        };

        axios
            .patch(`/api/v1/documents/${props.item.id}`, payload)
            .then(response => {
                toast.success('Document Updated Successfully');
                emit('close');
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.');
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            });
    };

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';
    const { documentTypeOptions } = storeToRefs(useOptionsStore());

    const optionsStore = useOptionsStore();
    const { fetchDocumentTypeOptions } = optionsStore;

    fetchDocumentTypeOptions();

    const record = ref({});

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/

    // Watch for changes on input record in order to set state for update or create modes
    watch(
        () => props.item,
        recordToUpdate => {
            if (recordToUpdate) {
                record.value = {
                    ...recordToUpdate,
                };
            }
        }
    );

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};
        }
    );

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :title="`Update Document`"
        @close="onClose"
        @save="onSave"
    >
        <div class="grid grid-cols-2 gap-4">
            <v-text-field
                v-model="record.original_name"
                label="File Name"
                type="text"
                :error="validationErrors.original_name ? true : false"
                :error-messages="validationErrors.original_name"
            />
            <v-autocomplete
                v-model="record.document_type_id"
                :items="documentTypeOptions"
                label="Document Type"
            />
        </div>
    </BaseModal>
</template>
