<template>
    <v-responsive>
        <v-app>
            <v-main class="bg-white flex h-screen">
                <div class="md:w-1/2 w-full flex flex-col">
                    <slot />
                </div>
                <div class="md:w-1/2 hidden md:block">
                    <img
                        class="h-full w-full object-cover object-center"
                        src="/imgs/row-homes-3.jpg"
                    />
                </div>
            </v-main>
        </v-app>
    </v-responsive>
</template>
