export function useMoneyFormatter() {
    const formatMoney = (
        value,
        showDecimals = true,
        currency = 'CAD',
        locale = 'en-CA'
    ) => {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: showDecimals ? 2 : 0,
            maximumFractionDigits: showDecimals ? 2 : 0,
        }).format(value);
    };

    return {
        formatMoney,
    };
}
