<script setup>
    import { ref, computed, watch } from 'vue';
    import { usePage, router } from '@inertiajs/vue3';
    const { props } = usePage();
    import axios from 'axios';
    import { useToast } from 'vue-toast-notification';
    import BudgetEntryComponent from '@/Components/Budget/BudgetEntryComponent.vue';
    const toast = useToast();

    /**************************************************************
     *      State
     **************************************************************/
    const operatingAccountEntiresAll = ref([]);
    const operatingAccountEntiresExpenses = ref([]);
    const operatingAccountEntiresIncome = ref([]);
    const validationErrors = ref({});
    const loading = ref(false);

    watch(
        () => props.operating_budget,
        newVal => {
            if (newVal && newVal.entries) {
                operatingAccountEntiresAll.value = newVal.entries;

                operatingAccountEntiresExpenses.value = newVal.entries
                    .filter(entry => entry.item.type_name === 'Expense')
                    .sort((a, b) => a.item.order - b.item.order);

                operatingAccountEntiresIncome.value = newVal.entries
                    .filter(entry => entry.item.type_name === 'Income')
                    .sort((a, b) => a.item.order - b.item.order);
            }
        },
        { immediate: true }
    );

    /**************************************************************
     *      Helpers
     **************************************************************/
    const refreshData = () => {
        router.reload({
            only: ['operating_budget'],
            replace: true,
        });
    };

    const saveOperatingAccount = () => {
        loading.value = true;

        const allEntires = [
            ...operatingAccountEntiresIncome.value,
            ...operatingAccountEntiresExpenses.value,
        ].map(entry => {
            return {
                id: entry.id,
                fiscal_year_budget_amount: entry.fiscal_year_budget_amount,
                reporting_period_amount: entry.reporting_period_amount,
                is_relevant: entry.is_relevant,
                notes: entry.notes,
            };
        });

        const payload = {
            entires: allEntires,
        };

        axios
            .post(
                `/api/v1/corporations/${props.corporation.id}/operating-accounts/${props.operating_budget.id}/details`,
                payload
            )
            .then(response => {
                toast.success('Operating Account saved successfully.');
                refreshData();
            })
            .catch(error => {
                if (error.response.status === 422) {
                    validationErrors.value = error.response.data.errors;
                }
                toast.error('There was an error saving the Operating Account.');
            })
            .finally(() => {
                loading.value = false;
            });
    };

    const handleUpdateIncomeEntries = entries => {
        operatingAccountEntiresIncome.value = entries;
    };

    const handleUpdateExpenseEntries = entries => {
        operatingAccountEntiresExpenses.value = entries;
    };

    const downloadTemplate = () => {
        window.open(
            `/api/v1/corporations/${props.corporation.id}/operating-accounts/${props.operating_budget.id}/details/template`,
            '_blank'
        );
    };

    /**************************************************************
     *      Breadcrumbs
     **************************************************************/
    const breadcrumbs = computed(() => {
        return [
            {
                title: 'Condo Doc Review',
                disabled: true,
                href: '#',
            },
            {
                title: 'Corporations',
                disabled: false,
                href: '/corporations',
            },
            {
                title: 'Plan # ' + props.corporation.plan_number,
                disabled: false,
                href: '/corporations/' + props.corporation.id,
            },
            {
                title: 'Operating Account',
                disabled: true,
                href: '#',
            },
        ];
    });

    /**************************************************************
     *      Modal
     **************************************************************/
    import UploadOperatingAccountTemplateModal from '../../Components/Modals/UploadOperatingAccountTemplateModal.vue';
    const isUploadModalOpen = ref(false);

    const showUploadModal = () => {
        isUploadModalOpen.value = true;
    };

    const closeUploadModal = () => {
        isUploadModalOpen.value = false;
        window.location.reload();
    };
</script>

<template>
    <AuthenticatedLayout>
        <UploadOperatingAccountTemplateModal
            :corporation="props.corporation"
            :operatingAccount="props.operating_budget"
            :isOpen="isUploadModalOpen"
            @close="closeUploadModal"
        />
        <div class="pt-8">
            <v-breadcrumbs class="-ml-3 mb-4" :items="breadcrumbs">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
            </v-breadcrumbs>
        </div>
        <div class="flex justify-between pb-6">
            <div class="text-xl self-center font-bold">
                Operating Account (Planned Vs. Actual) for
                {{ props.corporation.name }} (#{{
                    props.corporation.plan_number
                }})
            </div>
            <div class="my-6 flex justify-end gap-4">
                <v-btn color="primary" class="px-4" @click="downloadTemplate">
                    Template
                    <v-icon class="self-center ml-2">mdi-download</v-icon>
                </v-btn>
                <v-btn color="primary" class="px-4" @click="showUploadModal">
                    Upload
                    <v-icon class="self-center ml-2">mdi-upload</v-icon>
                </v-btn>
            </div>
        </div>
        <div class="flex flex-col gap-8">
            <div class="p-8 border rounded-lg shadow-lg">
                <div class="text-xl font-bold pb-4">Operating Income</div>
                <BudgetEntryComponent
                    :budget="props.operating_budget"
                    :entries="operatingAccountEntiresIncome"
                    :loading="loading"
                    :validation-errors="validationErrors"
                    @update:entries="handleUpdateIncomeEntries"
                />
            </div>

            <div class="p-8 border rounded-lg shadow-lg">
                <div class="text-xl font-bold pb-4">Operating Expenditure</div>
                <BudgetEntryComponent
                    :budget="props.operating_budget"
                    :entries="operatingAccountEntiresExpenses"
                    :loading="loading"
                    :validation-errors="validationErrors"
                    @update:entries="handleUpdateExpenseEntries"
                />
            </div>
            <v-btn
                block
                size="large"
                color="primary"
                @click="saveOperatingAccount"
            >
                Save Operational Budget Sheet
                <v-icon class="self-center ml-2">mdi-content-save-all</v-icon>
            </v-btn>
        </div>
    </AuthenticatedLayout>
</template>
