<script setup>
    import { ref, computed, watch, reactive } from 'vue';
    import { useDate } from 'vuetify';
    const adapter = useDate();

    const emit = defineEmits(['step-status']);

    const props = defineProps({
        review: {
            type: Object,
            required: true,
        },
    });

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});
    const loading = ref(false);

    const form = reactive({
        type_id: null,
        condition_date: null,
    });

    (form.type_id = props.review.type_id),
        (form.condition_date = props.review.condition_date
            ? adapter.parseISO(props.review.condition_date)
            : null);

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';

    const optionsStore = useOptionsStore();
    const { fetchReviewTypeOptions } = optionsStore;
    const { reviewTypeOptions } = storeToRefs(optionsStore);
    fetchReviewTypeOptions();

    const getReviewTypeOptions = computed(() => {
        const descriptions = {
            'Residential - Standard':
                '3-5 business days ($429.00 + GST & Fees)',
            'Residential - Rush': '1-2 business days ($529.00 + GST & Fees))',
            'Residential - Same Day': '24 hours ($629.00 + GST & Fees))',
            'Commercial - Standard':
                '3-5 business days ($629.00 + GST & Fees))',
            'Commercial - Rush': '1-2 business days ($729.00 + GST & Fees))',
            'Commercial - Same Day': '24 hours ($829.00 + GST & Fees))',
        };

        return reviewTypeOptions.value.map(option => ({
            value: option.value,
            title: option.title + ' - ' + descriptions[option.title],
        }));
    });

    const onReviewConditionDateSelected = value => {
        form.condition_date = value;
        updateReviewDetails();
    };

    const onReviewTypeSelected = value => {
        form.type_id = value;
        updateReviewDetails();
    };

    /**************************************************************
     *      Update Review
     **************************************************************/
    const updateReviewDetails = () => {
        validationErrors.value = {};
        loading.value = true;

        const payload = {
            type_id: form.type_id,
            condition_date: form.condition_date,
        };

        axios
            .patch(`/api/v1/reviews/${props.review.id}/details`, payload)
            .then(response => {
                if (isFormComplete.value) {
                    emit('step-status', 2, true);
                }
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            })
            .finally(() => {
                loading.value = false;
            });
    };

    const isFormComplete = computed(() => {
        return form.type_id != null && form.condition_date != null;
    });

    if (isFormComplete.value) {
        emit('step-status', 2, true);
    }
</script>
<template>
    <form @submit.prevent="updateReview" class="pt-4 flex flex-col gap-2">
        <p class="text-2xl font-medium">Please select your review product</p>
        <v-autocomplete
            @update:modelValue="onReviewTypeSelected"
            v-model="form.type_id"
            :items="getReviewTypeOptions"
            label="Review Product"
            required
            outlined
            :loading="loading"
            :clearable="false"
            :error="validationErrors.corporation_id ? true : false"
            :error-messages="validationErrors.corporation_id"
        />
        <p class="text-2xl font-medium">When is your condition date?</p>
        <v-date-picker
            @update:modelValue="onReviewConditionDateSelected"
            v-model="form.condition_date"
            required
            outlined
            :loading="loading"
            title="Condition Date"
            :error="validationErrors.condition_date ? true : false"
            :error-messages="validationErrors.condition_date"
        />
    </form>
</template>
