<script setup>
    import { ref } from 'vue';

    /**************************************************************
     *      Modal
     **************************************************************/
    import UserModal from '../../Components/Modals/UserModal.vue';
    const isModalOpen = ref(false);
    const selectedUser = ref(null);

    const showUpdateModal = item => {
        selectedUser.value = item;
        isModalOpen.value = true;
    };

    const showCreateModal = () => {
        selectedUser.value = null;
        isModalOpen.value = true;
    };

    const closeModal = () => {
        isModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = [
        {
            title: 'Name',
            key: 'name',
            value: item => item.first_name + ' ' + item.last_name,
            sortable: false,
        },
        { title: 'Email', key: 'email', sortable: false },
        {
            title: 'Phone',
            key: 'phone',
            value: item => {
                const phone = item.phone.toString();
                return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
            },
            sortable: false,
        },
        {
            title: 'Status',
            key: 'status',
            value: item => (item.is_active ? 'Active' : 'Inactive'),
            sortable: false,
        },
        {
            title: 'Role',
            key: 'role',
            value: item => item.roles[0].name,
            sortable: false,
        },
    ];

    const searchTrigger = ref(Date.now());

    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';
    const { clientRoleOptions } = storeToRefs(useOptionsStore());

    const additionalParams = ref({
        search: '',
        role: null,
        show_inactive: false,
    });
</script>
<template>
    <AuthenticatedLayout>
        <UserModal
            :isOpen="isModalOpen"
            :item="selectedUser"
            @close="closeModal"
            what="Client or Agent"
            :forAdmin="false"
        />
        <TableViewManager
            title="Client Users"
            :headers="headers"
            subtitle="This is where you can view a listing of all Client users, including Agents."
            endpoint="/api/v1/users/clients"
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            :actions="[
                {
                    title: 'Action',
                    icon: 'mdi-pencil',
                    buttonText: 'Edit',
                    buttonColor: 'secondary',
                    action: item => showUpdateModal(item),
                },
            ]"
        >
            <template #actions>
                <v-btn color="primary" class="px-4" @click="showCreateModal">
                    Create New
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn>
            </template>
            <template #filters>
                <v-text-field
                    v-model="additionalParams.search"
                    label="Search"
                    dense
                    hide-details
                ></v-text-field>
                <v-autocomplete
                    v-model="additionalParams.role"
                    :items="clientRoleOptions"
                    label="Role"
                    clearable
                    dense
                    hide-details
                />
                <v-checkbox
                    v-model="additionalParams.show_inactive"
                    label="Show Inactive"
                    dense
                    hide-details
                />
            </template>
        </TableViewManager>
    </AuthenticatedLayout>
</template>
