<script setup>
    import { ref, onMounted, computed } from 'vue';
    import { usePage } from '@inertiajs/vue3';
    const page = usePage();
    import { useToast } from 'vue-toast-notification';
    import { router } from '@inertiajs/vue3';
    import { loadStripe } from '@stripe/stripe-js';
    import axios from 'axios';
    const toast = useToast();
    const emit = defineEmits(['step-status', 'payment-complete']);

    import { useDisplay } from 'vuetify';
    const { smAndDown } = useDisplay();

    const loading = ref(true);

    const props = defineProps({
        review: Object,
    });

    if (props.review.is_paid) {
        emit('payment-complete', 3, true);
    }

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';

    const optionsStore = useOptionsStore();
    const { fetchReviewTypeOptions } = optionsStore;
    const { reviewTypeOptions } = storeToRefs(optionsStore);
    fetchReviewTypeOptions();

    const reviewTypeName = computed(() => {
        const reviewType = reviewTypeOptions.value.find(
            option => option.value === props.review.type_id
        );
        return reviewType ? reviewType.title : '-';
    });

    // Stripe initialization
    const stripePromise = loadStripe(
        import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY
    );

    const cardElement = ref(null);
    const card = ref(null);
    const clientSecret = ref('');

    const reviewCost = ref(0);
    const processingFee = ref(0);
    const gst = ref(0);
    const subTotal = ref(0);
    const finalTotal = ref(0);
    const discountAmount = ref(0);

    const couponCode = ref(page.props.tenant.active_coupon);
    const couponWasValid = ref(false);

    const fetchPaymentIntent = () => {
        loading.value = true;
        axios
            .post('/api/v1/payment-intents', {
                review_id: props.review.id,
                coupon_code: couponCode.value,
            })
            .then(response => {
                clientSecret.value = response.data.client_secret;

                reviewCost.value = response.data.review_cost;
                processingFee.value = response.data.processing_fee;
                subTotal.value = response.data.sub_total;
                gst.value = response.data.gst;
                discountAmount.value = response.data.discount_amount;
                finalTotal.value = response.data.final_total;

                couponWasValid.value = response.data.coupon_was_valid;

                if (couponCode.value) {
                    if (couponWasValid.value) {
                        toast.open({
                            message: 'Coupon applied successfully!',
                            type: 'success',
                        });
                    } else {
                        toast.open({
                            message: 'Invalid coupon code.',
                            type: 'error',
                        });
                    }
                }
            })
            .catch(error => {
                toast.open({
                    message:
                        error.response.data.message ??
                        'Failed to initialize payment, please try again.',
                    type: 'error',
                });
            })
            .finally(() => {
                loading.value = false;
            });
    };

    onMounted(async () => {
        loading.value = true;
        const stripe = await stripePromise;
        const elements = stripe.elements();

        card.value = elements.create('card');
        card.value.mount(cardElement.value);

        router.reload({
            only: ['review'],
            replace: true,
            onSuccess: () => {
                fetchPaymentIntent();
            },
        });
    });

    const submitPayment = async () => {
        loading.value = true;

        const stripe = await stripePromise;

        const { error, paymentIntent } = await stripe.confirmCardPayment(
            clientSecret.value,
            {
                payment_method: {
                    card: card.value,
                    billing_details: {
                        name:
                            page?.props?.auth?.user?.first_name +
                            ' ' +
                            page?.props?.auth?.user?.last_name,
                        phone: page?.props?.auth?.user?.phone,
                        email: page?.props?.auth?.user?.email,
                    },
                },
            }
        );

        if (error) {
            toast.open({
                message: error.message,
                type: 'error',
            });
            loading.value = false;
        } else if (paymentIntent && paymentIntent.status === 'succeeded') {
            axios
                .patch(`/api/v1/reviews/${props.review.id}/pay`, {
                    payment_intent_id: paymentIntent.id,
                })
                .then(() => {
                    emit('step-status', 3, true);
                    emit('payment-complete', 3, true);
                    loading.value = false;

                    toast.open({
                        message: 'Payment succeeded!',
                        type: 'success',
                    });
                })
                .catch(() => {
                    toast.open({
                        message:
                            'Payment succeeded, but failed to update order status.',
                        type: 'error',
                    });
                    loading.value = false;
                });
        }
    };
</script>

<template>
    <div
        :class="smAndDown ? 'p-4' : 'p-8'"
        class="flex flex-col gap-2 max-w-3xl sm:border rounded-lg"
    >
        <div class="text-xl py-4 flex flex-col gap-2">
            <div class="font-bold">Condominium Document Review</div>
            <div class="font-medium">{{ reviewTypeName }}</div>
            <div class="italic text-sm">
                <span v-if="reviewTypeName === 'Residential - Standard'">
                    (Report delivery within 3-5 business days of payment)
                </span>
                <span v-else-if="reviewTypeName === 'Residential - Rush'">
                    (Report delivery within 1-2 business days of payment)
                </span>
                <span v-else-if="reviewTypeName === 'Residential - Same Day'">
                    (Report delivery within 24 hours of payment)
                </span>
                <span v-else-if="reviewTypeName === 'Commercial - Standard'">
                    (Report delivery within 3-5 business days of payment)
                </span>
                <span v-else-if="reviewTypeName === 'Commercial - Rush'">
                    (Report delivery within 1-2 business days of payment)
                </span>
                <span v-else-if="reviewTypeName === 'Commercial - Same Day'">
                    (Report delivery within 24 hours of payment)
                </span>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4 my-8">
            <!-- Review Cost -->
            <div class="text-gray-600">Review Cost</div>
            <div class="text-gray-600">
                <template v-if="!loading">
                    ${{ reviewCost.toFixed(2) }}
                </template>
                <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                    size="20"
                ></v-progress-circular>
            </div>

            <!-- Discount -->
            <div class="text-gray-600" v-if="couponWasValid">Discount</div>
            <div class="text-gray-600" v-if="couponWasValid">
                <template v-if="!loading">
                    - ${{ discountAmount.toFixed(2) }}
                </template>
                <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                    size="20"
                ></v-progress-circular>
            </div>

            <!-- Processing Fee -->
            <div class="text-gray-600">Processing Fee</div>
            <div class="text-gray-600">
                <template v-if="!loading">
                    ${{ processingFee.toFixed(2) }}
                </template>
                <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                    size="20"
                ></v-progress-circular>
            </div>
            <!-- Subtotal -->
            <div class="text-gray-600">Subtotal</div>
            <div class="text-gray-600">
                <template v-if="!loading">${{ subTotal.toFixed(2) }}</template>
                <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                    size="20"
                ></v-progress-circular>
            </div>

            <!-- GST (5%) -->
            <div class="text-gray-600">GST (5%)</div>
            <div class="text-gray-600">
                <template v-if="!loading">${{ gst.toFixed(2) }}</template>
                <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                    size="20"
                ></v-progress-circular>
            </div>

            <!-- Total -->
            <div class="text-gray-600">Total</div>
            <div class="text-gray-600">
                <template v-if="!loading">
                    ${{ finalTotal.toFixed(2) }}
                </template>
                <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                    size="20"
                ></v-progress-circular>
            </div>
        </div>

        <div class="flex flex-col gap-2">
            <div>
                <div class="flex flex-col sm:flex-row gap-2">
                    <v-text-field
                        v-model="couponCode"
                        label="Enter coupon code"
                        :loading="loading"
                        class="sm:max-w-48"
                    ></v-text-field>
                    <v-btn
                        color="primary"
                        class="mt-1"
                        @click="fetchPaymentIntent"
                        :loading="loading"
                    >
                        Apply Coupon
                    </v-btn>
                </div>
            </div>
            <div class="text-2xl font-bold mb-4 mt-8">Payment</div>
            <div class="text-xs flex gap-2">
                <v-icon color="green">mdi-lock</v-icon>
                <div>
                    <a
                        href="https://stripe.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Via Stripe secure payment gateway
                    </a>
                </div>
            </div>
            <div
                class="mb-12 mt-4 border rounded-lg px-8 py-4 bg-gray-50"
                ref="cardElement"
                id="card-element"
            ></div>
            <div id="card-errors" role="alert"></div>
            <v-btn
                type="submit"
                color="secondary"
                size="large"
                @click="submitPayment"
                :loading="loading"
            >
                Pay Now
            </v-btn>
        </div>
    </div>
</template>
