<script setup>
    import { ref, computed, watch } from 'vue';
    import { Link, usePage } from '@inertiajs/vue3';
    import { storeToRefs } from 'pinia';
    import { useUserStore } from '@/stores/user';
    import { useDisplay } from 'vuetify';

    // Pinia user store references
    const userStore = useUserStore();
    const { userIsAdmin, userIsContractor, userIsSuperAdmin } =
        storeToRefs(userStore);

    const { props } = usePage();

    const gotoSettings = () => {
        window.location.href = '/settings';
    };

    /**************************************************************
     *  Menu Config
     **************************************************************/

    // Detect screen size
    const { smAndDown } = useDisplay();

    // Drawer open by default on desktop; closed on mobile
    const drawer = ref(!smAndDown.value);

    // Whenever screen size changes, update drawer state
    watch(smAndDown, val => {
        drawer.value = !val; // if smAndDown => screen is small => drawer = false
    });

    /**
     * Drawer "mode" becomes 'temporary' on smaller screens.
     * On large screens, it's the default (permanent) style.
     */
    const drawerMode = computed(() =>
        smAndDown.value ? 'temporary' : 'default'
    );

    // Menu items
    const menuItems = computed(() => {
        if (userIsAdmin.value || userIsContractor.value) {
            let baseTabs = [
                {
                    title: 'Dashboard',
                    icon: 'mdi-view-dashboard-outline',
                    href: '/dashboard',
                },
                {
                    title: 'Clients',
                    icon: 'mdi-account-group-outline',
                    href: '/clients',
                },
                {
                    title: 'Reviews',
                    icon: 'mdi-file-check-outline',
                    href: '/reviews',
                },
                {
                    title: 'Corporations',
                    icon: 'mdi-domain',
                    href: '/corporations',
                },
                // {
                //     title: 'Documents',
                //     icon: 'mdi-file-multiple-outline',
                //     href: '/documents',
                // },
                {
                    title: 'Components',
                    icon: 'mdi-cube-outline',
                    href: '/components',
                },
                {
                    title: 'Developers',
                    icon: 'mdi-account-hard-hat-outline',
                    href: '/developers',
                },
                {
                    title: 'Management',
                    icon: 'mdi-home-outline',
                    href: '/management-companies',
                },
                {
                    title: 'Admin Users',
                    icon: 'mdi-account-group-outline',
                    href: '/admins',
                },
            ];

            if (userIsContractor.value) {
                // filter out Admin Users and Clients
                return baseTabs
                    .filter(item => item.title !== 'Admin Users')
                    .filter(item => item.title !== 'Clients');
            }

            // if (userIsSuperAdmin.value) {
            //     baseTabs.push(
            //         {
            //             title: 'Pulse',
            //             icon: 'mdi-pulse',
            //             href: '/pulse',
            //         },
            //         {
            //             title: 'Horizon',
            //             icon: 'mdi-chart-line',
            //             href: '/horizon',
            //         }
            //     );
            // }

            return baseTabs;
        } else {
            return [
                {
                    title: 'Reviews',
                    icon: 'mdi-file-check-outline',
                    href: '/reviews',
                },
            ];
        }
    });

    // Bottom items
    const bottomItems = [
        { title: 'Settings', icon: 'mdi-cog-outline', href: '/settings' },
        { title: 'Logout', icon: 'mdi-logout', href: '/logout' },
    ];

    // Current route checking
    const page = usePage();
    const currentPath = computed(() => page.url);
</script>

<template>
    <v-responsive>
        <v-app>
            <!-- App Bar -->
            <v-app-bar flat class="border-b px-4 md:px-6">
                <!-- Hamburger Icon toggles the drawer -->
                <v-app-bar-nav-icon @click="drawer = !drawer" />

                <v-toolbar-title>
                    <div class="flex justify-between w-full">
                        <!-- Title/Logo area -->
                        <div class="font-bold text-sm md:text-base">
                            Condo Doc Review
                        </div>

                        <!-- User Info area -->
                        <div v-if="!smAndDown" class="flex items-center gap-2">
                            <div class="text-xs md:text-sm">
                                Welcome Back, {{ props.auth?.user?.first_name }}
                            </div>
                            <v-icon
                                @click="gotoSettings"
                                class="cursor-pointer"
                            >
                                mdi-account-circle-outline
                            </v-icon>
                        </div>
                    </div>
                </v-toolbar-title>
            </v-app-bar>

            <!-- Navigation Drawer -->
            <!-- mode changes to 'temporary' on mobile, 'default' on desktop -->
            <v-navigation-drawer
                v-model="drawer"
                :mode="drawerMode"
                app
                expand-on-hover
                :rail="!smAndDown"
                :rail-width="60"
                :style="{
                    background:
                        'linear-gradient(157.38deg, #F6F6FB 8.53%, #E6E1EC 44.37%, #DBE8F4 78.12%)',
                }"
            >
                <div class="flex flex-col justify-between h-full">
                    <!-- Main Menu -->
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in menuItems"
                            :key="index"
                            :class="{
                                'text-primary': currentPath.includes(item.href),
                            }"
                            class="hover:bg-white hover:border hover:scale-105 transition-transform"
                        >
                            <Link :href="item.href" class="flex gap-2 py-3">
                                <v-icon>{{ item.icon }}</v-icon>
                                <v-list-item-title>
                                    {{ item.title }}
                                </v-list-item-title>
                            </Link>
                        </v-list-item>
                        <v-list-item
                            v-if="userIsSuperAdmin"
                            class="hover:bg-white hover:border hover:scale-105 transition-transform"
                        >
                            <a
                                href="/pulse"
                                target="_blank"
                                class="flex gap-2 py-3"
                            >
                                <v-icon>mdi-pulse</v-icon>
                                <v-list-item-title>Pulse</v-list-item-title>
                            </a>
                        </v-list-item>
                        <v-list-item
                            v-if="userIsSuperAdmin"
                            class="hover:bg-white hover:border hover:scale-105 transition-transform"
                        >
                            <a
                                href="/horizon"
                                target="_blank"
                                class="flex gap-2 py-3"
                            >
                                <v-icon>mdi-chart-line</v-icon>
                                <v-list-item-title>Horizon</v-list-item-title>
                            </a>
                        </v-list-item>
                    </v-list>

                    <!-- Bottom Menu -->
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in bottomItems"
                            :key="index"
                            :class="{
                                'text-primary': currentPath.includes(item.href),
                            }"
                            class="hover:bg-white hover:border hover:scale-105 transition-transform"
                        >
                            <Link :href="item.href" class="flex gap-2 py-3">
                                <v-icon>{{ item.icon }}</v-icon>
                                <v-list-item-title>
                                    {{ item.title }}
                                </v-list-item-title>
                            </Link>
                        </v-list-item>
                    </v-list>
                </div>
            </v-navigation-drawer>

            <!-- Main Content -->
            <!-- Use responsive padding with Tailwind classes -->
            <v-main>
                <v-responsive>
                    <div class="p-4 md:px-24">
                        <slot />
                    </div>
                </v-responsive>
            </v-main>

            <!-- Footer -->
            <v-footer app class="p-2">
                <div class="text-center text-xs w-full">
                    © {{ new Date().getFullYear() }} Condo Doc Review
                </div>
            </v-footer>
        </v-app>
    </v-responsive>
</template>
