<script setup>
    import { ref } from 'vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        initialRawText: {
            type: [Object, String],
            default: null,
        },
        initialSummaryText: {
            type: [Object, String],
            default: null,
        },
        validationErrors: {
            type: Object,
            default: {},
        },
        what: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    });

    const rawText = ref(props.initialRawText);
    const summaryText = ref(props.initialSummaryText);

    /**************************************************************
     *      ChatGPT AI summarizer
     **************************************************************/
    import { useSummarizer } from '@/Composables/useSummarizer';
    const { summarize, summaryLoading } = useSummarizer();

    const prompt = ref(
        'Summarize the following information in 1-4 paragraphs (as needed) and point out the most important aspects (with a focus on clarity and consumer-friendly language): '
    );

    const handleSummarizeTextRequest = async () => {
        const summaryReturned = await summarize(rawText.value, prompt.value);

        summaryText.value = summaryReturned;

        emitUpdate();
    };

    /**************************************************************
     *      Emit - (HELPER)
     **************************************************************/
    const emit = defineEmits(['summaryUpdated', 'rawTextUpdated']);
    const emitUpdate = () => {
        emit('rawTextUpdated', rawText.value);
        emit('summaryUpdated', summaryText.value);
    };
</script>

<template>
    <div class="col-span-full">
        <v-expansion-panels multiple>
            <v-expansion-panel>
                <v-expansion-panel-title>
                    <div class="font-bold">
                        Notes - AI Summary Tool - {{ what }}
                    </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <div class="px-4">Use this tool to summarize notes.</div>
                    <div class="p-4 flex flex-col gap-4">
                        <v-textarea
                            v-model="prompt"
                            label="AI Prompt"
                            type="text"
                            required
                            :disabled="summaryLoading || disabled"
                            rows="2"
                            outlined
                        />
                        <v-textarea
                            @input="emitUpdate"
                            v-model="rawText"
                            :label="`${what} - Text (Raw Input Fed Into AI For Summary)`"
                            type="text"
                            required
                            class="col-span-full"
                            :disabled="summaryLoading || disabled"
                            outlined
                            :error="validationErrors.raw_text ? true : false"
                            :error-messages="validationErrors.raw_text"
                        />
                        <div class="mb-4 -mt-4">
                            <v-btn
                                @click="handleSummarizeTextRequest"
                                :loading="summaryLoading"
                                :disabled="summaryLoading || disabled"
                                color="primary"
                            >
                                <v-icon left>mdi-robot</v-icon>
                                Summarize {{ what }} With AI
                            </v-btn>
                        </div>
                        <v-textarea
                            @input="emitUpdate"
                            v-model="summaryText"
                            :label="`${what} - Summary (AI Generated)`"
                            type="text"
                            :disabled="summaryLoading || disabled"
                            required
                            class="col-span-full"
                            outlined
                            :error="validationErrors.summary_ai ? true : false"
                            :error-messages="validationErrors.summary_ai"
                        />
                        <div v-if="summaryLoading" class="mb-4 flex gap-4">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                            <div class="self-center">
                                AI is summarizing... Please Wait...
                            </div>
                        </div>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
