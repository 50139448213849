<script setup>
    import { ref, computed, watch } from 'vue';
    const emit = defineEmits(['refresh']);

    const loading = ref(false);

    const props = defineProps({
        review: {
            type: Object,
            required: true,
        },
    });

    import { useUserStore } from '@/stores/user';
    const userStore = useUserStore();
    const { userIsAdmin, userIsContractor } = storeToRefs(userStore);

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});

    const form = ref({
        id: props.review.id,
        status_id: props.review.status_id,
        rejection_reason: '',
        status_history: props.review.status_history,
        rejected_document_types: [],
    });

    const getStatusHistory = computed(() => form.value.status_history);

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';

    const optionsStore = useOptionsStore();
    const { fetchReviewStatusOptions, fetchDocumentTypeOptions } = optionsStore;
    const { reviewStatusOptions, documentTypeOptions } =
        storeToRefs(optionsStore);

    fetchReviewStatusOptions();
    fetchDocumentTypeOptions();

    const pendingDocumentsIsSelected = computed(() => {
        const rejectedStatus = reviewStatusOptions.value.find(
            s => s.title === 'Pending Documents'
        );
        return rejectedStatus
            ? form.value.status_id === rejectedStatus.value
            : false;
    });

    const orderedDocumentTypeOptions = computed(() => {
        if (!props.review.UploadedDocumentTypes || !documentTypeOptions.value)
            return [];
        const options = props.review.UploadedDocumentTypes.map(doc => {
            return (
                documentTypeOptions.value.find(
                    option => option.title === doc.name
                ) || { title: doc.name, value: doc.id }
            );
        });
        return options.sort((a, b) => a.title.localeCompare(b.title));
    });

    const sortedReceivedDocuments = computed(() => {
        if (!props.review.UploadedDocumentTypes) return [];
        return [...props.review.UploadedDocumentTypes].sort((a, b) =>
            a.name.localeCompare(b.name)
        );
    });

    watch(pendingDocumentsIsSelected, newVal => {
        if (newVal) {
            const missingDocs = props.review.UploadedDocumentTypes.filter(
                doc => doc.required && !doc.received
            )
                .map(doc => {
                    const option = orderedDocumentTypeOptions.value.find(
                        opt => opt.title === doc.name
                    );
                    return option ? option.value : null;
                })
                .filter(value => value !== null);
            form.value.rejected_document_types = missingDocs;
        }
    });

    const getReviewStatusOptions = computed(() => {
        if (userIsContractor.value) {
            return reviewStatusOptions.value.filter(
                s =>
                    s.title === 'Under Review' ||
                    s.title === 'Pending Administrative Review' ||
                    s.title === 'Cannot Proceed'
            );
        } else {
            return reviewStatusOptions.value;
        }
    });

    const isBeingRejected = computed(() => {
        const rejectedStatus = reviewStatusOptions.value.find(
            s => s.title === 'Pending Documents'
        );
        return rejectedStatus
            ? form.value.status_id === rejectedStatus.value
            : false;
    });

    const isBeingCompleted = computed(() => {
        const completedStatus = reviewStatusOptions.value.find(
            s => s.title === 'Completed'
        );
        return completedStatus
            ? form.value.status_id === completedStatus.value
            : false;
    });

    /**************************************************************
     *      Update Review
     **************************************************************/
    const updateReviewStatus = () => {
        validationErrors.value = {};

        let payload = {
            status_id: form.value.status_id,
        };

        if (isBeingRejected.value) {
            if (
                !confirm(
                    'Are you sure you want to mark this review as Pending Documents? This will send a notice to the client/agent.'
                )
            ) {
                return;
            }

            // Add additional fields to the payload
            payload = {
                ...payload,
                rejection_reason: form.value.rejection_reason,
                rejected_document_types: form.value.rejected_document_types,
            };
        }

        if (isBeingCompleted.value) {
            if (
                !confirm(
                    'Are you sure you want to complete this review? This will send a final confirmation notice to the client.'
                )
            ) {
                return;
            }
        }

        loading.value = true;
        axios
            .post(
                `/api/v1/reviews/${form.value.id}/status/${form.value.status_id}`,
                payload
            )
            .then(response => {
                toast.open({
                    message: 'Review status updated successfully!',
                    type: 'success',
                });
                form.value.status_history = response.data.status_history;
                form.value.rejection_reason = null;
                form.value.rejected_document_types = [];
                emit('refresh');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            })
            .finally(() => {
                loading.value = false;
            });
    };
</script>
<template>
    <div>
        <div class="max-w-[1000px] p-4 border rounded-lg shadow-lg mb-12">
            <form
                v-if="userIsAdmin || review.status.name === 'Under Review'"
                @submit.prevent="updateReviewStatus"
                class="grid grid-cols-1 gap-4 mb-4"
            >
                <div class="text-2xl self-center font-bold">Update Status</div>
                <v-autocomplete
                    v-model="form.status_id"
                    :items="getReviewStatusOptions"
                    label="Status"
                    item-text="title"
                    item-value="value"
                    required
                    :clearable="false"
                    outlined
                    :error="validationErrors.status_id ? true : false"
                    :error-messages="validationErrors.status_id"
                    :loading="loading"
                    :disabled="loading"
                />
                <v-autocomplete
                    v-if="pendingDocumentsIsSelected"
                    v-model="form.rejected_document_types"
                    :items="orderedDocumentTypeOptions"
                    label="Pending Documents"
                    :error="
                        validationErrors.rejected_document_types ? true : false
                    "
                    :error-messages="validationErrors.rejected_document_types"
                    multiple
                    clearable
                    dense
                    :loading="loading"
                    :disabled="loading"
                />
                <v-textarea
                    v-if="pendingDocumentsIsSelected"
                    v-model="form.rejection_reason"
                    label="Pending Documents Reason / Notes for Client"
                    required
                    outlined
                    :error="validationErrors.rejection_reason ? true : false"
                    :error-messages="validationErrors.rejection_reason"
                    :loading="loading"
                    :disabled="loading"
                />
                <div>
                    <v-btn
                        :disabled="
                            form.status_id === props.review.status_id ||
                            !form.status_id ||
                            loading
                        "
                        class="mb-4 -mt-4"
                        @click="updateReviewStatus"
                        color="primary"
                    >
                        Submit
                    </v-btn>
                </div>
            </form>
            <div class="text-2xl self-center font-bold my-6">
                Status History
            </div>
            <div class="grid grid-cols-4 border-b pb-2 gap-4">
                <div>From Status</div>
                <div>To Status</div>
                <div>Updated By</div>
                <div>Updated At</div>
            </div>
            <div
                class="grid grid-cols-4 gap-4 py-4 border-t"
                v-for="history in getStatusHistory"
            >
                <div>{{ history?.from_status?.name ?? '-' }}</div>
                <div>{{ history.to_status.name }}</div>
                <div>
                    {{ history.user.first_name }} {{ history.user.last_name }}
                </div>
                <div>{{ new Date(history.updated_at).toLocaleString() }}</div>
                <div
                    v-if="history.to_status.name === 'Pending Documents'"
                    class="col-span-full"
                >
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-title>
                                Requested Pending Documents by Admin
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <ul>
                                    <li
                                        v-for="doc in history.document_types"
                                        :key="doc.id"
                                    >
                                        - {{ doc.display_name }}
                                    </li>
                                </ul>
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </div>
        </div>
        <div class="max-w-[1000px]">
            <div class="text-2xl self-center font-bold">Received Documents</div>
            <v-data-table
                :items-per-page="9999"
                :headers="[
                    { title: 'Name', value: 'name' },
                    { title: 'Required', value: 'required' },
                    { title: 'Received', value: 'received' },
                ]"
                :items="sortedReceivedDocuments"
                hide-default-footer
                :loading="loading"
                :disabled="loading"
            >
                <template v-slot:item.required="{ item }">
                    <v-icon :color="item.required ? 'green' : 'red'">
                        {{ item.required ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                </template>
                <template v-slot:item.received="{ item }">
                    <v-icon :color="item.received ? 'green' : 'red'">
                        {{ item.received ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                </template>
            </v-data-table>
        </div>
    </div>
</template>
