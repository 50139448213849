<script setup>
    import { ref, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';
    import CorporationGeneralForm from '@/Components/Corporation/CorporationGeneralForm.vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
    });

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        const action = axios.post;
        const endpoint = '/api/v1/corporations';

        const payload = {
            ...record.value,
        };

        action(endpoint, payload)
            .then(response => {
                emit('close');
            })
            .catch(error => {
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            });
    };

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});
    const record = ref({});

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            record.value = {};
            validationErrors.value = {};
        }
    );

    const updateInternalFormState = updatedRecord => {
        validationErrors.value = {};
        record.value = {
            ...updatedRecord,
        };
    };

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :title="`Create Corporation`"
        @close="onClose"
        @save="onSave"
    >
        <CorporationGeneralForm
            narrow-mode
            @formUpdated="updateInternalFormState"
            :validationErrors="validationErrors"
        />
    </BaseModal>
</template>
