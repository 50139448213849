<script setup>
    import KPICard from './KPICard.vue';
    import { computed, ref, watch } from 'vue';

    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    const { formatMoney } = useMoneyFormatter();

    import { usePage } from '@inertiajs/vue3';
    const page = usePage();

    const timelineEvents = ref([]);

    watch(
        () => page.props.event_timeline_data,
        newValue => {
            timelineEvents.value = newValue;
        },
        { immediate: true }
    );

    // Projects already have planned_cost and actual_cost fields.
    // Note: For projects the event.date is assumed to be a year string.
    const getProjectByYear = () => {
        return timelineEvents.value.reduce((acc, event) => {
            if (event.type === 'Project') {
                const year = event.date; // assumed to be year already
                if (!acc[year]) {
                    acc[year] = {
                        events: [],
                        plannedCost: 0,
                        actualCost: 0,
                        eventCount: 0,
                    };
                }
                acc[year].events.push(event);
                acc[year].plannedCost += Number(event.planned_cost) || 0;
                acc[year].actualCost += Number(event.actual_cost) || 0;
                acc[year].eventCount += 1;
            }
            return acc;
        }, {});
    };

    // Loans – each event is assumed to have a "cost" field.
    const getLoansByYear = () => {
        return timelineEvents.value.reduce((acc, event) => {
            if (event.type === 'Loan') {
                // For loans we group by full year from the date.
                const year = new Date(event.date).getFullYear();
                if (!acc[year]) {
                    acc[year] = { events: [], totalCost: 0, eventCount: 0 };
                }
                acc[year].events.push(event);
                acc[year].totalCost += Number(event.cost) || 0;
                acc[year].eventCount += 1;
            }
            return acc;
        }, {});
    };

    // Levies – each event is assumed to have a "cost" field.
    const getLeviesByYear = () => {
        return timelineEvents.value.reduce((acc, event) => {
            if (event.type === 'Levy') {
                const year = new Date(event.date).getFullYear();
                if (!acc[year]) {
                    acc[year] = { events: [], totalCost: 0, eventCount: 0 };
                }
                acc[year].events.push(event);
                acc[year].totalCost += Number(event.cost) || 0;
                acc[year].eventCount += 1;
            }
            return acc;
        }, {});
    };

    // Litigation – no cost fields should be displayed.
    const getLitigationByYear = () => {
        return timelineEvents.value.reduce((acc, event) => {
            if (event.type === 'Litigation') {
                const year = new Date(event.date).getFullYear();
                if (!acc[year]) {
                    acc[year] = { events: [], eventCount: 0 };
                }
                acc[year].events.push(event);
                acc[year].eventCount += 1;
            }
            return acc;
        }, {});
    };
</script>

<template>
    <KPICard whiteText padding="p-12">
        <template #icon>6</template>
        <template #title>
            <div class="text-2xl md:text-4xl font-bold">Key Event Timeline</div>
        </template>
        <template #subtitle>
            <div>Timeline for Loans, Levies, Projects, and Litigation.</div>
        </template>
        <template #chart>
            <div class="overflow-x-auto pb-12">
                <v-timeline line-color="white" direction="horizontal">
                    <v-timeline-item
                        v-for="event in timelineEvents"
                        :key="event.date"
                        min-width="380px"
                        dot-color="white"
                    >
                        <template v-slot:opposite>
                            <div class="font-bold text-center text-xl">
                                {{
                                    event.type === 'Project'
                                        ? event.date
                                        : new Date(event.date).toLocaleString(
                                              'en-CA',
                                              {
                                                  year: 'numeric',
                                                  month: 'long',
                                                  day: 'numeric',
                                                  timeZone: 'UTC',
                                              }
                                          )
                                }}
                            </div>
                        </template>
                        <div
                            class="text-center p-5 bg-[#f3f3f3] text-[#131313] rounded-xl"
                        >
                            <div class="text-xl font-bold">
                                {{ event.type }}
                            </div>
                            <p class="text-sm">
                                {{ event.description }}
                            </p>
                        </div>
                    </v-timeline-item>
                </v-timeline>
            </div>
        </template>
        <template #why-it-matters>
            <div class="flex flex-col gap-4">
                <p>
                    One of the best ways to gauge a condominium corporation's
                    health is by reviewing how it has handled significant events
                    in the past—particularly loans, levies, projects, and legal
                    claims. These events are critical indicators of the
                    corporation's management history.
                </p>
                <p>
                    Be cautious if you see multiple levies, as they suggest the
                    corporation's reserve fund may be underfunded. A history of
                    levies can also signal potential future costs you might be
                    required to cover.
                </p>
                <p>
                    Loans, meanwhile, can be a practical way to spread out the
                    cost of large projects over time. However, multiple loans
                    might indicate poor financial management.
                </p>
                <p>
                    Lastly, be aware of any ongoing or past legal issues, as
                    these can affect both the corporation's finances and your
                    potential liability as an owner.
                </p>
            </div>
        </template>
        <template #view-details>
            <!-- Projects Section -->
            <v-card outlined>
                <v-card-title class="headline">Projects</v-card-title>
                <v-expansion-panels multiple>
                    <v-expansion-panel
                        v-for="(projects, year) in getProjectByYear()"
                        :key="year"
                    >
                        <v-expansion-panel-title>
                            <div
                                class="d-flex justify-space-between align-center"
                                style="width: 100%"
                            >
                                <div class="flex flex-col gap-2">
                                    <div class="font-weight-bold">
                                        {{ year }} -
                                        {{ projects.eventCount }} Project(s)
                                    </div>
                                    <div>
                                        Planned Cost:
                                        <strong>
                                            {{
                                                formatMoney(
                                                    projects.plannedCost
                                                )
                                            }}
                                        </strong>
                                    </div>
                                    <div>
                                        Actual Expenditure:
                                        <strong>
                                            {{
                                                formatMoney(projects.actualCost)
                                            }}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <v-list dense>
                                <v-list-item
                                    v-for="project in projects.events"
                                    :key="project.date"
                                >
                                    <div class="py-6 border-t">
                                        {{ project.description }}
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>

            <!-- Loans Section -->
            <v-card outlined class="mt-6">
                <v-card-title class="headline">Loans</v-card-title>
                <v-expansion-panels multiple>
                    <v-expansion-panel
                        v-for="(loanGroup, year) in getLoansByYear()"
                        :key="year"
                    >
                        <v-expansion-panel-title>
                            <div
                                class="d-flex justify-space-between align-center"
                                style="width: 100%"
                            >
                                <div class="flex flex-col gap-2">
                                    <div class="font-weight-bold">
                                        {{ year }} -
                                        {{ loanGroup.eventCount }} Loan(s)
                                    </div>
                                    <div>
                                        Cost:
                                        <strong>
                                            {{
                                                formatMoney(loanGroup.totalCost)
                                            }}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <v-list dense>
                                <v-list-item
                                    v-for="loan in loanGroup.events"
                                    :key="loan.date"
                                >
                                    <div class="py-6 border-t">
                                        {{ loan.description }}
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>

            <!-- Levies Section -->
            <v-card outlined class="mt-6">
                <v-card-title class="headline">Levies</v-card-title>
                <v-expansion-panels multiple>
                    <v-expansion-panel
                        v-for="(levyGroup, year) in getLeviesByYear()"
                        :key="year"
                    >
                        <v-expansion-panel-title>
                            <div
                                class="d-flex justify-space-between align-center"
                                style="width: 100%"
                            >
                                <div class="flex flex-col gap-2">
                                    <div class="font-weight-bold">
                                        {{ year }} -
                                        {{ levyGroup.eventCount }} Levy(s)
                                    </div>
                                    <div>
                                        Cost:
                                        <strong>
                                            {{
                                                formatMoney(levyGroup.totalCost)
                                            }}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <v-list dense>
                                <v-list-item
                                    v-for="levy in levyGroup.events"
                                    :key="levy.date"
                                >
                                    <div class="py-6 border-t">
                                        {{ levy.description }}
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>

            <!-- Litigation Section -->
            <v-card outlined class="mt-6">
                <v-card-title class="headline">Litigation</v-card-title>
                <v-expansion-panels multiple>
                    <v-expansion-panel
                        v-for="(socGroup, year) in getLitigationByYear()"
                        :key="year"
                    >
                        <v-expansion-panel-title>
                            <div
                                class="d-flex justify-space-between align-center"
                                style="width: 100%"
                            >
                                <div class="flex flex-col gap-2">
                                    <div class="font-weight-bold">
                                        {{ year }} -
                                        {{ socGroup.eventCount }} Legal Event(s)
                                    </div>
                                </div>
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <v-list dense>
                                <v-list-item
                                    v-for="soc in socGroup.events"
                                    :key="soc.date"
                                >
                                    <div class="py-6 border-t">
                                        {{ soc.description }}
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>
        </template>
    </KPICard>
</template>
