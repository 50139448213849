<script setup>
    import { Link, usePage } from '@inertiajs/vue3';
    const { props } = usePage();
    import { ref, computed } from 'vue';

    const errorCode = ref(props.error_code);
    const errorMessage = ref(props.error_message);
    const reviewNumber = ref(props.review_number);
</script>
<template>
    <div class="flex flex-col">
        <div class="flex items-center justify-center min-h-screen px-8">
            <div v-if="errorCode === 1" class="text-center flex flex-col gap-4">
                <div class="mb-4">
                    <v-icon color="red" size="100">
                        mdi-alert-circle-outline
                    </v-icon>
                </div>
                <div class="font-bold text-xl">
                    You are not authorized to view this report at this time.
                </div>
                <Link
                    href="/login"
                    class="self-center underline text-blue-500"
                    :disabled="loading"
                >
                    Back
                </Link>
            </div>
            <div
                v-else-if="errorCode === 2"
                class="text-center flex flex-col gap-4"
            >
                <div class="mb-4">
                    <v-icon color="blue" size="100">mdi-clock-outline</v-icon>
                </div>
                <div class="font-bold text-xl">
                    This review has expired and is no longer available to view.
                </div>
                <Link
                    href="/login"
                    class="self-center underline text-blue-500"
                    :disabled="loading"
                >
                    Back
                </Link>
            </div>
            <div class="text-center flex flex-col gap-4" v-else>
                <div class="mb-4">
                    <v-icon color="red" size="100">
                        mdi-alert-circle-outline
                    </v-icon>
                </div>
                <div class="font-bold text-xl">
                    We are unable to display this report
                </div>
                <div>
                    If you are seeing this, please contact us and provide the
                    information below.
                </div>
                <div>+1 (780) 886-0869</div>
                <div>admin@condodocreview.com</div>
                <div>We will work to resolve this as soon as possible.</div>
                <div class="p-4 border shadow rounded-xl mt-4">
                    <p>Review Case Number: #{{ reviewNumber }}</p>
                    <p>Error Message: {{ errorMessage }}</p>
                    <p>Error Code: {{ errorCode }}</p>
                </div>
                <Link
                    href="/login"
                    class="self-center underline text-blue-500"
                    :disabled="loading"
                >
                    Back
                </Link>
            </div>
        </div>
    </div>
</template>
