<script setup>
    import { usePage } from '@inertiajs/vue3';
    import { computed, ref } from 'vue';

    import { useDisplay } from 'vuetify';
    const { smAndDown } = useDisplay();

    const page = usePage();
    const review = computed(() => page.props.review);
</script>
<template>
    <div
        :class="[
            review.ai_risk_assessment_level === 'Low'
                ? 'bg-green-100'
                : review.ai_risk_assessment_level === 'Medium'
                  ? 'bg-orange-200'
                  : 'bg-red-200',
        ]"
    >
        <div
            :class="[
                'flex flex-col md:flex-row gap-12 mt-12 max-w-[1400px] mx-auto',
                smAndDown ? 'p-8' : 'px-8 py-24',
            ]"
        >
            <div class="md:w-2/3">
                <div class="text-4xl md:text-7xl font-bold">
                    RISK ASSESSMENT
                </div>
                <div>
                    <p class="mt-4 whitespace-pre-line">
                        {{ review.ai_risk_assessment }}
                    </p>
                    <p class="mt-4 text-xs text-gray-500">
                        Disclaimer: The risk assessment provided here is for
                        <span class="font-bold">
                            informational purposes only
                        </span>
                        and should not be considered as legal or financial
                        advice. Everyone has individual risk tolerances, and it
                        is important to consult with a legal or financial
                        advisor for personalized recommendations.
                    </p>
                </div>
            </div>
            <div class="md:w-1/3 flex items-center justify-center">
                <div
                    :class="{
                        'bg-green-400':
                            review.ai_risk_assessment_level === 'Low',
                        'bg-orange-400':
                            review.ai_risk_assessment_level === 'Medium',
                        'bg-red-400':
                            review.ai_risk_assessment_level === 'High',
                    }"
                    class="w-64 h-64 rounded-full flex items-center justify-center border-4 border-dotted shadow-xl"
                    :style="{
                        borderColor:
                            review.ai_risk_assessment_level === 'Low'
                                ? 'darkgreen'
                                : review.ai_risk_assessment_level === 'Medium'
                                  ? 'darkyellow'
                                  : 'red',
                    }"
                >
                    <span class="text-black text-2xl font-bold">
                        {{ review.ai_risk_assessment_level }} Risk
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
