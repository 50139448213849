<script setup>
    import { ref, computed } from 'vue';
    const { props } = usePage();
    import { usePage } from '@inertiajs/vue3';
    const page = usePage();
    const dashboardData = computed(() => page.props.dashboard_data);
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    const { formatMoney } = useMoneyFormatter();

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useUserStore } from '@/stores/user';

    const userStore = useUserStore();
    const { userIsAdmin, userIsContractor } = storeToRefs(userStore);
</script>
<template>
    <AuthenticatedLayout>
        <div v-if="userIsAdmin">
            <div class="grid md:grid-cols-5">
                <div class="col-span-full py-8">
                    <div class="font-bold text-xl">
                        Welcome Back, {{ props.auth?.user?.first_name }} |
                        Current Review Status (Admin)
                    </div>
                </div>
                <div class="bg-green-500 text-white p-8">
                    <div class="font-bold text-xl">
                        Pending Submission (For Follow Up)
                    </div>
                    <div class="text-4xl">
                        {{
                            dashboardData.admin.pending_review_breakdown
                                .pending_submission
                        }}
                    </div>
                </div>
                <div class="bg-yellow-500 text-white p-8">
                    <div class="font-bold text-xl">Under Review</div>
                    <div class="text-4xl">
                        {{
                            dashboardData.admin.pending_review_breakdown
                                .under_review
                        }}
                    </div>
                </div>
                <div class="bg-orange-400 text-white p-8">
                    <div class="font-bold text-xl">
                        Pending Administrative Review
                    </div>
                    <div class="text-4xl">
                        {{
                            dashboardData.admin.pending_review_breakdown
                                .pending_administrative_review
                        }}
                    </div>
                </div>
                <div class="bg-red-500 text-white p-8">
                    <div class="font-bold text-xl">Pending Documents</div>
                    <div class="text-4xl">
                        {{
                            dashboardData.admin.pending_review_breakdown
                                .rejected
                        }}
                    </div>
                </div>
                <div class="bg-red-600 text-white p-8">
                    <div class="font-bold text-xl">Cannot Proceed</div>
                    <div class="text-4xl">
                        {{
                            dashboardData.admin.pending_review_breakdown
                                .cannot_proceed
                        }}
                    </div>
                </div>
            </div>
            <div class="grid md:grid-cols-3">
                <div class="text-black p-8">
                    <div class="font-bold text-xl">Reviews All Time</div>
                    <div class="text-4xl">
                        {{ dashboardData.admin.reviews_all_time }}
                    </div>
                </div>
                <div class="text-black p-8">
                    <div class="font-bold text-xl">Reviews This Year</div>
                    <div class="text-4xl">
                        {{ dashboardData.admin.reviews_this_year }}
                    </div>
                </div>
                <div class="text-black p-8">
                    <div class="font-bold text-xl">Reviews This Month</div>
                    <div class="text-4xl">
                        {{ dashboardData.admin.reviews_this_month }}
                    </div>
                </div>
            </div>
            <div
                :style="{
                    background:
                        'linear-gradient(157.38deg, #F6F6FB 8.53%, #E6E1EC 44.37%, #DBE8F4 78.12%)',
                }"
                class="grid md:grid-cols-3"
            >
                <div class="p-8">
                    <div class="font-bold text-xl">Sales All Time</div>
                    <div class="text-4xl">
                        {{ formatMoney(dashboardData.admin.sales_all_time) }}
                    </div>
                </div>
                <div class="p-8">
                    <div class="font-bold text-xl">Sales This Year</div>
                    <div class="text-4xl">
                        {{ formatMoney(dashboardData.admin.sales_this_year) }}
                    </div>
                </div>
                <div class="p-8">
                    <div class="font-bold text-xl">Sales This Month</div>
                    <div class="text-4xl">
                        {{ formatMoney(dashboardData.admin.sales_this_month) }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="userIsContractor">
            <div class="grid md:grid-cols-5">
                <div class="col-span-full py-8">
                    <div class="font-bold text-xl">
                        Welcome Back, {{ props.auth?.user?.first_name }} |
                        Current Review Status
                    </div>
                </div>
                <div class="bg-yellow-500 text-white p-8">
                    <div class="font-bold text-xl">
                        Currently Under Review By You
                    </div>
                    <div class="text-4xl">
                        {{
                            dashboardData.contractor.pending_review_breakdown
                                .under_review
                        }}
                    </div>
                </div>
                <div class="bg-orange-400 text-white p-8">
                    <div class="font-bold text-xl">
                        Submitted for Administrative Review
                    </div>
                    <div class="text-4xl">
                        {{
                            dashboardData.contractor.pending_review_breakdown
                                .pending_administrative_review
                        }}
                    </div>
                </div>
                <div class="bg-red-500 text-white p-8">
                    <div class="font-bold text-xl">
                        Pending Documents (Waiting For Client)
                    </div>
                    <div class="text-4xl">
                        {{
                            dashboardData.contractor.pending_review_breakdown
                                .rejected
                        }}
                    </div>
                </div>
                <div class="bg-red-600 text-white p-8">
                    <div class="font-bold text-xl">
                        Cannot Proceed (Waiting For Admin)
                    </div>
                    <div class="text-4xl">
                        {{
                            dashboardData.contractor.pending_review_breakdown
                                .cannot_proceed
                        }}
                    </div>
                </div>
                <div class="bg-green-500 text-white p-8">
                    <div class="font-bold text-xl">Completed (All Time)</div>
                    <div class="text-4xl">
                        {{
                            dashboardData.contractor.pending_review_breakdown
                                .completed
                        }}
                    </div>
                </div>
            </div>
            <div class="grid md:grid-cols-3">
                <div class="text-black p-8">
                    <div class="font-bold text-xl">Reviews All Time</div>
                    <div class="text-4xl">
                        {{ dashboardData.contractor.reviews_all_time }}
                    </div>
                </div>
                <div class="text-black p-8">
                    <div class="font-bold text-xl">Reviews This Year</div>
                    <div class="text-4xl">
                        {{ dashboardData.contractor.reviews_this_year }}
                    </div>
                </div>
                <div class="text-black p-8">
                    <div class="font-bold text-xl">Reviews This Month</div>
                    <div class="text-4xl">
                        {{ dashboardData.contractor.reviews_this_month }}
                    </div>
                </div>
            </div>
        </div>
    </AuthenticatedLayout>
</template>
