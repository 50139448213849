<script setup>
    import { ref, computed } from 'vue';
    import { Bar } from 'vue-chartjs';
    import { usePage } from '@inertiajs/vue3';
    import {
        Chart as ChartJS,
        BarElement,
        Tooltip,
        Legend,
        CategoryScale,
        LinearScale,
    } from 'chart.js';

    import { useDisplay } from 'vuetify';
    const { smAndDown } = useDisplay();

    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    const { formatMoney } = useMoneyFormatter();

    ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale);

    const page = usePage();

    // Aggregate projects by year
    const projectsByYear = computed(() => {
        const groups = {};
        // Use a default empty array if there are no projects
        const projects = page.props.review.corporation.projects || [];
        projects.forEach(project => {
            const year = project.year;
            if (!groups[year]) {
                groups[year] = { year, planned: 0, actual: 0 };
            }
            // You can customize this logic.
            // Here we add the amounts regardless of was_planned/was_completed.
            groups[year].planned += Number(project.planned_amount) || 0;
            groups[year].actual += Number(project.actual_amount) || 0;
        });
        // Convert the grouped object to a sorted array and compute the difference
        return Object.values(groups)
            .sort((a, b) => a.year - b.year)
            .map(item => ({ ...item, difference: item.planned - item.actual }));
    });

    // Prepare chart data using the aggregated project data
    const chartData = computed(() => {
        const labels = projectsByYear.value.map(item => item.year);
        const plannedData = projectsByYear.value.map(item => item.planned);
        const actualData = projectsByYear.value.map(item => item.actual);

        return {
            labels,
            datasets: [
                {
                    label: 'Planned Amount',
                    data: plannedData,
                    backgroundColor: 'rgba(54, 162, 235, 0.9)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Actual Amount',
                    data: actualData,
                    backgroundColor: 'rgba(255, 99, 132, 0.9)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                },
            ],
        };
    });

    const chartOptions = ref({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: { display: true },
            tooltip: {
                callbacks: {
                    label: tooltipItem => formatMoney(tooltipItem.raw, true),
                },
            },
            datalabels: {
                formatter: value => `${formatMoney(value, false)}`,
                color: 'black',
                font: { size: 16, weight: 'bold' },
                backgroundColor: 'white',
                borderRadius: 5,
            },
        },
        scales: {
            x: {
                stacked: false,
                title: {
                    display: true,
                    text: 'Year',
                },
            },
            y: {
                beginAtZero: true,
                stacked: false,
                ticks: {
                    callback: value => formatMoney(value, true),
                },
                title: {
                    display: true,
                    text: 'Amount (CAD)',
                },
            },
        },
    });
</script>

<template>
    <div
        :class="[
            smAndDown ? 'my-12 py-12 bg-[#f3f3f3]' : 'py-24 my-24 bg-[#f3f3f3]',
        ]"
    >
        <div class="max-w-[1400px] mx-auto px-8">
            <div class="text-2xl md:text-4xl font-bold mb-8">
                Capital Projects Overview
            </div>
            <div class="flex flex-col md:flex-row gap-12 my-12">
                <div class="flex flex-col md:flex-row gap-12 my-12">
                    <div class="max-w-[800px] self-center">
                        This report lists the capital projects that the
                        corporation plans or has executed. Use this data to
                        verify if the organization is following the reserve fund
                        plan detailed in the Reserve Fund Study.
                    </div>
                    <p
                        class="max-w-[600px] self-center md:border-l-2 md:pl-2 text-sm bg-gray-50 p-2"
                    >
                        A Reserve Fund Study produces a five-year plan that
                        outlines the expected projects and budgeted
                        expenditures. Comparing the planned versus actual
                        amounts here will help you assess if the corporation is
                        adhering to those recommendations.
                    </p>
                </div>
            </div>

            <!-- Desktop: Display the chart if there is data -->
            <div class="mb-12" v-if="!smAndDown && projectsByYear.length > 0">
                <Bar :data="chartData" :options="chartOptions" />
            </div>

            <!-- If there are no projects -->
            <div v-if="projectsByYear.length === 0" class="text-2xl font-bold">
                No Capital Projects have been reported as of the date of this
                review.
            </div>

            <!-- Mobile: Use expansion panels to show details by year -->
            <v-expansion-panels v-if="smAndDown">
                <v-expansion-panel
                    v-for="project in projectsByYear"
                    :key="project.year"
                >
                    <v-expansion-panel-title class="font-bold">
                        Year - {{ project.year }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <div class="mt-2">
                            <div>
                                <strong>Planned:</strong>
                                {{ formatMoney(project.planned, true) }}
                            </div>
                            <div>
                                <strong>Actual:</strong>
                                {{ formatMoney(project.actual, true) }}
                            </div>
                            <div
                                :class="
                                    project.difference > 0
                                        ? 'text-green-500'
                                        : 'text-red-500'
                                "
                            >
                                <strong>Difference:</strong>
                                {{
                                    formatMoney(
                                        Math.abs(project.difference),
                                        true
                                    )
                                }}
                                ({{
                                    project.difference > 0
                                        ? 'Under Budget'
                                        : 'Over Budget'
                                }})
                            </div>
                        </div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>
