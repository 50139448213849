<script setup>
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    import { usePage } from '@inertiajs/vue3';
    import { computed, ref } from 'vue';
    const { formatMoney } = useMoneyFormatter();
    const page = usePage();
    import { useDisplay } from 'vuetify';
    const { smAndDown } = useDisplay();
    const review = computed(() => page.props.review);
    const corporation = computed(() => review.value.corporation);
</script>
<template>
    <div class="max-w-[1400px] mx-auto" :class="smAndDown ? 'my-0' : 'my-24'">
        <div class="flex px-8">
            <div class="md:w-1/3" />
            <div
                :class="smAndDown ? 'py-8' : ''"
                class="md:text-6xl text-3xl font-bold md:px-6 md:-mb-2"
            >
                OVERVIEW
            </div>
        </div>
        <div
            class="flex flex-col gap-6 md:flex-row bg-[#F3F3F3] px-8 rounded-2xl"
        >
            <div class="md:w-1/3 rounded-xl md:flex md:justify-center md:pb-12">
                <iframe
                    class="md:-mt-11 rounded-xl"
                    :width="smAndDown ? '100%' : '80%'"
                    :height="smAndDown ? 350 : 450"
                    frameborder="0"
                    style="border: 0"
                    :src="`https://www.google.com/maps?q=${encodeURIComponent(corporation.FullAddress)}&output=embed`"
                    allowfullscreen
                ></iframe>
            </div>
            <div class="md:w-2/3">
                <div>
                    <div>
                        <div
                            class="font-bold text-lg md:text-2xl md:px-6 md:pt-6"
                        >
                            General Information
                        </div>
                        <div class="grid gap-4 md:gap-0 md:grid-cols-3">
                            <div class="md:p-6">
                                <div class="font-bold">Corporation</div>
                                <div>{{ corporation.name }}</div>
                                <div>{{ corporation.FullAddress }}</div>
                                <div v-if="corporation.management_company">
                                    Mgnt:
                                    {{
                                        corporation.management_company
                                            ?.company_name
                                    }}
                                </div>
                                <div
                                    v-if="
                                        corporation?.management_company?.phone
                                    "
                                >
                                    Mgnt Phone:
                                    {{ corporation.management_company?.phone }}
                                </div>
                                <div>Plan #{{ corporation.plan_number }}</div>
                                <div>
                                    {{
                                        corporation.bylaws_are_registered
                                            ? 'Bylaws are registered'
                                            : 'Bylaws are not registered*'
                                    }}
                                </div>
                            </div>
                            <div
                                class="md:p-6"
                                :style="
                                    !smAndDown
                                        ? 'border-left: 2px dotted; border-right: 2px dotted;'
                                        : ''
                                "
                            >
                                <div class="font-bold">Building</div>
                                <div v-if="corporation.year_built_approx">
                                    Built In
                                    {{ corporation.year_built_approx }}
                                    (approx.)
                                </div>
                                <div>{{ corporation?.type?.name }}</div>
                                <div v-if="corporation.number_of_floors">
                                    {{ corporation.number_of_floors }} Floors
                                </div>
                                <div v-if="corporation.residential_units">
                                    {{ corporation.residential_units }}
                                    Residential Units
                                </div>
                                <div v-if="corporation.commercial_units">
                                    {{ corporation.commercial_units }}
                                    Commercial Units
                                </div>
                                <div v-if="corporation.parking_units">
                                    {{ corporation.parking_units }} Parking
                                    Units
                                </div>
                                <div v-if="corporation.storage_units">
                                    {{ corporation.storage_units }} Storage
                                    Units
                                </div>
                                <div v-if="corporation.corporation_owned_units">
                                    {{ corporation.corporation_owned_units }}
                                    Corporation Owned Units
                                </div>
                                <div
                                    v-if="corporation.OwnerOccupancyPercentage"
                                >
                                    {{ corporation.OwnerOccupancyPercentage }}%
                                    Owner Occupancy
                                </div>
                                <div v-if="corporation.has_post_tension_cables">
                                    Post Tension Cables: Yes
                                </div>
                            </div>
                            <div class="md:p-6">
                                <div class="font-bold">Property</div>
                                <div v-if="review.municipal_address">
                                    Municipal Address:
                                    {{ review.municipal_address }}
                                </div>
                                <div v-if="review.unit_number">
                                    Unit #{{ review.unit_number }}
                                </div>
                                <div v-if="review.legal_unit_number">
                                    Legal Unit #{{ review.legal_unit_number }}
                                </div>
                                <div
                                    v-if="
                                        review.unit_unit_factors ||
                                        review.parking_unit_factors ||
                                        review.storage_unit_factors
                                    "
                                >
                                    Unit Factors:
                                    {{
                                        (review.unit_unit_factors || 0) +
                                        (review.parking_unit_factors || 0) +
                                        (review.storage_unit_factors || 0)
                                    }}
                                </div>
                                <div>
                                    Monthly Fees:
                                    {{ formatMoney(review.condo_fees) }}
                                </div>
                                <div v-if="review.property_tax">
                                    Property Tax:
                                    {{ formatMoney(review.property_tax) }}
                                </div>
                                <div>
                                    Approx.
                                    {{ review.approx_square_footage }} Sq. Ft.
                                </div>
                                <div v-if="review.parking_stall_number">
                                    Parking Stall #{{
                                        review.parking_stall_number
                                    }}
                                </div>
                                <div>
                                    Parking Stall Is Titled:
                                    {{
                                        review.parking_is_titled ? 'Yes' : 'No'
                                    }}
                                </div>
                                <div
                                    v-if="
                                        review.parking_is_titled &&
                                        review.legal_unit_number_parking
                                    "
                                >
                                    Parking Legal #{{
                                        review.legal_unit_number_parking
                                    }}
                                </div>
                                <div v-if="review.storage_stall_number">
                                    Storage #{{ review.storage_stall_number }}
                                </div>
                                <div v-if="review.storage_is_titled">
                                    Storage is Titled:
                                    {{
                                        review.storage_is_titled ? 'Yes' : 'No'
                                    }}
                                </div>
                                <div
                                    v-if="
                                        review.storage_is_titled &&
                                        review.legal_unit_number_storage
                                    "
                                >
                                    Storage Legal #{{
                                        review.legal_unit_number_storage
                                    }}
                                </div>
                                <div>
                                    {{
                                        corporation.is_bareland
                                            ? 'Bareland'
                                            : 'Not Bareland'
                                    }}
                                </div>
                                <div
                                    v-if="review.outstanding_levy_amount"
                                    class="text-red-500"
                                >
                                    Outstanding Levy:
                                    {{
                                        formatMoney(
                                            review.outstanding_levy_amount
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="md:pl-6 pt-4 pb-8 md:py-0">
                            <v-btn
                                color="primary"
                                :href="`https://www.google.com/maps?q=${encodeURIComponent(corporation.FullAddress)}`"
                                target="_blank"
                            >
                                Open in Maps
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
