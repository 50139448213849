import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import '@mdi/font/css/materialdesignicons.css';
// import 'vuetify/styles';

import { aliases, mdi } from 'vuetify/iconsets/mdi'; // Import MDI iconset from Vuetify

const customTheme = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        primary: '#131313',
    },
};

export const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'customTheme',
        themes: {
            customTheme,
        },
    },
    display: {
        mobileBreakpoint: 'sm',
        //Match TailwindCSS
        thresholds: {
            xs: 0,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
            '2xl': 1400,
            '3xl': 1600,
        },
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
    defaults: {
        VAutocomplete: {
            variant: 'outlined',
            clearable: true,
            color: 'primary',
            density: 'compact',
            hideNoData: true,
            // chips: true,
            closableChips: true,
        },
        // VCheckbox: {
        //     variant: 'outlined',
        //     clearable: true,
        //     color: 'primary',
        //     density: 'compact'
        // },
        // VDatePicker: {
        //     variant: 'outlined',
        //     clearable: true,
        //     color: 'accent',
        //     density: 'compact'
        // },
        VFileInput: {
            variant: 'outlined',
            color: 'primary',
            density: 'compact',
        },
        // VRadio: {
        //     color: 'primary',
        // },
        // VSwitch: {
        //     variant: 'outlined',
        //     clearable: true,
        //     color: 'primary',
        //     density: 'compact'
        // },
        // VTextarea: {
        //     variant: 'outlined',
        //     clearable: true,
        //     color: 'primary',
        //     density: 'compact'
        // },
        VTextField: {
            variant: 'outlined',
            clearable: true,
            color: 'primary',
            density: 'compact',
        },
        VSelect: {
            variant: 'outlined',
            //clearable: true,
            color: 'primary',
            density: 'compact',
        },
        VTextarea: {
            variant: 'outlined',
            color: 'primary',
            density: 'compact',
        },
        VBtn: {
            // variant: 'elevated',
            size: 'default',
            // type: 'button',
            // block: false,
            // density: 'default',
            // color: 'primary',
        },
        // VIcon: {
        //     color: 'accent',
        // },
    },
});
