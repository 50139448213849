<script setup>
    import { ref, computed, watch } from 'vue';
    import CurrencyInput from '../Inputs/CurrencyInput.vue';
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';

    const { formatMoney } = useMoneyFormatter();

    const props = defineProps({
        budget: {
            type: Object,
            required: true,
        },
        entries: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        validationErrors: {
            type: Object,
            default: () => ({}),
        },
    });

    const emit = defineEmits(['update:entries']);

    const localEntries = ref([...props.entries]);

    watch(
        () => localEntries.value,
        newValue => {
            emit('update:entries', newValue);
        },
        { deep: true }
    );

    // Computed totals
    const totalBudget = computed(() =>
        localEntries.value
            .filter(entry => entry.is_relevant)
            .reduce(
                (total, entry) =>
                    total + parseFloat(entry.fiscal_year_budget_amount || 0),
                0
            )
    );

    const totalActual = computed(() =>
        localEntries.value
            .filter(entry => entry.is_relevant)
            .reduce(
                (total, entry) =>
                    total + parseFloat(entry.reporting_period_amount || 0),
                0
            )
    );

    const balance = computed(() => totalBudget.value - totalActual.value);
</script>
<template>
    <div>
        <div class="flex py-2">
            <v-card elevation="2">
                <v-card-title class="font-bold">
                    Budget Reporting Period
                </v-card-title>
                <v-card-subtitle>
                    Fiscal Year Start to Latest Reporting Period
                </v-card-subtitle>
                <v-card-text>
                    <div>
                        {{ budget.fiscal_year_start }} -
                        {{ budget.reporting_period_end }}
                    </div>
                    <div>{{ budget.fiscal_year_elapsed_time }}</div>
                </v-card-text>
            </v-card>
        </div>

        <div
            class="grid grid-cols-1 md:grid-cols-5 gap-4 border-b font-bold py-4"
        >
            <div class="self-center">Item</div>
            <div class="self-center">
                <div>Budget</div>
                <div>
                    Fiscal Year
                    {{ new Date(budget.fiscal_year_start).getFullYear() }}-{{
                        new Date(budget.fiscal_year_start).getFullYear() + 1
                    }}
                </div>
            </div>
            <div class="self-center">
                <div>Actual As Of</div>
                <div>{{ budget.reporting_period_end }}</div>
            </div>
            <div>Note</div>
            <div class="self-center">Use in Report</div>
        </div>
        <div>
            <div v-for="(entry, index) in localEntries" :key="index">
                <div
                    class="grid grid-cols-1 md:grid-cols-5 gap-4 border-b pt-7"
                    :class="{ 'bg-gray-50': !entry.is_relevant }"
                >
                    <div
                        class="self-center -mt-6"
                        :class="{ 'text-gray-400': !entry.is_relevant }"
                    >
                        <div>
                            <div>{{ entry.item.name }}</div>
                            <div
                                :class="{
                                    'text-gray-400': !entry.is_relevant,
                                    'text-gray-700': entry.is_relevant,
                                }"
                                class="text-xs italic"
                            >
                                {{ entry.item.type.name }}
                            </div>
                        </div>
                    </div>
                    <CurrencyInput
                        :loading="loading"
                        :disabled="!entry.is_relevant || loading"
                        v-model="entry.fiscal_year_budget_amount"
                        label="Budgeted"
                        :error="
                            validationErrors[
                                `entries.${index}.fiscal_year_budget_amount`
                            ]
                        "
                        :error-messages="
                            validationErrors[
                                `entries.${index}.fiscal_year_budget_amount`
                            ]
                        "
                    />

                    <CurrencyInput
                        :loading="loading"
                        :disabled="!entry.is_relevant || loading"
                        v-model="entry.reporting_period_amount"
                        label="Actual"
                        :error="
                            validationErrors[
                                `entries.${index}.reporting_period_amount`
                            ]
                        "
                        :error-messages="
                            validationErrors[
                                `entries.${index}.reporting_period_amount`
                            ]
                        "
                    />

                    <v-text-field
                        v-model="entry.notes"
                        :disabled="loading"
                        label="Notes"
                        :error="validationErrors[`entries.${index}.notes`]"
                        :error-messages="
                            validationErrors[`entries.${index}.notes`]
                        "
                    />

                    <VCheckbox
                        class="self-center"
                        :disabled="loading"
                        :loading="loading"
                        v-model="entry.is_relevant"
                        label="Show"
                    />
                </div>
            </div>
            <div>
                <div
                    class="grid grid-cols-1 md:grid-cols-4 gap-4 font-bold py-4 mt-5"
                >
                    <div>Total</div>
                    <div>{{ formatMoney(totalBudget) }}</div>
                    <div>{{ formatMoney(totalActual) }}</div>
                    <div>
                        <span>
                            {{ formatMoney(balance) }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
