<script setup>
    import { computed } from 'vue';

    // Props
    const props = defineProps({
        isOpen: Boolean,
        title: String,
        noActions: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: String,
            default: '1100px',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        saveText: {
            type: String,
            default: 'Save',
        },
        cancelText: {
            type: String,
            default: 'Cancel',
        },
    });

    // Emits
    const emit = defineEmits(['close', 'save']);

    // Computed property to control dialog
    const isOpen = computed({
        get: () => props.isOpen,
        set: value => {
            if (!value) emit('close');
        },
    });

    // Methods
    const onSave = () => {
        emit('save');
    };

    const onClose = () => {
        emit('close');
    };
</script>

<template>
    <v-dialog v-model="isOpen" persistent :max-width="maxWidth">
        <v-card>
            <v-card-title>
                <slot name="title">
                    <div class="text-lg sm:text-3xl font-bold p-4">
                        {{ title }}
                    </div>
                </slot>
            </v-card-title>
            <v-card-text>
                <slot></slot>
            </v-card-text>

            <v-card-actions v-if="!props.noActions">
                <v-btn :loading="loading" class="mr-4" text @click="onClose">
                    {{ cancelText }}
                </v-btn>
                <v-btn
                    :loading="loading"
                    variant="flat"
                    class="px-8"
                    color="primary"
                    @click="onSave"
                >
                    {{ saveText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
