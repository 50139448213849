<script setup>
    import VueSpeedometer from 'vue-speedometer';
    import { ref, computed, watch } from 'vue';
    import { useDisplay } from 'vuetify';
    import KPICard from './KPICard.vue';
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';

    const { smAndDown } = useDisplay();
    const { formatMoney } = useMoneyFormatter();

    import { usePage } from '@inertiajs/vue3';
    const page = usePage();

    const reserveFundData = ref({
        recommended_70: 0,
        recommend_80: 0,
        recommended: 0,
        actual: 0,
        total_depreciation: 0,
    });

    watch(
        () => page.props.reserve_fund_analysis_chart_data,
        newValue => {
            reserveFundData.value.recommended = newValue.rf_recommended_70;
            reserveFundData.value.recommended_70 = newValue.rf_recommended_70;
            reserveFundData.value.recommended_80 = newValue.rf_recommended_80;
            reserveFundData.value.actual = newValue.actual_rf;
            reserveFundData.value.total_depreciation =
                newValue.total_depreciation;
        },
        { immediate: true }
    );

    const percentage = computed(() => {
        const recommended = reserveFundData.value.recommended;
        const actual = reserveFundData.value.actual;
        return recommended > 0
            ? Math.min((actual / recommended) * 100, 100)
            : 0;
    });

    const shortfall = computed(
        () => reserveFundData.value.recommended - reserveFundData.value.actual
    );

    const segments = [
        { color: '#FF6384', label: 'Critical', range: [0, 50] },
        { color: '#FFCE56', label: 'Risky', range: [50, 70] },
        { color: '#36A2EB', label: 'Moderate', range: [70, 80] },
        { color: '#4BC0C0', label: 'Healthy', range: [80, 100] },
    ];
</script>

<template>
    <KPICard>
        <template #icon>2</template>
        <template #title>
            <div class="text-2xl md:text-4xl font-bold break-words">
                Reserve Fund Analysis
            </div>
        </template>
        <template #subtitle>
            <div>A review of the recommended reserve funding.</div>
        </template>
        <template #chart>
            <div
                class="flex justify-center"
                :class="{
                    'md:w-1/3': true,
                    'w-screen pr-12 -mb-40': smAndDown,
                }"
            >
                <VueSpeedometer
                    :fluidWidth="!smAndDown"
                    :value="percentage"
                    :min-value="0"
                    :max-value="100"
                    :custom-segment-stops="[0, 50, 70, 80, 100]"
                    :segments="segments.length"
                    :segment-colors="segments.map(segment => segment.color)"
                    :custom-segment-labels="
                        segments.map(segment => ({
                            text: segment.label,
                            position: 'OUTSIDE',
                            fontSize: '12px',
                        }))
                    "
                    :current-value-text="`${percentage.toFixed(2)}% Recommended Funding`"
                    needle-color="#000000"
                    :needle-transition-duration="2500"
                    needle-transition="easeQuadInOut"
                    text-color="#000000"
                />
            </div>
        </template>
        <template #additional-info>
            <div
                :class="{ 'px-0 mt-24': smAndDown, 'px-8': !smAndDown }"
                class="md:w-2/3"
            >
                <div class="flex flex-col md:flex-row gap-4 md:gap-6">
                    <div class="border-dotted border-2 border-gray-400 p-6">
                        <div class="font-bold text-lg md:text-2xl">
                            Recommended
                        </div>
                        <div class="font-bold text-xl">
                            {{ formatMoney(reserveFundData.recommended) }}
                        </div>
                    </div>
                    <div class="border-dotted border-2 border-gray-400 p-6">
                        <div class="font-bold text-lg md:text-2xl">Actual</div>
                        <div class="font-bold text-xl">
                            {{ formatMoney(reserveFundData.actual) }}
                        </div>
                    </div>
                    <div
                        :class="{
                            'border-dotted border-2 p-6': true,
                            'border-red-500 bg-red-100': shortfall > 0,
                            'border-green-500 bg-green-100': shortfall <= 0,
                        }"
                    >
                        <div
                            :class="{
                                'text-red-500': shortfall > 0,
                                'text-green-500': shortfall <= 0,
                            }"
                            class="font-bold text-xl"
                        >
                            {{ shortfall > 0 ? 'Shortfall' : 'Surplus' }}
                        </div>
                        <div
                            :class="{
                                'text-red-500': shortfall > 0,
                                'text-green-500': shortfall <= 0,
                            }"
                            class="font-bold text-xl"
                        >
                            {{ formatMoney(Math.abs(shortfall), true) }}
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-4 mt-8">
                    <div class="font-bold text-lg md:text-3xl">
                        The reserve fund should keep up with the depreciation of
                        the corporation's assets
                    </div>
                    <div class="text-sm italic text-gray-500">
                        The Reserve Fund should be at least 70-80% of the
                        depreciated value of the corporation's assets.
                        Otherwise, you may risk insufficient funds for future
                        maintenance or replacement costs.
                    </div>
                </div>
            </div>
        </template>
        <template #why-it-matters>
            <div>
                When buying a condominium, it's important to look beyond the
                unit itself and consider the financial health of the
                corporation. A key factor in this is the reserve fund—the
                corporation's savings account for major repairs and
                replacements. This fund is used for things like fixing the roof,
                replacing elevators, or updating plumbing systems. If the
                reserve fund isn't adequately funded, the corporation may face
                financial risks, and those costs could fall on owners in the
                form of
                <span class="font-bold">
                    unexpected special assessments or increased fees.
                </span>
            </div>
        </template>
        <template #view-details>
            <div>
                <!-- Header Section -->
                <div class="mb-6">
                    <h2 class="text-xl font-semibold text-gray-800">
                        Reserve Fund & Depreciation Overview
                    </h2>
                    <p class="text-gray-600 text-sm">
                        The reserve fund should align with the depreciation of
                        corporation assets to maintain financial health.
                    </p>
                </div>

                <!-- Key Details Section -->
                <div class="space-y-6">
                    <!-- Depreciation Value -->
                    <div>
                        <h3 class="font-bold text-lg text-gray-800">
                            Total Depreciation
                        </h3>
                        <p class="text-gray-600 text-sm">
                            Current depreciation:
                            <span class="text-red-500 font-bold">
                                {{
                                    formatMoney(
                                        reserveFundData.total_depreciation
                                    )
                                }}
                            </span>
                        </p>
                    </div>

                    <!-- Reserve Fund Status -->
                    <div>
                        <h3 class="font-bold text-lg text-gray-800">
                            Reserve Fund Status
                        </h3>
                        <p class="text-gray-600 text-sm">
                            Recommended range (70%-80%):
                            <span class="text-red-500 font-bold">
                                {{
                                    formatMoney(reserveFundData.recommended_70)
                                }}
                            </span>
                            -
                            <span class="text-red-500 font-bold">
                                {{
                                    formatMoney(reserveFundData.recommended_80)
                                }}
                            </span>
                            .
                            <br />
                            Current reserve fund:
                            <span class="text-red-500 font-bold">
                                {{ formatMoney(reserveFundData.actual) }}
                            </span>
                        </p>
                    </div>

                    <!-- Surplus/Shortfall -->
                    <div>
                        <h3 class="font-bold text-lg text-gray-800">
                            Financial Position
                        </h3>
                        <p
                            :class="{
                                'text-red-500': shortfall > 0,
                                'text-green-500': shortfall <= 0,
                            }"
                            class="text-gray-600 text-sm"
                        >
                            Reserve fund is
                            <span
                                :class="{
                                    'font-bold': true,
                                    'text-red-500': shortfall > 0,
                                    'text-green-500': shortfall <= 0,
                                }"
                            >
                                {{ shortfall > 0 ? 'short' : 'in surplus' }} by
                                {{ formatMoney(Math.abs(shortfall), true) }}
                            </span>
                            .
                        </p>
                    </div>
                </div>
            </div>
        </template>
    </KPICard>
</template>
