<script setup>
    import axios from 'axios';
    import { ref } from 'vue';
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';
    const { documentTypeOptions } = storeToRefs(useOptionsStore());

    const optionsStore = useOptionsStore();
    const { fetchDocumentTypeOptions } = optionsStore;
    fetchDocumentTypeOptions();

    const props = defineProps({
        review: {
            type: Object,
            required: true,
        },
    });

    const overrides = ref(
        props.review.overrides ? JSON.parse(props.review.overrides) : []
    );

    const saveOverrides = () => {
        axios
            .post(`/api/v1/reviews/${props.review.id}/document-overrides`, {
                overrides: overrides.value,
            })
            .then(() => {
                toast.success('Overrides saved successfully');
            })
            .catch(() => {
                toast.error('Failed to save overrides');
            });
    };
</script>
<template>
    <div class="max-w-[1000px] p-4 border rounded-lg shadow-lg mb-12">
        <div class="flex flex-col gap-6 p-4">
            <div class="text-2xl font-bold">
                Override Required Document Types
            </div>
            <v-alert variant="tonal" type="warning" outline>
                Documents selected here will be marked as NOT required, allowing
                the client to bypass uploading the required document.
            </v-alert>
            <v-autocomplete
                v-model="overrides"
                multiple
                chips
                :items="documentTypeOptions"
                label="Document Type Override"
                clearable
                dense
                hide-details
            />
            <div>
                <v-btn @click="saveOverrides" class="mt-4" color="primary">
                    Save Overrides
                </v-btn>
            </div>
        </div>
    </div>
</template>
