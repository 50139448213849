<script setup>
    import { ref, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        item: Object,
    });

    const loading = ref(false);

    const record = ref({});

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        loading.value = true;

        const payload = {
            email: email.value,
            review_id: props.item.id,
        };

        axios
            .post(`/api/v1/reviews/${props.item.id}/invite/`, payload)
            .then(response => {
                toast.success(
                    'Invitation sent! The recipient will receive an email with a link to create an account and join.',
                    {
                        duration: 5000,
                    }
                );
                emit('close');
            })
            .catch(error => {
                toast.open({
                    message:
                        'Something went wrong! Please make sure this email is valid and try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            })
            .finally(() => {
                email.value = '';
                loading.value = false;
            });
    };

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});
    const email = ref('');

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};
        }
    );

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :loading="loading"
        title="Invite a Collaborator"
        saveText="Invite"
        @close="onClose"
        @save="onSave"
    >
        <div class="mb-12 px-4">
            Use this to invite someone to assist with loading documents for this
            review. They will receive an email with a link to create an account
            and join. This can be anyone who is assisting you, such as your real
            estate agent, a family member, or friend. They will receive an email
            with instructions to create an account and join.
        </div>
        <div class="grid grid-cols-1 gap-4">
            <v-text-field
                :loading="loading"
                v-model="email"
                label="Email Address"
                :error="validationErrors.email ? true : false"
                :error-messages="validationErrors.email"
            />
        </div>
    </BaseModal>
</template>
