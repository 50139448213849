<script setup>
    import { ref, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';
    import AISummarizer from '../AISummarizer.vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        item: Object,
        corporation: Object,
    });

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';
    const optionsStore = useOptionsStore();
    const { fetchDeficiencyTypeOptions } = optionsStore;

    const { deficiencyTypeOptions } = storeToRefs(optionsStore);

    // Fetch options
    fetchDeficiencyTypeOptions();

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        const action = mode.value === 'Create' ? axios.post : axios.patch;
        const endpoint =
            mode.value === 'Create'
                ? `/api/v1/corporations/${props.corporation.id}/deficiencies`
                : `/api/v1/corporations/${props.corporation.id}/deficiencies/${record.value.id}`;

        const payload = {
            ...record.value,
        };

        action(endpoint, payload)
            .then(response => {
                emit('close');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            });
    };

    const record = ref({});
    const mode = ref('Create');

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});
    const defaultRecord = ref({
        component_name: '',
        has_technical_audit: false,
        reported_on: '',
        audit_summary_raw_ai: '',
        audit_summary_summarized_ai: '',
        has_engineering_report: false,
        date_of_report: '',
        estimated_project_cost: null,
        engineering_report_raw_ai: '',
        engineering_report_summarized_ai: '',
    });

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/

    // Watch for changes on input record in order to set state for update or create modes
    watch(
        () => props.item,
        recordToUpdate => {
            if (recordToUpdate) {
                record.value = {
                    ...recordToUpdate,
                };
                record.value.deficiency_types =
                    recordToUpdate.deficiency_types.map(type => type.id);
                mode.value = 'Update';
            } else {
                record.value = { ...defaultRecord.value };
                mode.value = 'Create';
            }
        }
    );

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};

            if (isOpen && mode.value === 'Create') {
                record.value = { ...defaultRecord.value };
            }
        }
    );

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };

    const handleRawTextUpdatedEngineeringReport = rawText => {
        record.value.engineering_report_raw_ai = rawText;
    };

    const handleSummaryTextUpdatedEngineeringReport = summaryText => {
        record.value.engineering_report_summarized_ai = summaryText;
    };

    const handleRawTextUpdatedAuditSummary = rawText => {
        record.value.audit_summary_raw_ai = rawText;
    };

    const handleSummaryTextUpdatedAuditSummary = summaryText => {
        record.value.audit_summary_summarized_ai = summaryText;
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :title="`${mode} Deficiency`"
        @close="onClose"
        @save="onSave"
    >
        <div class="grid grid-cols-2 gap-4">
            <v-text-field
                v-model="record.component_name"
                label="Component Name"
                outlined
                :error="validationErrors.component_name ? true : false"
                :error-messages="validationErrors.component_name"
            />

            <v-autocomplete
                v-model="record.deficiency_types"
                :items="deficiencyTypeOptions"
                label="Deficiency Type"
                chips
                multiple
                :error="validationErrors.deficiency_types ? true : false"
                :error-messages="validationErrors.deficiency_types"
            />

            <CurrencyInput
                v-model="record.estimated_project_cost"
                label="Estimated Project Cost"
                outlined
                :error="validationErrors.estimated_project_cost ? true : false"
                :error-messages="validationErrors.estimated_project_cost"
            />

            <v-text-field
                v-model="record.reported_on"
                label="Reported On"
                type="date"
                outlined
                :error="validationErrors.reported_on ? true : false"
                :error-messages="validationErrors.reported_on"
            />

            <div
                class="col-span-full text-2xl font-semibold border-b py-4 my-4"
            >
                Report and Audit
            </div>

            <v-checkbox
                v-model="record.has_engineering_report"
                label="Has Engineering Report"
            />

            <v-text-field
                v-model="record.date_of_report"
                label="Date of Report"
                type="date"
                :disabled="!record.has_engineering_report"
                outlined
                :error="validationErrors.date_of_report ? true : false"
                :error-messages="validationErrors.date_of_report"
            />

            <AISummarizer
                what="Engineering Report"
                :disabled="!record.has_engineering_report"
                :initialRawText="record.engineering_report_raw_ai"
                :initialSummaryText="record.engineering_report_summarized_ai"
                @rawTextUpdated="handleRawTextUpdatedEngineeringReport"
                @summaryUpdated="handleSummaryTextUpdatedEngineeringReport"
            />

            <v-checkbox
                v-model="record.has_technical_audit"
                label="Has Technical Audit"
            />
        </div>
    </BaseModal>
</template>
