<script setup>
    import { ref } from 'vue';
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    const { formatMoney } = useMoneyFormatter();
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Modal
     **************************************************************/
    import LitigationModal from '../../Components/Modals/LitigationModal.vue';
    const isModalOpen = ref(false);
    const selectedLitigation = ref(null);

    const showUpdateModal = item => {
        selectedLitigation.value = item;
        isModalOpen.value = true;
    };

    const showCreateModal = () => {
        selectedLitigation.value = null;
        isModalOpen.value = true;
    };

    const closeModal = () => {
        isModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    const props = defineProps({
        corporation: Object,
    });

    const destroy = item => {
        if (
            !confirm(
                'Are you sure you want to delete this litigation? This action cannot be undone.'
            )
        ) {
            return;
        }

        axios
            .delete(
                `/api/v1/corporations/${props.corporation.id}/litigations/${item.id}`
            )
            .then(response => {
                toast.success('Litigation Deleted Successfully');
                searchTrigger.value = Date.now(); // cause the table to reload
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.');
            });
    };

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = [
        {
            title: 'Year',
            key: 'year',
            value: item => item.year,
            sortable: false,
        },
        {
            title: 'Reason',
            key: 'reason',
            value: item =>
                item.reason.substring(0, 50) +
                (item.reason.length > 50 ? '...' : ''),
            sortable: false,
        },
        {
            title: 'Is Suing',
            key: 'is_suing',
            value: item => (item.is_suing ? 'Is Suing' : 'Being Sued'),
            sortable: false,
        },
        {
            title: 'Status',
            key: 'is_resolved',
            value: item => (item.is_resolved ? 'Resolved' : 'On-going'),
            sortable: false,
        },
        {
            title: 'AI Generated',
            key: 'documents',
            value: item => (item.documents.length > 0 ? 'Yes' : 'No'),
            sortable: false,
        },
    ];

    const searchTrigger = ref(Date.now());

    const additionalParams = ref({
        search: '',
        type: null,
    });
</script>
<template>
    <div>
        <LitigationModal
            :corporation="corporation"
            :isOpen="isModalOpen"
            :item="selectedLitigation"
            @close="closeModal"
        />
        <TableViewManager
            title="Litigations"
            :headers="headers"
            :endpoint="`/api/v1/corporations/${corporation.id}/litigations`"
            subtitle="This is where you can view a listing of a corporation's litigations."
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            hide-breadcrumbs
            :actions="[
                {
                    title: 'Action',
                    icon: 'mdi-pencil',
                    buttonText: 'Edit',
                    buttonColor: 'secondary',
                    action: item => showUpdateModal(item),
                },
                {
                    title: 'Delete',
                    icon: 'mdi-delete',
                    buttonText: 'Delete',
                    buttonColor: 'error',
                    action: item => destroy(item),
                },
            ]"
        >
            <template #actions>
                <v-btn color="primary" class="px-4" @click="showCreateModal">
                    Create New
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn>
            </template>
            <template #filters>
                <v-text-field
                    v-model="additionalParams.search"
                    label="Search"
                    dense
                    hide-details
                ></v-text-field>
            </template>
        </TableViewManager>
    </div>
</template>
