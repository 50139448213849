<script setup>
    import { ref, computed, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();
    import { vMaska } from 'maska/vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        item: Object,
    });

    /**************************************************************
     *      Form
     **************************************************************/
    const record = ref({});
    const mode = ref('Create');

    const validationErrors = ref({});
    const defaultRecord = ref({
        province_id: null,
        company_name: '',
        city: '',
        broker: '',
        phone: '',
        email: '',
        address: '',
        postal: '',
        notes: '',
    });

    const unmaskedPhone = ref('');
    defineExpose({ unmaskedPhone });

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';

    const optionsStore = useOptionsStore();
    const { fetchProvinceOptions } = optionsStore;
    const { provinceOptions } = storeToRefs(optionsStore);
    fetchProvinceOptions();

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        const action = mode.value === 'Create' ? axios.post : axios.patch;
        const endpoint =
            mode.value === 'Create'
                ? '/api/v1/management-companies'
                : `/api/v1/management-companies/${record.value.id}`;

        const payload = {
            province_id: record.value.province_id,
            company_name: record.value.company_name,
            city: record.value.city,
            broker: record.value.broker,
            phone: unmaskedPhone.value,
            email: record.value.email,
            address: record.value.address,
            postal: record.value.postal,
            notes: record.value.notes,
        };

        action(endpoint, payload)
            .then(response => {
                emit('close');
                toast.success('Management company saved successfully.');
                record.value = { ...defaultRecord.value };
            })
            .catch(error => {
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
                toast.error(
                    'An error occurred while saving the management company.'
                );
            });
    };

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/

    // Watch for changes on input record in order to set state for update or create modes
    watch(
        () => props.item,
        recordToUpdate => {
            if (recordToUpdate) {
                record.value = {
                    ...recordToUpdate,
                };
                mode.value = 'Update';
            } else {
                record.value = { ...defaultRecord.value };
                mode.value = 'Create';
            }
        }
    );

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};
        }
    );

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :title="`${mode} Management Company`"
        @close="onClose"
        @save="onSave"
    >
        <div class="grid grid-cols-2 gap-4">
            <v-text-field
                v-model="record.company_name"
                label="Company Name"
                type="text"
                :error="validationErrors.company_name ? true : false"
                :error-messages="validationErrors.company_name"
            />

            <v-text-field
                v-model="record.broker"
                label="Broker"
                type="text"
                :error="validationErrors.broker ? true : false"
                :error-messages="validationErrors.broker"
            />

            <v-text-field
                v-model="record.phone"
                label="Phone"
                type="tel"
                :error="validationErrors.phone ? true : false"
                :error-messages="validationErrors.phone"
                placeholder="(###) ###-####"
                v-maska:unmaskedPhone.unmasked="'(###) ###-####'"
            />

            <v-text-field
                v-model="record.email"
                label="Email"
                type="email"
                :error="validationErrors.email ? true : false"
                :error-messages="validationErrors.email"
            />

            <v-autocomplete
                v-model="record.province_id"
                :items="provinceOptions"
                label="Province"
                :error="validationErrors.province_id ? true : false"
                :error-messages="validationErrors.province_id"
            />

            <v-text-field
                v-model="record.address"
                label="Address"
                type="text"
                :error="validationErrors.address ? true : false"
                :error-messages="validationErrors.address"
            />

            <v-text-field
                v-model="record.city"
                label="City"
                type="text"
                :error="validationErrors.city ? true : false"
                :error-messages="validationErrors.city"
            />

            <v-text-field
                v-model="record.postal"
                label="Postal Code"
                type="text"
                :error="validationErrors.postal ? true : false"
                :error-messages="validationErrors.postal"
            />

            <v-textarea
                class="col-span-full"
                v-model="record.notes"
                label="Notes"
                type="text"
                :error="validationErrors.notes ? true : false"
                :error-messages="validationErrors.notes"
            />

            <!--             
            <v-text-field
                v-model="record.name"
                label="Name"
                type="text"
                :error="validationErrors.name ? true : false"
                :error-messages="validationErrors.name"
            />

            <v-textarea
                class="col-span-full"
                v-model="record.notes"
                label="Notes"
                type="text"
                :error="validationErrors.notes ? true : false"
                :error-messages="validationErrors.notes"
            /> -->
        </div>
    </BaseModal>
</template>
