<script setup>
    import { ref } from 'vue';
    import axios from 'axios';

    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    const props = defineProps({
        corporation: Object,
        validationErrors: Object,
    });

    const corp = ref({
        ...props.corporation,
    });

    /**************************************************************
     *      Methods for Managing Bylaw QA
     **************************************************************/
    const addBylawEntry = () => {
        corp.value.bylaw_qa.push({ question: '', answer: '' });
    };

    const removeBylawEntry = index => {
        corp.value.bylaw_qa.splice(index, 1);
    };

    const updateBylawEntry = (index, field, value) => {
        corp.value.bylaw_qa[index][field] = value;
    };

    const update = () => {
        axios
            .patch(`/api/v1/corporations/${corp.value.id}/bylaws`, corp.value)
            .then(response => {
                toast.success('Bylaws Updated Successfully');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            });
    };
</script>
<template>
    <div class="w-full">
        <div class="flex justify-between">
            <div class="text-2xl self-center font-bold">Bylaw Q/A</div>
        </div>
        <div class="text-gray-700 mt-3 mb-8">
            Edit information pertaining to the Corporation's Bylaws Q/A here.
        </div>
        <div class="max-w-[1000px] p-4 border rounded-lg shadow-lg mb-12">
            <v-checkbox
                @input="emitUpdate"
                v-model="corp.bylaws_are_registered"
                label="Bylaws are Registered"
                required
                :error="validationErrors.bylaws_are_registered ? true : false"
                :error-messages="validationErrors.bylaws_are_registered"
            />

            <div
                v-for="(entry, index) in corp.bylaw_qa"
                :key="index"
                class="mb-4"
            >
                <v-text-field
                    v-model="entry.question"
                    label="Question"
                    type="text"
                    outlined
                    required
                    class="mb-2"
                    @input="
                        () =>
                            updateBylawEntry(index, 'question', entry.question)
                    "
                />
                <v-textarea
                    v-model="entry.answer"
                    label="Answer"
                    outlined
                    required
                    @input="
                        () => updateBylawEntry(index, 'answer', entry.answer)
                    "
                />
                <v-btn
                    size="small"
                    color="error"
                    class="mb-4"
                    @click="removeBylawEntry(index)"
                >
                    Remove
                </v-btn>
            </div>
            <div>
                <v-btn color="primary" size="small" @click="addBylawEntry">
                    Add Bylaw Entry
                </v-btn>
            </div>
            <v-btn color="primary" class="mt-8" @click="update">Save</v-btn>
        </div>
    </div>
</template>
