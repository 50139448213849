<script setup>
    import { ref, computed } from 'vue';
    import { usePage } from '@inertiajs/vue3';

    import { useDisplay } from 'vuetify';
    const { smAndDown } = useDisplay();

    const page = usePage();
    const bylawQa = ref(page.props.review.corporation.bylaw_qa);
</script>

<template>
    <div class="max-w-[1400px] mx-auto px-8 my-24">
        <div class="text-2xl md:text-4xl font-bold mb-8">Bylaw Q&A</div>
        <p class="max-w-[800px] self-center mb-2">
            Below is a list of common questions and answers regarding the bylaws
            of this corporation.
        </p>
        <p class="max-w-[800px] self-center mb-8 text-sm italic">
            If you have any additional questions, contact the property
            management company for clarification.
        </p>
        <v-expansion-panels>
            <v-expansion-panel v-for="(item, index) in bylawQa" :key="index">
                <v-expansion-panel-title>
                    {{ item.question }}
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <div class="whitespace-pre-line">{{ item.answer }}</div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
