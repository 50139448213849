<script setup>
    import { ref } from 'vue';
    import CorporationGeneralForm from './CorporationGeneralForm.vue';

    const emit = defineEmits(['update']);

    const props = defineProps({
        corporation: Object,
        validationErrors: Object,
    });

    const form = ref({
        ...props.corporation,
    });

    const update = () => {
        emit('update', form.value);
    };

    const updateInternalFormState = updatedRecord => {
        form.value = {
            ...updatedRecord,
        };
    };
</script>
<template>
    <div class="w-full">
        <div class="flex justify-between">
            <div class="text-2xl self-center font-bold">General</div>
        </div>
        <div class="text-gray-700 mt-3 mb-8">
            Edit information pertaining to the Corporation here.
        </div>
        <div class="max-w-[1000px] p-4 border rounded-lg shadow-lg mb-12">
            <CorporationGeneralForm
                :initialRecord="form"
                @formUpdated="updateInternalFormState"
                :validationErrors="props.validationErrors"
            />
            <v-btn color="primary" class="mt-8" @click="update">Save</v-btn>
        </div>
    </div>
</template>
