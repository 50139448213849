<script setup>
    import { ref, computed } from 'vue';
    import { useDisplay } from 'vuetify';
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    import { usePage } from '@inertiajs/vue3';

    const { smAndDown } = useDisplay();
    const { formatMoney } = useMoneyFormatter();
    const page = usePage();

    const showTable = ref(false); // Toggle variable for showing/hiding the table

    const components = ref(
        page.props.review.corporation.components
            .map(component => {
                return {
                    name: component.name,
                    depreciation_percentage:
                        component.corporation_component.depreciation_percentage,
                    remaining_life_expectancy:
                        component.corporation_component
                            .remaining_life_expectancy,
                    effective_age:
                        component.corporation_component.effective_age,
                    current_replacement_cost:
                        component.corporation_component
                            .current_replacement_cost,
                    depreciation_date:
                        component.corporation_component.depreciation_date,
                };
            })
            .sort(
                (a, b) => b.depreciation_percentage - a.depreciation_percentage
            )
    );

    const yearsSinceLastStudy = computed(() => {
        const studyDate = page.props.review.corporation.LatestReserveFundStudy
            .study_date
            ? new Date(
                  page.props.review.corporation.LatestReserveFundStudy.study_date
              )
            : new Date();
        const currentDate = new Date();
        return currentDate.getFullYear() - studyDate.getFullYear();
    });

    const getHealthBarColor = percentage => {
        if (percentage > 75) return 'red';
        if (percentage > 50) return 'orange';
        return 'green';
    };
</script>

<template>
    <div class="max-w-[1400px] mx-auto px-8">
        <!-- Header Section -->
        <div class="text-2xl md:text-4xl font-bold break-words">
            Component Depreciation Analysis
        </div>
        <div class="text-lg font-bold">
            As of
            {{
                new Date(
                    page.props.review.corporation.LatestReserveFundStudy.study_date
                ).getFullYear() || 'N/A'
            }}
            ({{ yearsSinceLastStudy }} years ago)
        </div>

        <!-- Introductory Text -->
        <div class="flex flex-col md:flex-row gap-4 my-12">
            <p class="max-w-[800px] self-center">
                When purchasing a condo, it's important to understand that
                you're not just buying your individual unit—you’re also
                investing in a shared portion of the condominium corporation's
                collective assets.
                <span class="font-bold">Depreciation</span>
                refers to the gradual wear and tear on these shared assets, such
                as the systems, facilities, and common areas maintained by the
                corporation. Over time, repairs or replacements may be needed,
                and all owners contribute to these costs. Being aware of
                depreciation helps you anticipate potential expenses, safeguard
                your investment, and make informed decisions.
            </p>
            <p
                class="max-w-[800px] self-center md:border-l-2 md:pl-2 text-sm bg-gray-50 p-2"
            >
                Below, you'll find a breakdown of the components, their
                percentage of depreciation (highlighted in green, yellow, or red
                for clarity), and additional details about each component. This
                information can help you better assess the corporation's overall
                asset condition and plan for future financial responsibilities.
            </p>
        </div>

        <!-- Depreciation Totals Section -->
        <div class="mb-10">
            <div class="p-6 flex flex-col md:flex-row gap-12 items-center">
                <!-- Overall Depreciation -->
                <div
                    class="flex flex-col gap-2 items-center bg-gray-50 py-4 px-12 rounded-lg border shadow-xl"
                >
                    <div>Depreciation</div>
                    <v-progress-circular
                        :model-value="
                            components.reduce(
                                (acc, component) =>
                                    acc + component.depreciation_percentage,
                                0
                            ) / components.length
                        "
                        :size="100"
                        :width="15"
                        :color="
                            getHealthBarColor(
                                components.reduce(
                                    (acc, component) =>
                                        acc + component.depreciation_percentage,
                                    0
                                ) / components.length
                            )
                        "
                    >
                        <template v-slot:default>
                            <span class="text-[#131313] text-sm font-bold">
                                {{
                                    Math.round(
                                        components.reduce(
                                            (acc, component) =>
                                                acc +
                                                component.depreciation_percentage,
                                            0
                                        ) / components.length
                                    )
                                }}%
                            </span>
                        </template>
                    </v-progress-circular>
                    <div>Of All Assets</div>
                </div>
                <!-- Total Depreciation -->
                <div>
                    <div class="flex gap-2">
                        <div class="font-bold text-lg md:text-2xl self-center">
                            Total Depreciation
                        </div>
                        <v-icon
                            small
                            class="self-center"
                            v-tooltip.bottom="
                                'This is the sum total of the accrued deprecation of all assets.'
                            "
                        >
                            mdi-help-rhombus
                        </v-icon>
                    </div>
                    <div class="font-bold text-xl">
                        {{
                            formatMoney(
                                page.props.reserve_fund_analysis_chart_data
                                    .total_depreciation
                            )
                        }}
                        <span class="text-sm">
                            as of
                            {{ new Date().getFullYear() - yearsSinceLastStudy }}
                        </span>
                    </div>
                </div>
                <!-- Your Share Of Depreciation -->
                <div>
                    <div class="flex gap-2">
                        <div class="font-bold text-lg md:text-2xl self-center">
                            Your Share Of Depreciation
                        </div>
                        <v-icon
                            small
                            class="self-center"
                            v-tooltip.bottom="
                                'This is the portion of depreciation your shares are liable for'
                            "
                        >
                            mdi-help-rhombus
                        </v-icon>
                    </div>
                    <div class="font-bold text-xl">
                        {{
                            formatMoney(
                                page.props.reserve_fund_analysis_chart_data
                                    .total_depreciation *
                                    (page.props.review.OwnershipPercentage /
                                        100)
                            )
                        }}
                        <span class="text-sm">
                            as of
                            {{ new Date().getFullYear() - yearsSinceLastStudy }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Expansion Panels Section -->
        <div>
            <v-expansion-panels>
                <v-expansion-panel
                    v-for="(component, index) in components"
                    :key="index"
                >
                    <v-expansion-panel-title>
                        <div
                            class="flex gap-4 items-center justify-between w-full"
                        >
                            <div class="font-bold">
                                <v-icon
                                    class="mr-2"
                                    size="xs"
                                    :color="
                                        getHealthBarColor(
                                            component.depreciation_percentage
                                        )
                                    "
                                >
                                    mdi-circle
                                </v-icon>
                                {{ component.name }}
                            </div>
                            <div class="flex gap-4">
                                <div
                                    :class="{
                                        'text-red-500':
                                            component.depreciation_percentage >
                                            75,
                                        'text-orange-500':
                                            component.depreciation_percentage >
                                                50 &&
                                            component.depreciation_percentage <=
                                                75,
                                        'text-green-500':
                                            component.depreciation_percentage <=
                                            50,
                                    }"
                                >
                                    <span
                                        class="text-[#131313] uppercase text-xs"
                                    >
                                        Depreciated By
                                    </span>
                                    {{
                                        Math.round(
                                            component.depreciation_percentage
                                        )
                                    }}%
                                </div>
                            </div>
                        </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <div
                            class="flex flex-col md:flex-row md:justify-between"
                        >
                            <div>
                                <div class="flex flex-col gap-2">
                                    <div class="flex flex-col">
                                        <div class="font-bold">
                                            Depreciation As of
                                            {{
                                                new Date().getFullYear() -
                                                yearsSinceLastStudy
                                            }}
                                        </div>
                                        <div>
                                            {{
                                                formatMoney(
                                                    component.current_replacement_cost *
                                                        (component.depreciation_percentage /
                                                            100)
                                                )
                                            }}
                                            <span
                                                class="font-bold uppercase text-xs"
                                            >
                                                ({{ yearsSinceLastStudy }} years
                                                ago)
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex flex-col">
                                        <div class="font-bold">
                                            Full Replacement Cost
                                        </div>
                                        <div>
                                            {{
                                                formatMoney(
                                                    component.current_replacement_cost
                                                )
                                            }}
                                        </div>
                                    </div>
                                    <div class="flex flex-col">
                                        <div class="font-bold">
                                            Effective Age As of
                                            {{ new Date().getFullYear() }}
                                        </div>
                                        <div>
                                            {{
                                                component.effective_age +
                                                yearsSinceLastStudy
                                            }}
                                            years
                                        </div>
                                    </div>
                                    <div class="flex flex-col">
                                        <div class="font-bold">
                                            Remaining Life Expectancy As of
                                            {{ new Date().getFullYear() }}
                                        </div>
                                        <div>
                                            {{
                                                component.remaining_life_expectancy -
                                                yearsSinceLastStudy
                                            }}
                                            years
                                        </div>
                                    </div>
                                    <div class="flex flex-col">
                                        <div class="font-bold">
                                            End Of Life On
                                        </div>
                                        <div>
                                            {{ component.depreciation_date }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4">
                                <v-progress-circular
                                    :model-value="
                                        component.depreciation_percentage
                                    "
                                    :size="85"
                                    :width="12"
                                    :color="
                                        getHealthBarColor(
                                            component.depreciation_percentage
                                        )
                                    "
                                >
                                    <template v-slot:default>
                                        <span
                                            class="text-[#131313] text-sm font-bold"
                                        >
                                            {{
                                                component.depreciation_percentage
                                            }}%
                                        </span>
                                    </template>
                                </v-progress-circular>
                            </div>
                        </div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>

        <div class="mt-10">
            <v-btn
                color="primary"
                @click="showTable = !showTable"
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
                {{ showTable ? 'Hide Table' : 'Show As Table' }}
            </v-btn>

            <div v-if="showTable" class="overflow-x-auto">
                <div class="text-2xl font-bold mt-8">
                    Component Depreciation Table
                </div>
                <div class="mb-4">
                    As of
                    {{
                        new Date(
                            page.props.review.corporation.LatestReserveFundStudy.study_date
                        ).getFullYear() || 'N/A'
                    }}
                    ({{ yearsSinceLastStudy }} years ago)
                </div>
                <thead class="bg-gray-200">
                    <tr>
                        <th class="text-left px-4 py-2">Component Name</th>
                        <th class="text-left px-4 py-2">
                            Depreciation Percentage
                        </th>
                        <th class="text-left px-4 py-2">
                            Remaining Life Expectancy
                            <div class="uppercase text-xs">
                                As Of {{ new Date().getFullYear() }}
                            </div>
                        </th>
                        <th class="text-left px-4 py-2">Effective Age</th>
                        <th class="text-left px-4 py-2">
                            Current Replacement Cost
                        </th>
                        <th class="text-left px-4 py-2">End Of Lifecycle</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(component, index) in components"
                        :key="index"
                        class="hover:bg-gray-100"
                    >
                        <td class="border px-4 py-2">
                            {{ component.name }}
                        </td>
                        <td class="border px-4 py-2">
                            {{ component.depreciation_percentage }}%
                        </td>
                        <td class="border px-4 py-2">
                            {{ component.remaining_life_expectancy }}
                            years
                        </td>
                        <td class="border px-4 py-2">
                            {{ component.effective_age }} years
                        </td>
                        <td class="border px-4 py-2">
                            {{
                                formatMoney(component.current_replacement_cost)
                            }}
                        </td>
                        <td class="border px-4 py-2">
                            {{ component.depreciation_date }}
                        </td>
                    </tr>
                </tbody>
            </div>
        </div>
    </div>
</template>
