<script setup>
    import { ref, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';
    import { useToast } from 'vue-toast-notification';
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';

    const validationErrors = ref({});
    const toast = useToast();
    const loading = ref(false);

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        review: Object,
    });

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        loading.value = true;
        const formData = new FormData();

        records.value.forEach((record, index) => {
            formData.append(`files[${index}][file]`, record.file);
            formData.append(
                `files[${index}][document_type_id]`,
                record.document_type_id
            );
        });

        axios
            .post(
                `/api/v1/documents/reviews/${props.review.id}/admin`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then(response => {
                toast.open({
                    message: 'Documents were uploaded successfully!',
                    type: 'success',
                });
                emit('close');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            })
            .finally(() => {
                loading.value = false;
            });
    };

    const records = ref([]);

    const addRecord = () => {
        records.value.push({
            file: null,
            document_type_id: null,
        });
    };

    const removeRecord = index => {
        records.value.splice(index, 1);
    };

    /**************************************************************
     *      Drag-and-Drop
     **************************************************************/
    const dragOver = ref(false);

    const handleDrop = event => {
        event.preventDefault();
        dragOver.value = false;

        // clear any empty records
        records.value = records.value.filter(record => record.file !== null);

        const files = Array.from(event.dataTransfer.files);

        files.forEach(file => {
            records.value.push({
                file: file,
                document_type_id: null, // Optional: Default type or prompt user for input
            });
        });
    };

    const handleDragOver = event => {
        event.preventDefault();
        dragOver.value = true;
    };

    const handleDragLeave = () => {
        dragOver.value = false;
    };

    /**************************************************************
     *      Options
     **************************************************************/
    const { documentTypeOptions } = storeToRefs(useOptionsStore());
    const optionsStore = useOptionsStore();
    const { fetchDocumentTypeOptions } = optionsStore;

    fetchDocumentTypeOptions();

    /**************************************************************
     *      Form
     **************************************************************/
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};
            records.value = [
                {
                    file: null,
                    document_type_id: null,
                },
            ];
        }
    );

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };
</script>

<template>
    <BaseModal
        :loading="loading"
        maxWidth="1280px"
        :isOpen="isOpen"
        :title="`Document Uploader for Review Case #${props.review.review_case_number}`"
        @close="onClose"
        @save="onSave"
    >
        <!-- Drag-and-Drop Area -->
        <div
            class="mb-4 py-8 border-2 border-dashed rounded-md border-gray-800"
            :class="{ 'border-primary': dragOver }"
            @dragover="handleDragOver"
            @dragleave="handleDragLeave"
            @drop="handleDrop"
        >
            <p class="text-center">Drag and drop files here</p>
        </div>

        <v-divider class="mb-12"></v-divider>

        <div
            v-for="(record, index) in records"
            :key="index"
            class="mb-4 border-b pb-4"
        >
            <div class="flex gap-4 items-center w-full">
                <v-file-input
                    :loading="loading"
                    class="w-5/12"
                    v-model="record.file"
                    label="File"
                    accept="application/pdf,image/gif,image/tiff,image/jpeg,image/png,image/bmp,image/webp,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    required
                    outlined
                    :error="
                        validationErrors[`files.${index}.file`] ? true : false
                    "
                    :error-messages="true"
                />

                <v-autocomplete
                    :loading="loading"
                    class="w-4/12"
                    v-model="record.document_type_id"
                    :items="documentTypeOptions"
                    label="Document Type"
                    required
                    outlined
                    :error="
                        validationErrors[`files.${index}.document_type_id`]
                            ? true
                            : false
                    "
                    :error-messages="
                        validationErrors[`files.${index}.document_type_id`]
                    "
                />
                <v-icon
                    class="w-1/12 mb-6"
                    color="error"
                    outlined
                    @click="removeRecord(index)"
                    v-if="records.length > 1"
                >
                    mdi-delete-outline
                </v-icon>
            </div>
        </div>

        <v-btn
            :loading="loading"
            class="mt-4"
            color="primary"
            outlined
            @click="addRecord"
        >
            <v-icon>mdi-plus</v-icon>
            File
        </v-btn>
    </BaseModal>
</template>
