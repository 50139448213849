<script setup>
    import { ref, inject } from 'vue';

    /**************************************************************
     *      Modal
     **************************************************************/
    import ComponentModal from '../../Components/Modals/ComponentModal.vue';
    const isModalOpen = ref(false);
    const selectedComponent = ref(null);

    const showUpdateModal = item => {
        selectedComponent.value = item;
        isModalOpen.value = true;
    };

    const showCreateModal = () => {
        selectedComponent.value = null;
        isModalOpen.value = true;
    };

    const closeModal = () => {
        isModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = [
        { title: 'Name', key: 'name', sortable: false },
        {
            title: 'Default Life Expectancy',
            key: 'default_life_expectancy',
            sortable: false,
        },
    ];

    const searchTrigger = ref(Date.now());

    const additionalParams = ref({
        search: '',
        type: null,
    });
</script>
<template>
    <AuthenticatedLayout>
        <ComponentModal
            :isOpen="isModalOpen"
            :item="selectedComponent"
            @close="closeModal"
        />
        <TableViewManager
            title="Components"
            :headers="headers"
            subtitle="This is where you can modify components System Wide."
            endpoint="/api/v1/components"
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            :actions="[
                {
                    title: 'Action',
                    icon: 'mdi-pencil',
                    buttonText: 'Edit',
                    buttonColor: 'secondary',
                    action: item => showUpdateModal(item),
                },
            ]"
        >
            <template #actions>
                <v-btn color="primary" class="px-4" @click="showCreateModal">
                    Create New
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn>
            </template>
            <template #filters>
                <v-text-field
                    v-model="additionalParams.search"
                    label="Search"
                    dense
                    hide-details
                ></v-text-field>
            </template>
        </TableViewManager>
    </AuthenticatedLayout>
</template>
