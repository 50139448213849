<script setup>
    import { ref, watch, computed } from 'vue';
    import { useToast } from 'vue-toast-notification';
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';

    const toast = useToast();
    const loading = ref(false);
    const validationErrors = ref({});
    const emit = defineEmits(['step-status', 'open-support']);

    const props = defineProps({
        review: Object,
    });

    // Store references
    const optionsStore = useOptionsStore();
    const { documentTypeOptions } = storeToRefs(optionsStore);
    const { fetchDocumentTypeOptions } = optionsStore;

    // Fetch initial document type options
    fetchDocumentTypeOptions();

    const SPECIAL_DOC_TITLE = 'primary_overview_disclosure_document';

    const clientDocuments = ref([]);

    const overrides = ref(
        props.review.overrides ? JSON.parse(props.review.overrides) : []
    );

    /**
     * Generates a unique identifier for client documents.
     */
    const generateIdentifier = () =>
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);

    /**
     * Returns the label for a given document type ID.
     */
    const getDocumentTypeLabel = documentTypeId => {
        const docType = documentTypeOptions.value.find(
            option => option.value === documentTypeId
        );
        return docType ? docType.title : '';
    };

    /**
     * Initializes the clientDocuments array whenever documentTypeOptions change.
     * It maps existing documents onto their corresponding document types and ensures
     * each required document type has at least one entry.
     */
    watch(
        documentTypeOptions,
        documentTypeOptions => {
            if (documentTypeOptions.length) {
                clientDocuments.value = [];

                documentTypeOptions.forEach(docTypeOption => {
                    const isOverridden = overrides.value.includes(
                        docTypeOption.value
                    );
                    const isRequired =
                        docTypeOption.is_required && !isOverridden;

                    const existingDocs = props.review.documents.filter(
                        doc => doc.document_type_id === docTypeOption.value
                    );

                    if (existingDocs.length) {
                        // For each existing document, create a corresponding entry
                        existingDocs.forEach(existingDoc => {
                            clientDocuments.value.push({
                                identifier: generateIdentifier(),
                                existing_document: existingDoc,
                                file: null,
                                document_type_id: docTypeOption.value,
                                is_required: isRequired,
                                can_have_multiple:
                                    docTypeOption.can_have_multiple,
                                description: docTypeOption.description,
                            });
                        });
                    } else {
                        // If no existing documents, create a blank entry
                        clientDocuments.value.push({
                            identifier: generateIdentifier(),
                            existing_document: null,
                            file: null,
                            document_type_id: docTypeOption.value,
                            is_required: isRequired,
                            can_have_multiple: docTypeOption.can_have_multiple,
                            description: docTypeOption.description,
                        });
                    }
                });
            }
        },
        { immediate: true }
    );

    /**
     * Adds a new empty document entry for a given document type ID.
     * This is used when multiple documents of the same type can be uploaded.
     */
    const addClientDocument = documentTypeId => {
        const docTypeOption = documentTypeOptions.value.find(
            option => option.value === documentTypeId
        );

        if (!docTypeOption) return;

        clientDocuments.value.push({
            identifier: generateIdentifier(),
            existing_document: null,
            file: null,
            document_type_id: documentTypeId,
            is_required: docTypeOption.is_required,
            can_have_multiple: docTypeOption.can_have_multiple,
            description: docTypeOption.description,
        });
    };

    /**
     * Handles file selection by uploading the file to the server and
     * updating the corresponding entry in clientDocuments.
     */
    const onHandleFileSelection = (file, identifier) => {
        if (!file) return;

        const index = clientDocuments.value.findIndex(
            doc => doc.identifier === identifier
        );
        if (index === -1) return;

        loading.value = true;

        const formData = new FormData();
        formData.append('file', file);
        formData.append(
            'document_type_id',
            clientDocuments.value[index].document_type_id
        );

        axios
            .post(`/api/v1/documents/reviews/${props.review.id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(response => {
                clientDocuments.value[index].existing_document =
                    response.data.document;
                clientDocuments.value[index].file = null;
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
                // Reset the file input
                clientDocuments.value[index].file = null;
            })
            .finally(() => {
                loading.value = false;
            });
    };

    /**
     * Removes a client document. If the document is already uploaded,
     * it deletes it from the server; otherwise, it just clears the file or removes the entry.
     */
    const removeClientDocument = identifier => {
        const index = clientDocuments.value.findIndex(
            doc => doc.identifier === identifier
        );
        if (index === -1) return;

        const clientDoc = clientDocuments.value[index];
        loading.value = true;

        // If an existing document is present, delete it from the server
        if (clientDoc.existing_document) {
            axios
                .delete(
                    `/api/v1/documents/reviews/${props.review.id}/documents/${clientDoc.existing_document.id}`
                )
                .then(() => {
                    clientDocuments.value[index].existing_document = null;
                    clientDocuments.value[index].file = null;
                })
                .catch(() => {
                    toast.open({
                        message: 'Something went wrong! Please try again.',
                        type: 'error',
                    });
                })
                .finally(() => {
                    loading.value = false;
                });
        } else {
            // If not uploaded, either remove the entry or clear the file
            const docsOfType = clientDocuments.value.filter(
                doc => doc.document_type_id === clientDoc.document_type_id
            );

            // If multiple documents are allowed and more than one exists, remove this entry
            if (clientDoc.can_have_multiple && docsOfType.length > 1) {
                clientDocuments.value.splice(index, 1);
                loading.value = false;
            } else {
                // Otherwise, just reset the file selection
                clientDocuments.value[index].file = null;
                loading.value = false;
            }
        }
    };

    /**
     * Computed property to retrieve the primary overview document type option (if exists).
     */
    const primaryOverviewDocType = computed(() =>
        documentTypeOptions.value.find(
            option => option.name === SPECIAL_DOC_TITLE
        )
    );

    /**
     * Checks if the primary overview document has been uploaded.
     */
    const primaryOverviewDocumentUploaded = computed(() => {
        if (!primaryOverviewDocType.value) return false;
        return clientDocuments.value.some(
            doc =>
                doc.document_type_id === primaryOverviewDocType.value.value &&
                doc.existing_document !== null
        );
    });

    /**
     * Groups client documents by their document type.
     * If the primary overview document is uploaded, only that document group is returned.
     */
    const groupedDocuments = computed(() => {
        const groups = {};

        clientDocuments.value.forEach(doc => {
            const docTypeId = doc.document_type_id;
            if (!groups[docTypeId]) {
                groups[docTypeId] = {
                    documentTypeOption: documentTypeOptions.value.find(
                        option => option.value === docTypeId
                    ),
                    documents: [],
                };
            }
            groups[docTypeId].documents.push(doc);
        });

        // If the primary overview document is uploaded, show only that group
        if (
            primaryOverviewDocumentUploaded.value &&
            primaryOverviewDocType.value
        ) {
            const primaryGroup = groups[primaryOverviewDocType.value.value];
            return primaryGroup ? [primaryGroup] : [];
        }

        return Object.values(groups);
    });

    /**
     * Checks if all required documents have been submitted or if the primary overview is uploaded.
     */
    const allRequiredSubmitted = computed(() => {
        return true;
        // // If primary overview doc is uploaded, all requirements are considered met
        // if (primaryOverviewDocumentUploaded.value) return true;

        // const requiredDocumentTypeIds = documentTypeOptions.value
        //     .filter(
        //         option =>
        //             option.is_required &&
        //             !overrides.value.includes(option.value)
        //     )
        //     .map(option => option.value);

        // return requiredDocumentTypeIds.every(typeId =>
        //     clientDocuments.value.some(
        //         doc =>
        //             doc.document_type_id === typeId &&
        //             doc.existing_document !== null
        //     )
        // );
    });

    // Emit step status whenever allRequiredSubmitted changes
    watch(
        allRequiredSubmitted,
        newValue => {
            emit('step-status', 1, newValue);
        },
        { immediate: true }
    );
</script>

<template>
    <div>
        <v-alert type="success" variant="tonal" class="mb-6">
            <div class="flex flex-col gap-4">
                <div>
                    To proceed with your Condo Review, we kindly request that
                    you submit the following documentation. You can request to
                    obtain these documents from the seller's agent, the seller's
                    management company, or your own agent.
                </div>
                <div>
                    If you have any questions or need assistance, please don't
                    hesitate to reach out.
                </div>
            </div>
        </v-alert>
        <div
            v-for="group in groupedDocuments"
            :key="group.documentTypeOption.value"
        >
            <div
                v-for="(clientDocument, index) in group.documents"
                :key="clientDocument.identifier"
                class="flex flex-col gap-2 border-t pt-4 px-0 sm:px-4 mb-4"
                :class="
                    clientDocument.file || clientDocument.existing_document
                        ? 'bg-green-100'
                        : ''
                "
            >
                <div class="flex justify-between">
                    <div class="flex gap-4">
                        <v-icon
                            v-tooltip.top="clientDocument.description"
                            class="cursor-help"
                        >
                            mdi-information-outline
                        </v-icon>
                        <div class="font-bold">
                            {{
                                getDocumentTypeLabel(
                                    clientDocument.document_type_id
                                )
                            }}
                        </div>
                        <!-- <span
                            v-if="
                                clientDocument.is_required &&
                                !clientDocument.existing_document
                            "
                            class="text-red-500 text-xs self-center"
                        >
                            * Required
                        </span> -->
                    </div>
                    <div
                        v-if="
                            clientDocument.file ||
                            clientDocument.existing_document
                        "
                        class="flex gap-1"
                    >
                        <v-icon color="green">mdi-check-circle-outline</v-icon>
                    </div>
                </div>

                <!-- File Input (when no existing document present) -->
                <v-file-input
                    v-if="!clientDocument.existing_document"
                    :disabled="loading"
                    :loading="loading"
                    v-model="clientDocument.file"
                    label="File"
                    accept="application/pdf,image/gif,image/tiff,image/jpeg,image/png,image/bmp,image/webp"
                    :required="clientDocument.is_required"
                    outlined
                    @update:model-value="
                        file =>
                            onHandleFileSelection(
                                file,
                                clientDocument.identifier
                            )
                    "
                    :error="
                        validationErrors[`files.${index}.file`] ? true : false
                    "
                    :error-messages="
                        validationErrors[`files.${index}.file`] || ''
                    "
                />

                <!-- Existing Document Display -->
                <div
                    v-if="clientDocument.existing_document"
                    class="flex pb-4 justify-between"
                >
                    <div class="flex pl-12">
                        {{ clientDocument.existing_document.original_name }} ({{
                            clientDocument.existing_document.mime_type
                        }})
                    </div>
                    <v-icon
                        v-if="!loading"
                        v-tooltip="'Remove'"
                        class="cursor-pointer self-center"
                        color="red"
                        @click="removeClientDocument(clientDocument.identifier)"
                    >
                        mdi-trash-can-outline
                    </v-icon>
                    <v-progress-circular
                        v-else
                        indeterminate
                        size="24"
                    ></v-progress-circular>
                </div>
            </div>

            <!-- Add additional file if multiple allowed and no primary overview uploaded -->
            <div
                class="flex justify-center mt-4 mb-4"
                v-if="
                    group.documentTypeOption.can_have_multiple &&
                    group.documents.length > 0 &&
                    group.documents.every(doc => doc.existing_document) &&
                    !primaryOverviewDocumentUploaded
                "
            >
                <v-icon
                    size="large"
                    class="cursor-pointer"
                    v-tooltip.top="'Upload additional file'"
                    @click="addClientDocument(group.documentTypeOption.value)"
                >
                    mdi-plus-circle
                </v-icon>
            </div>
        </div>
        <v-alert
            v-if="!allRequiredSubmitted"
            type="warning"
            variant="tonal"
            class="mb-6"
            closable
        >
            Are you missing required documents and unable to proceed? Please
            contact us for assistance.
            <div class="my-6">
                <v-btn color="yellow-darken-4" @click="emit('open-support')">
                    Contact Us
                </v-btn>
            </div>
        </v-alert>
    </div>
</template>
