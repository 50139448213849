<script setup>
    import { ref } from 'vue';

    /**************************************************************
     *      Modal
     **************************************************************/
    import TenantModal from '../../Components/Modals/TenantModal.vue';
    const isModalOpen = ref(false);
    const selectedTenant = ref(null);

    const showUpdateModal = item => {
        selectedTenant.value = item;
        isModalOpen.value = true;
    };

    const showCreateModal = () => {
        selectedTenant.value = null;
        isModalOpen.value = true;
    };

    const closeModal = () => {
        selectedTenant.value = null;
        isModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = [
        {
            title: 'Name',
            key: 'name',
            value: item => item.name,
            sortable: false,
        },
    ];

    const searchTrigger = ref(Date.now());

    const additionalParams = ref({
        search: '',
    });
</script>
<template>
    <AuthenticatedLayout>
        <TenantModal
            :isOpen="isModalOpen"
            :item="selectedTenant"
            @close="closeModal"
            what="Tenant"
        />
        <TableViewManager
            title="Tenants"
            :headers="headers"
            subtitle="This is where you can view a listing of all tenants."
            endpoint="/api/v1/tenants"
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            :actions="[
                {
                    title: 'Action',
                    icon: 'mdi-pencil',
                    buttonText: 'Edit',
                    buttonColor: 'secondary',
                    action: item => showUpdateModal(item),
                },
            ]"
        >
            <template #actions>
                <v-btn color="primary" class="px-4" @click="showCreateModal">
                    Create New
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn>
            </template>
            <template #filters>
                <v-text-field
                    v-model="additionalParams.search"
                    label="Search"
                    dense
                    hide-details
                ></v-text-field>
            </template>
        </TableViewManager>
    </AuthenticatedLayout>
</template>
