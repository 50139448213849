<script setup>
    import { ref, computed, onMounted } from 'vue';
    import { useDisplay } from 'vuetify';

    const { smAndDown } = useDisplay();
    const emit = defineEmits(['loadItems']);

    const props = defineProps({
        headers: { type: Array, required: true },
        serverItems: { type: Array, required: true },
        totalItems: { type: Number, required: true },
        actions: { type: Array, default: () => [] },
        loading: { type: Boolean, required: true },
        searchTrigger: { type: Number, required: true },
        dropdownActions: { type: Boolean, default: false },
    });

    const per_page = ref(50);
    const currentPage = ref(1);
    const mobileItemsPerPage = 50;

    const hasActions = computed(() => props.actions.length > 0);
    const headers = computed(() => {
        const copy = props.headers.map(h => ({ ...h }));
        if (hasActions.value) {
            copy.push({
                key: 'actions',
                title: 'Action',
                sortable: false,
                width: '100px',
            });
        }
        return copy;
    });

    const loadItems = async opts => {
        opts = { ...opts, per_page: per_page.value };
        emit('loadItems', opts);
    };

    const loadMobileItems = page => {
        const opts = { page, per_page: mobileItemsPerPage };
        emit('loadItems', opts);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    onMounted(() => {
        loadMobileItems(1);
    });
</script>

<template>
    <div>
        <div v-if="smAndDown" class="space-y-4">
            <v-card
                v-for="(item, idx) in serverItems.slice(
                    (currentPage - 1) * mobileItemsPerPage,
                    currentPage * mobileItemsPerPage
                )"
                :key="idx"
                elevation="4"
                outlined
            >
                <v-list>
                    <div
                        v-for="(header, i) in headers"
                        :key="header.key + '-' + i"
                        class="px-5 mb-3"
                    >
                        <template v-if="header.key !== 'actions'">
                            <span class="font-bold">{{ header.title }}</span>
                            :
                            <template v-if="$slots['item.' + header.key]">
                                <slot
                                    :name="'item.' + header.key"
                                    :item="item"
                                    :header="header"
                                />
                            </template>
                            <template v-else>
                                <span v-if="header.value">
                                    {{ header.value(item) }}
                                </span>
                                <span v-else>{{ item[header.key] }}</span>
                            </template>
                        </template>
                        <div
                            v-else-if="hasActions"
                            class="grid grid-cols-2 gap-2 mt-2"
                        >
                            <v-btn
                                v-for="(action, actIdx) in props.actions"
                                :key="actIdx"
                                size="small"
                                @click="action.action(item)"
                            >
                                {{ action.title }}
                                <v-icon class="ml-2">{{ action.icon }}</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-list>
            </v-card>

            <v-pagination
                v-model="currentPage"
                density="compact"
                @update:modelValue="loadMobileItems"
            />
        </div>

        <v-data-table-server
            v-else
            v-model:items-per-page="per_page"
            :headers="headers"
            :items="serverItems"
            :items-length="totalItems"
            :loading="loading"
            :search="searchTrigger.toString()"
            hover
            @update:options="loadItems"
        >
            <template
                v-for="(header, idx) in headers"
                :key="header.key + idx"
                #[`item.${header.key}`]="slotProps"
            >
                <template v-if="$slots['item.' + header.key]">
                    <slot :name="'item.' + header.key" v-bind="slotProps" />
                </template>
                <template v-else>
                    <span v-if="header.value">
                        {{ header.value(slotProps.item) }}
                    </span>
                    <span v-else>{{ slotProps.item[header.key] }}</span>
                </template>
            </template>

            <template v-if="hasActions" #[`item.actions`]="{ item }">
                <div v-if="dropdownActions" class="w-20">
                    <v-select
                        :items="actions"
                        item-title="title"
                        item-value="action"
                        label="Actions"
                        placeholder="Actions"
                        @update:modelValue="
                            selectedAction => selectedAction(item)
                        "
                    >
                        <template v-slot:item="{ props: itemProps, item }">
                            <v-list-item v-bind="itemProps">
                                <template v-slot:prepend>
                                    <v-icon color="black">
                                        {{ item.raw.icon }}
                                    </v-icon>
                                </template>
                            </v-list-item>
                        </template>
                    </v-select>
                </div>
                <div v-else class="flex gap-1">
                    <v-btn
                        v-for="(action, aIdx) in actions"
                        :key="aIdx"
                        @click="action.action(item)"
                        size="small"
                    >
                        <v-icon>{{ action.icon }}</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table-server>
    </div>
</template>

<style>
    .v-data-table-footer__items-per-page .v-field__clearable {
        display: none;
    }
</style>
