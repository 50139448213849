<script setup>
    import axios from 'axios';
    import { ref } from 'vue';
    import { useToast } from 'vue-toast-notification';
    const emit = defineEmits(['refresh']);

    const loading = ref(false);

    const toast = useToast();
    const validationErrors = ref({});

    const props = defineProps({
        review: {
            type: Object,
            required: true,
        },
    });

    const form = ref({
        ...props.review,
    });

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';

    const optionsStore = useOptionsStore();
    const { fetchReviewTypeOptions } = optionsStore;
    const { reviewTypeOptions } = storeToRefs(optionsStore);
    fetchReviewTypeOptions();

    /**************************************************************
     *      Update Review Settings
     **************************************************************/
    const updateRiskAssessment = () => {
        loading.value = true;
        validationErrors.value = {};

        const payload = {
            type_id: form.value.type_id,
            contractor_is_paid: form.value.contractor_is_paid,
            condition_date: form.value.condition_date,
            due_date: form.value.due_date,
            review_accessible_until: form.value.review_accessible_until,
            allow_agent_to_view: form.value.allow_agent_to_view,
        };

        axios
            .post(`/api/v1/reviews/${form.value.id}/settings`, payload)
            .then(response => {
                toast.open({
                    message: 'Review updated successfully!',
                    type: 'success',
                });

                emit('refresh');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            })
            .finally(() => {
                loading.value = false;
            });
    };
</script>

<template>
    <div
        class="max-w-[1000px] flex flex-col gap-4 p-4 border rounded-lg shadow-lg mb-12"
    >
        <div class="text-2xl font-bold">Admin Review Settings</div>
        <div class="grid grid-cols-2 gap-4">
            <v-autocomplete
                v-model="form.type_id"
                :items="reviewTypeOptions"
                label="Review Type"
                outlined
                class="col-span-full"
                :error="validationErrors.type_id ? true : false"
                :error-messages="validationErrors.type_id"
                :loading="loading"
            />

            <v-text-field
                v-model="form.condition_date"
                label="Condition Date"
                type="date"
                outlined
                :error="validationErrors.condition_date ? true : false"
                :error-messages="validationErrors.condition_date"
                :loading="loading"
            />
            <!-- <v-text-field
                v-model="form.due_date"
                label="Due Date"
                type="date"
                outlined
                :error="validationErrors.due_date ? true : false"
                :error-messages="validationErrors.due_date"
                :loading="loading"
            /> -->
            <v-text-field
                v-model="form.review_accessible_until"
                label="Review Accessible Until"
                type="date"
                outlined
                :clearable="true"
                :error="validationErrors.review_accessible_until ? true : false"
                :error-messages="validationErrors.review_accessible_until"
                :loading="loading"
            />
            <v-checkbox
                v-model="form.allow_agent_to_view"
                label="Allow Agent to View"
                :error="validationErrors.allow_agent_to_view ? true : false"
                :error-messages="validationErrors.allow_agent_to_view"
                :loading="loading"
            />
            <v-checkbox
                v-model="form.contractor_is_paid"
                label="Contractor Is Paid"
                required
                :error="validationErrors.contractor_is_paid ? true : false"
                :error-messages="validationErrors.contractor_is_paid"
                :loading="loading"
            />
        </div>

        <div class="flex justify-end gap-4">
            <v-btn
                @click="updateRiskAssessment"
                color="primary"
                :loading="loading"
            >
                Save
                <v-icon class="ml-4">mdi-content-save</v-icon>
            </v-btn>
        </div>
    </div>
</template>
