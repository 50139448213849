<script setup>
    import { ref, computed } from 'vue';
    import { usePage } from '@inertiajs/vue3';
    const page = usePage();
    import { router } from '@inertiajs/vue3';

    const refreshData = () => {
        router.reload({
            only: ['review'],
            replace: true,
        });
    };

    /**************************************************************
     *      Misc
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useUserStore } from '@/stores/user';
    const userStore = useUserStore();
    const { userIsAdmin, userIsContractor } = storeToRefs(userStore);

    /**************************************************************
     *      Components
     **************************************************************/
    import ReviewGeneralTab from '@/Components/Review/ReviewGeneralTab.vue';
    import ReviewClientsTab from '@/Components/Review/ReviewClientsTab.vue';
    import ReviewStatusTab from '@/Components/Review/ReviewStatusTab.vue';
    import ReviewDocumentsTab from '@/Components/Review/ReviewDocumentsTab.vue';
    import ReviewPaymentInvoiceTab from '@/Components/Review/ReviewPaymentInvoiceTab.vue';
    import ReviewDocumentOverrideTab from '@/Components/Review/ReviewDocumentOverrideTab.vue';
    import ReviewNotesTab from '@/Components/Review/ReviewNotesTab.vue';
    import ReviewRiskAssesmentTab from '@/Components/Review/ReviewRiskAssesmentTab.vue';
    import ReviewSettingsTab from '@/Components/Review/ReviewSettingsTab.vue';

    /**************************************************************
     *      Utils
     **************************************************************/
    import { useToast } from 'vue-toast-notification';

    const toast = useToast();

    /**************************************************************
     *      Corporation State/Data
     **************************************************************/
    const activeTab = ref('general');
    const validationErrors = ref({});

    /**************************************************************
     *      Tabs
     **************************************************************/
    const tabs = computed(() => {
        let baseTabs = [
            {
                name: 'general',
                title: 'General',
                icon: 'mdi-information-outline',
                component: ReviewGeneralTab,
            },
            {
                name: 'risk_assessment',
                title: 'Risk Assessment',
                icon: 'mdi-shield-alert-outline',
                component: ReviewRiskAssesmentTab,
            },
            {
                name: 'documents',
                title: 'Documents',
                icon: 'mdi-file-document-outline',
                component: ReviewDocumentsTab,
            },
            {
                name: 'notes',
                title: 'Notes',
                icon: 'mdi-note-outline',
                component: ReviewNotesTab,
            },
            {
                name: 'clients',
                title: 'Clients & Contractors',
                icon: 'mdi-account-group',
                component: ReviewClientsTab,
            },
            {
                name: 'status',
                title: 'Status',
                icon: 'mdi-information-outline',
                component: ReviewStatusTab,
            },
            {
                name: 'payment_and_invoice',
                title: 'Payment & Invoice',
                icon: 'mdi-file-document-outline',
                component: ReviewPaymentInvoiceTab,
            },
            {
                name: 'document_overrides',
                title: 'Document Overrides',
                icon: 'mdi-file-document-alert-outline',
                component: ReviewDocumentOverrideTab,
            },
            {
                name: 'settings',
                title: 'Settings',
                icon: 'mdi-cog-outline',
                component: ReviewSettingsTab,
            },
        ];

        if (!userIsAdmin.value) {
            baseTabs = baseTabs.filter(tab => tab.name !== 'clients');
            baseTabs = baseTabs.filter(
                tab => tab.name !== 'payment_and_invoice'
            );
            baseTabs = baseTabs.filter(
                tab => tab.name !== 'document_overrides'
            );
            baseTabs = baseTabs.filter(tab => tab.name !== 'settings');
        }

        return baseTabs;
    });

    /**************************************************************
     *      Breadcrumbs
     **************************************************************/
    const breadcrumbs = computed(() => {
        return [
            {
                title: 'Condo Doc Review',
                disabled: true,
                href: '#',
            },
            {
                title: 'Reviews',
                disabled: false,
                href: '/reviews',
            },
            {
                title: 'Case # ' + page.props.review.review_case_number,
                disabled: false,
                href: '#',
            },
        ];
    });
</script>

<template>
    <AuthenticatedLayout>
        <div class="py-8">
            <v-breadcrumbs class="-ml-3 mb-4" :items="breadcrumbs">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
            </v-breadcrumbs>
            <div class="text-xl self-center font-bold">
                <div class="flex flex-col md:flex-row justify-between">
                    <div class="flex flex-col md:flex-row gap-2">
                        <div>
                            Review Details Case #{{
                                page.props.review.review_case_number
                            }}
                        </div>
                        <div v-if="page.props.review.corporation">
                            - {{ page.props.review?.corporation?.name }} ({{
                                page.props.review?.corporation?.plan_number
                            }})
                        </div>
                    </div>
                    <div>
                        <v-chip
                            variant="flat"
                            density="comfortable"
                            size="large"
                            :color="
                                {
                                    'Pending Submission': 'grey',
                                    'Under Review': 'blue',
                                    'Pending Administrative Review': 'orange',
                                    'Pending Documents': 'yellow',
                                    'Cannot Proceed': 'red',
                                    Completed: 'green',
                                    Cancelled: 'black',
                                }[page.props.review.status.name]
                            "
                        >
                            <v-icon icon="mdi-flag-outline" start></v-icon>
                            Review Case Status:
                            {{ page.props.review.status.name }}
                        </v-chip>
                    </div>
                </div>
            </div>
            <p class="text-sm text-gray-500">Modify the Review Information</p>
        </div>
        <v-alert
            v-if="!page.props.review.corporation"
            type="warning"
            class="mb-6"
        >
            This review does not have a corporation assigned. This is mandatory
            for the review to be completed. Assign a corporation as soon as it
            is known.
        </v-alert>
        <v-tabs v-model="activeTab">
            <v-tab
                :value="tab.name"
                v-for="(tab, index) in tabs"
                :key="`tab_header_${index}`"
            >
                <div class="flex gap-1">
                    <v-icon class="self-center">{{ tab.icon }}</v-icon>
                    <div class="self-center">{{ tab.title }}</div>
                </div>
            </v-tab>
        </v-tabs>
        <v-tabs-window v-model="activeTab" class="pt-8">
            <v-tabs-window-item
                :value="tab.name"
                v-for="(tab, index) in tabs"
                :key="`tab_component_${index}`"
            >
                <component
                    @refresh="refreshData"
                    @update="tab.updateFunc"
                    :is="tab.component"
                    :review="page.props.review"
                    :corporation="page.props.review.corporation"
                    :validationErrors="validationErrors"
                />
            </v-tabs-window-item>
        </v-tabs-window>
    </AuthenticatedLayout>
</template>
