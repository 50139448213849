<script setup>
    import { onMounted, ref, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';

    const emit = defineEmits(['close', 'resubmit-review']);

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        review: Object,
    });

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      State
     **************************************************************/
    const rejectionHistory = ref({});
    const documents = ref([]);
    const loading = ref(false);
    const validationErrors = ref({});

    /**************************************************************
     *      API Calling
     **************************************************************/
    const loadRejectedReviewDocuments = () => {
        loading.value = true;
        axios
            .get(`/api/v1/reviews/${props.review.id}/rejected-documents`)
            .then(response => {
                rejectionHistory.value = response.data.rejection_history;

                rejectionHistory.value.document_types.forEach(doc => {
                    documents.value.push({
                        document_type: doc,
                        file: null,
                    });
                });

                documents.value.sort((a, b) => {
                    return a.document_type.order - b.document_type.order;
                });
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            })
            .finally(() => {
                loading.value = false;
            });
    };

    onMounted(() => {
        loadRejectedReviewDocuments();
    });

    const onSave = () => {
        loading.value = true;
        const formData = new FormData();

        documents.value.forEach((document, index) => {
            formData.append(`files[${index}][file]`, document.file);
            formData.append(
                `files[${index}][document_type_id]`,
                document.document_type.id
            );
        });

        axios
            .post(
                `/api/v1/reviews/${props.review.id}/documents/fix-rejected`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then(response => {
                toast.open({
                    message: 'Documents were uploaded successfully!',
                    type: 'success',
                });
                emit('close');
                emit('resubmit-review');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            })
            .finally(() => {
                loading.value = false;
            });
    };

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};
        }
    );

    const onClose = () => {
        emit('close');
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :title="`Resolve Document Issues`"
        @close="onClose"
        @save="onSave"
    >
        <div v-if="loading" class="flex justify-center items-center p-4">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>
        <div v-else>
            <div class="pb-8 text-lg flex flex-col gap-4 p-4 -mt-6">
                <div>
                    The following documents are required for review. Please
                    upload the latest and most detailed versions available.
                </div>

                <div>
                    <ul class="list-disc pl-5 space-y-2">
                        <li>
                            If this is the first time we are requesting these
                            documents, please ensure they are as complete and
                            up-to-date as possible.
                        </li>
                        <li>
                            If you have already submitted the requested
                            documents, there may have been issues such as a
                            corrupted file, unreadable format, or missing
                            information. In such cases, we need you to re-upload
                            a new version.
                        </li>
                        <li>
                            Required documents are marked with red asterisks
                            (*). These must be uploaded for the review to
                            proceed.
                        </li>
                        <li>
                            If you are unable to upload a document or do not
                            have access to it, please contact us for further
                            instructions.
                        </li>
                    </ul>
                </div>

                <div>
                    Once the correct documents are submitted, your application
                    will be resubmitted and placed back in review.
                </div>
            </div>
            <div class="p-4 border rounded-lg shadow my-4">
                <div class="font-bold">Note from Reviewer</div>
                <div class="whitespace-pre">
                    {{ rejectionHistory.rejection_reason }}
                </div>
            </div>
            <div
                v-for="(document, index) in documents"
                :key="`document_${index}`"
                class="flex flex-col gap-4"
            >
                <div class="font-bold">
                    {{ document.document_type.display_name }}
                    <span
                        v-if="document.document_type.is_required"
                        class="text-red-500 text-xs"
                    >
                        * Required
                    </span>
                </div>
                <v-file-input
                    v-model="document.file"
                    :label="
                        document.document_type.is_required
                            ? 'File Required *'
                            : 'File Optional'
                    "
                    required
                    outlined
                    accept="application/pdf,image/gif,image/tiff,image/jpeg,image/png,image/bmp,image/webp"
                    :error="
                        validationErrors[`files.${index}.file`] ? true : false
                    "
                    :error-messages="
                        validationErrors[`files.${index}.file`] || []
                    "
                />
            </div>
        </div>
    </BaseModal>
</template>
