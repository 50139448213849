<script setup>
    import { ref } from 'vue';
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    const { formatMoney } = useMoneyFormatter();

    const props = defineProps({
        corporation: Object,
    });

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Modal
     **************************************************************/
    import CorporationComponentModal from '../../Components/Modals/CorporationComponentModal.vue';
    import ConfirmationModal from '../Modals/ConfirmationModal.vue';
    import UploadComponentTemplateModal from '../Modals/UploadComponentTemplateModal.vue';

    const isModalOpen = ref(false);
    const isConfirmModalOpen = ref(false);
    const isUploadModalOpen = ref(false);
    const selectedCorporationComponent = ref(null);

    const showUpdateModal = item => {
        selectedCorporationComponent.value = item;
        isModalOpen.value = true;
    };

    const showCreateModal = () => {
        selectedCorporationComponent.value = null;
        isModalOpen.value = true;
    };

    const showUploadModal = () => {
        isUploadModalOpen.value = true;
    };

    const closeUploadModal = () => {
        isUploadModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    const closeModal = () => {
        isModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    const handleConfirmClose = () => {
        isConfirmModalOpen.value = false;
    };

    const handleConfirmDeleteComponent = () => {
        isConfirmModalOpen.value = false;
        const payload = {
            corporation_component_id:
                selectedCorporationComponent.value.corporation_component.id,
        };

        axios
            .post(
                `/api/v1/corporations/${props.corporation.id}/components/destroy`,
                payload
            )
            .then(response => {
                searchTrigger.value = Date.now(); // cause the table to reload
                selectedCorporationComponent.value = null;
                toast.success('Component deleted successfully.');
            })
            .catch(error => {
                console.error(error);
            });
    };

    const destroy = item => {
        selectedCorporationComponent.value = item;
        isConfirmModalOpen.value = true;
    };

    const downloadTemplate = () => {
        window.open(
            `/api/v1/corporations/${props.corporation.id}/components/template`,
            '_blank'
        );
    };

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = [
        {
            title: 'Component',
            key: 'name',
            value: component => component.name,
            sortable: false,
        },
        {
            title: 'Life Expectancy',
            key: 'corporation_component.actual_life_expectancy',
            value: component =>
                `${component.corporation_component.actual_life_expectancy} Years`,
            sortable: false,
        },
        {
            title: 'Effective Age',
            key: 'corporation_component.effective_age',
            value: component =>
                `${component.corporation_component.effective_age} Years`,
            sortable: false,
        },
        {
            title: 'End Of Life On',
            key: 'corporation_component.depreciation_date',
            value: component =>
                component.corporation_component.depreciation_date,
            sortable: false,
        },
        {
            title: 'Remaining Life',
            key: 'corporation_component.remaining_life_expectancy',
            value: component =>
                `${component.corporation_component.remaining_life_expectancy} Years`,
            sortable: false,
        },
        {
            title: 'Depreciation (%)',
            key: 'corporation_component.depreciation_percentage',
            value: component =>
                `${component.corporation_component.depreciation_percentage}%`,
            sortable: false,
        },
        {
            title: 'Current Replacement Cost',
            key: 'corporation_component.current_replacement_cost',
            value: component =>
                `${formatMoney(component.corporation_component.current_replacement_cost)}`,
            sortable: false,
        },
    ];

    const searchTrigger = ref(Date.now());

    const additionalParams = ref({
        search: '',
        type: null,
    });
</script>
<template>
    <div>
        <UploadComponentTemplateModal
            :corporation="corporation"
            :isOpen="isUploadModalOpen"
            @close="closeUploadModal"
        />
        <ConfirmationModal
            :isOpen="isConfirmModalOpen"
            message="Are you sure you want to delete this corporation's component?"
            @close="handleConfirmClose"
            @confirm="handleConfirmDeleteComponent"
        />
        <CorporationComponentModal
            :corporation="corporation"
            :isOpen="isModalOpen"
            :item="selectedCorporationComponent"
            @close="closeModal"
        />
        <TableViewManager
            title="Components"
            subtitle="This is where you can view a listing of a corporation's components."
            :headers="headers"
            :endpoint="`/api/v1/corporations/${corporation.id}/components`"
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            hide-breadcrumbs
            :actions="[
                {
                    title: 'Update',
                    icon: 'mdi-pencil',
                    buttonText: 'Edit',
                    buttonColor: 'secondary',
                    action: item => showUpdateModal(item.corporation_component),
                },
                {
                    title: 'Delete',
                    icon: 'mdi-delete',
                    buttonText: 'Delete',
                    buttonColor: 'secondary',
                    action: item => destroy(item),
                },
            ]"
        >
            <template #actions>
                <!-- <v-btn color="primary" class="px-4" @click="showCreateModal">
                    Create New
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn> -->
                <v-btn color="primary" class="px-4" @click="downloadTemplate">
                    Template
                    <v-icon class="self-center ml-2">mdi-download</v-icon>
                </v-btn>
                <v-btn color="primary" class="px-4" @click="showUploadModal">
                    Upload
                    <v-icon class="self-center ml-2">mdi-upload</v-icon>
                </v-btn>
            </template>
        </TableViewManager>
    </div>
</template>
