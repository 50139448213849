<script setup>
    import { ref } from 'vue';
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    import { router } from '@inertiajs/vue3';
    const { formatMoney } = useMoneyFormatter();
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Modal
     **************************************************************/

    import OperatingAccountModal from '../../Components/Modals/OperatingAccountModal.vue';
    const isModalOpen = ref(false);
    const selectedOperatingAccount = ref(null);

    const showUpdateModal = item => {
        selectedOperatingAccount.value = item;
        isModalOpen.value = true;
    };

    const showCreateModal = () => {
        selectedOperatingAccount.value = null;
        isModalOpen.value = true;
    };

    const closeModal = () => {
        searchTrigger.value = Date.now(); // cause the table to reload
        selectedOperatingAccount.value = null;
        isModalOpen.value = false;
    };

    const props = defineProps({
        corporation: Object,
    });

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = [
        {
            title: 'Fiscal Year Start',
            value: 'fiscal_year_start',
            sortable: false,
        },
        {
            title: 'Reporting Period End',
            value: 'reporting_period_end',
            sortable: false,
        },
        {
            title: 'Fiscal Year Progress',
            key: 'fiscal_year_progress',
            value: item => `${item.fiscal_year_progress}%`,
            sortable: false,
        },
        {
            title: 'Fiscal Year Elapsed Time',
            key: 'fiscal_year_elapsed_time',
            value: item => `${item.fiscal_year_elapsed_time}`,
            sortable: false,
        },
    ];

    const searchTrigger = ref(Date.now());

    const additionalParams = ref({
        search: '',
        type: null,
    });

    /**************************************************************
     *      Navigation
     **************************************************************/
    const access = item => {
        router.visit(
            `/corporations/${props.corporation.id}/operating-accounts/${item.id}`
        );
    };

    const destroy = item => {
        if (
            !confirm(
                'Are you sure you want to delete this Operating Account? This action cannot be undone.'
            )
        ) {
            return;
        }

        axios
            .delete(
                `/api/v1/corporations/${props.corporation.id}/operating-accounts/${item.id}`
            )
            .then(() => {
                toast.success('Operating Account deleted successfully.');
                searchTrigger.value = Date.now(); // cause the table to reload
            })
            .catch(() => {
                toast.error('Failed to delete Operating Account.');
            });
    };
</script>
<template>
    <div>
        <OperatingAccountModal
            :corporation="corporation"
            :isOpen="isModalOpen"
            :item="selectedOperatingAccount"
            @close="closeModal"
        />
        <TableViewManager
            title="Operating Account (Planned Vs. Actual)"
            :headers="headers"
            subtitle="This is where you can view a listing of the corporations operating account (Planned Vs. Actual) information."
            :endpoint="`/api/v1/corporations/${corporation.id}/operating-accounts`"
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            hide-breadcrumbs
            :actions="[
                {
                    title: 'Edit',
                    icon: 'mdi-pencil',
                    buttonText: 'Edit',
                    buttonColor: 'primary',
                    action: item => showUpdateModal(item),
                },
                {
                    title: 'Access',
                    icon: 'mdi-arrow-right-bold-circle-outline',
                    buttonText: 'Access',
                    buttonColor: 'secondary',
                    action: item => access(item),
                },
                {
                    title: 'Delete',
                    icon: 'mdi-delete',
                    buttonText: 'Delete',
                    buttonColor: 'error',
                    action: item => destroy(item),
                },
            ]"
        >
            <template #actions>
                <v-btn color="primary" class="px-4" @click="showCreateModal">
                    Create New
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn>
            </template>
        </TableViewManager>
    </div>
</template>
