<script setup>
    import { ref } from 'vue';
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    const { formatMoney } = useMoneyFormatter();
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Modal
     **************************************************************/
    import ReserveFundStudyModal from '../../Components/Modals/ReserveFundStudyModal.vue';
    const isModalOpen = ref(false);
    const selectedReserveFundStudy = ref(null);

    const showUpdateModal = item => {
        selectedReserveFundStudy.value = item;
        isModalOpen.value = true;
    };

    const showCreateModal = () => {
        selectedReserveFundStudy.value = null;
        isModalOpen.value = true;
    };

    const closeModal = () => {
        isModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    const props = defineProps({
        corporation: Object,
    });

    const destroy = item => {
        if (
            !confirm(
                'Are you sure you want to delete this RFS? This action cannot be undone.'
            )
        ) {
            return;
        }

        axios
            .delete(
                `/api/v1/corporations/${props.corporation.id}/reserve-fund-studies/${item.id}`
            )
            .then(response => {
                toast.success('Reserve Fund Study Deleted Successfully');
                searchTrigger.value = Date.now(); // cause the table to reload
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.');
            });
    };

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = [
        {
            title: 'Study Date',
            key: 'study_date',
            value: item => item.study_date.split('T')[0],
            sortable: false,
        },
        { title: 'Funding Model', key: 'funding_model', sortable: false },
        { title: 'Conducted By', key: 'study_conducted_by', sortable: false },
    ];

    const searchTrigger = ref(Date.now());

    const additionalParams = ref({
        search: '',
        type: null,
    });
</script>
<template>
    <div>
        <ReserveFundStudyModal
            :corporation="corporation"
            :isOpen="isModalOpen"
            :item="selectedReserveFundStudy"
            @close="closeModal"
        />
        <TableViewManager
            title="Reserve Fund Studies"
            :headers="headers"
            :endpoint="`/api/v1/corporations/${corporation.id}/reserve-fund-studies`"
            subtitle="This is where you can view a listing of a corporation's RFS."
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            hide-breadcrumbs
            :actions="[
                {
                    title: 'Action',
                    icon: 'mdi-pencil',
                    buttonText: 'Edit',
                    buttonColor: 'secondary',
                    action: item => showUpdateModal(item),
                },
                {
                    title: 'Delete',
                    icon: 'mdi-delete',
                    buttonText: 'Delete',
                    buttonColor: 'error',
                    action: item => destroy(item),
                },
            ]"
        >
            <template #actions>
                <v-btn color="primary" class="px-4" @click="showCreateModal">
                    Create New
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn>
            </template>
        </TableViewManager>
    </div>
</template>
