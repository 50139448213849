<script setup>
    import { ref, computed } from 'vue';
    import { usePage } from '@inertiajs/vue3';

    import { useDisplay } from 'vuetify';
    const { smAndDown } = useDisplay();

    const page = usePage();
    const footnotes = ref(page.props.review.footnotes);
</script>

<template>
    <div v-if="footnotes" class="max-w-[1400px] mx-auto px-8 my-24">
        <div class="text-2xl md:text-4xl font-bold mb-8">Footnotes</div>
        <div class="max-w-[800px] self-center mb-2 whitespace-pre-line">
            {{ footnotes }}
        </div>
    </div>
</template>
