<script setup>
    import { ref } from 'vue';
    import { useDisplay } from 'vuetify';

    const { smAndDown } = useDisplay();
    const showWhyItMatters = ref(false);
    const showViewDetails = ref(false);

    defineProps({
        backgroundColor: {
            type: String,
            default: '',
        },
        whiteText: {
            type: Boolean,
            default: false,
        },
        hideViewDetails: {
            type: Boolean,
            default: false,
        },
    });
</script>

<template>
    <div class="mx-4" :class="{ 'text-[#FEFEFE]': whiteText }">
        <div class="text-7xl font-bold flex justify-start">
            <div
                :class="{
                    'rounded-full w-28 h-28 flex items-center justify-center -mb-12': true,
                    [backgroundColor]: backgroundColor === 'bg-[#131313]',
                    'bg-[#F3F3F3]': backgroundColor !== 'bg-[#131313]',
                    'mx-auto': smAndDown,
                    '-ml-8': !smAndDown,
                }"
            >
                <slot name="icon"></slot>
            </div>
        </div>
        <div
            :class="{
                [backgroundColor]: true,
                'p-12': !smAndDown,
                'px-0 py-16': smAndDown,
            }"
            class="flex flex-col gap-4 rounded-xl"
        >
            <div class="flex flex-col md:flex-row justify-between">
                <div class="px-8">
                    <slot name="title"></slot>
                    <slot name="subtitle"></slot>
                </div>
                <div
                    class="flex flex-col md:flex-row gap-3 px-8"
                    :class="{ 'py-6': smAndDown, 'py-0': !smAndDown }"
                >
                    <div v-if="!hideViewDetails">
                        <v-btn
                            :block="smAndDown"
                            :color="whiteText ? 'secondary' : 'primary'"
                            :size="smAndDown ? 'default' : 'small'"
                            @click="showViewDetails = true"
                        >
                            View Details
                        </v-btn>
                    </div>
                    <div>
                        <v-btn
                            :block="smAndDown"
                            :size="smAndDown ? 'default' : 'small'"
                            color="yellow-darken-1"
                            @click="showWhyItMatters = true"
                        >
                            Why It Matters
                        </v-btn>
                    </div>
                </div>
            </div>
            <div
                :class="{ 'p-8': !smAndDown, 'p-4': smAndDown }"
                class="flex md:flex-row flex-col gap-4"
            >
                <slot name="chart"></slot>
                <slot name="additional-info"></slot>
            </div>
            <slot name="footer"></slot>
        </div>

        <!-- Why It Matters Overlay -->
        <v-dialog v-model="showWhyItMatters" max-width="600px">
            <v-card>
                <v-card-title>Why It Matters</v-card-title>
                <v-card-text>
                    <slot name="why-it-matters"></slot>
                </v-card-text>
                <v-card-actions>
                    <v-btn text @click="showWhyItMatters = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- View Details Overlay -->
        <v-dialog v-model="showViewDetails" max-width="600px">
            <v-card>
                <v-card-title>Details</v-card-title>
                <v-card-text>
                    <slot name="view-details"></slot>
                </v-card-text>
                <v-card-actions>
                    <v-btn text @click="showViewDetails = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
