<script setup>
    import { ref, watch, computed } from 'vue';
    import debounce from 'lodash.debounce';
    import axios from 'axios';
    import { usePage } from '@inertiajs/vue3';
    import TableView from './TableView.vue';

    const props = defineProps({
        title: { type: String, required: true },
        headers: { type: Array, required: true },
        subtitle: { type: String, default: '' },
        endpoint: { type: String, required: true },
        actions: { type: Array, default: () => [] },
        additionalParams: { type: Object, default: () => ({}) },
        searchTrigger: { type: Number, required: true },
        hideBreadcrumbs: { type: Boolean, default: false },
        dropdownActions: { type: Boolean, default: false },
    });

    const serverItems = ref([]);
    const totalItems = ref(0);
    const loading = ref(false);
    const page = usePage();
    const currentPath = computed(() => page.url);

    const loadItems = async options => {
        loading.value = true;
        const merged = { ...options, ...props.additionalParams };
        try {
            const r = await axios.get(props.endpoint, { params: merged });
            serverItems.value = r.data.data;
            totalItems.value = Number(r.data.total);
        } catch (e) {
            console.error(e);
        } finally {
            loading.value = false;
        }
    };

    const debouncedLoadItems = debounce(loadItems, 300);

    const breadcrumbs = computed(() => {
        const parts = currentPath.value.split('/').filter(p => p);
        const chain = parts.map((p, i) => ({
            title: p.charAt(0).toUpperCase() + p.slice(1),
            disabled: false,
            href: '/' + parts.slice(0, i + 1).join('/'),
        }));
        chain.unshift({
            title: 'Condo Doc Review',
            disabled: true,
            href: null,
        });
        return chain;
    });

    watch(
        () => props.additionalParams,
        () => {
            debouncedLoadItems();
        },
        { deep: true }
    );
</script>

<template>
    <div class="flex flex-col gap-4 mt-4 px-4 md:px-0">
        <v-breadcrumbs
            v-if="!props.hideBreadcrumbs"
            class="-ml-3 md:ml-0 mb-4"
            :items="breadcrumbs"
        >
            <template #divider>
                <v-icon icon="mdi-chevron-right" />
            </template>
        </v-breadcrumbs>

        <div
            class="flex flex-col md:flex-row justify-between flex-wrap gap-y-2"
        >
            <div class="text-lg md:text-2xl font-bold">
                {{ title }}
            </div>
            <div class="flex flex-col md:flex-row gap-2">
                <slot name="actions"></slot>
            </div>
        </div>

        <div class="text-gray-800 text-sm md:text-base font-medium">
            {{ subtitle }}
        </div>

        <div class="grid grid-cols-1 md:grid-cols-4 gap-4 pb-10 mt-4">
            <slot name="filters" />
        </div>

        <TableView
            :searchTrigger="searchTrigger"
            :headers="headers"
            :serverItems="serverItems"
            :totalItems="totalItems"
            :loading="loading"
            :actions="actions"
            :dropdownActions="dropdownActions"
            @loadItems="debouncedLoadItems"
        >
            <template
                v-for="(unused, slotName) in $slots"
                :key="slotName"
                v-slot:[slotName]="slotProps"
            >
                <template v-if="slotName.startsWith('item.')">
                    <slot :name="slotName" v-bind="slotProps" />
                </template>
            </template>
        </TableView>
    </div>
</template>
