<script setup>
    import { ref, computed } from 'vue';
    import { usePage, router } from '@inertiajs/vue3';
    const { props } = usePage();

    import { useDisplay } from 'vuetify';
    const { smAndDown } = useDisplay();

    /**************************************************************
     *      Components
     **************************************************************/
    import CorporationGeneralTab from '@/Components/Corporation/CorporationGeneralTab.vue';
    import CorporationInsuranceCertificatesTab from '@/Components/Corporation/CorporationInsuranceCertificatesTab.vue';
    import CorporationLeviesTab from '@/Components/Corporation/CorporationLeviesTab.vue';
    import CorporationBalancesTab from '@/Components/Corporation/CorporationBalancesTab.vue';
    import CorporationMeetingMinutesTab from '@/Components/Corporation/CorporationMeetingMinutesTab.vue';
    import CorporationLoansTab from '@/Components/Corporation/CorporationLoansTab.vue';
    import CorporationProjectsTab from '@/Components/Corporation/CorporationProjectsTab.vue';
    import CorporationComponentsTab from '@/Components/Corporation/CorporationComponentsTab.vue';
    import CorporationReserveFundStudiesTab from '@/Components/Corporation/CorporationReserveFundStudiesTab.vue';
    import CorporationOperatingAccountsTable from '@/Components/Corporation/CorporationOperatingAccountsTable.vue';
    import CorporationLitigationsTab from '@/Components/Corporation/CorporationLitigationsTab.vue';
    import CorporationDeficienciesTab from '@/Components/Corporation/CorporationDeficienciesTab.vue';
    import DocumentsTab from '@/Components/Corporation/CorporationDocumentsTab.vue';
    import CorporationBylawsTab from '@/Components/Corporation/CorporationBylawsTab.vue';

    /**************************************************************
     *      Utils
     **************************************************************/
    import axios from 'axios';
    import { useToast } from 'vue-toast-notification';

    const toast = useToast();

    /**************************************************************
     *      Corporation State/Data
     **************************************************************/
    const activeTab = ref('general');
    const corporation = ref({
        ...props.corporation,
    });
    const validationErrors = ref({});

    const updateGeneralInformation = corp => {
        validationErrors.value = {};

        runRequest(
            `/api/v1/corporations/${corporation.value.id}`,
            axios.patch,
            corp
        );
    };

    const unusedFunc = corp => {
        // This is unused because the tab handles its own updates
    };

    /**************************************************************
     *      Tabs
     **************************************************************/
    const tabs = [
        {
            name: 'general',
            title: 'General',
            component: CorporationGeneralTab,
            icon: 'mdi-information-outline',
            updateFunc: updateGeneralInformation,
        },
        {
            name: 'documents',
            title: 'Documents',
            component: DocumentsTab,
            icon: 'mdi-file-document-outline',
            updateFunc: unusedFunc,
        },
        {
            name: 'levies',
            title: 'Levies',
            component: CorporationLeviesTab,
            icon: 'mdi-receipt-text-outline',
            updateFunc: unusedFunc,
        },
        {
            name: 'loans',
            title: 'Loans',
            component: CorporationLoansTab,
            icon: 'mdi-handshake-outline',
            updateFunc: unusedFunc,
        },
        {
            name: 'litigations',
            title: 'Litigation',
            component: CorporationLitigationsTab,
            icon: 'mdi-scale-balance',
            updateFunc: unusedFunc,
        },
        {
            name: 'meeting_minutes',
            title: 'Minutes',
            component: CorporationMeetingMinutesTab,
            icon: 'mdi-note-text-outline',
            updateFunc: unusedFunc,
        },
        {
            name: 'deficiencies',
            title: 'Deficiencies',
            component: CorporationDeficienciesTab,
            icon: 'mdi-alert-circle-outline',
            updateFunc: unusedFunc,
        },
        {
            name: 'reserve_fund_studies',
            title: 'RFS',
            component: CorporationReserveFundStudiesTab,
            icon: 'mdi-file-search-outline',
            updateFunc: unusedFunc,
        },
        {
            name: 'components',
            title: 'Components',
            component: CorporationComponentsTab,
            icon: 'mdi-cogs',
            updateFunc: unusedFunc,
        },
        {
            name: 'projects',
            title: 'Projects',
            component: CorporationProjectsTab,
            icon: 'mdi-clipboard-text-outline',
            updateFunc: unusedFunc,
        },
        {
            name: 'operating-accounts',
            title: 'Operating Account',
            component: CorporationOperatingAccountsTable,
            icon: 'mdi-book-check-outline',
            updateFunc: unusedFunc,
        },
        {
            name: 'balances',
            title: 'Balances',
            component: CorporationBalancesTab,
            icon: 'mdi-finance',
            updateFunc: unusedFunc,
        },
        {
            name: 'insurance',
            title: 'Insurance',
            component: CorporationInsuranceCertificatesTab,
            icon: 'mdi-shield-check-outline',
            updateFunc: unusedFunc,
        },
        {
            name: 'bylaws',
            title: 'Bylaws',
            component: CorporationBylawsTab,
            icon: 'mdi-gavel',
            updateFunc: unusedFunc,
        },
    ];

    /**************************************************************
     *      Full Picture
     **************************************************************/
    const refreshData = () => {
        router.reload({
            only: ['review'],
            replace: true,
        });
    };

    const seeFullPicture = ref(false);

    const toggleSeeFullPicture = () => {
        seeFullPicture.value = !seeFullPicture.value;

        refreshData();

        if (seeFullPicture.value) {
            activeTab.value = tabs.map(tab => tab.name);
        } else {
            activeTab.value = 'general';
        }
    };

    /**************************************************************
     *      Axios call(s)
     * @param endpoint - the endpoint to call
     * @param method - i.e axios.post etc
     * @param data - the data to send
     **************************************************************/
    const runRequest = (endpoint, method, data) => {
        method(endpoint, data)
            .then(response => {
                // Show a toast maybe?
                corporation.value = response.data.corporation;

                toast.open({
                    title: 'Success',
                    message: 'Corporation Information Updated!',
                    type: 'success',
                });
            })
            .catch(error => {
                console.error(error);

                toast.open({
                    title: 'Error',
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });

                try {
                    if (error?.response?.data?.errors) {
                        validationErrors.value = error.response.data.errors;
                    }
                } catch (e) {
                    console.error(e);
                }
            });
    };

    /**************************************************************
     *      Breadcrumbs
     **************************************************************/
    const breadcrumbs = computed(() => {
        return [
            {
                title: 'Condo Doc Review',
                disabled: true,
                href: '#',
            },
            {
                title: 'Corporations',
                disabled: false,
                href: '/corporations',
            },
            {
                title: 'Plan # ' + corporation.value.plan_number,
                disabled: false,
                href: '#',
            },
        ];
    });
</script>

<template>
    <AuthenticatedLayout>
        <div class="py-8">
            <v-breadcrumbs
                v-if="!smAndDown"
                class="-ml-3 mb-4"
                :items="breadcrumbs"
            >
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
            </v-breadcrumbs>
            <div class="flex flex-col md:flex-row gap-4 justify-between">
                <div class="text-xl self-center font-bold">
                    {{ corporation?.name }}
                    #{{ corporation.plan_number }}
                </div>
                <div :class="smAndDown ? 'self-center' : ''">
                    <v-btn
                        size="small"
                        color="secondary"
                        @click="toggleSeeFullPicture"
                    >
                        {{ seeFullPicture ? 'Hide' : 'See' }} Full Picture
                        <v-icon class="self-center ml-2">
                            {{
                                seeFullPicture
                                    ? 'mdi-arrow-collapse-all'
                                    : 'mdi-arrow-expand-all'
                            }}
                        </v-icon>
                    </v-btn>
                </div>
            </div>
            <p class="text-sm text-gray-500 mt-4" v-if="!smAndDown">
                Edit the corporation information below.
            </p>
        </div>
        <v-tabs :multiple="seeFullPicture" v-model="activeTab">
            <v-tab
                :value="tab.name"
                v-for="(tab, index) in tabs"
                :key="`tab_header_${index}`"
            >
                <div class="flex gap-1">
                    <v-icon v-tooltip="tab.title" class="self-center">
                        {{ tab.icon }}
                    </v-icon>
                    <div class="self-center">{{ tab.title }}</div>
                </div>
            </v-tab>
        </v-tabs>
        <v-tabs-window v-model="activeTab" class="pt-8 flex-grow">
            <v-tabs-window-item
                :value="tab.name"
                v-for="(tab, index) in tabs"
                :key="`tab_component_${index}`"
            >
                <component
                    @update="tab.updateFunc"
                    :is="tab.component"
                    :corporation="corporation"
                    :validationErrors="validationErrors"
                />
            </v-tabs-window-item>
        </v-tabs-window>
    </AuthenticatedLayout>
</template>
