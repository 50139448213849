<script setup>
    import BaseModal from '../BaseModal.vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        message: String,
    });

    /**************************************************************
     *      API Calling
     **************************************************************/
    const emit = defineEmits(['close', 'confirm']);
    const onConfirm = () => {
        emit('confirm');
    };

    const onClose = () => {
        emit('close');
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :title="'Confirmation'"
        noActions
        @close="onClose"
        @save="onConfirm"
    >
        <div class="p-4">
            <p>{{ message }}</p>
        </div>
        <div class="flex justify-end space-x-2 mt-4">
            <v-btn color="secondary" @click="onClose">Cancel</v-btn>
            <v-btn color="primary" @click="onConfirm">Confirm</v-btn>
        </div>
    </BaseModal>
</template>
