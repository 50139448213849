<script setup>
    import KPICard from './KPICard.vue';
    import { ref, computed, watch } from 'vue';
    import { Doughnut } from 'vue-chartjs';
    import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
    import { usePage } from '@inertiajs/vue3';
    import { useDisplay } from 'vuetify';

    ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

    const { smAndDown } = useDisplay();
    const { formatMoney } = useMoneyFormatter();
    const page = usePage();
    const review = computed(() => page.props.review);
    const operatingAccountChartData = computed(
        () => page.props.operating_budget_ratio_chart_data
    );

    const chartData = ref({});
    const chartOptions = ref({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 15,
                    font: {
                        size: 13,
                        weight: 'bold',
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: tooltipItem => {
                        const percentage = tooltipItem.raw;
                        const totalFees = review.value.condo_fees;
                        const amount = (percentage / 100) * totalFees;
                        return `${tooltipItem.raw}% (${formatMoney(amount, false)})`;
                    },
                },
            },
            datalabels: smAndDown.value
                ? {
                      formatter: value => `${value}%`,
                      color: 'white',
                      textStrokeColor: '#000000',
                      textStrokeWidth: 2,
                      font: { size: 10, weight: 'bold' },
                  }
                : {
                      formatter: value => `${value}%`,
                      color: 'black',
                      font: { size: 15, weight: 'bold' },
                      backgroundColor: 'white',
                      borderRadius: 99999,
                  },
        },
    });

    watch(
        operatingAccountChartData,
        newData => {
            const sortedKeys = Object.keys(newData).sort(
                (a, b) => newData[b] - newData[a]
            );
            chartData.value = {
                labels: sortedKeys.map(key => {
                    const percentage = newData[key];
                    const totalFees = review.value.condo_fees;
                    const amount = (percentage / 100) * totalFees;
                    return `${key} - ${formatMoney(amount)} (${percentage}%)`;
                }),
                datasets: [
                    {
                        data: Object.values(newData),
                        backgroundColor: [
                            '#FF6384',
                            '#36A2EB',
                            '#FFCE56',
                            '#4BC0C0',
                            '#9966FF',
                            '#FF9F40',
                            '#FFCD56',
                            '#C9CBCF',
                            '#36A2EB',
                        ],
                    },
                ],
            };
        },
        { immediate: true }
    );
</script>

<template>
    <KPICard>
        <template #icon>1</template>
        <template #title>
            <div class="text-2xl md:text-4xl font-bold">
                Condo Fee Breakdown
            </div>
        </template>
        <template #subtitle>
            <div>Where your condo fees are allocated each month.</div>
        </template>
        <template #chart>
            <div
                :class="{
                    'md:w-2/5': true,
                    'w-full flex justify-center': smAndDown,
                }"
            >
                <Doughnut :data="chartData" :options="chartOptions" />
            </div>
        </template>
        <template #additional-info>
            <div class="md:w-3/5">
                <div class="flex flex-col md:flex-row gap-4 md:gap-12">
                    <div class="border-dotted border-2 border-gray-400 p-6">
                        <div class="font-bold text-lg md:text-3xl">
                            Monthly Fees
                        </div>
                        <div class="font-bold text-lg md:text-3xl">
                            {{ formatMoney(review.condo_fees) }}
                        </div>
                    </div>
                    <div
                        class="border-dotted border-2 border-gray-400 p-6"
                        v-if="review.CostPerSquareFoot"
                    >
                        <div class="font-bold text-lg md:text-3xl">
                            Cost per Sq. Ft.
                        </div>
                        <div class="font-bold text-lg md:text-3xl">
                            {{ formatMoney(review.CostPerSquareFoot) }}
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-4 mt-8">
                    <div class="font-bold text-lg md:text-3xl my-6">
                        How are your unit's condo fees determined?
                    </div>
                    <div class="flex">
                        <div
                            class="text-base border-dotted border-2 border-gray-400 p-6"
                        >
                            Contribution fees are determined by your share of
                            the operational expenses.
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #why-it-matters>
            Condo fee allocation is a critical measurement as it ensures that
            fees collected from owners are distributed effectively to maintain
            the property, fund reserves, and cover shared expenses.
        </template>
        <template #view-details>
            <div class="mb-4">
                Here is a breakdown of your monthly condo fee's allocation.
            </div>
            <v-expansion-panels>
                <v-expansion-panel
                    v-for="(percentage, category) in operatingAccountChartData"
                    :key="category"
                >
                    <v-expansion-panel-title>
                        <div class="font-bold">
                            {{ category }} - {{ percentage }}%
                        </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <div>
                            {{
                                formatMoney(
                                    (percentage / 100) * review.condo_fees,
                                    false
                                )
                            }}
                        </div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </template>
    </KPICard>
</template>
