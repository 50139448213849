import { ref } from 'vue';

export function useSummarizer() {
    const summary = ref('');
    const error = ref(null);
    const summaryLoading = ref(false);

    /**
     * Summarize a large body of text and return a promise.
     * @param {string} text - The text to be summarized.
     * @returns {Promise<string>}
     */
    const summarize = async (text, prompt) => {
        summaryLoading.value = true;
        error.value = null;

        const message = `${prompt} : ${text}`;

        try {
            const response = await axios.post(
                '/api/v1/chatgpt',
                {
                    prompt: message,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            summary.value = response.data.response;
            return summary.value;
        } catch (err) {
            error.value = err.message;
            throw err; // Re-throw error so it can be caught in component
        } finally {
            summaryLoading.value = false;
        }
    };

    return {
        summary,
        error,
        summaryLoading,
        summarize,
    };
}
