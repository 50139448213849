<script setup>
    import { ref } from 'vue';
    import EditDocumentModal from '../Modals/EditDocumentModal.vue';
    import UploadDocumentModal from '../Modals/UploadDocumentModal.vue';
    import ViewDocumentModal from '../Modals/ViewDocumentModal.vue';
    import { useToast } from 'vue-toast-notification';
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';

    import { useUserStore } from '@/stores/user';
    const userStore = useUserStore();
    const { userIsAdmin, userIsSuperAdmin } = storeToRefs(userStore);

    const props = defineProps({
        corporation: { type: Object, required: true },
    });

    const toast = useToast();
    const { documentTypeOptions } = storeToRefs(useOptionsStore());
    const optionsStore = useOptionsStore();
    const { fetchDocumentTypeOptions } = optionsStore;
    fetchDocumentTypeOptions();

    const headers = [
        {
            title: 'Flag',
            key: 'is_flagged',
            sortable: true,
        },
        {
            title: 'Filename',
            key: 'original_name',
            value: i => i.original_name,
            sortable: true,
        },
        {
            title: 'Document Type',
            key: 'document_type',
            value: i => i.document_type.display_name,
            sortable: true,
        },
        {
            title: 'Uploaded At',
            key: 'created_at',
            value: i =>
                new Date(i.created_at).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                }),
            sortable: true,
        },
        {
            title: 'File Info',
            key: 'mime_type',
            value: i => {
                const mimeTypes = {
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                        'WORD DOC',
                    'application/msword': 'WORD DOC',
                    'application/pdf': 'PDF',
                    'image/jpeg': 'JPEG',
                    'image/png': 'PNG',
                    'image/gif': 'GIF',
                    'image/tiff': 'TIFF',
                };
                return (
                    mimeTypes[i.mime_type] ||
                    i.mime_type.replace('application/', '').toUpperCase()
                );
            },
            sortable: true,
        },
    ];

    const searchTrigger = ref(Date.now());
    const additionalParams = ref({
        search: '',
        documentType: null,
        corporation: null,
    });

    const isModalOpen = ref(false);
    const isViewModalOpen = ref(false);
    const selectedDocument = ref(null);

    const showUploadModal = () => {
        selectedDocument.value = null;
        isModalOpen.value = true;
    };
    const closeModal = () => {
        selectedDocument.value = null;
        isModalOpen.value = false;
        searchTrigger.value = Date.now();
    };

    const download = i => {
        window.open(`/api/v1/documents/${i.id}/download`, '_blank');
    };
    const view = i => {
        window.open(`/api/v1/documents/${i.id}/view`, '_blank');
    };

    const runOCR = i => {
        axios
            .post(`/api/v1/documents/${i.id}/run-ocr`)
            .then(() => {
                toast.success('OCR process started successfully');
                searchTrigger.value = Date.now();
            })
            .catch(e => {
                if (e.response?.data?.message)
                    toast.error(e.response.data.message);
                else toast.error('Something went wrong, please try again.');
            });
    };

    const runAI = i => {
        axios
            .post(`/api/v1/documents/${i.id}/run-ai`)
            .then(() => {
                toast.success('AI process started successfully');
                searchTrigger.value = Date.now();
            })
            .catch(e => {
                if (e.response?.data?.message)
                    toast.error(e.response.data.message);
                else toast.error('Something went wrong, please try again.');
            });
    };

    const downloadAllDocuments = () => {
        window.open(
            `/api/v1/corporations/${props.corporation.id}/documents/download-bulk`,
            '_blank'
        );
    };

    const destroy = i => {
        if (!confirm('Are you sure you want to delete this document?')) return;
        axios
            .delete(`/api/v1/documents/${i.id}`)
            .then(() => {
                toast.success('Document Deleted Successfully');
                searchTrigger.value = Date.now();
            })
            .catch(() => {
                toast.error('Something went wrong, please try again.');
            });
    };

    const runOCRAll = () => {
        axios
            .post(
                `/api/v1/corporations/${props.corporation.id}/documents/run-ocr-all`
            )
            .then(() => {
                toast.success(
                    "System will OCR all documents that are not already OCR'd"
                );
                searchTrigger.value = Date.now();
            })
            .catch(e => {
                if (e.response?.data?.message)
                    toast.error(e.response.data.message);
                else toast.error('Something went wrong, please try again.');
            });
    };

    const runAIAll = () => {
        axios
            .post(
                `/api/v1/corporations/${props.corporation.id}/documents/run-ai-all`
            )
            .then(() => {
                toast.success(
                    'System will AI process all documents that are not already processed by AI'
                );
                searchTrigger.value = Date.now();
            })
            .catch(e => {
                if (e.response?.data?.message)
                    toast.error(e.response.data.message);
                else toast.error('Something went wrong, please try again.');
            });
    };

    const isUpdateDocumentModalOpen = ref(false);
    const showUpdateModal = i => {
        selectedDocument.value = i;
        isUpdateDocumentModalOpen.value = true;
    };
    const closeUpdateDocumentModal = () => {
        selectedDocument.value = null;
        isUpdateDocumentModalOpen.value = false;
        searchTrigger.value = Date.now();
    };
    const showViewModal = i => {
        selectedDocument.value = i;
        isViewModalOpen.value = true;
    };
    const closeViewModal = () => {
        selectedDocument.value = null;
        isViewModalOpen.value = false;
    };

    const actions = () => {
        let baseActions = [
            {
                title: 'Edit',
                icon: 'mdi-pencil',
                buttonText: 'Edit',
                buttonColor: 'secondary',
                action: item => showUpdateModal(item),
            },
            {
                title: 'Download',
                icon: 'mdi-download',
                buttonText: 'Download',
                buttonColor: 'secondary',
                action: item => download(item),
            },
            {
                title: 'View AI Data',
                icon: 'mdi-eye',
                buttonText: 'View AI Data',
                buttonColor: 'primary',
                action: item => showViewModal(item),
            },
            {
                title: 'Run AI',
                icon: 'mdi-robot',
                buttonText: 'Run AI',
                buttonColor: 'info',
                action: item => runAI(item),
            },
            {
                title: 'Delete',
                icon: 'mdi-delete',
                buttonText: 'Delete',
                buttonColor: 'error',
                action: item => destroy(item),
            },
        ];

        if (!userIsSuperAdmin.value && !userIsAdmin.value) {
            baseActions = baseActions.filter(
                action => action.title !== 'Update' && action.title !== 'Delete'
            );
        }

        return baseActions;
    };

    const flagDocument = item => {
        const flaggedReason = prompt(
            'Please provide a reason for flagging this document:'
        );
        if (!flaggedReason) {
            toast.error('Flagging canceled. Reason is required.');
            return;
        }

        axios
            .post(`/api/v1/documents/${item.id}/flag`, {
                flagged_reason: flaggedReason,
            })
            .then(response => {
                toast.success('Document Flagged Successfully');
                searchTrigger.value = Date.now(); // cause the table to reload
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.');
            });
    };

    const unflagDocument = item => {
        axios
            .post(`/api/v1/documents/${item.id}/unflag`)
            .then(response => {
                toast.success('Document Unflagged Successfully');
                searchTrigger.value = Date.now(); // cause the table to reload
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.');
            });
    };
</script>

<template>
    <EditDocumentModal
        :item="selectedDocument"
        :isOpen="isUpdateDocumentModalOpen"
        @close="closeUpdateDocumentModal"
    />
    <UploadDocumentModal
        :corporation="corporation"
        :isOpen="isModalOpen"
        @close="closeModal"
    />
    <ViewDocumentModal
        :item="selectedDocument"
        :isOpen="isViewModalOpen"
        @close="closeViewModal"
    />

    <TableViewManager
        :title="`Documents`"
        :headers="headers"
        subtitle="This is where you can modify the Corporation's documents."
        :endpoint="`/api/v1/corporations/${corporation.id}/documents`"
        :searchTrigger="searchTrigger"
        :additionalParams="additionalParams"
        hide-breadcrumbs
        :dropdownActions="true"
        :actions="actions()"
    >
        <template #actions>
            <v-btn color="primary" class="px-4" @click="showUploadModal">
                Upload Document
                <v-icon class="self-center ml-2">mdi-upload</v-icon>
            </v-btn>
            <v-btn color="primary" class="px-4" @click="downloadAllDocuments">
                Download All
                <v-icon class="self-center ml-2">mdi-download</v-icon>
            </v-btn>
            <v-btn color="primary" class="px-4" @click="runOCRAll">
                Run OCR
                <v-icon class="self-center ml-2">mdi-text-recognition</v-icon>
            </v-btn>
            <v-btn color="primary" class="px-4" @click="runAIAll">
                Run AI
                <v-icon class="self-center ml-2">mdi-robot</v-icon>
            </v-btn>
        </template>

        <template #filters>
            <v-text-field
                v-model="additionalParams.search"
                label="Search"
                dense
                hide-details
            />
            <v-autocomplete
                v-model="additionalParams.documentType"
                :items="documentTypeOptions"
                label="Document Type"
                clearable
                dense
                hide-details
            />
        </template>
        <template #item.is_flagged="{ item }">
            <div>
                <v-icon
                    :color="item.is_flagged ? 'red' : 'grey-lighten-1'"
                    class="cursor-pointer hover:transform hover:scale-110"
                    v-tooltip="
                        item.is_flagged
                            ? `[${item.flagged_by ? `${item.flagged_by.first_name} ${item.flagged_by.last_name.charAt(0)}.` : 'System Flag'}] Reason: ${item.flagged_reason}`
                            : 'Flag'
                    "
                    @click="
                        item.is_flagged
                            ? unflagDocument(item)
                            : flagDocument(item)
                    "
                >
                    mdi-flag-variant
                </v-icon>
            </div>
        </template>
    </TableViewManager>
</template>
