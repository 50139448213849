<script setup>
    import { ref } from 'vue';
    import EditDocumentModal from '../Modals/EditDocumentModal.vue';
    import UploadReviewDocumentsModal from '../Modals/UploadReviewDocumentsModal.vue';

    import { useUserStore } from '@/stores/user';
    const userStore = useUserStore();
    const { userIsAdmin, userIsSuperAdmin } = storeToRefs(userStore);

    const props = defineProps({
        review: {
            type: Object,
            required: true,
        },
    });

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = [
        {
            title: 'Flag',
            key: 'is_flagged',
            sortable: true,
        },
        {
            title: 'Filename',
            key: 'original_name',
            value: item => item.original_name,
            sortable: true,
        },
        {
            title: 'Document Type',
            key: 'document_type',
            value: item => item.document_type.display_name,
            sortable: true,
        },
        {
            title: 'Uploaded At',
            key: 'created_at',
            value: item =>
                new Date(item.created_at).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                }),
            sortable: true,
        },
        {
            title: 'File Info',
            key: 'mime_type',
            value: i => {
                const mimeTypes = {
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                        'WORD DOC',
                    'application/msword': 'WORD DOC',
                    'application/pdf': 'PDF',
                    'image/jpeg': 'JPEG',
                    'image/png': 'PNG',
                    'image/gif': 'GIF',
                    'image/tiff': 'TIFF',
                };
                return (
                    mimeTypes[i.mime_type] ||
                    i.mime_type.replace('application/', '').toUpperCase()
                );
            },
            sortable: true,
        },
    ];

    const searchTrigger = ref(Date.now());

    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';

    const { documentTypeOptions } = storeToRefs(useOptionsStore());

    const optionsStore = useOptionsStore();
    const { fetchDocumentTypeOptions } = optionsStore;

    fetchDocumentTypeOptions();

    const additionalParams = ref({
        search: '',
        documentType: null,
    });

    const download = item => {
        window.open(`/api/v1/documents/${item.id}/download`, '_blank');
    };

    const view = item => {
        window.open(`/api/v1/documents/${item.id}/view`, '_blank');
    };

    const downloadAllDocuments = () => {
        window.open(
            `/api/v1/reviews/${props.review.id}/documents/download-bulk`,
            '_blank'
        );
    };

    const destroy = item => {
        if (
            !confirm(
                'Are you sure you want to delete this document? This action cannot be undone.'
            )
        ) {
            return;
        }

        axios
            .delete(`/api/v1/documents/${item.id}`)
            .then(response => {
                toast.success('Document Deleted Successfully');
                searchTrigger.value = Date.now(); // cause the table to reload
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.');
            });
    };

    /**************************************************************
     *      Modal
     **************************************************************/
    const isModalOpen = ref(false);
    const isUploadReviewDocumentsModalOpen = ref(false);
    const selectedDocument = ref(null);

    const showUpdateModal = item => {
        selectedDocument.value = item;
        isModalOpen.value = true;
    };

    const closeModal = () => {
        selectedDocument.value = null;
        isModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    const showUploadReviewDocumentsModal = () => {
        isUploadReviewDocumentsModalOpen.value = true;
    };

    const closeUploadReviewDocumentsModal = () => {
        isUploadReviewDocumentsModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    const actions = () => {
        let baseActions = [
            {
                title: 'Download',
                icon: 'mdi-download',
                buttonText: 'Download',
                buttonColor: 'secondary',
                action: item => download(item),
            },
            {
                title: 'View',
                icon: 'mdi-eye',
                buttonText: 'View',
                buttonColor: 'primary',
                action: item => view(item),
            },
            {
                title: 'Update',
                icon: 'mdi-pencil',
                buttonText: 'Edit',
                buttonColor: 'secondary',
                action: item => showUpdateModal(item),
            },
            {
                title: 'Delete',
                icon: 'mdi-delete',
                buttonText: 'Delete',
                buttonColor: 'error',
                action: item => destroy(item),
            },
        ];

        if (!userIsSuperAdmin.value && !userIsAdmin.value) {
            baseActions = baseActions.filter(
                action => action.title !== 'Update' && action.title !== 'Delete'
            );
        }

        return baseActions;
    };

    const flagDocument = item => {
        const flaggedReason = prompt(
            'Please provide a reason for flagging this document:'
        );
        if (!flaggedReason) {
            toast.error('Flagging canceled. Reason is required.');
            return;
        }

        axios
            .post(`/api/v1/documents/${item.id}/flag`, {
                flagged_reason: flaggedReason,
            })
            .then(response => {
                toast.success('Document Flagged Successfully');
                searchTrigger.value = Date.now(); // cause the table to reload
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.');
            });
    };

    const unflagDocument = item => {
        axios
            .post(`/api/v1/documents/${item.id}/unflag`)
            .then(response => {
                toast.success('Document Unflagged Successfully');
                searchTrigger.value = Date.now(); // cause the table to reload
            })
            .catch(error => {
                toast.error('Something went wrong, please try again.');
            });
    };
</script>
<template>
    <EditDocumentModal
        :item="selectedDocument"
        :isOpen="isModalOpen"
        @close="closeModal"
    />
    <UploadReviewDocumentsModal
        :isOpen="isUploadReviewDocumentsModalOpen"
        :review="props.review"
        @close="closeUploadReviewDocumentsModal"
    />
    <TableViewManager
        title="Documents"
        :headers="headers"
        subtitle="This is where you can view the Documents specific to this Review."
        :endpoint="`/api/v1/reviews/${props.review.id}/documents`"
        :searchTrigger="searchTrigger"
        :additionalParams="additionalParams"
        hide-breadcrumbs
        :dropdownActions="true"
        :actions="actions()"
    >
        <template #actions>
            <v-btn
                color="primary"
                class="px-4"
                @click="showUploadReviewDocumentsModal"
            >
                Upload Documents
                <v-icon class="self-center ml-2">mdi-upload</v-icon>
            </v-btn>
            <v-btn color="primary" class="px-4" @click="downloadAllDocuments">
                Download All
                <v-icon class="self-center ml-2">mdi-download</v-icon>
            </v-btn>
        </template>
        <template #filters>
            <v-text-field
                v-model="additionalParams.search"
                label="Search"
                dense
                hide-details
            ></v-text-field>
            <v-autocomplete
                v-model="additionalParams.documentType"
                :items="documentTypeOptions"
                label="Document Type"
                clearable
                dense
                hide-details
            />
        </template>
        <template #item.is_flagged="{ item }">
            <div>
                <v-icon
                    :color="item.is_flagged ? 'red' : 'grey-lighten-1'"
                    class="cursor-pointer hover:transform hover:scale-110"
                    v-tooltip="
                        item.is_flagged
                            ? `[${item.flagged_by ? `${item.flagged_by.first_name} ${item.flagged_by.last_name.charAt(0)}.` : 'System Flag'}] Reason: ${item.flagged_reason}`
                            : 'Flag'
                    "
                    @click="
                        item.is_flagged
                            ? unflagDocument(item)
                            : flagDocument(item)
                    "
                >
                    mdi-flag-variant
                </v-icon>
            </div>
        </template>
    </TableViewManager>
</template>
