<script setup>
    import { ref, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';
    import AISummarizer from '../AISummarizer.vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        item: Object,
        corporation: Object,
    });

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        const action = mode.value === 'Create' ? axios.post : axios.patch;
        const endpoint =
            mode.value === 'Create'
                ? `/api/v1/corporations/${props.corporation.id}/meeting-minutes`
                : `/api/v1/corporations/${props.corporation.id}/meeting-minutes/${record.value.id}`;

        const payload = {
            ...record.value,
        };

        action(endpoint, payload)
            .then(response => {
                emit('close');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            });
    };

    const record = ref({});
    const mode = ref('Create');

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});
    const defaultRecord = ref({
        date: null,
        type: 'regular',
        notes_raw_ai: '',
        notes_summary_ai: '',
    });

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/

    // Watch for changes on input record in order to set state for update or create modes
    watch(
        () => props.item,
        recordToUpdate => {
            if (recordToUpdate) {
                record.value = {
                    ...recordToUpdate,
                };
                mode.value = 'Update';
            } else {
                record.value = { ...defaultRecord.value };
                mode.value = 'Create';
            }
        }
    );

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};

            if (isOpen && mode.value === 'Create') {
                record.value = { ...defaultRecord.value };
            }
        }
    );

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };

    const handleRawTextUpdated = rawText => {
        record.value.notes_raw_ai = rawText;
    };

    const handleSummaryTextUpdated = summaryText => {
        record.value.notes_summary_ai = summaryText;
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :title="`${mode} Meeting Minutes`"
        @close="onClose"
        @save="onSave"
    >
        <div class="grid grid-cols-2 gap-4">
            <v-text-field
                v-model="record.date"
                label="Date"
                type="date"
                :error="validationErrors.date ? true : false"
                :error-messages="validationErrors.date"
            />

            <v-autocomplete
                v-model="record.type"
                :items="['regular', 'agm', 'extraordinary']"
                label="Meeting Type"
                class="col-span-full"
            />

            <AISummarizer
                what="Meeting Minutes"
                :initialRawText="record.notes_raw_ai"
                :initialSummaryText="record.notes_summary_ai"
                @rawTextUpdated="handleRawTextUpdated"
                @summaryUpdated="handleSummaryTextUpdated"
            />
        </div>
    </BaseModal>
</template>
