<template>
    <div class="p-8 max-w-[800px] mx-auto">
        <img class="max-w-40 p-2" src="/imgs/logo.png" />
        <div class="text-3xl font-bold mb-4">
            Condo Doc Review Privacy Policy
        </div>
        <div class="space-y-4">
            <p class="text-lg">
                We value your privacy. Condo Doc Review's policy is to respect
                your privacy and comply with any applicable law and regulation
                regarding any personal information we may collect about you,
                including on our website, condodocreview.com.
            </p>
            <p class="text-lg">
                Personal information is any information about you that can be
                used to identify you. This includes information about you as a
                person (such as name, address, and date of birth), your devices,
                payment details, and even information about how you use a
                website or online service.
            </p>
            <p class="text-lg">
                If our site contains links to third-party sites and services,
                please be aware that those sites and services have their own
                privacy policies. After following a link to any third-party
                content, you should read their posted privacy policy information
                about how they collect and use personal information. This
                Privacy Policy does not apply to your activities after leaving
                our site.
            </p>
            <p class="text-lg font-semibold">
                This policy is effective as of January 25, 2025
            </p>
            <h2 class="text-2xl font-bold mt-6">Information We Collect</h2>
            <p class="text-lg">
                Information we collect falls into “voluntarily provided”
                information and “automatically collected” information.
            </p>
            <p class="text-lg">
                “Voluntarily provided” refers to any information you knowingly
                and actively provide us when using or participating in our
                services and promotions.
            </p>
            <p class="text-lg">
                “Automatically collected” information refers to any information
                automatically sent by your devices when you access our products
                and services.
            </p>
            <h2 class="text-2xl font-bold mt-6">Log Data</h2>
            <p class="text-lg">
                When you visit our website, our servers may log data provided by
                your web browser. It may include your device's Internet Protocol
                (IP) address, browser type and version, the pages you visit on
                our website, the time and date of your visit, the time spent on
                each page, and other details about your visit.
            </p>
            <p class="text-lg">
                Additionally, if you encounter specific errors while using the
                site, we may automatically collect data about the error and its
                circumstances. This data may include technical details about
                your device, what you tried to do when the error occurred, and
                other technical information relating to the problem. You may or
                may not receive notice of such errors, even in the moment they
                occur, that they have occurred, or that the nature of the error
                is unknown.
            </p>
            <p class="text-lg">
                Please be aware that while this information may not be
                personally identifying by itself, it may be possible to combine
                it with other data to identify individual persons personally.
            </p>
            <h2 class="text-2xl font-bold mt-6">Personal Information</h2>
            <p class="text-lg">
                We may ask for personal information — for example, when you
                submit content to us or when you contact us — which may include
                one or more of the following:
            </p>
            <ul class="list-disc list-inside text-lg">
                <li>Form Submissions</li>
                <li>E-mail Inquiries</li>
                <li>Portal Registration</li>
                <li>Third Party Payment Service Providers</li>
            </ul>
            <h2 class="text-2xl font-bold mt-6">
                Legitimate Reasons for Processing Your Personal Information
            </h2>
            <p class="text-lg">
                We only collect and use your personal information for legitimate
                reasons. In this instance, we only collect personal information
                that is reasonably necessary to provide our services to you.
            </p>
            <h2 class="text-2xl font-bold mt-6">
                Collection and Use of Information
            </h2>
            <p class="text-lg">
                We may collect personal information from you when you do any of
                the following on our website:
            </p>
            <ul class="list-disc list-inside text-lg">
                <li>Access our content</li>
                <li>
                    Contact us via contact forms, email, social media, or
                    similar technologies
                </li>
                <li>Participate in surveys</li>
            </ul>
            <p class="text-lg">
                We may collect, hold, use, and disclose information for the
                following purposes, and personal information will not be further
                processed in a manner that is incompatible with these purposes:
            </p>
            <ul class="list-disc list-inside text-lg">
                <li>
                    To provide you with our platform's core features and
                    services
                </li>
                <li>
                    To enable you to customize or personalize your experience of
                    our website
                </li>
                <li>To contact and communicate with you</li>
                <li>
                    For analytics, market research, and business development,
                    including to operate and improve our website, associated
                    applications, and associated social media platforms
                </li>
                <li>For advertising and marketing</li>
            </ul>
            <p class="text-lg">
                We may combine voluntarily provided and automatically collected
                personal information with general information or research data
                we receive from other trusted sources. For example, our
                marketing and market research activities may uncover data and
                insights, which we may combine with information about how
                visitors use our site to improve it and your experience.
            </p>
        </div>
        <div class="space-y-4">
            <h2 class="text-2xl font-bold mt-6">
                Security of Your Personal Information
            </h2>
            <p class="text-lg">
                When we collect and process personal information and retain it,
                we will protect it within commercially acceptable means to
                prevent loss and theft, as well as unauthorized access,
                disclosure, copying, use, or modification.
            </p>
            <p class="text-lg">
                We will do our best to protect the personal information you
                provide to us, and we advise that no method of electronic
                transmission or storage is 100% secure. No one can guarantee
                absolute data security.
            </p>
            <p class="text-lg">
                You are responsible for selecting any password and its overall
                security strength, ensuring the security of your information
                within the bounds of our services. For example, ensuring any
                passwords associated with accessing your personal information
                and accounts are secure and confidential.
            </p>
            <h2 class="text-2xl font-bold mt-6">
                How Long We Keep Your Personal Information
            </h2>
            <p class="text-lg">
                We keep your personal information only for as long as we need
                to. This time may depend on what we use your information for
                under this privacy policy. For example, if you have provided us
                with personal information as part of creating an account, we may
                retain this information for the duration your account exists on
                our system. If your personal information is no longer required
                for this purpose, we will delete it or make it anonymous by
                removing all details that identify you.
            </p>
            <p class="text-lg">
                However, if necessary, we may retain your personal information
                for our compliance with a legal, accounting, or reporting
                obligation or for archiving purposes in the public interest,
                historical research purposes, or statistical purposes.
            </p>
            <h2 class="text-2xl font-bold mt-6">
                Disclosure of Personal Information to Third Parties
            </h2>
            <p class="text-lg">We may disclose personal information to:</p>
            <ul class="list-disc list-inside text-lg">
                <li>
                    third-party service providers to enable them to provide
                    their services
                </li>
                <li>our employees, contractors, and/or related entities</li>
                <li>our existing or potential agents or business partners</li>
            </ul>
            <p class="text-lg">Third parties we currently use include:</p>
            <ul class="list-disc list-inside text-lg">
                <li>Google Analytics</li>
                <li>social media platforms</li>
                <li>Adwords</li>
            </ul>
            <h2 class="text-2xl font-bold mt-6">
                International Transfers of Personal Information
            </h2>
            <p class="text-lg">
                The personal information we collect is stored and/or processed
                where we or our partners, affiliates, and third-party providers
                maintain facilities. The countries to which we store, process,
                or transfer your personal information may not have the same data
                protection laws as the country in which you initially provided
                the information. If we transfer your personal information to
                third parties in other countries: (i) we will perform those
                transfers in accordance with the requirements of applicable law,
                and (ii) we will protect the transferred personal information in
                accordance with this privacy policy.
            </p>
            <h2 class="text-2xl font-bold mt-6">
                Your Rights and Controlling Your Personal Information
            </h2>
            <p class="text-lg">
                Your choice: By providing personal information to us, you
                understand we will collect, hold, use, and disclose your
                personal information in accordance with this privacy policy. You
                do not have to provide personal information to us. However, if
                you do not, it may affect your use of our website or the
                products and/or services offered on or through it.
            </p>
            <p class="text-lg">
                Information from third parties: If we receive personal
                information about you from a third party, we will protect it as
                set out in this privacy policy. If you are a third party
                providing personal information about somebody else, you
                represent and warrant that you have such person's consent to
                provide the personal information to us.
            </p>
            <p class="text-lg">
                Marketing permission: If you have previously agreed to allow us
                to use your personal information for direct marketing purposes,
                you may change your mind in the future and cancel by contacting
                us using the details below.
            </p>
            <p class="text-lg">
                Access: You may request details of the personal information we
                hold about you.
            </p>
            <p class="text-lg">
                Correction: If you believe that any information we hold about
                you is inaccurate, out of date, incomplete, irrelevant, or
                misleading, please contact us using the details provided in this
                privacy policy. We will take reasonable steps to correct any
                information found to be inaccurate, incomplete, misleading, or
                out of date.
            </p>
            <p class="text-lg">
                Non-discrimination: We will not discriminate against you for
                exercising your rights over your personal information. Unless
                your personal information is required to provide you with a
                particular service or offer (for example, providing user
                support).
            </p>
            <p class="text-lg">
                Downloading of Personal Information: We provide a means for you
                to download the personal information you have shared through our
                site. Please contact us for more information.
            </p>
            <p class="text-lg">
                Notification of data breaches: We will comply with laws
                applicable to us regarding any data breach.
            </p>
            <p class="text-lg">
                Complaints: If you believe that we have breached a relevant data
                protection law and wish to make a complaint, please contact us
                using the details below and provide full details of the alleged
                breach. We will promptly investigate your complaint and respond
                to you in writing, setting out the outcome of our investigation
                and the steps we will take to deal with your complaint. You also
                have the right to contact a regulatory body or data protection
                authority concerning your complaint.
            </p>
            <p class="text-lg">
                Unsubscribe: To unsubscribe from our email database or opt out
                of communications (including marketing communications), please
                contact us using the details provided in this privacy policy or
                opt out using the opt-out facilities provided in the
                communication. We may need to request specific information from
                you to help us confirm your identity.
            </p>
            <h2 class="text-2xl font-bold mt-6">Business Transfers</h2>
            <p class="text-lg">
                If we or our assets are acquired, or in the unlikely event that
                we go out of business or enter bankruptcy, we would include
                data, including your personal information, among the assets
                transferred to any parties who acquire us. You acknowledge that
                such transfers may occur and that any parties who acquire us
                may, to the extent permitted by applicable law, continue to use
                your personal information according to this policy, which they
                will be required to assume as it is the basis for any ownership
                or use rights we have over such information.
            </p>
            <h2 class="text-2xl font-bold mt-6">Limits of Our Policy</h2>
            <p class="text-lg">
                Our website may link to external sites that we do not operate.
                Please be aware that we have no control over the content and
                policies of those sites and cannot accept responsibility or
                liability for their respective privacy practices.
            </p>
            <h2 class="text-2xl font-bold mt-6">Changes to This Policy</h2>
            <p class="text-lg">
                We may change our privacy policy to reflect updates to our
                business processes, current acceptable practices, or legislative
                or regulatory changes. If we decide to change this privacy
                policy, we will post the changes here at the same link by which
                you are accessing this privacy policy.
            </p>
            <h2 class="text-2xl font-bold mt-6">
                Additional Disclosures for Personal Information Protection and
                Electronic Documents Act (PIPEDA) Compliance (Canada)
            </h2>
            <h3 class="text-xl font-semibold mt-4">Customer Data Rights</h3>
            <p class="text-lg">
                Although PIPEDA does not contain an extensive set of consumer
                rights, it does grant consumers the right to:
            </p>
            <ul class="list-disc list-inside text-lg">
                <li>
                    Access the personal information organizations hold about
                    them;
                </li>
                <li>
                    Correct any inaccurate or outdated personal information the
                    organization hold about them (or, if this is not possible,
                    delete the inaccurate personal information);
                </li>
                <li>
                    Withdraw consent for any activities for which they have
                    consented (e.g. direct marketing or cookies).
                </li>
            </ul>
            <h3 class="text-xl font-semibold mt-4">
                Right to Withdraw Consent
            </h3>
            <p class="text-lg">
                You give us consent to collect and use your personal information
                for a specific purpose. Subject to some restrictions, you can,
                at any time, refuse to consent or continue to consent to the
                collection, use, or disclosure of your personal information by
                notifying us using the email address below in the ‘Contact Us'
                section. Withdrawal of consent may impact our ability to provide
                or continue to provide services.
            </p>
            <p class="text-lg">
                Customers cannot refuse collection, use, and disclosure of their
                personal information if such information is required to:
            </p>
            <ul class="list-disc list-inside text-lg">
                <li>
                    be collected, used, or disclosed as required by any law;
                </li>
                <li>fulfill the terms of any contractual agreement; and</li>
                <li>
                    be collected, used or disclosed as required by any
                    regulators, including self-regulatory organizations.
                </li>
            </ul>
            <p class="text-lg">
                While you may request that we delete your contact details
                anytime, we cannot recall any email we have already sent. If you
                have any further inquiries about how to withdraw your consent,
                please feel free to enquire using the details provided in the
                Contact Us section of this privacy policy.
            </p>
            <h3 class="text-xl font-semibold mt-4">
                Right of Access under PIPEDA
            </h3>
            <p class="text-lg">
                PIPEDA gives you a general right to access the PII held by
                businesses subject to this law. Under PIPEDA, you must make your
                access request in writing and pay a minimal fee of $30.00.
            </p>
            <p class="text-lg">
                You have the right to complain about organizational fees that
                seem unjust. We retain the right to decide how we disclose the
                copies of your PII to you. We will take all necessary measures
                to fulfill your request within 30 days of receipt. Otherwise, we
                must inform you of our inability to do so before the 30-day
                timeframe if:
            </p>
            <ul class="list-disc list-inside text-lg">
                <li>
                    Meeting the time limit would unreasonably interfere with our
                    business activities or
                </li>
                <li>
                    The time required to undertake consultations necessary to
                    respond to the request would make it impractical to meet the
                    time limit.
                </li>
            </ul>
            <p class="text-lg">
                We can also extend the time limit for the length of time
                required to convert the personal information into an alternative
                format. In these circumstances, we will advise you of the delay
                within the first 30 days and explain its reason.
            </p>
            <h3 class="text-xl font-semibold mt-4">
                Right of rectification under PIPEDA
            </h3>
            <p class="text-lg">
                You may request a correction to any factual errors or omissions
                within your PII. Please provide some evidence to back up your
                claim. Under PIPEDA, an organization must amend the information,
                as required, if you successfully demonstrate that it's
                incomplete or inaccurate.
            </p>
            <p class="text-lg">
                You may contact us at any time, using the information provided
                in the Contact Us section of this privacy policy, if you believe
                your PII on our systems is incorrect or incomplete.
            </p>
            <p class="text-lg">
                If we cannot agree on changing the information, you have the
                right to have your concerns recorded with the Office of the
                Privacy Commission of Canada.
            </p>
            <h3 class="text-xl font-semibold mt-4">
                Compliance with PIPEDA's Ten Principles of Privacy
            </h3>
            <p class="text-lg">
                This privacy policy complies with the PIPEDA's requirements and
                ten principles of privacy, which are as follows:
            </p>
            <ul class="list-disc list-inside text-lg">
                <li>
                    <span class="font-semibold">Accountability:</span>
                    Condo Doc Review Ltd. is responsible for the PII under its
                    control and will designate one or more persons to ensure
                    organizational accountability for compliance with the ten
                    privacy principles under PIPEDA. All personnel are
                    accountable for the protection of customers' personal
                    information.
                </li>
                <li>
                    <span class="font-semibold">Identifying purposes:</span>
                    Condo Doc Review Ltd. identifies the purposes for which
                    personal information is collected at or before the time the
                    information is collected.
                </li>
                <li>
                    <span class="font-semibold">Consent:</span>
                    Consent is required for Condo Doc Review Ltd.'s collection,
                    use, or disclosure of personal information, except where
                    required or permitted by PIPEDA or other law. In addition,
                    when customers access a product or service we offer, consent
                    is deemed to be granted. Express consent may be obtained
                    verbally, in writing, or electronically. Alternatively,
                    consent may be implied through customers' actions or
                    continued use of a product or service following Condo Doc
                    Review Ltd.'s notification of changes.
                </li>
                <li>
                    <span class="font-semibold">Limiting collection:</span>
                    Personal information collected will be limited to what is
                    necessary for the purposes identified by Condo Doc Review
                    Ltd.
                </li>
                <li>
                    <span class="font-semibold">
                        Limiting use, disclosure, and retention:
                    </span>
                    We will not use or disclose personal information for
                    purposes other than those for which the information was
                    collected, except with your consent or as required by law.
                    We will retain personal information only as long as
                    necessary to fulfill the purposes for collecting such
                    information and comply with legal requirements.
                </li>
                <li>
                    <span class="font-semibold">Accuracy:</span>
                    Personal information will be maintained by Condo Doc Review
                    Ltd. only in an accurate, complete, and up-to-date format as
                    is necessary for the purpose(s) for which the personal
                    information was collected.
                </li>
                <li>
                    <span class="font-semibold">Safeguards:</span>
                    We will protect personal information with security
                    safeguards appropriate to the sensitivity of such
                    information.
                </li>
                <li>
                    <span class="font-semibold">Openness:</span>
                    We will make our policies and practices for collecting and
                    managing personal information readily available upon
                    request, including our brochures or other information that
                    explains our policies.
                </li>
                <li>
                    <span class="font-semibold">Customer access:</span>
                    We will inform customers of the existence, use, and
                    disclosure of their personal information and provide access
                    to it, subject to legal restrictions. We may require written
                    requests for access to personal information and, in most
                    cases, will respond within 30 days of receipt of such
                    requests. Customers may verify the accuracy and completeness
                    of their personal information and may request the personal
                    information be corrected or updated, if appropriate.
                </li>
                <li>
                    <span class="font-semibold">Challenging compliance:</span>
                    Customers are welcome to direct any questions or inquiries
                    concerning our compliance with this privacy policy and
                    PIPEDA requirements using the contact information provided
                    in the Contact Us section of this privacy policy.
                </li>
            </ul>
            <h3 class="text-xl font-semibold mt-4">
                Inquiries, Reports, and Escalation
            </h3>
            <p class="text-lg">
                To enquire about Condo Doc Review Ltd's privacy policy or to
                report user privacy violations, you may contact us using the
                details in the Contact Us section of this privacy policy.
            </p>
            <p class="text-lg">
                If we fail to resolve your concern to your satisfaction, you may
                also contact the Office of the Privacy Commissioner of Canada:
            </p>
            <address class="text-lg">
                30 Victoria Street
                <br />
                Gatineau, QC K1A 1H3
                <br />
                Toll Free: 1.800.282.1376
                <br />
                <a
                    href="https://www.priv.gc.ca"
                    class="text-blue-500 underline"
                >
                    www.priv.gc.ca
                </a>
            </address>
            <h3 class="text-xl font-semibold mt-4">Contact Us</h3>
            <p class="text-lg">
                For any questions or concerns regarding your privacy, you may
                contact us using the following details:
            </p>
            <address class="text-lg">
                Strider White
                <br />
                Data Protection Officer
                <br />
                Condo Doc Review Ltd.
                <br />
                <a
                    href="mailto:info@condodocreview.com"
                    class="text-blue-500 underline"
                >
                    info@condodocreview.com
                </a>
            </address>
        </div>
    </div>
</template>
