<script setup>
    import { ref, computed } from 'vue';
    const emit = defineEmits(['refresh']);

    import { useDisplay } from 'vuetify';
    const { smAndDown } = useDisplay();

    const props = defineProps({
        review: {
            type: Object,
            required: true,
        },
    });

    const loading = ref(false);

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});

    const form = ref({
        ...props.review,
    });

    /**************************************************************
     *      Maska
     **************************************************************/
    import { vMaska } from 'maska/vue';
    const unmaskedApproxSquareFootage = ref(form.value.approx_square_footage);
    const unmaskedUnitUnitFactors = ref(form.value.unit_unit_factors);
    const unmaskedParkingUnitFactors = ref(form.value.parking_unit_factors);
    const unmaskedStorageUnitFactors = ref(form.value.storage_unit_factors);
    const unmaskedUnitNumber = ref(form.value.unit_number);
    const unmaskedLegalUnitNumber = ref(form.value.legal_unit_number);

    defineExpose({
        unmaskedApproxSquareFootage,
        unmaskedUnitUnitFactors,
        unmaskedUnitNumber,
        unmaskedLegalUnitNumber,
        unmaskedParkingUnitFactors,
        unmaskedStorageUnitFactors,
    });

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';

    const optionsStore = useOptionsStore();
    const { fetchCorporationOptions, fetchProvinceOptions } = optionsStore;
    const { corporationOptions } = storeToRefs(optionsStore);
    fetchCorporationOptions();

    /**************************************************************
     *      Update Review
     **************************************************************/
    const updateReview = () => {
        loading.value = true;
        validationErrors.value = {};

        const payload = {
            corporation_id: form.value.corporation_id,
            unit_number: unmaskedUnitNumber.value,
            municipal_address: form.value.municipal_address,
            legal_unit_number: unmaskedLegalUnitNumber.value,
            legal_unit_number_parking: form.value.legal_unit_number_parking,
            legal_unit_number_storage: form.value.legal_unit_number_storage,
            unit_unit_factors: unmaskedUnitUnitFactors.value,
            parking_unit_factors: unmaskedParkingUnitFactors.value,
            storage_unit_factors: unmaskedStorageUnitFactors.value,
            parking_stall_number: form.value.parking_stall_number,
            parking_is_titled: form.value.parking_is_titled,
            storage_is_titled: form.value.storage_is_titled,
            storage_stall_number: form.value.storage_stall_number,
            property_tax: form.value.property_tax,
            condo_fees: form.value.condo_fees,
            outstanding_levy_amount: form.value.outstanding_levy_amount,
            list_price: form.value.list_price,
            approx_square_footage: unmaskedApproxSquareFootage.value,
            footnotes: form.value.footnotes,
        };

        axios
            .post(`/api/v1/reviews/${form.value.id}`, payload)
            .then(response => {
                toast.open({
                    message: 'Review updated successfully!',
                    type: 'success',
                });

                emit('refresh');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            })
            .finally(() => {
                loading.value = false;
            });
    };

    const totalUnitFactors = computed(() => {
        return (
            (isNaN(Number(unmaskedUnitUnitFactors.value))
                ? 0
                : Number(unmaskedUnitUnitFactors.value)) +
            (isNaN(Number(unmaskedParkingUnitFactors.value))
                ? 0
                : Number(unmaskedParkingUnitFactors.value)) +
            (isNaN(Number(unmaskedStorageUnitFactors.value))
                ? 0
                : Number(unmaskedStorageUnitFactors.value))
        );
    });

    /**************************************************************
     *      Misc
     **************************************************************/
    import { useUserStore } from '@/stores/user';
    const userStore = useUserStore();
    const { userIsAdmin, userIsContractor } = storeToRefs(userStore);
</script>
<template>
    <div class="max-w-[1000px] p-4 border rounded-lg shadow-lg mb-12">
        <form
            @submit.prevent="updateReview"
            :class="smAndDown ? 'grid-cols-1' : 'grid-cols-2'"
            class="grid gap-4"
        >
            <div class="col-span-full text-xl font-semibold mb-4">
                Unit Information
            </div>
            <v-autocomplete
                v-model="form.corporation_id"
                :items="corporationOptions"
                label="Corporation"
                required
                outlined
                :error="validationErrors.corporation_id ? true : false"
                :error-messages="validationErrors.corporation_id"
                :loading="loading"
            />

            <v-text-field
                v-model="form.unit_number"
                label="Unit Number"
                required
                outlined
                :error="validationErrors.unit_number ? true : false"
                :error-messages="validationErrors.unit_number"
                v-maska:unmaskedUnitNumber.unmasked="'######'"
                :loading="loading"
            />

            <v-text-field
                v-model="form.municipal_address"
                label="Municipal Address (If Applicable)"
                required
                outlined
                :error="validationErrors.municipal_address ? true : false"
                :error-messages="validationErrors.municipal_address"
                :loading="loading"
            />

            <v-text-field
                v-model="form.legal_unit_number"
                label="Legal Unit Number (Unit)"
                required
                outlined
                :error="validationErrors.legal_unit_number ? true : false"
                :error-messages="validationErrors.legal_unit_number"
                v-maska:unmaskedLegalUnitNumber.unmasked="'######'"
                :loading="loading"
            />

            <div class="col-span-full text-xl font-semibold mb-4">Metrics</div>
            <CurrencyInput
                v-model="form.condo_fees"
                label="Condo Fees (Monthly)"
                required
                outlined
                :error="validationErrors.condo_fees ? true : false"
                :error-messages="validationErrors.condo_fees"
                :loading="loading"
            />

            <v-text-field
                v-model="form.approx_square_footage"
                label="Approximate Square Footage"
                required
                type="number"
                outlined
                :error="validationErrors.approx_square_footage ? true : false"
                :error-messages="validationErrors.approx_square_footage"
                v-maska:unmaskedApproxSquareFootage.unmasked="'######'"
                :loading="loading"
            />

            <CurrencyInput
                v-model="form.list_price"
                label="List Price"
                required
                outlined
                :error="validationErrors.list_price ? true : false"
                :error-messages="validationErrors.list_price"
                :loading="loading"
            />

            <CurrencyInput
                v-model="form.property_tax"
                label="Property Tax"
                required
                outlined
                :error="validationErrors.property_tax ? true : false"
                :error-messages="validationErrors.property_tax"
                :loading="loading"
            />

            <CurrencyInput
                v-model="form.outstanding_levy_amount"
                label="Outstanding Levy Amount"
                required
                outlined
                :error="validationErrors.outstanding_levy_amount ? true : false"
                :error-messages="validationErrors.outstanding_levy_amount"
                :loading="loading"
            />
            <div class="col-span-full text-xl font-semibold my-4">Parking</div>

            <v-text-field
                v-model="form.parking_stall_number"
                label="Parking Stall Number"
                type="text"
                required
                outlined
                :error="validationErrors.parking_stall_number ? true : false"
                :error-messages="validationErrors.parking_stall_number"
            />

            <v-checkbox
                v-model="form.parking_is_titled"
                label="Parking is Titled?"
                required
                :error="validationErrors.parking_is_titled ? true : false"
                :error-messages="validationErrors.parking_is_titled"
            />

            <v-text-field
                :disabled="!form.parking_is_titled"
                v-model="form.legal_unit_number_parking"
                label="Parking Legal Number"
                type="text"
                required
                outlined
                :error="
                    validationErrors.legal_unit_number_parking ? true : false
                "
                :error-messages="validationErrors.legal_unit_number_parking"
            />

            <div class="col-span-full text-xl font-semibold my-4">Storage</div>

            <v-text-field
                v-model="form.storage_stall_number"
                label="Storage Stall Number"
                type="text"
                required
                outlined
                :error="validationErrors.storage_stall_number ? true : false"
                :error-messages="validationErrors.storage_stall_number"
            />

            <v-checkbox
                v-model="form.storage_is_titled"
                label="Storage is Titled?"
                required
                :error="validationErrors.storage_is_titled ? true : false"
                :error-messages="validationErrors.storage_is_titled"
            />

            <v-text-field
                :disabled="!form.storage_is_titled"
                v-model="form.legal_unit_number_storage"
                label="Storage Legal Number"
                type="text"
                required
                outlined
                :error="
                    validationErrors.legal_unit_number_storage ? true : false
                "
                :error-messages="validationErrors.legal_unit_number_storage"
                :loading="loading"
            />

            <div
                class="col-span-full grid grid-cols-3 gap-4 p-4 rounded-lg shadow mb-4 border"
            >
                <div class="font-medium text-sm col-span-full">
                    Unit Factor Distribution
                </div>
                <v-text-field
                    v-model="form.unit_unit_factors"
                    label="Unit's Unit Factors"
                    type="number"
                    :error="validationErrors.unit_unit_factors ? true : false"
                    :error-messages="validationErrors.unit_unit_factors"
                    v-maska:unmaskedUnitUnitFactors.unmasked="'#####'"
                    :loading="loading"
                />
                <v-text-field
                    v-model="form.storage_unit_factors"
                    label="Storage Unit Factors"
                    type="number"
                    :error="
                        validationErrors.storage_unit_factors ? true : false
                    "
                    :error-messages="validationErrors.storage_unit_factors"
                    v-maska:unmaskedStorageUnitFactors.unmasked="'#####'"
                    :loading="loading"
                />
                <v-text-field
                    v-model="form.parking_unit_factors"
                    label="Parking Unit Factors"
                    type="number"
                    :error="
                        validationErrors.parking_unit_factors ? true : false
                    "
                    :error-messages="validationErrors.parking_unit_factors"
                    v-maska:unmaskedParkingUnitFactors.unmasked="'#####'"
                    :loading="loading"
                />
                <div class="col-span-full -mt-4 mb-4 text-sm">
                    <div class="text-xs">Total Unit Factors</div>
                    {{ totalUnitFactors }}
                </div>
            </div>

            <div class="col-span-full text-xl font-semibold">
                Report Footnotes
            </div>
            <div class="col-span-full text-base">
                These footnotes appear on the bottom of the client's report.
                <span class="font-bold underline">
                    These are not internal notes.
                </span>
            </div>
            <v-textarea
                class="col-span-full"
                v-model="form.footnotes"
                label="Footnotes - appears on report"
                outlined
                :error="validationErrors.footnotes ? true : false"
                :error-messages="validationErrors.footnotes"
            />

            <div>
                <v-btn @click="updateReview" color="primary" :loading="loading">
                    Save
                </v-btn>
            </div>
        </form>
    </div>
</template>
