<script setup>
    import { ref, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';
    import AISummarizer from '../AISummarizer.vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        item: Object,
        corporation: Object,
    });

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        const action = mode.value === 'Create' ? axios.post : axios.patch;
        const endpoint =
            mode.value === 'Create'
                ? `/api/v1/corporations/${props.corporation.id}/litigations`
                : `/api/v1/corporations/${props.corporation.id}/litigations/${record.value.id}`;

        const payload = {
            ...record.value,
        };

        action(endpoint, payload)
            .then(response => {
                emit('close');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            });
    };

    const record = ref({});
    const mode = ref('Create');

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});
    const defaultRecord = ref({
        year: '',
        reason: '',
        is_suing: false,
        is_resolved: false,

        notes_raw_ai: '',
        notes_summary_ai: '',
    });

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/

    // Watch for changes on input record in order to set state for update or create modes
    watch(
        () => props.item,
        recordToUpdate => {
            if (recordToUpdate) {
                record.value = {
                    ...recordToUpdate,
                };
                mode.value = 'Update';
            } else {
                record.value = { ...defaultRecord.value };
                mode.value = 'Create';
            }
        }
    );

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};

            if (isOpen && mode.value === 'Create') {
                record.value = { ...defaultRecord.value };
            }
        }
    );

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };

    const handleRawTextUpdated = rawText => {
        record.value.notes_raw_ai = rawText;
    };

    const handleSummaryTextUpdated = summaryText => {
        record.value.notes_summary_ai = summaryText;
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :title="`${mode} Litigation`"
        @close="onClose"
        @save="onSave"
    >
        <div class="grid grid-cols-2 gap-4">
            <v-autocomplete
                v-model="record.year"
                label="Year"
                placeholder="Year"
                :items="
                    Array.from(
                        { length: 110 },
                        (_, i) => new Date().getFullYear() - i + 5
                    )
                "
                :error="validationErrors.year ? true : false"
                :error-messages="validationErrors.year"
            />

            <v-text-field
                v-model="record.reason"
                label="Reason"
                type="text"
                :error="validationErrors.reason ? true : false"
                :error-messages="validationErrors.reason"
            />

            <v-checkbox
                v-model="record.is_suing"
                label="Is Suing"
                required
                :error="validationErrors.is_suing ? true : false"
                :error-messages="validationErrors.is_suing"
            />

            <v-checkbox
                v-model="record.is_resolved"
                label="Is Resolved"
                required
                :error="validationErrors.is_resolved ? true : false"
                :error-messages="validationErrors.is_resolved"
            />

            <AISummarizer
                what="Litigation"
                :initialRawText="record.notes_raw_ai"
                :initialSummaryText="record.notes_summary_ai"
                @rawTextUpdated="handleRawTextUpdated"
                @summaryUpdated="handleSummaryTextUpdated"
            />
        </div>
    </BaseModal>
</template>
