<script setup>
    import { computed, ref, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';
    import AISummarizer from '../AISummarizer.vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        item: Object,
        corporation: Object,
    });

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    import CurrencyInput from '../Inputs/CurrencyInput.vue';
    const toast = useToast();

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';
    const optionsStore = useOptionsStore();
    const { fetchBalanceTypeOptions } = optionsStore;

    const { balanceTypeOptions } = storeToRefs(optionsStore);

    // Fetch options
    fetchBalanceTypeOptions();

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        const action = mode.value === 'Create' ? axios.post : axios.patch;
        const endpoint =
            mode.value === 'Create'
                ? `/api/v1/corporations/${props.corporation.id}/balances`
                : `/api/v1/corporations/${props.corporation.id}/balances/${record.value.id}`;

        const payload = {
            ...record.value,
        };

        action(endpoint, payload)
            .then(response => {
                emit('close');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            });
    };

    const record = ref({});
    const mode = ref('Create');

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});
    const defaultRecord = ref({
        date: null,
        type_id: null,
        balance: 0,
        due_to: 0,
        due_from: 0,
        receivables: 0,
        loan_receivables: 0,
        notes_raw_ai: '',
        notes_summary_ai: '',
    });

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/

    // Watch for changes on input record in order to set state for update or create modes
    watch(
        () => props.item,
        recordToUpdate => {
            if (recordToUpdate) {
                record.value = {
                    ...recordToUpdate,
                };
                mode.value = 'Update';
            } else {
                record.value = { ...defaultRecord.value };
                mode.value = 'Create';
            }
        }
    );

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};

            if (isOpen && mode.value === 'Create') {
                const operatingType = balanceTypeOptions.value.find(
                    option => option.title === 'Operating'
                );
                record.value = { ...defaultRecord.value };
                record.value.type_id = operatingType?.value;
            }
        }
    );

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };

    const typeIsOperating = computed(() => {
        const operatingType = balanceTypeOptions.value.find(
            option => option.title === 'Operating'
        );

        return record.value.type_id === operatingType?.value;
    });

    const handleRawTextUpdated = rawText => {
        record.value.notes_raw_ai = rawText;
    };

    const handleSummaryTextUpdated = summaryText => {
        record.value.notes_summary_ai = summaryText;
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :title="`${mode} Balance`"
        @close="onClose"
        @save="onSave"
    >
        <div class="grid grid-cols-2 gap-4">
            <v-autocomplete
                v-model="record.type_id"
                :items="balanceTypeOptions"
                label="Balance Type"
                :clearable="false"
                :error="validationErrors.type_id ? true : false"
                :error-messages="validationErrors.type_id"
            />

            <v-text-field
                v-model="record.date"
                label="As of Date"
                type="date"
                :error="validationErrors.date ? true : false"
                :error-messages="validationErrors.date"
            />

            <CurrencyInput
                class="col-span-full"
                v-model="record.balance"
                label="Balance"
                :error="validationErrors.balance ? true : false"
                :error-messages="validationErrors.balance"
            />

            <CurrencyInput
                v-model="record.due_to"
                :label="
                    typeIsOperating
                        ? 'Due To Operating From Reserve (Incoming)'
                        : 'Due To Reserve From Operating (Incoming)'
                "
                :error="validationErrors.due_to ? true : false"
                :error-messages="validationErrors.due_to"
            />

            <CurrencyInput
                v-model="record.due_from"
                :label="
                    typeIsOperating
                        ? 'Due From Operating To Reserve (Outgoing)'
                        : 'Due From Reserve To Operating (Outgoing)'
                "
                :error="validationErrors.due_from ? true : false"
                :error-messages="validationErrors.due_from"
            />

            <CurrencyInput
                v-if="typeIsOperating"
                v-model="record.receivables"
                label="Receivables To Operating"
                :error="validationErrors.receivables ? true : false"
                :error-messages="validationErrors.receivables"
            />

            <CurrencyInput
                v-if="typeIsOperating"
                v-model="record.loan_receivables"
                label="Loan Receivables To Operating"
                :error="validationErrors.loan_receivables ? true : false"
                :error-messages="validationErrors.loan_receivables"
            />

            <!-- <AISummarizer
                what="Balance Sheet"
                :initialRawText="record.notes_raw_ai"
                :initialSummaryText="record.notes_summary_ai"
                @rawTextUpdated="handleRawTextUpdated"
                @summaryUpdated="handleSummaryTextUpdated"
            /> -->
        </div>
    </BaseModal>
</template>
