<script setup>
    import { ref, computed, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        item: Object,
    });

    /**************************************************************
     *      Form
     **************************************************************/
    const record = ref({});
    const mode = ref('Create');
    const validationErrors = ref({});
    const defaultRecord = ref({
        name: null,
        notes: null,
    });

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        const action = mode.value === 'Create' ? axios.post : axios.patch;
        const endpoint =
            mode.value === 'Create'
                ? '/api/v1/developers'
                : `/api/v1/developers/${record.value.id}`;

        const payload = {
            ...record.value,
        };

        action(endpoint, payload)
            .then(response => {
                record.value = { ...defaultRecord.value };
                emit('close');
            })
            .catch(error => {
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            });
    };

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/

    // Watch for changes on input record in order to set state for update or create modes
    watch(
        () => props.item,
        recordToUpdate => {
            if (recordToUpdate) {
                record.value = {
                    ...recordToUpdate,
                };
                mode.value = 'Update';
            } else {
                record.value = { ...defaultRecord.value };
                mode.value = 'Create';
            }
        }
    );

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};
        }
    );

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :title="`${mode} Developer`"
        @close="onClose"
        @save="onSave"
    >
        <div class="grid grid-cols-2 gap-4">
            <v-text-field
                v-model="record.name"
                label="Name"
                type="text"
                :error="validationErrors.name ? true : false"
                :error-messages="validationErrors.name"
            />

            <v-textarea
                v-model="record.notes"
                label="Notes"
                type="text"
                required
                class="col-span-full"
                outlined
                :error="validationErrors.notes ? true : false"
                :error-messages="validationErrors.notes"
            />
        </div>
    </BaseModal>
</template>
